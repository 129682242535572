
<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add City to Operations'"
              [subTitle]="'You can add cities to operations from here'"></app-top-title>
          </div>
          <div class="bg-white">

            <form class="" role="form" [formGroup]="cityForm" (ngSubmit)="updateCity()">
              <div class="row p-4">

                <div class="col-md-5" style="width: 100%;">
                  <div class="form-group">
                    <label class="col-form-label" for="country">Country <span style="color:#ff0000">*</span></label>
                    <ng-multiselect-dropdown name="country" id="selCountry" [placeholder]="'Select Country'" [data]="countries"
                                             [settings]="countryDropdownSettings" [formControl]="cityForm.controls['country']"
                                             [class.valid]="cityForm.get('country').valid &&
                                                                (cityForm.get('country').dirty || cityForm.get('country').touched)"
                                             [class.invalid]="cityForm.get('country').invalid &&
                                                                (cityForm.get('country').dirty || cityForm.get('country').touched)">
                    </ng-multiselect-dropdown>
                    <div
                      *ngIf="cityForm.get('country').invalid &&
                                                                   cityForm.get('country').errors &&
                                                                   (cityForm.get('country').dirty || cityForm.get('country').touched)">
                      <small class="text-danger" *ngIf="cityForm.get('country').hasError('required')">
                        Country is required. </small>
                    </div>
                  </div>
                </div>
                <div class="col-md-5" style="width: 100%;">
                  <div class="form-group">
                    <label class="col-form-label" for="city">City<span style="color:#ff0000">*</span></label>
                    <input placeholder="Enter City Name" type="text" class="form-control" id="city"
                      formControlName="name" [class.valid]="cityForm.get('name').valid &&
                      (cityForm.get('name').dirty || cityForm.get('name').touched)" [class.invalid]="cityForm.get('name').invalid &&
                      (cityForm.get('name').dirty || cityForm.get('name').touched)">
                    <div
                      *ngIf="cityForm.get('name').invalid && cityForm.get('name').errors && (cityForm.get('name').dirty || cityForm.get('name').touched)">
                      <small class="text-danger" *ngIf="cityForm.get('name').hasError('required')">
                        City name is required. </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row px-5 pb-3 d-flex">
                <div class="form-group">
                  <button type="submit" class="btn btn-success px-5 mr-2">Update</button>
                  <button (click)="toggleUpdateMode()" type="button" class="btn btn-danger px-5">Cancel</button>
                </div>
              </div>

            </form>
            <!-- end row -->
          </div>


          <div class="mt-3 p-3 bg-white mb-5">
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>City Id</th>
                  <th>City Name</th>
                  <th>Country Id</th>
                  <th>Country Name</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let city of cities">
                  <td>{{ city.id }}</td>
                  <td>{{ city.name }}</td>
                  <td>{{ city.country_id }}</td>
                  <td>{{ city.country_name }}</td>
                  <td>
                    <a [routerLink]="" (click)="fetchCity(city)" style="color:'blue';">Edit</a>
                  </td>
                  <td>
                    <a [routerLink]="" (click)="removeCity(city.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>
