import { LocalizeService } from './../_services/localize.service';
import { AuthService } from './../_services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MyAccountService } from './../_services/my-account.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  public profileImage: string;
  userId: string;
  isResearcher: Boolean = true;
  public userDetails;
  errorStatus: boolean;
  errorMessage: string;
  public roles: Array<any> = [];
  countries: Array<any> = [];
  selectedCountry: string;
  selectedRole: string;
  dropdownSettings: IDropdownSettings;
  public storeNationality: any;

  constructor(
    public myAccountService: MyAccountService,
    public router: Router,
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private localizeService: LocalizeService
  ) { }

  ngOnInit(): void {

    if (localStorage.getItem('role') == "Admin") {
      this.getRoles();
      this.isResearcher = false;
    }

    this.localizeService.getNationalities().then(data => {
      if (data.length > 0) {
        this.countries = data;
      } else {
        this.countries = [];
      }
    }).catch(err => {
      this.handleNotification('Error Fetching Countries', true)
    });

    this.profileImage = localStorage.getItem('avatar');
    this.userId = localStorage.getItem('userId');
    this.getUserDetails(this.userId)

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  profileDetails = this.formBuilder.group({
    fullname: ['', Validators.required],
    email: ['', Validators.required],
    nationality: ['', Validators.required],
    contact_number: ['', Validators.required],
    date_of_birth: ['', Validators.required],
    position: ['', Validators.required],
    role: ['', Validators.required]
  })

  getUserDetails(userId): void {
    this.myAccountService.getAgentUserDetail(userId)
      .subscribe(res => {
        if (!res.success) {
          Swal.fire({
            icon: "error",
            titleText: "Problem in fetching user details!"
          })
          return;
        }
        this.profileDetails = this.formBuilder.group({
          fullname: [res.data?.fullname, Validators.required],
          email: [res.data?.email, Validators.required],
          nationality: [[res.data.nationality], Validators.required],
          contact_number: [res.data?.contact_number, Validators.required],
          date_of_birth: [this.formatDate(res.data.date_of_birth), Validators.required],
          position: [res.data?.position, Validators.required],
          role: [res.data?.role?.id, Validators.required]
        })
        this.storeNationality = this.profileDetails.value?.nationality;
        this.userId = res.data.id
      }, err => {
        Swal.fire({
          icon: "error",
          titleText: "Problem in fetching user details!"
        })
      })
  }

  changeProfilePicture(userId) {
    Swal.fire({
      title: 'Select an image',
      showCancelButton: true,
      confirmButtonText: 'Upload',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload your profile picture'
      },
      preConfirm: (file => {
        return new Promise(function (resolve) {
          if (file) {
            Swal.showLoading()
            let formData = new FormData();
            formData.append('avatar', file);
            let accessToken = localStorage.getItem('accessToken');

            $.ajax({
              headers: {
                'Authorization': `JWT ${accessToken}`
              },
              method: 'post',
              url: `${environment.baseUrl}/user/portal-user/${userId}/avatar/upload`,
              data: formData,
              processData: false,
              contentType: false,
              success: function (response) {
                resolve(response);
              },
              error: function (error) {
                Swal.hideLoading();
                Swal.showValidationMessage(
                  `${error.responseJSON?.error_message}`
                );
                Swal.enableButtons();
              }
            })
          } else {
            Swal.showValidationMessage('Please upload a file!');
            Swal.enableButtons();
          }
        })
      })
    }).then((response: any) => {
      Swal.hideLoading();
      let newImageUrl = response.value?.data?.avatar || null;
      if (response.isConfirmed && newImageUrl) {
        Swal.fire('Updated', 'Your profile picture has been changed!', 'success');
        localStorage.setItem("avatar", newImageUrl);
        window.location.reload();
      }
    })
  }

  changePassword() {
    Swal.fire({
      title: 'Change Password',
      showCancelButton: true,
      confirmButtonText: 'Change Password',
      html:
        '<hr>' +
        '<input type="password" name="newPassword" id="newPassword" placeholder="New Password" class="swal2-input" />' +
        '<input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" class="swal2-input" /> ',
      preConfirm: (res => {
        return new Promise(function (resolve) {
          let new_password = $('#newPassword').val();
          let confirm_password = $('#confirmPassword').val();

          if (!(new_password && confirm_password)) {
            Swal.showValidationMessage('New password and confirm password are required!');
            Swal.enableButtons();
          } else if (!(new_password == confirm_password)) {
            Swal.showValidationMessage('New password and confirm password didn\'t match!');
            Swal.enableButtons();
          } else {
            Swal.showLoading();
            let accessToken = localStorage.getItem('accessToken');
            $.ajax({
              headers: {
                'Authorization': `JWT ${accessToken}`
              },
              method: 'post',
              url: `${environment.baseUrl}/user/password/change`,
              data: JSON.stringify({
                new_password
              }),
              processData: true,
              contentType: 'application/json',
              success: function (response) {
                resolve(response)
              }, error: function (error) {
                Swal.hideLoading()
                Swal.showValidationMessage(
                  `${error.responseJSON?.error_message}`
                );
                Swal.enableButtons();
              }
            })
          }
        });
      })
    }).then((response: any) => {
      if (response.isConfirmed && response.value) {
        Swal.hideLoading()
        const successmessage = response.value?.data?.message || "Password has been changed successfully!"
        Swal.fire('Updated', successmessage, 'success');
        this.resetSession();
      }
    })
  }

  resetSession() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  updateProfileFields(userId) {

    if (this.profileDetails.invalid) {
      for (var i in this.profileDetails.controls) {
        this.profileDetails.controls[i].markAsDirty();
        this.profileDetails.controls[i].updateValueAndValidity();
      }
      this.profileDetails.controls['nationality'].setValue(this.storeNationality);
      return;
    }

    this.profileDetails.controls['nationality'].setValue(this.profileDetails.value?.nationality[0]?.id);
    this.myAccountService.updateProfileFields(userId, this.profileDetails.value)
      .subscribe(response => {
        if (response.success) {
          localStorage.setItem("fullName", this.profileDetails.value.fullname);
          if (localStorage.getItem('role') == "Admin") {
            localStorage.setItem("role", this.roles.find(role => role.id == this.profileDetails.value.role).name);
          }
          Swal.fire({
            icon: 'success',
            titleText: 'Profile details updated successfully!',
            showConfirmButton: true
          }).then(() => {
            window.location.reload();
          })
        } else {
          let error_message: any;
          this.profileDetails.controls['nationality'].setValue(this.storeNationality);
          if (response.errors[0].field === 'non_field_errors') {
            error_message += response.error_message;
          } else {
            error_message = response.error.errors.map((error) => {
              return `${error.message} \n`
            }).join('')
          }
          Swal.fire({
            icon: 'error',
            titleText: error_message
          })
        }
      }, err => {
        this.profileDetails.controls['nationality'].setValue(this.storeNationality);
        let error_message: string;
        if (err.error.errors[0].field === 'non_field_errors') {
          error_message += err.error_message;
        } else {
          error_message = err.error.errors.map((error) => {
            return `${error.message} \n`
          }).join('')
        }
        Swal.fire({
          icon: 'error',
          titleText: error_message
        })
        // const validationErrors = err.error.errors;
        // if(err instanceof HttpErrorResponse) {
        //   const errorMessages = new Array<{propName: string; errors: string}>();
        //   if(err.status === 400) {
        //     Object.keys(validationErrors).forEach(prop => {
        //       const formControl = this.profileDetails.get(prop);
        //       if(formControl) {
        //         formControl.setErrors({
        //           serverError: validationErrors[prop]
        //         })
        //       }
        //     })
        //   }
        // }
      })
  }

  formatDate(date: string) {
    let year = date?.split('-')[2]
    let month = date?.split('-')[1]
    let day = date?.split('-')[0]

    return `${year}-${month}-${day}`;
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message,
        timer: 1500
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  public getRoles() {
    this.authService.getRoles().subscribe(res => {
      if (res.success) {
        this.roles = res.data.results;
      } else {
        this.errorStatus = true;
        this.errorMessage = "Error fetching Roles";
      }
    })
  }

}
