import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { FieldWorkerService } from '../_services/field-worker.service';

@Component({
  selector: 'app-single-field-work',
  templateUrl: './single-field-work.component.html',
  styleUrls: ['./single-field-work.component.scss']
})
export class SingleFieldWorkComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  public fieldWorkersQuestionnaire = [];
  public fieldWorkerId: string;
  public pageTitle: string = '';

  // variables used during exporting user agents data

  agentUsersData: any[];
  fileHeaders = ['fullname', 'id', 'operation_city', 'operation_country', 'total_completed_questionnaire', 'username'];
  csvExportFileName = 'fieldwork_questionnaire';

  accessToken: string;
  constructor(
    private fieldService: FieldWorkerService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: '<"top"<"left-col"B><"center-col"l><"right-col">>rtip',
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export',
          title: 'single_fieldwork',
          className: ' mr-1 btn btn-info float-right',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          }
        }
      ],
      // scrollX: true,
    };
    this.fieldWorkerId = this.route.snapshot.params['id'] || null;

    this.pageTitle = 'Agent ID: '+ this.fieldWorkerId;
    this.getQuestionnaireOfFieldWorkers(this.fieldWorkerId)
    this.accessToken = localStorage.getItem('accessToken')
  }

  getQuestionnaireOfFieldWorkers(userId) {
    Swal.showLoading();
    this.fieldService.listAgentQuestionnaireReportById(userId)
      .subscribe(res => {
        Swal.close();
        
        if (res && res.success) {
          this.fieldWorkersQuestionnaire = res.data.results;
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'No data available for field workers!',
            timer: 1500
          });
        }
      }, (error) => {
        
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong in fetching field workers!',
          timer: 1500
        });
      })
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
}
