<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add Data to your Database'"
              [subTitle]="'You can add data to the portal from here'"></app-top-title>
          </div>
          <div class="bg-white mb-5">
            <div class="card-box">

              <form class="" role="form" [formGroup]="brandForm" (ngSubmit)="updateBrand()">

                <div class="row d-flex p-4">

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label" for="brand">Brand<span style="color:#ff0000">*</span></label>
                      <input placeholder="Enter Brand Name" type="text" class="form-control" id="brand"
                        formControlName="name" [class.valid]="brandForm.get('name').valid && 
                        (brandForm.get('name').dirty || brandForm.get('name').touched)" [class.invalid]="brandForm.get('name').invalid && 
                        (brandForm.get('name').dirty || brandForm.get('name').touched)">
                      <div
                        *ngIf="brandForm.get('name').invalid && brandForm.get('name').errors && (brandForm.get('name').dirty || brandForm.get('name').touched)">
                        <small class="text-danger" *ngIf="brandForm.get('name').hasError('required')">
                          Brand name is required. </small>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row px-5 pb-3 d-flex">
                  <div class="form-group">
                    <button type="submit" class="btn btn-success px-5 mr-2">Update</button>
                    <button (click)="toggleUpdateMode()" type="button" class="btn btn-danger px-5">Cancel</button>
                  </div>
                </div>

              </form>
            </div>

            <!-- end row -->
          </div>
          <div class="mt-1 p-2 bg-white mb-5">
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>Brand Id</th>
                  <th>Brand Name</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let brand of brands">
                  <td>{{ brand.id }}</td>
                  <td>{{ brand.name }}</td>
                  <td>
                    <a [routerLink]="" (click)="fetchBrand(brand)" style="color:'blue';">Edit</a>
                  </td>
                  <td>
                    <a [routerLink]="" (click)="removeBrand(brand.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>
