import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-inputs',
  templateUrl: './profile-inputs.component.html',
  styleUrls: ['./profile-inputs.component.scss']
})
export class ProfileInputsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
