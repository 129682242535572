import { SharedService } from './../_shared/shared.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

export interface Question {
  question_type: number,
  statement: string,
  question_options?: Array<String>,
  sku: String
}

export interface QuestionObject {
  data: Array<Question>
}

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private sharedService: SharedService
  ) { }

  addBulkQuestions(qnnId: String, questions: QuestionObject) {
    return this.sharedService.post(`${environment.baseUrl}/question/questionnaire/${qnnId}/bulk-add`, questions);
  }

  getQuestionsType(questionnaireId) {
    return this.sharedService.get(`${environment.baseUrl}/question/question-type/list?questionnaire=` + questionnaireId);
  }

  getQuestionsByQuestionnaireId(qnnId) {
    return this.sharedService.get(`${environment.baseUrl}/question/list?questionnaire=${qnnId}`);
  }

  getAnswerByQnnIdAndUserId(responseId) {
    return this.sharedService.get(`${environment.baseUrl}/response/${responseId}/answer/list`);
  }

  importQuestions(qnnId, questionsData) {
    return this.sharedService.post(`${environment.baseUrl}/question/questionnaire/${qnnId}/import`, questionsData);
  }

  removeQuestion(questionId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/question/${questionId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

}
