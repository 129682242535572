<!DOCTYPE html>
<html lang="en">
    <body>
        <div id="wrapper"  >
            <!-- Topbar Start -->
            <app-top-bar [pageTitle]="'Operations'" ></app-top-bar>
            <!-- end Topbar -->
        </div>
        <!-- Begin page -->
        <div id="wrapper" style="background-color: '#ebeff2';" >



            <!-- ========== Left Sidebar Start ========== -->
            <app-left-side-bar></app-left-side-bar>
            <!-- Left Sidebar End -->

            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="content-page">
                <div class="content">

                    <!-- Start Content-->
                    <div class="container-fluid">

                        <div class="mb-0" >
                            <app-top-title [title]="'Add Data to your Database'" [subTitle]="'You can add data to the portal from here'" ></app-top-title>
                        </div>
                        <div class="mt-0 p-4 bg-white"  >


                        <div class="mt-1 mb-3" >
                            <span class="import-btn">
                                <button type="submit" style="background-color:'#8836be';color:'white'" class="btn rounded-pill pl-sm-1 pr-sm-1 pl-md-4 pr-md-4" data-toggle="tooltip" data-placement="top" title="Upload CSV File">Import</button> <input type="file" name="import" />&nbsp;</span>
                        </div>

                        <!-- start row -->
                        <div class="row">
                            <div class="col-12">
                                <div class="card-box">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="p-2">
                                                <form class="form-horizontal" role="form">
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">Country List<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <select class="form-control">
                                                                <option>UAE</option>
                                                                <option>KSA</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">City List<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <select class="form-control">
                                                                <option>Dubai</option>
                                                                <option>Damam</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">Channel List<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <div [formGroup]="form"> 
                                                                <div class="editor-tag" (click)="focusTagInput('channel')">
                                                                    <span class="tag-item" *ngFor="let channel of channels">
                                                                          {{channel}}
                                                                          <span class="remove-tag" (click)="removeTag('channel', channel)">×</span>
                                                                        </span>
                                                                        <input
                                                                        placeholder="Type then press space.."
                                                                        #channelInput type="text"
                                                                        class="input-tag"
                                                                        id="channel"
                                                                        (keyup)="onKeyUp($event, 'channel')"
                                                                        formControlName="channel">
                                                                      </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">Retailers List<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <div [formGroup]="form">
                                                                <div class="editor-tag" (click)="focusTagInput('retailer')">
                                                                    <span class="tag-item" *ngFor="let retailer of retailers">
                                                                          {{retailer}}
                                                                          <span class="remove-tag" (click)="removeTag('retailer',retailer)">×</span>
                                                                        </span>
                                                                        <input
                                                                        placeholder="Type then press space.."
                                                                        #retailerInput type="text"
                                                                        class="input-tag"
                                                                        (keyup)="onKeyUp($event,'retailer')"
                                                                        formControlName="retailer">
                                                                      </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">Categories List<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <div [formGroup]="form">
                                                                <div class="editor-tag" (click)="focusTagInput('category')">
                                                                    <span class="tag-item" *ngFor="let category of categories">
                                                                          {{category}}
                                                                          <span class="remove-tag" (click)="removeTag('category',category)">×</span>
                                                                        </span>
                                                                        <input
                                                                        placeholder="Type then press space.."
                                                                        #categoryInput type="text"
                                                                        class="input-tag"
                                                                        (keyup)="onKeyUp($event,'category')"
                                                                        formControlName="category">
                                                                      </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">Brands List<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <div [formGroup]="form">
                                                                <div class="editor-tag" (click)="focusTagInput('brand')">
                                                                    <span class="tag-item" *ngFor="let brand of brands">
                                                                          {{brand}}
                                                                          <span class="remove-tag" (click)="removeTag('brand',brand)">×</span>
                                                                        </span>
                                                                        <input
                                                                        placeholder="Type then press space.."
                                                                        #brandInput type="text"
                                                                        class="input-tag"
                                                                        (keyup)="onKeyUp($event,'brand')"
                                                                        formControlName="brand">
                                                                      </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">SKU List<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <div [formGroup]="form">
                                                                <div class="editor-tag" (click)="focusTagInput('sku')">
                                                                    <span class="tag-item" *ngFor="let sku of skus">
                                                                          {{sku}}
                                                                          <span class="remove-tag" (click)="removeTag('sku',sku)">×</span>
                                                                        </span>
                                                                        <input
                                                                        placeholder="Type then press space.."
                                                                        #skuInput type="text"
                                                                        class="input-tag"
                                                                        (keyup)="onKeyUp($event,'sku')"
                                                                        formControlName="sku">
                                                                      </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">Question Types<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <div [formGroup]="form">
                                                                <div class="editor-tag" (click)="focusTagInput('QType')">
                                                                    <span class="tag-item" *ngFor="let QType of QTypes">
                                                                          {{QType}}
                                                                          <span class="remove-tag" (click)="removeTag('QType',QType)">×</span>
                                                                        </span>
                                                                        <input
                                                                        placeholder="Type then press space.."
                                                                        #QTypeInput type="text"
                                                                        class="input-tag"
                                                                        (keyup)="onKeyUp($event,'QType')"
                                                                        formControlName="QType">
                                                                      </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <!-- end row -->

                                </div> <!-- end card-box -->
                            </div><!-- end col -->

                        <!-- end row -->


                        <button type="submit" class="btn btn-success rounded-pill pl-4 pr-4">Save</button> &nbsp;
                        <button type="submit" class="btn btn-danger rounded-pill pl-4 pr-4">Cancel</button>
                        <br /><br />
                    </div>
                    </div> <!-- container-fluid -->

                </div> <!-- content -->

                <!-- Footer Start -->
                <app-footer></app-footer>
                <!-- end Footer -->

            </div>

            <!-- ============================================================== -->
            <!-- End Page content -->
            <!-- ============================================================== -->


        </div>
        <!-- END wrapper -->

    </body>
</html>