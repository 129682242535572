import { Router } from '@angular/router';
import { DataService } from './../_services/data.service';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import Swal from 'sweetalert2';
import { DashboardService } from '../_services/dashboard.service';

export interface ExcelData {
  labels: Array<any>,
  data: Array<any>
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  currentQnnView: string = 'pm';
  currentFilterString: string;

  // common variables
  height: string = '120';
  doubleHeight: string = '200';
  // bar chart variables
  maxDataArray = [];
  minDataArray = [];
  meanDataArray = [];
  modeDataArray = [];
  barChartExcelData: ExcelData = { labels: [], data: [] };
  public minMaxBarChartData: ChartDataSets[] = [];
  public minMaxBarChartLabels: Label[] = [];

  // stacked barchart for SKU VS month max report variables
  public monthMaxBarChartData: ChartDataSets[] = [];
  public monthMaxBarChartLabels: Label[] = [];
  public barChartMonthMaxExcelData: ExcelData = { labels: [], data: [] };

  // stacked bar chart month min variables
  public monthMinBarChartData: ChartDataSets[] = [];
  public monthMinBarChartLabels: Label[] = [];
  public barChartMonthMinExcelData: ExcelData = { labels: [], data: [] };

  // stacked bar chart month mode variables
  public monthModeBarChartData: ChartDataSets[] = [];
  public monthModeBarChartLabels: Label[] = [];
  public barChartMonthModeExcelData: ExcelData = { labels: [], data: [] };

  // stacked bar chart month mode variables
  public monthMeanBarChartData: ChartDataSets[] = [];
  public monthMeanBarChartLabels: Label[] = [];
  public barChartMonthMeanExcelData: ExcelData = { labels: [], data: [] };

  // bar chart country max varaibles
  countryMaxValueArray = [];
  countryMaxExcelData: ExcelData = { labels: [], data: [] };
  public countryMaxBarChartData: ChartDataSets[] = [];
  public countryMaxBarChartLabels: Label[] = [];

  // bar chart country min varaibles
  countryMinValueArray = [];
  countryMinExcelData: ExcelData = { labels: [], data: [] };
  public countryMinBarChartData: ChartDataSets[] = [];
  public countryMinBarChartLabels: Label[] = [];

  // bar chart country mode varaibles
  countryModeValueArray = [];
  countryModeExcelData: ExcelData = { labels: [], data: [] };
  public countryModeBarChartData: ChartDataSets[] = [];
  public countryModeBarChartLabels: Label[] = [];

  // bar chart country mean varaibles
  countryMeanValueArray = [];
  countryMeanExcelData: ExcelData = { labels: [], data: [] };
  public countryMeanBarChartData: ChartDataSets[] = [];
  public countryMeanBarChartLabels: Label[] = [];

  // PieChart - Outlet visited by Country
  outletVisitsValue = [];
  public outletPieChartLabels: Label[] = [];
  public outletPieChartData: ChartDataSets[] = [];
  public pieChartExcelData: ExcelData = { labels: [], data: [] };

  // line chart answer per city variables
  answerPerCityValuesArray = [];
  public answerPerCityChartData: ChartDataSets[] = [];
  public answerPerCityChartLabels: Label[] = [];
  public answerPerCityExcelData: ExcelData = { labels: [], data: [] };

  // bar chart brand vs avg variables
  brandVsAvgMaxDataArray = [];
  brandVsAvgMinDataArray = [];
  brandVsAvgMeanDataArray = [];
  brandVsAvgModeDataArray = [];
  brandVsAvgExcelData: ExcelData = { labels: [], data: [] };
  public brandVsAvgChartData: ChartDataSets[] = [];
  public brandVsAvgChartLabels: Label[] = [];


  // PieChart - Total answers per country
  public totalAnswersPerCountryPieChartLabels: Label[] = [];
  public totalAnswersPerCountryPieChartData: ChartDataSets[] = [];
  public totalAnswersPerCountryPieChartExcelData: ExcelData = { labels: [], data: [] };

  // PieChart - Total answers per country
  public totalAnswersPerSkuPieChartLabels: Label[] = [];
  public totalAnswersPerSkuPieChartData: ChartDataSets[] = [];
  public totalAnswersPerSkuPieChartExcelData: ExcelData = { labels: [], data: [] };

  // Out of Stock
  // Barchart with Line graph for Overall
  public overallOOSBLData: ChartDataSets[] = [];
  public overallOOSBLLabels: Label[] = [];
  public overallOOSBLExcelData: ExcelData = { labels: [], data: [] }

  // Barchart for Month Available
  public monthAvailableOOSData: ChartDataSets[] = [];
  public monthAvailableOOSLabels: Label[] = [];
  public monthAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Barchart for Month Not Available
  public monthNotAvailableOOSData: ChartDataSets[] = [];
  public monthNotAvailableOOSLabels: Label[] = [];
  public monthNotAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Barchart for Less than 6 SKU report
  public lessMonthNotAvailableOOSData: ChartDataSets[] = [];
  public lessMonthOOSLabels: Label[] = [];
  public lessMonthOOSExcelData: ExcelData = { labels: [], data: [] }
  // Barchart for Store Less
  public storeLessOOSData: ChartDataSets[] = [];
  public storeLessOOSLabels: Label[] = [];
  public storeLessOOSExcelData: ExcelData = { labels: [], data: [] }

  // Line Chart for City Available
  public cityAvailableOOSData: ChartDataSets[] = [];
  public cityAvailableOOSLabels: Label[] = [];
  public cityAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Line Chart for City Not Available
  public cityNotAvailableOOSData: ChartDataSets[] = [];
  public cityNotAvailableOOSLabels: Label[] = [];
  public cityNotAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Pie Chart for Total Visits in a city
  public totalVisitsInCityOOSData: ChartDataSets[] = [];
  public totalVisitsInCityOOSLabels: Label[] = [];
  public totalVisitsInCityOOSExcelData: ExcelData = { labels: [], data: [] }

  // Line Chart for Total Visits in a city
  public lessSKUInCityOOSData: ChartDataSets[] = [];
  public lessSKUInCityOOSLabels: Label[] = [];
  public lessSKUInCityOOSExcelData: ExcelData = { labels: [], data: [] }

  // Table for SKU not available in 4 weeks
  public weekNotAvailableOOSData: any;
  public weekNotAvailableOOSLabels: Label[] = [];
  public weekNotAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  //Distribution Check
  //Visit per city
  public submittedQnnByCityDCData: any = [];
  public submittedQnnByCityDCLabels: Label[] = [];
  public submittedQnnByCityDCExcelData: ExcelData = { labels: [], data: [] }

  //Visit per country
  public submittedQnnByCountryDCData: any = [];
  public submittedQnnByCountryDCLabels: Label[] = [];
  public submittedQnnByCountryDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total visit by channel
  public submittedQnnByChannelDCData: any = [];
  public submittedQnnByChannelDCLabels: Label[] = [];
  public submittedQnnByChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total sku in outlets in a city
  public totalOutletsOfSkuInCityDCData: any = [];
  public totalOutletsOfSkuInCityDCLabels: Label[] = [];
  public totalOutletsOfSkuInCityDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total sku in outlets in Country
  public totalOutletsOfSkuInCountryDCData: any = [];
  public totalOutletsOfSkuInCountryDCLabels: Label[] = [];
  public totalOutletsOfSkuInCountryDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total sku in outlets in Country
  public totalOutletsOfSkuInChannelDCData: any = [];
  public totalOutletsOfSkuInChannelDCLabels: Label[] = [];
  public totalOutletsOfSkuInChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // horizontal bar chart DC sum of one brand by city
  public sumOfOneBrandByCityDCData: any = [];
  public sumOfOneBrandByCityDCLabels: Label[] = [];
  public sumOfOneBrandByCityDCExcelData: ExcelData = { labels: [], data: [] }

  // horizontal bar chart DC sum of one brand by country
  public sumOfOneBrandByCountryDCData: any = [];
  public sumOfOneBrandByCountryDCLabels: Label[] = [];
  public sumOfOneBrandByCountryDCExcelData: ExcelData = { labels: [], data: [] }

  // horizontal bar chart DC sum of one brand by channel
  public sumOfOneBrandByChannelDCData: any = [];
  public sumOfOneBrandByChannelDCLabels: Label[] = [];
  public sumOfOneBrandByChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC average per sku
  public avgPerSkuDCData: any = [];
  public avgPerSkuDCLabels: Label[] = [];
  public avgPerSkuDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC average per brand
  public avgPerBrandDCData: any = [];
  public avgPerBrandDCLabels: Label[] = [];
  public avgPerBrandDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC sku by channel
  public avgSKUPerChannelDC: any = [];
  public avgSKUPerChannelDCLabels: Label[] = [];
  public avgSKUPerChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC Brand by channel
  public avgBrandByChannelDCData: any = [];
  public avgBrandByChannelDCLabels: Label[] = [];
  public avgBrandByChannelDCExcelData: ExcelData = { labels: [], data: [] }

  //Consumer Questionnaire

  // Stacked Bar chart CQ yes no question chart
  public yesNoQuestionCQData: any = [];
  public yesNoQuestionCQLabels: Label[] = [];
  public yesNoQuestionCQExcelData: ExcelData = { labels: [], data: [] }

  // Stacked Bar chart CQ Rating One to Ten
  public ratingOneToTenCQData: any = [];
  public ratingOneToTenCQLabels: Label[] = [];
  public ratingOneToTenCQExcelData: ExcelData = { labels: [], data: [] }

  // Stacked Bar chart CQ rating one to three question chart
  public ratingOneToThreeQuestionCQData: any = [];
  public ratingOneToThreeCQLabels: Label[] = [];
  public ratingOneToThreeCQExcelData: ExcelData = { labels: [], data: [] }

  // Stacked Bar chart CQ rating one to five question chart
  public ratingOneToFiveQuestionCQData: any = [];
  public ratingOneToFiveCQLabels: Label[] = [];
  public ratingOneToFiveCQExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart CQ numeric question chart
  public numericQuestionCQData: any = [];
  public numericCQLabels: Label[] = [];
  public numericCQExcelData: ExcelData = { labels: [], data: [] }

  constructor(
    private dashboardService: DashboardService,
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.currentQnnView == 'pm')
      this.refreshPriceMonitorChartApis();

    if (this.currentQnnView == 'oos')
      this.refreshOutOfStockChartApis();

    if (this.currentQnnView == 'dc')
      this.refreshDistributionCheckChartApis();

    if (this.currentQnnView == 'cq')
      this.refreshConsumerQuestionnaireChartApis();
  }

  // main methods
  refreshPriceMonitorChartApis(filterString?) {
    this.loadPMMinMax(filterString);
    this.loadPMMonthMax(filterString);
    this.loadPMMonthMin(filterString);
    this.loadPMMonthMode(filterString);
    this.loadPMMonthMean(filterString);
    this.loadPMCountryMax(filterString);
    this.loadPMCountryMin(filterString);
    this.loadPMCountryMode(filterString);
    this.loadPMCountryMean(filterString);
    this.loadPMAnswersByCountry(filterString);
    this.loadPMAnswersPerSKU(filterString);
    this.loadPMAnswerPerCity(filterString);
    this.loadPMBrandVsAvg(filterString);
  }

  refreshOutOfStockChartApis(filterString?) {
    this.loadOOSOverall(filterString)
    this.loadOOSMonthAvailable(filterString)
    this.loadOOSMonthNotAvailable(filterString)
    this.loadOOSOOSMonthLess(filterString)
    this.loadOOSStoreNotAvailable(filterString)
    this.loadOOSCityAvailable(filterString)
    this.loadOOSCityNotAvailable(filterString)
    this.loadOOSTotalVisitsInCity(filterString)
    this.loadOOSCityLess(filterString);
    this.loadOOSWeekNotAvailable(filterString)
  }

  refreshDistributionCheckChartApis(filterString?) {
    this.loadDCSubmittedQnnByChannel(filterString)
    this.loadTotalOutletsOfSkuInCity(filterString)
    this.loadDCSubmittedQnnByCity(filterString)
    this.loadDCSubmittedQnnByCountry(filterString)
    this.loadTotalOutletsOfSkuInCountry(filterString)
    this.loadTotalOutletsOfSkuInChannel(filterString)
    this.loadSumOfBrandByCity(filterString)
    this.loadSumOfBrandByCountry(filterString)
    this.loadSumOfBrandByChannel(filterString)
    this.loadDCAvgPerSku(filterString)
    this.loadDCAvgPerBrand(filterString)
    this.loadDCShelfShareSkuChannel(filterString)
    this.loadDCShelfShareBrandChannel(filterString)
  }

  refreshConsumerQuestionnaireChartApis(filterString?) {
    this.loadCQYesNoQuestion(filterString)
    this.loadCQRatingOneToTen(filterString)
    this.loadCQRatingOneToThreeQuestion(filterString)
    this.loadCQRatingOneToFiveQuestion(filterString)
    this.loadCQNumericQuestion(filterString)
  }

  filterApplied(filterString) {

    if (filterString) {
      this.currentFilterString = filterString
    }

    if (this.currentQnnView == 'pm')
      this.refreshPriceMonitorChartApis(filterString);

    if (this.currentQnnView == 'oos')
      this.refreshOutOfStockChartApis(filterString);

    if (this.currentQnnView == 'dc')
      this.refreshDistributionCheckChartApis(filterString);

    if (this.currentQnnView == 'cq')
      this.refreshConsumerQuestionnaireChartApis(filterString);
  }

  onQnnTypeChange(currentOptionObj) {
    let { currentOptionText, filterString } = currentOptionObj;
    if (currentOptionText == "Price Monitor")
      this.currentQnnView = 'pm'

    if (currentOptionText == "Out Of Stock")
      this.currentQnnView = 'oos'

    if (currentOptionText == "Brand Health Tracker")
      this.currentQnnView = 'cq'

    if (currentOptionText == "Distribution Check")
      this.currentQnnView = 'dc'

    this.filterApplied(filterString)
  }


  // Chart APIs
  loadPMMinMax(filterString?) {

    Swal.showLoading();
    this.dashboardService.getPMMinMax(filterString)
      .subscribe(
        response => {
          console.log(response)
          this.barChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success) {
            let pmMinMaxData = response.data ? [...response.data] : [];

            this.maxDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.max);
            this.minDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.min);
            this.modeDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.mode);
            this.meanDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.mean);

            this.minMaxBarChartLabels = pmMinMaxData.map(eachSKUData => eachSKUData.sku);
            this.minMaxBarChartData = [
              { data: this.maxDataArray, label: 'max' },
              { data: this.minDataArray, label: 'min' },
              { data: this.meanDataArray, label: 'mean' },
              { data: this.modeDataArray, label: 'mode' },
            ];

            this.barChartExcelData.labels = Object.keys(pmMinMaxData[0]);
            this.barChartExcelData.data = pmMinMaxData;

          } else {
            this.minMaxBarChartData = [{ data: [], label: '' }]
            this.minMaxBarChartLabels = []
            this.barChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          Swal.close();
          this.minMaxBarChartData = [{ data: [], label: '' }]
          this.minMaxBarChartLabels = []
          this.barChartExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching min max data!", true);
        }
      )
  }
  loadPMMonthMax(filterString?) {

    Swal.showLoading();
    this.dashboardService.getPMMonthMax(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmMonthMaxData = response.data ? [...response.data] : [];

            this.monthMaxBarChartLabels = this.formatStackedBarData(pmMonthMaxData).uniqueSku;
            this.monthMaxBarChartData = this.formatStackedBarData(pmMonthMaxData).stackedBarChartData;

            this.barChartMonthMaxExcelData.labels = Object.keys(pmMonthMaxData[0]);
            this.barChartMonthMaxExcelData.data = [...pmMonthMaxData];

          } else {
            this.monthMaxBarChartData = [{ data: [], label: '' }]
            this.monthMaxBarChartLabels = []
            this.barChartMonthMaxExcelData = { labels: [], data: [] };

          }
        },
        error => {
          Swal.close();
          this.monthMaxBarChartData = [{ data: [], label: '' }]
          this.monthMaxBarChartLabels = []
          this.barChartMonthMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching month max data!", true);
        }
      )
  }
  loadPMMonthMin(filterString?) {

    Swal.showLoading();
    this.dashboardService.getPMMonthMin(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmMonthMinData = response.data ? [...response.data] : [];
            // let pmMonthMinData:Array<any> = this.responseBarDataStacked;

            this.monthMinBarChartLabels = this.formatStackedBarData(pmMonthMinData).uniqueSku;
            this.monthMinBarChartData = this.formatStackedBarData(pmMonthMinData).stackedBarChartData;


            this.barChartMonthMinExcelData.labels = Object.keys(pmMonthMinData[0]);
            this.barChartMonthMinExcelData.data = [...pmMonthMinData];

          } else {
            this.monthMinBarChartData = [{ data: [], label: '' }]
            this.monthMinBarChartLabels = []
            this.barChartMonthMaxExcelData = { labels: [], data: [] };

          }
        },
        error => {
          Swal.close();
          this.handleNotification("Error fetching month min data!", true);
        }
      )
  }
  loadPMMonthMode(filterString?) {
    Swal.showLoading();
    this.dashboardService.getPMMonthMode(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmMonthModeData = response.data ? [...response.data] : [];
            this.monthModeBarChartLabels = this.formatStackedBarData(pmMonthModeData).uniqueSku;
            this.monthModeBarChartData = this.formatStackedBarData(pmMonthModeData).stackedBarChartData;

            this.barChartMonthModeExcelData.labels = Object.keys(pmMonthModeData[0]);
            this.barChartMonthModeExcelData.data = [...pmMonthModeData];

          } else {
            this.monthModeBarChartData = [{ data: [], label: '' }]
            this.monthModeBarChartLabels = []
            this.barChartMonthModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.monthModeBarChartData = [{ data: [], label: '' }]
          this.monthModeBarChartLabels = []
          this.barChartMonthModeExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching month mode data!", true);
        }
      )
  }
  loadPMMonthMean(filterString?) {

    Swal.showLoading();
    this.dashboardService.getPMMonthMean(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmMonthMeanData = response.data ? [...response.data] : [];

            this.monthMeanBarChartLabels = this.formatStackedBarData(pmMonthMeanData).uniqueSku;
            this.monthMeanBarChartData = this.formatStackedBarData(pmMonthMeanData).stackedBarChartData;

            this.barChartMonthMeanExcelData.labels = Object.keys(pmMonthMeanData[0]);
            this.barChartMonthMeanExcelData.data = [...pmMonthMeanData];

          } else {
            this.monthMeanBarChartData = [{ data: [], label: '' }]
            this.monthMeanBarChartLabels = []
            this.barChartMonthMeanExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.monthMeanBarChartData = [{ data: [], label: '' }]
          this.monthMeanBarChartLabels = []
          this.barChartMonthMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching month mean data!", true);
        }
      )
  }

  loadPMCountryMax(filterString?) {
    Swal.showLoading();
    this.dashboardService.getPMCountryMax(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmCountryMaxData = response.data ? [...response.data] : [];

            this.countryMaxBarChartLabels = this.formatCountryMaxBarData(pmCountryMaxData).uniqueSku;
            this.countryMaxBarChartData = this.formatCountryMaxBarData(pmCountryMaxData).stackedBarChartData;

            this.countryMaxExcelData.labels = Object.keys(pmCountryMaxData[0]);
            this.countryMaxExcelData.data = [...pmCountryMaxData];

          } else {
            this.countryMaxBarChartData = [{ data: [], label: '' }]
            this.countryMaxBarChartLabels = []
            this.countryMaxExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.countryMaxBarChartData = [{ data: [], label: '' }]
          this.countryMaxBarChartLabels = []
          this.countryMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country max data!", true);
        }
      )
  }
  loadPMCountryMin(filterString?) {
    Swal.showLoading();
    this.dashboardService.getPMCountryMin(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmCountryMinData = response.data ? [...response.data] : [];

            this.countryMinBarChartLabels = this.formatCountryMinBarData(pmCountryMinData).uniqueCountry;
            this.countryMinBarChartData = this.formatCountryMinBarData(pmCountryMinData).stackedBarChartData;

            this.countryMinExcelData.labels = Object.keys(pmCountryMinData[0]);
            this.countryMinExcelData.data = [...pmCountryMinData];

          } else {
            this.countryMinBarChartData = [{ data: [], label: '' }]
            this.countryMinBarChartLabels = []
            this.countryMinExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.countryMinBarChartData = [{ data: [], label: '' }]
          this.countryMinBarChartLabels = []
          this.countryMinExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country min data!", true);
        }
      )
  }
  loadPMCountryMode(filterString?) {
    Swal.showLoading();
    this.dashboardService.getPMCountryMode(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmCountryModeData = response.data ? [...response.data] : [];

            this.countryModeBarChartLabels = this.formatCountryMinBarData(pmCountryModeData).uniqueCountry;
            this.countryModeBarChartData = this.formatCountryMinBarData(pmCountryModeData).stackedBarChartData;

            this.countryModeExcelData.labels = Object.keys(pmCountryModeData[0]);
            this.countryModeExcelData.data = [...pmCountryModeData];

          } else {
            this.countryModeBarChartData = [{ data: [], label: '' }]
            this.countryModeBarChartLabels = []
            this.countryModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.handleNotification("Error fetching country mode data!", true);
        }
      )
  }

  loadPMCountryMean(filterString?) {
    Swal.showLoading();
    this.dashboardService.getPMCountryMean(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmCountryMeanData = response.data ? [...response.data] : [];

            this.countryMeanBarChartLabels = this.formatCountryMaxBarData(pmCountryMeanData).uniqueSku;
            this.countryMeanBarChartData = this.formatCountryMaxBarData(pmCountryMeanData).stackedBarChartData;

            this.countryMeanExcelData.labels = Object.keys(pmCountryMeanData[0]);
            this.countryMeanExcelData.data = [...pmCountryMeanData];

          } else {
            this.countryMeanBarChartData = [{ data: [], label: '' }]
            this.countryMeanBarChartLabels = []
            this.countryMeanExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching country mean data!", true);
          }
        },
        error => {
          Swal.close();
          this.countryMeanBarChartData = [{ data: [], label: '' }]
          this.countryMeanBarChartLabels = []
          this.countryMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country mean data!", true);
        }
      )
  }


  loadPMAnswerPerCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.getPMCountryAnswerPerCity(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmAnswerPerCityData = response.data ? [...response.data] : [];

            this.answerPerCityValuesArray = pmAnswerPerCityData.map(eachSKUData => eachSKUData.value);

            this.answerPerCityChartLabels = this.formatAnswerPerCityData(pmAnswerPerCityData).uniqueCities;
            this.answerPerCityChartData = this.formatAnswerPerCityData(pmAnswerPerCityData).stackedBarChartData;

            this.answerPerCityExcelData.labels = Object.keys(pmAnswerPerCityData[0]);
            this.answerPerCityExcelData.data = [...pmAnswerPerCityData];


          } else {
            this.answerPerCityChartData = [{ data: [], label: '' }]
            this.answerPerCityChartLabels = []
            this.answerPerCityExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching country mean data!", true);
          }
        },
        error => {
          Swal.close();
          this.answerPerCityChartData = [{ data: [], label: '' }]
          this.answerPerCityChartLabels = []
          this.answerPerCityExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country mean data!", true);
        }
      )
  }

  loadPMAnswersByCountry(filterString?) {

    Swal.showLoading();
    this.dashboardService.getPMCountryAnswerPerCountry(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmAnswerPerCountryData = response.data ? [...response.data] : [];

            this.totalAnswersPerCountryPieChartData = pmAnswerPerCountryData.map(eachAnswerByCountry => eachAnswerByCountry.value);
            this.totalAnswersPerCountryPieChartLabels = pmAnswerPerCountryData.map(eachAnswerByCountry => eachAnswerByCountry.country);
            this.totalAnswersPerCountryPieChartExcelData.labels = Object.keys(pmAnswerPerCountryData[0]);
            this.totalAnswersPerCountryPieChartExcelData.data = [...pmAnswerPerCountryData];
          } else {
            this.totalAnswersPerCountryPieChartData = [{ data: [], label: '' }]
            this.totalAnswersPerCountryPieChartLabels = []
            this.totalAnswersPerCountryPieChartExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.totalAnswersPerCountryPieChartData = [{ data: [], label: '' }]
          this.totalAnswersPerCountryPieChartLabels = []
          this.totalAnswersPerCountryPieChartExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total answers per country data!", true);
        }
      )
  }

  loadPMAnswersPerSKU(filterString?) {

    Swal.showLoading();
    this.dashboardService.getPMCountryAnswerPerSku(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let pmAnswerPerSkuData = response.data ? [...response.data] : [];

            this.totalAnswersPerSkuPieChartData = pmAnswerPerSkuData.map(eachAnswerBySku => eachAnswerBySku.value);

            this.totalAnswersPerSkuPieChartLabels = pmAnswerPerSkuData.map(eachAnswerBySku => eachAnswerBySku.name);

            this.totalAnswersPerSkuPieChartExcelData.labels = Object.keys(pmAnswerPerSkuData[0]);
            this.totalAnswersPerSkuPieChartExcelData.data = [...pmAnswerPerSkuData];

          } else {
            this.totalAnswersPerSkuPieChartData = [{ data: [], label: '' }]
            this.totalAnswersPerSkuPieChartLabels = []
            this.totalAnswersPerSkuPieChartExcelData = { labels: [], data: [] };
            //  this.handleNotification("Problem in fetching total answers per Sku data!", true);
          }
        },
        error => {
          Swal.close();
          this.totalAnswersPerSkuPieChartData = [{ data: [], label: '' }]
          this.totalAnswersPerSkuPieChartLabels = []
          this.totalAnswersPerSkuPieChartExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total answers per Sku data!", true);
        }
      )
  }

  loadPMBrandVsAvg(filterString?) {

    Swal.showLoading();
    this.dashboardService.getPMBrandVsAvg(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let pmBrandVsAvgData = response.data ? [...response.data] : [];

            this.brandVsAvgMaxDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.max);
            this.brandVsAvgMinDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.min);
            this.brandVsAvgModeDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.mode);
            this.brandVsAvgMeanDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.mean);

            this.brandVsAvgChartLabels = pmBrandVsAvgData.map(eachSKUData => eachSKUData.brand);
            this.brandVsAvgChartData = [
              { data: this.brandVsAvgMaxDataArray, label: 'max' },
              { data: this.brandVsAvgMinDataArray, label: 'min' },
              { data: this.brandVsAvgMeanDataArray, label: 'mean' },
              { data: this.brandVsAvgModeDataArray, label: 'mode' },
            ];

            this.brandVsAvgExcelData.labels = Object.keys(pmBrandVsAvgData[0]);
            this.brandVsAvgExcelData.data = [...pmBrandVsAvgData];

          } else {
            this.brandVsAvgChartData = [{ data: [], label: '' }]
            this.brandVsAvgChartLabels = []
            this.brandVsAvgExcelData = { labels: [], data: [] };
            //  this.handleNotification("Problem in fetching brand vs avg data!", true);
          }
        },
        error => {
          Swal.close();
          this.brandVsAvgChartData = [{ data: [], label: '' }]
          this.brandVsAvgChartLabels = []
          this.brandVsAvgExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching brand vs avg data!", true);
        }
      )
  }

  // Out of Stock Load methods
  loadOOSOverall(filterString?) {

    Swal.showLoading();
    this.dashboardService.getOOSOverall(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let oosOverallData = response.data ? [...response.data] : [];

            const availableData = oosOverallData.map(eachOData => eachOData.available);
            const notAvailableData = oosOverallData.map(eachOData => eachOData.not_available);
            const lessData = oosOverallData.map(eachOData => eachOData.less);

            this.overallOOSBLLabels = oosOverallData.map(eachOData => eachOData.sku);
            this.overallOOSBLData = [
              { data: notAvailableData, label: 'Not Available', type: 'line', fill: false, borderColor: 'red', },
              { data: lessData, label: 'Less', type: 'line', fill: false, borderColor: 'blue', },
              { data: availableData, label: 'Available' },
            ];

            this.overallOOSBLExcelData.labels = Object.keys(oosOverallData[0]);
            this.overallOOSBLExcelData.data = [...oosOverallData];

          } else {
            this.overallOOSBLData = [{ data: [], label: '' }]
            this.overallOOSBLLabels = []
            this.overallOOSBLExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.overallOOSBLData = [{ data: [], label: '' }]
          this.overallOOSBLLabels = []
          this.overallOOSBLExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching overall out of stock data!", true);
        }
      )
  }
  loadOOSMonthAvailable(filterString?) {
    Swal.showLoading();
    this.dashboardService.getOOSMonthAvailable(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let oosmonthAvailableData = response.data ? [...response.data] : [];

            this.monthAvailableOOSLabels = this.formatMonthAvailableBarData(oosmonthAvailableData).uniqueMonth
            this.monthAvailableOOSData = this.formatMonthAvailableBarData(oosmonthAvailableData).stackedBarChartData

            this.monthAvailableOOSExcelData.labels = Object.keys(oosmonthAvailableData[0]);
            this.monthAvailableOOSExcelData.data = [...oosmonthAvailableData];

          } else {
            this.monthAvailableOOSData = [{ data: [], label: '' }]
            this.monthAvailableOOSLabels = []
            this.monthAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.monthAvailableOOSData = [{ data: [], label: '' }]
          this.monthAvailableOOSLabels = []
          this.monthAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching Out of stock month available data!", true);
        }
      )
  }
  loadOOSMonthNotAvailable(filterString?) {
    Swal.showLoading();
    this.dashboardService.getOOSMonthNotAvailable(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let oosMonthNotAvailableData = response.data ? [...response.data] : [];

            this.monthNotAvailableOOSLabels = this.formatMonthAvailableBarData(oosMonthNotAvailableData).uniqueMonth
            this.monthNotAvailableOOSData = this.formatMonthAvailableBarData(oosMonthNotAvailableData).stackedBarChartData

            this.monthNotAvailableOOSExcelData.labels = Object.keys(oosMonthNotAvailableData[0]);
            this.monthNotAvailableOOSExcelData.data = [...oosMonthNotAvailableData];

          } else {
            this.monthNotAvailableOOSData = [{ data: [], label: '' }]
            this.monthNotAvailableOOSLabels = []
            this.monthNotAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.monthNotAvailableOOSData = [{ data: [], label: '' }]
          this.monthNotAvailableOOSLabels = []
          this.monthNotAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching month not available data!", true);
        }
      )
  }
  loadOOSOOSMonthLess(filterString?) {
    Swal.showLoading();
    this.dashboardService.getOOSMonthLess(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let oosLessMonthData = response.data ? [...response.data] : [];

            this.lessMonthOOSLabels = this.formatMonthAvailableBarData(oosLessMonthData).uniqueMonth
            this.lessMonthNotAvailableOOSData = this.formatMonthAvailableBarData(oosLessMonthData).stackedBarChartData

            this.lessMonthOOSExcelData.labels = Object.keys(oosLessMonthData[0]);
            this.lessMonthOOSExcelData.data = [...oosLessMonthData];

          } else {
            this.lessMonthNotAvailableOOSData = [{ data: [], label: '' }]
            this.lessMonthOOSLabels = []
            this.lessMonthOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.lessMonthNotAvailableOOSData = [{ data: [], label: '' }]
          this.lessMonthOOSLabels = []
          this.lessMonthOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching less month available data!", true);
        }
      )
  }

  loadOOSStoreNotAvailable(filterString?) {

    Swal.showLoading();
    this.dashboardService.getOOSStoreNotAvailable(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let storeLessOOSData = response.data ? [...response.data] : [];
            this.storeLessOOSLabels = this.formatStoreNotAvailableBarData(storeLessOOSData).uniqueSku
            this.storeLessOOSData = this.formatStoreNotAvailableBarData(storeLessOOSData).stackedBarChartData

            this.storeLessOOSExcelData.labels = Object.keys(storeLessOOSData[0]);
            this.storeLessOOSExcelData.data = [...storeLessOOSData];

          } else {
            this.storeLessOOSData = [{ data: [], label: '' }]
            this.storeLessOOSLabels = []
            this.storeLessOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.storeLessOOSData = [{ data: [], label: '' }]
          this.storeLessOOSLabels = []
          this.storeLessOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching store not available data!", true);
        }
      )
  }

  loadOOSCityAvailable(filterString?) {

    Swal.showLoading();
    this.dashboardService.getOOSCityAvailable(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let ooscityAvailableData = response.data ? [...response.data] : [];

            this.cityAvailableOOSLabels = this.formatCityAvailableLineData(ooscityAvailableData).uniqueSku
            this.cityAvailableOOSData = this.formatCityAvailableLineData(ooscityAvailableData).stackedBarChartData
            console.log(this.cityAvailableOOSData)
            this.cityAvailableOOSExcelData.labels = Object.keys(ooscityAvailableData[0]);
            this.cityAvailableOOSExcelData.data = [...ooscityAvailableData];

          } else {
            this.cityAvailableOOSData = [{ data: [], label: '' }]
            this.cityAvailableOOSLabels = []
            this.cityAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.cityAvailableOOSData = [{ data: [], label: '' }]
          this.cityAvailableOOSLabels = []
          this.cityAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city available data!", true);
        }
      )
  }

  loadOOSCityNotAvailable(filterString?) {

    Swal.showLoading();
    this.dashboardService.getOOSCityNotAvailable(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let ooscityNotAvailableData = response.data ? [...response.data] : [];

            this.cityNotAvailableOOSLabels = this.formatCityNotAvailableLineData(ooscityNotAvailableData).uniqueSku;
            this.cityNotAvailableOOSData = this.formatCityNotAvailableLineData(ooscityNotAvailableData).stackedBarChartData;
            this.cityNotAvailableOOSExcelData.labels = Object.keys(ooscityNotAvailableData[0]);
            this.cityNotAvailableOOSExcelData.data = [...ooscityNotAvailableData];

          } else {
            this.cityNotAvailableOOSData = [{ data: [], label: '' }]
            this.cityNotAvailableOOSLabels = []
            this.cityNotAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.cityNotAvailableOOSData = [{ data: [], label: '' }]
          this.cityNotAvailableOOSLabels = []
          this.cityNotAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city not available data!", true);
        }
      )
  }

  loadOOSCityLess(filterString?) { //for less than 6 sku in cities

    Swal.showLoading();
    this.dashboardService.getOOSCityLess(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let oosCityLessData = response.data ? [...response.data] : [];

            this.lessSKUInCityOOSLabels = this.formatCityLessLineData(oosCityLessData).uniqueSku
            this.lessSKUInCityOOSData = this.formatCityLessLineData(oosCityLessData).stackedBarChartData

            this.lessSKUInCityOOSExcelData.labels = Object.keys(oosCityLessData[0]);
            this.lessSKUInCityOOSExcelData.data = [...oosCityLessData];

          } else {
            this.lessSKUInCityOOSData = [{ data: [], label: '' }]
            this.lessSKUInCityOOSLabels = []
            this.lessSKUInCityOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.lessSKUInCityOOSData = [{ data: [], label: '' }]
          this.lessSKUInCityOOSLabels = []
          this.lessSKUInCityOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city not available data!", true);
        }
      )
  }

  loadOOSTotalVisitsInCity(filterString?) {

    Swal.showLoading();
    this.dashboardService.getOOSTotalVisit(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let oosTotalVisitsInCityData = response.data ? [...response.data] : [];

            this.totalVisitsInCityOOSData = oosTotalVisitsInCityData.map(eachVisitByCity => eachVisitByCity.value);

            this.totalVisitsInCityOOSLabels = oosTotalVisitsInCityData.map(eachVisitByCity => eachVisitByCity.city);

            this.totalVisitsInCityOOSExcelData.labels = Object.keys(oosTotalVisitsInCityData[0]);
            this.totalVisitsInCityOOSExcelData.data = [...oosTotalVisitsInCityData];
          } else {
            this.totalVisitsInCityOOSData = [{ data: [], label: '' }]
            this.totalVisitsInCityOOSLabels = []
            this.totalVisitsInCityOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.totalVisitsInCityOOSData = [{ data: [], label: '' }]
          this.totalVisitsInCityOOSLabels = []
          this.totalVisitsInCityOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visits per city data!", true);
        }
      )
  }

  loadOOSWeekNotAvailable(filterString?) {

    Swal.showLoading();
    this.dashboardService.getOOSWeekNotAvailable(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let oosWeekNotAvailableData = response.data ? [...response.data] : [];
            this.weekNotAvailableOOSLabels = Object.keys(this.formatWeekNotAvailableData(oosWeekNotAvailableData));
            this.weekNotAvailableOOSData = this.formatWeekNotAvailableData(oosWeekNotAvailableData);
          } else {
            this.weekNotAvailableOOSData = {};
            this.weekNotAvailableOOSLabels = [];
          }
        },
        error => {
          Swal.close();
          this.weekNotAvailableOOSData = {};
          this.weekNotAvailableOOSLabels = [];
          this.handleNotification("Error in fetching SKU not available in weeks data!", true);
        }
      )
  }


  // Distribution Check Methods

  loadDCSubmittedQnnByChannel(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSubmittedQnnByChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let dcSubmittedQnnByChannel = response.data ? [...response.data] : [];
            this.submittedQnnByChannelDCLabels = this.formatQnnByChannelDCData(dcSubmittedQnnByChannel).channels;
            this.submittedQnnByChannelDCData = [
              { data: this.formatQnnByChannelDCData(dcSubmittedQnnByChannel).channelValue }
            ];

            this.submittedQnnByChannelDCExcelData.labels = Object.keys(dcSubmittedQnnByChannel[0]);
            this.submittedQnnByChannelDCExcelData.data = [...dcSubmittedQnnByChannel];

          } else {
            this.submittedQnnByChannelDCData = [{ data: [], label: '' }];
            this.submittedQnnByChannelDCLabels = [];
            this.submittedQnnByChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.submittedQnnByChannelDCData = [{ data: [], label: '' }];
          this.submittedQnnByChannelDCLabels = [];
          this.submittedQnnByChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visit by channel data!", true);
        }
      )
  }

  loadTotalOutletsOfSkuInCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.getTotalOutletsInCity(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let totalOutletsOfSkuInCity = response.data ? [...response.data] : [];

            this.totalOutletsOfSkuInCityDCLabels = this.formatTotalOutletsOfSKUInCityDCData(totalOutletsOfSkuInCity).uniqueCity
            this.totalOutletsOfSkuInCityDCData = this.formatTotalOutletsOfSKUInCityDCData(totalOutletsOfSkuInCity).stackedBarChartData

            this.totalOutletsOfSkuInCityDCExcelData.labels = Object.keys(totalOutletsOfSkuInCity[0]);
            this.totalOutletsOfSkuInCityDCExcelData.data = [...totalOutletsOfSkuInCity];
          } else {
            this.totalOutletsOfSkuInCityDCData = [{ data: [], label: '' }];
            this.totalOutletsOfSkuInCityDCLabels = [];
            this.totalOutletsOfSkuInCityDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.totalOutletsOfSkuInCityDCData = [{ data: [], label: '' }];
          this.totalOutletsOfSkuInCityDCLabels = [];
          this.totalOutletsOfSkuInCityDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visit by channel data!", true);
        }
      )
  }

  loadDCSubmittedQnnByCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.getDCSubmittedQnnByCity(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let dcSubmittedQnnByCity = response.data ? [...response.data] : [];

            this.submittedQnnByCityDCLabels = this.formatQnnByCityDCData(dcSubmittedQnnByCity).city;
            this.submittedQnnByCityDCData = [
              { data: this.formatQnnByCityDCData(dcSubmittedQnnByCity).value }
            ];

            this.submittedQnnByCityDCExcelData.labels = Object.keys(dcSubmittedQnnByCity[0]);
            this.submittedQnnByCityDCExcelData.data = [...dcSubmittedQnnByCity];

          } else {
            this.submittedQnnByCityDCData = [{ data: [], label: '' }];
            this.submittedQnnByCityDCLabels = [];
            this.submittedQnnByCityDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.submittedQnnByCityDCData = [{ data: [], label: '' }];
          this.submittedQnnByCityDCLabels = [];
          this.submittedQnnByCityDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visit by city data!", true);
        }
      )
  }

  loadDCSubmittedQnnByCountry(filterString?) {
    Swal.showLoading();
    this.dashboardService.getDCSubmittedQnnByCountry(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let dcSubmittedQnnByCountry = response.data ? [...response.data] : [];
            this.submittedQnnByCountryDCLabels = this.formatQnnByCountryDCData(dcSubmittedQnnByCountry).country;
            this.submittedQnnByCountryDCData = [
              { data: this.formatQnnByCountryDCData(dcSubmittedQnnByCountry).value }
            ];

            this.submittedQnnByCountryDCExcelData.labels = Object.keys(dcSubmittedQnnByCountry[0]);
            this.submittedQnnByCountryDCExcelData.data = [...dcSubmittedQnnByCountry];

          } else {
            this.submittedQnnByCountryDCData = [{ data: [], label: '' }];
            this.submittedQnnByCountryDCLabels = [];
            this.submittedQnnByCountryDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.submittedQnnByCountryDCData = [{ data: [], label: '' }];
          this.submittedQnnByCountryDCLabels = [];
          this.submittedQnnByCountryDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visit by country data!", true);
        }
      )
  }

  loadTotalOutletsOfSkuInCountry(filterString?) {
    Swal.showLoading();
    this.dashboardService.getTotalOutletsInCountry(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let totalOutletsOfSkuInCountry = response.data ? [...response.data] : [];

            this.totalOutletsOfSkuInCountryDCLabels = this.formatTotalOutletsOfSKUInCountryDCData(totalOutletsOfSkuInCountry).uniqueCountry
            this.totalOutletsOfSkuInCountryDCData = this.formatTotalOutletsOfSKUInCountryDCData(totalOutletsOfSkuInCountry).stackedBarChartData

            this.totalOutletsOfSkuInCountryDCExcelData.labels = Object.keys(totalOutletsOfSkuInCountry[0]);
            this.totalOutletsOfSkuInCountryDCExcelData.data = [...totalOutletsOfSkuInCountry];
          } else {
            this.totalOutletsOfSkuInCountryDCData = [{ data: [], label: '' }];
            this.totalOutletsOfSkuInCountryDCLabels = [];
            this.totalOutletsOfSkuInCountryDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.totalOutletsOfSkuInCountryDCData = [{ data: [], label: '' }];
          this.totalOutletsOfSkuInCountryDCLabels = [];
          this.totalOutletsOfSkuInCountryDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total number of outlets in the country data!", true);
        }
      )
  }

  loadTotalOutletsOfSkuInChannel(filterString?) {
    Swal.showLoading();
    this.dashboardService.getTotalOutletsInChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let totalOutletsOfSkuInChannel = response.data ? [...response.data] : [];

            this.totalOutletsOfSkuInChannelDCLabels = this.formatTotalOutletsOfSKUInChannelDCData(totalOutletsOfSkuInChannel).uniqueChannel
            this.totalOutletsOfSkuInChannelDCData = this.formatTotalOutletsOfSKUInChannelDCData(totalOutletsOfSkuInChannel).stackedBarChartData

            this.totalOutletsOfSkuInChannelDCExcelData.labels = Object.keys(totalOutletsOfSkuInChannel[0]);
            this.totalOutletsOfSkuInChannelDCExcelData.data = [...totalOutletsOfSkuInChannel];
          } else {
            this.totalOutletsOfSkuInChannelDCData = [{ data: [], label: '' }];
            this.totalOutletsOfSkuInChannelDCLabels = [];
            this.totalOutletsOfSkuInChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.totalOutletsOfSkuInChannelDCData = [{ data: [], label: '' }];
          this.totalOutletsOfSkuInChannelDCLabels = [];
          this.totalOutletsOfSkuInChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total number of outlets in the channel data!", true);
        }
      )
  }

  loadSumOfBrandByCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSumOfOneBrandByCity(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let dcBrandByCityData = response.data ? [...response.data] : [];
            this.sumOfOneBrandByCityDCLabels = this.formatBrandPerCityData(dcBrandByCityData).uniqueCity;
            this.sumOfOneBrandByCityDCData = this.formatBrandPerCityData(dcBrandByCityData).stackedBarChartData;

            this.sumOfOneBrandByCityDCExcelData.labels = Object.keys(dcBrandByCityData[0]);
            this.sumOfOneBrandByCityDCExcelData.data = [...dcBrandByCityData];

          } else {
            this.sumOfOneBrandByCityDCData = [{ data: [], label: '' }]
            this.sumOfOneBrandByCityDCLabels = []
            this.sumOfOneBrandByCityDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.sumOfOneBrandByCityDCData = [{ data: [], label: '' }]
          this.sumOfOneBrandByCityDCLabels = []
          this.sumOfOneBrandByCityDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching sum of one brand by city data!", true);
        }
      )
  }

  loadSumOfBrandByCountry(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSumOfOneBrandByCountry(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let dcBrandByCountryData = response ? [...response.data] : [];
            this.sumOfOneBrandByCountryDCLabels = this.formatBrandPerCountryData(dcBrandByCountryData).uniqueCountry;
            this.sumOfOneBrandByCountryDCData = this.formatBrandPerCountryData(dcBrandByCountryData).stackedBarChartData;

            this.sumOfOneBrandByCountryDCExcelData.labels = Object.keys(dcBrandByCountryData[0]);
            this.sumOfOneBrandByCountryDCExcelData.data = [...dcBrandByCountryData];

          } else {
            this.sumOfOneBrandByCountryDCData = [{ data: [], label: '' }]
            this.sumOfOneBrandByCountryDCLabels = []
            this.sumOfOneBrandByCountryDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.sumOfOneBrandByCountryDCData = [{ data: [], label: '' }]
          this.sumOfOneBrandByCountryDCLabels = []
          this.sumOfOneBrandByCountryDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching sum of one brand by country data!", true);
        }
      )
  }

  loadSumOfBrandByChannel(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSumOfOneBrandByChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let dcBrandByChannelData = response ? [...response.data] : [];
            this.sumOfOneBrandByChannelDCLabels = this.formatBrandPerChannelData(dcBrandByChannelData).uniqueChannel;
            this.sumOfOneBrandByChannelDCData = this.formatBrandPerChannelData(dcBrandByChannelData).stackedBarChartData;

            this.sumOfOneBrandByChannelDCExcelData.labels = Object.keys(dcBrandByChannelData[0]);
            this.sumOfOneBrandByChannelDCExcelData.data = [...dcBrandByChannelData];

          } else {
            this.sumOfOneBrandByChannelDCData = [{ data: [], label: '' }]
            this.sumOfOneBrandByChannelDCLabels = []
            this.sumOfOneBrandByChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.sumOfOneBrandByChannelDCData = [{ data: [], label: '' }]
          this.sumOfOneBrandByChannelDCLabels = []
          this.sumOfOneBrandByChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching sum of one brand by country data!", true);
        }
      )
  }

  loadDCAvgPerSku(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCAvgPerSku(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let dcAvgPerSkuData = response.data ? [...response.data] : [];

            this.avgPerSkuDCData = dcAvgPerSkuData.map(avgPerSku => avgPerSku.value);

            this.avgPerSkuDCLabels = dcAvgPerSkuData.map(avgPerSku => avgPerSku.sku);

            this.avgPerSkuDCExcelData.labels = Object.keys(dcAvgPerSkuData[0]);
            this.avgPerSkuDCExcelData.data = [...dcAvgPerSkuData];
          } else {
            this.avgPerSkuDCData = [{ data: [], label: '' }]
            this.avgPerSkuDCLabels = []
            this.avgPerSkuDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgPerSkuDCData = [{ data: [], label: '' }]
          this.avgPerSkuDCLabels = []
          this.avgPerSkuDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching average per sku data!", true);
        }
      )
  }

  loadDCAvgPerBrand(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCAvgPerBrand(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let dcAvgPerBrandData = response.data ? [...response.data] : [];

            this.avgPerBrandDCData = dcAvgPerBrandData.map(avgPerSku => avgPerSku.value);

            this.avgPerBrandDCLabels = dcAvgPerBrandData.map(avgPerSku => avgPerSku.brand);

            this.avgPerBrandDCExcelData.labels = Object.keys(dcAvgPerBrandData[0]);
            this.avgPerBrandDCExcelData.data = [...dcAvgPerBrandData];
          } else {
            this.avgPerBrandDCData = [{ data: [], label: '' }]
            this.avgPerBrandDCLabels = []
            this.avgPerBrandDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgPerBrandDCData = [{ data: [], label: '' }]
          this.avgPerBrandDCLabels = []
          this.avgPerBrandDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching average per sku data!", true);
        }
      )
  }

  loadDCShelfShareSkuChannel(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCShelfShareSkuPerChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let dcAvgPerBrandData = response.data ? [...response.data] : [];

            this.avgSKUPerChannelDC = this.formatSSSkuChannel(dcAvgPerBrandData).stackedBarChartData;

            this.avgSKUPerChannelDCLabels = this.formatSSSkuChannel(dcAvgPerBrandData).uniqueChannel;

            this.avgSKUPerChannelDCExcelData.labels = Object.keys(dcAvgPerBrandData[0]);
            this.avgSKUPerChannelDCExcelData.data = [...dcAvgPerBrandData];
          } else {
            this.avgSKUPerChannelDC = [{ data: [], label: '' }]
            this.avgSKUPerChannelDCLabels = []
            this.avgSKUPerChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgSKUPerChannelDC = [{ data: [], label: '' }]
          this.avgSKUPerChannelDCLabels = []
          this.avgSKUPerChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching shelf share sku per channel!", true);
        }
      )
  }

  loadDCShelfShareBrandChannel(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCShelfShareBrandPerChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {

            let dcAvgPerBrandData = response.data ? [...response.data] : [];

            this.avgBrandByChannelDCData = this.formatSSBrandChannel(dcAvgPerBrandData).stackedBarChartData;

            this.avgBrandByChannelDCLabels = this.formatSSBrandChannel(dcAvgPerBrandData).uniqueChannel;

            this.avgBrandByChannelDCExcelData.labels = Object.keys(dcAvgPerBrandData[0]);
            this.avgBrandByChannelDCExcelData.data = [...dcAvgPerBrandData];
          } else {
            this.avgBrandByChannelDCData = [{ data: [], label: '' }]
            this.avgBrandByChannelDCLabels = []
            this.avgBrandByChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgBrandByChannelDCData = [{ data: [], label: '' }]
          this.avgBrandByChannelDCLabels = []
          this.avgBrandByChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching shelf share brand by channel!", true);
        }
      )
  }

  //Consumer Question Methods
  loadCQYesNoQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.getCQYesNoQuestion(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let cqYesNoQuestion = response.data ? [...response.data] : [];

            this.yesNoQuestionCQLabels = [...new Set(cqYesNoQuestion.map(item => item.question_statement))]
            this.yesNoQuestionCQData = this.formatCQYesNoQuestionBarData(cqYesNoQuestion);
            this.yesNoQuestionCQExcelData.labels = Object.keys(cqYesNoQuestion[0]);
            this.yesNoQuestionCQExcelData.data = [...cqYesNoQuestion];

          } else {
            this.yesNoQuestionCQData = [{ data: [], label: '' }]
            this.yesNoQuestionCQLabels = []
            this.yesNoQuestionCQExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.yesNoQuestionCQData = [{ data: [], label: '' }]
          this.yesNoQuestionCQLabels = []
          this.yesNoQuestionCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching yes no question data!", true);
        }
      )
  }

  loadCQRatingOneToThreeQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.getCQRatingOneToThreeQuestion(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let cqRatingOneToThreeQuestion = response.data ? [...response.data] : [];

            this.ratingOneToThreeCQLabels = [...new Set(cqRatingOneToThreeQuestion.map(item => item.question_statement))]
            this.ratingOneToThreeQuestionCQData = this.formatCQOneToThreeBarData(cqRatingOneToThreeQuestion);
            this.ratingOneToThreeCQExcelData.labels = Object.keys(cqRatingOneToThreeQuestion[0]);
            this.ratingOneToThreeCQExcelData.data = [...cqRatingOneToThreeQuestion];

          } else {
            this.ratingOneToThreeQuestionCQData = [{ data: [], label: '' }]
            this.ratingOneToThreeCQLabels = []
            this.ratingOneToThreeCQExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.ratingOneToThreeQuestionCQData = [{ data: [], label: '' }]
          this.ratingOneToThreeCQLabels = []
          this.ratingOneToThreeCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching rating one to three question data!", true);
        }
      )
  }

  loadCQRatingOneToFiveQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.getCQRatingOneToFiveQuestion(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let cqRatingOneToFiveQuestion = response.data ? [...response.data] : [];

            this.ratingOneToFiveCQLabels = [...new Set(cqRatingOneToFiveQuestion.map(item => item.question_statement))]
            this.ratingOneToFiveQuestionCQData = this.formatCQOneToFiveBarData(cqRatingOneToFiveQuestion);
            this.ratingOneToFiveCQExcelData.labels = Object.keys(cqRatingOneToFiveQuestion[0]);
            this.ratingOneToFiveCQExcelData.data = [...cqRatingOneToFiveQuestion];

          } else {
            this.ratingOneToFiveQuestionCQData = [{ data: [], label: '' }]
            this.ratingOneToFiveCQLabels = []
            this.ratingOneToFiveCQExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching City not available data!", true);
          }
        },
        error => {
          Swal.close();
          this.ratingOneToFiveQuestionCQData = [{ data: [], label: '' }]
          this.ratingOneToFiveCQLabels = []
          this.ratingOneToFiveCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching rating one to five question data!", true);
        }
      )
  }

  loadCQNumericQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.getCQNumericQuestion(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let cqNumericQuestion = response.data ? [...response.data] : [];

            this.numericCQLabels = this.formatCQNumericQuestionData(cqNumericQuestion).question_statement;
            this.numericQuestionCQData = [{ data: this.formatCQNumericQuestionData(cqNumericQuestion).value }];
            this.numericCQExcelData.labels = Object.keys(cqNumericQuestion[0]);
            this.numericCQExcelData.data = [...cqNumericQuestion];

          } else {
            this.numericQuestionCQData = [{ data: [], label: '' }]
            this.numericCQLabels = []
            this.numericCQExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.numericQuestionCQData = [{ data: [], label: '' }]
          this.numericCQLabels = []
          this.numericCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching numeric question data!", true);
        }
      )
  }

  loadCQRatingOneToTen(filterString?) {
    Swal.showLoading();
    this.dashboardService.getCQRatingOneToTen(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            let cqRatingOneToTen = response.data ? [...response.data] : [];
            this.ratingOneToTenCQLabels = [...new Set(cqRatingOneToTen.map(item => item.question_statement))]
            this.ratingOneToTenCQData = this.formatCQRatingOneToTenBarData(cqRatingOneToTen);
            this.ratingOneToTenCQExcelData.labels = Object.keys(cqRatingOneToTen[0]);
            this.ratingOneToTenCQExcelData.data = [...cqRatingOneToTen];

          } else {
            this.ratingOneToTenCQData = [{ data: [], label: '' }]
            this.ratingOneToTenCQLabels = []
            this.ratingOneToTenCQExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching City not available data!", true);
          }
        },
        error => {
          Swal.close();
          this.ratingOneToTenCQData = [{ data: [], label: '' }]
          this.ratingOneToTenCQLabels = []
          this.ratingOneToTenCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching yes no question data!", true);
        }
      )
  }



  // helper methods to format API response data

  formatCQYesNoQuestionBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let yesData = []
    let noData = []

    dataToFormat.forEach(pair => {
      yesData = dataToFormat
        .map(uniqueStackData => uniqueStackData.yes);
      noData = dataToFormat
        .map(uniqueStackData => uniqueStackData.no)
    });
    stackedBarChartData.push({
      data: yesData,
      label: 'Yes',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: noData,
      label: 'No',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }

  formatCQRatingOneToTenBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let ratingOne = []
    let ratingTwo = []
    let ratingThree = []
    let ratingFour = []
    let ratingFive = []
    let ratingSix = []
    let ratingSeven = []
    let ratingEight = []
    let ratingNine = []
    let ratingTen = []

    dataToFormat.forEach(pair => {
      ratingOne = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_one);
      ratingTwo = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_two);
      ratingThree = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_three);
      ratingFour = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_four);
      ratingFive = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_five);
      ratingSix = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_six);
      ratingSeven = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_seven);
      ratingEight = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_eight);
      ratingNine = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_nine);
      ratingTen = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_ten);

    });
    stackedBarChartData.push({
      data: ratingOne,
      label: 'Rating 1',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTwo,
      label: 'Rating 2',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingThree,
      label: 'Rating 3',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFour,
      label: 'Rating 4',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFive,
      label: 'Rating 5',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingSix,
      label: 'Rating 6',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingSeven,
      label: 'Rating 7',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingEight,
      label: 'Rating 8',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingNine,
      label: 'Rating 9',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTen,
      label: 'Rating 10',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }

  formatCQOneToThreeBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let ratingOne = []
    let ratingTwo = []
    let ratingThree = []

    dataToFormat.forEach(pair => {
      ratingOne = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_one);
      ratingTwo = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_two);
      ratingThree = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_three);
    });
    stackedBarChartData.push({
      data: ratingOne,
      label: 'Rating 1',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTwo,
      label: 'Rating 2',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingThree,
      label: 'Rating 3',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }

  formatCQOneToFiveBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let ratingOne = []
    let ratingTwo = []
    let ratingThree = []
    let ratingFour = []
    let ratingFive = []

    dataToFormat.forEach(pair => {
      ratingOne = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_one);
      ratingTwo = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_two);
      ratingThree = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_three);
      ratingFour = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_four);
      ratingFive = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_five);
    });
    stackedBarChartData.push({
      data: ratingOne,
      label: 'Rating 1',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTwo,
      label: 'Rating 2',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingThree,
      label: 'Rating 3',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFour,
      label: 'Rating 4',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFive,
      label: 'Rating 5',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }

  formatCQNumericQuestionData(dataToFormat: Array<any>) {
    let question_statement = [];
    let value = [];

    dataToFormat.map(numericData => {
      question_statement.push(numericData.question_statement);
      value.push(numericData.value)
    });

    return {
      question_statement,
      value
    }
  }

  formatStackedBarData(dataToFormat: Array<any>) {
    let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))],
      order = Object.fromEntries(uniqueSku.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { month, value, sku }) => { r[month] ||= { data: Array(uniqueSku.length).fill(null), label: month, stack: 'sameStack' }; r[month].data[order[sku]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueSku
    }
  }

  formatCountryMaxBarData(dataToFormat: Array<any>) {
    let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))],
      order = Object.fromEntries(uniqueSku.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { country, value, sku }) => { r[country] ||= { data: Array(uniqueSku.length).fill(null), label: country }; r[country].data[order[sku]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueSku
    }
  }

  formatMonthAvailableBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.month))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { month, value, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[month]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }

  formatStoreNotAvailableBarData(dataToFormat: Array<any>) {
    let uniqueSku = [...new Set(dataToFormat.map(item => item.store))],
      order = Object.fromEntries(uniqueSku.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { store, value, sku }) => { r[sku] ||= { data: Array(uniqueSku.length).fill(null), label: sku }; r[sku].data[order[store]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueSku
    }
  }

  formatCityAvailableLineData(dataToFormat: Array<any>) {
    let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))];
    let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
    let stackedBarChartData = []
    uniqueCities.forEach(uniqueCity => {
      let values = []
      dataToFormat.forEach(dTF => {
        if (dTF.city == uniqueCity) {
          values.push(dTF.value);
        } else {
          values.push(0);
        }
      })
      stackedBarChartData.push({data: values, label: uniqueCity})
    })

    return {
      stackedBarChartData,
      uniqueSku
    }
  }

  formatCityNotAvailableLineData(dataToFormat: Array<any>) {
    let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))];
    let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
    let stackedBarChartData = []
    uniqueCities.forEach(uniqueCity => {
      let values = []
      dataToFormat.forEach(dTF => {
        if (dTF.city == uniqueCity) {
          values.push(dTF.value);
        } else {
          values.push(0);
        }
      })
      stackedBarChartData.push({data: values, label: uniqueCity})
    })

    return {
      stackedBarChartData,
      uniqueSku
    }
  }

  formatCityLessLineData(dataToFormat: Array<any>) {
    let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))];
    let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
    let stackedBarChartData = []
    uniqueCities.forEach(uniqueCity => {
      let values = []
      dataToFormat.forEach(dTF => {
        if (dTF.city == uniqueCity) {
          values.push(dTF.value);
        } else {
          values.push(0);
        }
      })
      stackedBarChartData.push({data: values, label: uniqueCity})
    })

    return {
      stackedBarChartData,
      uniqueSku
    }
  }

  formatCountryMinBarData(dataToFormat: Array<any>) {
    let uniqueCountry = [...new Set(dataToFormat.map(item => item.country))],
      order = Object.fromEntries(uniqueCountry.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { country, value, sku }) => { r[sku] ||= { data: Array(uniqueCountry.length).fill(null), label: sku }; r[sku].data[order[country]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCountry
    }
  }

  formatAnswerPerCityData(dataToFormat: Array<any>) {
    let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
    let stackedBarChartData = []
    let values = []
    uniqueCities.forEach(uniqueCity => {
      dataToFormat.forEach(dTF => {
        if (dTF.city == uniqueCity) {
          values.push(dTF.value);
        }
      })
    })
    stackedBarChartData.push({ data: values, label: 'Outlets' })

    return {
      stackedBarChartData,
      uniqueCities
    }
  }

  formatWeekNotAvailableData(dataToFormat: Array<any>) {
    let tableData = {
    };
    let uniqueWeeks = [...new Set(dataToFormat.map(item => item.week))];
    uniqueWeeks.forEach(week => {
      tableData[week] = dataToFormat.filter(weekData => weekData.week == week)
    });

    return tableData;
  }

  formatQnnByChannelDCData(dataToFormat: Array<any>) {
    let channels = [];
    let channelValue = [];

    dataToFormat.map(channaleData => {
      channels.push(channaleData.channel);
      channelValue.push(channaleData.value)
    });

    return {
      channels,
      channelValue
    }
  }

  formatQnnByCityDCData(dataToFormat: Array<any>) {
    let city = [];
    let value = [];

    dataToFormat.map(cityData => {
      city.push(cityData.city);
      value.push(cityData.value)
    });

    return {
      city,
      value
    }
  }

  formatQnnByCountryDCData(dataToFormat: Array<any>) {
    let country = [];
    let value = [];

    dataToFormat.map(countryData => {
      country.push(countryData.country);
      value.push(countryData.value)
    });

    return {
      country,
      value
    }
  }

  formatTotalOutletsOfSKUInCityDCData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, city, value }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }

  formatSSSkuChannel(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, channel, value }) => { r[sku] ||= { data: Array(uniqueChannel.length).fill(null), label: sku }; r[sku].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }

  formatSSBrandChannel(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { brand, channel, value }) => { r[brand] ||= { data: Array(uniqueChannel.length).fill(null), label: brand }; r[brand].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }

  formatTotalOutletsOfSKUInCountryDCData(dataToFormat: Array<any>) {
    let uniqueCountry = [...new Set(dataToFormat.map(item => item.country))],
      order = Object.fromEntries(uniqueCountry.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, country, value }) => { r[sku] ||= { data: Array(uniqueCountry.length).fill(null), label: sku }; r[sku].data[order[country]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCountry
    }
  }

  formatTotalOutletsOfSKUInChannelDCData(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, channel, value }) => { r[sku] ||= { data: Array(uniqueChannel.length).fill(null), label: sku }; r[sku].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }
  formatBrandPerCityData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, brand }) => { r[brand] ||= { data: Array(uniqueCity.length).fill(null), label: brand }; r[brand].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }

  formatBrandPerCountryData(dataToFormat: Array<any>) {
    let uniqueCountry = [...new Set(dataToFormat.map(item => item.country))],
      order = Object.fromEntries(uniqueCountry.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { country, value, brand }) => { r[brand] ||= { data: Array(uniqueCountry.length).fill(null), label: brand }; r[brand].data[order[country]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCountry
    }
  }

  formatBrandPerChannelData(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { channel, value, brand }) => { r[brand] ||= { data: Array(uniqueChannel.length).fill(null), label: brand }; r[brand].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }

  resetFilter() {

    if (this.currentQnnView == 'pm')
      this.refreshPriceMonitorChartApis();

    if (this.currentQnnView == 'oos')
      this.refreshOutOfStockChartApis();

    if (this.currentQnnView == 'dc')
      this.refreshDistributionCheckChartApis();

    if (this.currentQnnView == 'cq')
      this.refreshConsumerQuestionnaireChartApis();
  }


  // helper methods

  set data(value: string) {
    this.dataService.serviceData = value
  }

  navigateToOptionQuestionChart() {
    this.dataService.serviceData = this.currentFilterString
    this.router.navigate(['/consumer-question', 'view-option-question-charts']);
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }


}
