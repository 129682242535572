import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PortalService } from '../_services';
import Swal from "sweetalert2";

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { fas } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-portal-user',
  templateUrl: './portal-user.component.html',
  styleUrls: ['./portal-user.component.scss']
})
export class PortalUserComponent implements AfterViewInit, OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();
  public portalUser$ = new Subject<any>();
  public erroStatus: Boolean;
  public successStatus: Boolean;
  public portalUsers = [];
  accessToken:string;
  constructor(
    public portalService: PortalService,
    ) {

  }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');
    this.getPortalUser()
  }

  ngAfterViewInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      stateDuration: -1,
      stateSaveCallback: function (settings, data) {
          const ls =  JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance + window.location.pathname ) )
          if(ls){
              if(ls.start !== data.start)
              localStorage.setItem( 'DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data) )
          }else {
              localStorage.setItem( 'DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data) )
          }
          
      },
      stateLoadCallback: function (settings) {
          return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance + window.location.pathname ) )
      }
    };
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  getPortalUser() {
    Swal.showLoading();
    this.portalService.getPortalUser()
      .subscribe(res => {
        Swal.close();
        if (res.success) {
          this.portalUsers = res.data.results;
          this.portalUser$.next(res.data.results);
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error.error_message
          });
        }
      }, (error) => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong in fetching portal users!',
          timer: 1500
        });
      })
  }

  searchText(searchText:any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  removePortalUser(userId) {
    this.portalService.deletePortalUser(userId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "User deleted successfully!"
        }).then(() => {
          this.getPortalUser();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting user!"
        })
      })
  }

}
