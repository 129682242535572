<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add Retailers to Operations'"
              [subTitle]="'You can add retailers to operations from here'"></app-top-title>
          </div>

          <div class="bg-white mb-5">
            <div class="card-box">
              <form class="" role="form" [formGroup]="retailerForm"
                (ngSubmit)="isUpdateMode ? editRetailer() : addRetailer()" onkeydown="return event.key != 'Enter';">
                <div class="row px-4 pb-4">
                  <div class="col-md-7" *ngIf="!isUpdateMode" style="width: 100%;">
                    <div class="form-group">
                      <label class="col-form-label" for="retailer">Retailer<span style="color:#ff0000">*</span></label>
                      <div class="editor-tag">
                        <span class="tag-item" *ngFor="let retailer of retailerNames">
                          {{retailer}}
                          <span class="remove-tag" (click)="removeTag(retailer)">×</span>
                        </span>
                        <input placeholder="Type then press enter.." #retailerInput class="input-tag" type="text"
                          id="retailer" (keyup)="onKeyUp($event, retailerInput.value)" formControlName="name"
                          (ngModelChange)="updateValidator()">
                      </div>
                      <div *ngIf="invalidRetailerLength">
                        <small class="text-danger">
                          Retailer is required. </small>
                      </div>
                      <small style="color:#53a7db"><i class="fa fa-info-circle" aria-hidden="true"></i><em>&nbsp;Type and press Enter to add retailer.</em></small>
                    </div>
                    
                  </div>

                  <div class="col-md-5" *ngIf="isUpdateMode" style="width: 100%;">
                    <div class="form-group">
                      <label class="col-form-label" for="retailer">Retailer<span style="color:#ff0000">*</span></label>
                      <input type="text" class="form-control" formControlName="name" [class.valid]="retailerForm.get('name').valid && 
                      (retailerForm.get('name').dirty || retailerForm.get('name').touched)" [class.invalid]="retailerForm.get('name').invalid && 
                      (retailerForm.get('name').dirty || retailerForm.get('name').touched)">
                      <div
                        *ngIf="retailerForm.get('name').invalid && 
                                                                   retailerForm.get('name').errors && 
                                                                   (retailerForm.get('name').dirty || retailerForm.get('name').touched)">
                        <small class="text-danger" *ngIf="retailerForm.get('name').hasError('required')">
                          Retailer is required. </small>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row px-5 pb-3 d-flex">
                  <div class="form-group">
                    <button *ngIf="!isUpdateMode" type="submit" class="btn btn-success px-5 mr-2">Add</button>
                    <button *ngIf="isUpdateMode" type="submit" class="btn btn-success px-5 mr-2">Update</button>
                    <button (click)="toggleUpdateMode()" type="button" class="btn btn-danger px-5">Cancel</button>
                  </div>
                </div>


              </form>
            </div>
            <!-- end row -->

            <!-- end row -->
          </div>
          <div class="mt-1 p-2 bg-white mb-5">

            <div class="mt-2 mb-3">

              <div class="float-right">
                <div id="import_csv" class="file btn btn-lg btn-sm btn-success rounded-pill py-2 px-4 mr-3"
                  style="font-size: 16px;">
                  Import
                  <input id="input_csv" type="file" name="file" (change)="importRetailers($event)" accept=".csv" />
                </div>
              </div>
            </div>

            <table  class="custom-table"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>Retailer Id</th>
                  <th>Retailer Name</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let retailer of retailers">
                  <td>{{ retailer.id }}</td>
                  <td>{{ retailer.name }}</td>
                  <td><a [routerLink]="" (click)="fetchRetailer(retailer)" style="color:'blue';">Edit</a></td>
                  <td>
                    <a [routerLink]="" (click)="removeRetailer(retailer.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>