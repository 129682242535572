<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Questionnaires'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="'Questionnaires'"
              [subTitle]="'Create, view and export questionnaire'">
            </app-top-title>
          </div>
          <div class="mt-5 p-4" style="background-color: white;">


            <div class="mt-2 mb-4">
              <a routerLink="/create-questionnaire" class="btn btn-info rounded-pill pl-sm-1 pr-sm-1 pl-md-4 pr-md-4">Create</a>
              &nbsp;
              <!-- <span class="import-btn">
                              <button type="submit" style="background-color:'#8836be';color:'white'" class="btn rounded-pill pl-sm-1 pr-sm-1 pl-md-4 pr-md-4" data-toggle="tooltip" data-placement="top" title="Upload CSV File">Import</button> <input type="file" name="import" />&nbsp;</span> -->
              <!-- <button type="submit" style="background-color:'#be3d36';color:'white'"
                class="btn rounded-pill pl-sm-1 pr-sm-1 pl-md-4 pr-md-4">Export</button> -->
            </div>


            <!-- end row -->

            <table  class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="questionnaires">
              <thead>
                <tr>
                  <th>Questionnaire ID</th>
                  <th>Questionnaire name</th>
                  <th>Questionnaires Type</th>
                  <th>No. of Questions</th>
                  <th>Initiated Date</th>
                  <th>Country</th>
                  <th>City</th>
                  <th>User Tag</th>
                  <th>Edit</th>
                  <th>Operation</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let questionnaire of questionnaires">
                  <td data-column="Questionnaire ID"><a routerLink="/view-single-questionnaire/{{ questionnaire.id}}">{{ questionnaire.id}}</a></td>
                  <td data-column="Questionnaires">
                    <a routerLink="/view-single-questionnaire/{{ questionnaire.id}}">{{ questionnaire.name}}</a>
                  </td>
                  <td data-column="Questionnaires Type">{{ questionnaire.questionnaire_type}}</td>
                  <td data-column="No. Questionnaires">{{ questionnaire.number_of_questions}}</td>
                  <td data-column="Initiated Date">{{ questionnaire.initiated_data}}</td>
                  <td data-column="Country">{{ formatTableViews(questionnaire?.country).join(', ')}}</td>
                  <td data-column="City">{{ formatTableViews(questionnaire?.city).join(', ')}}</td>
                  <td data-column="Tag">
                    <span *ngFor="let qnt of questionnaire?.tags">
                      <a routerLink="/single-field-work/{{qnt}}">{{qnt}}</a>&nbsp;
                    </span>
                    </td>
                  <td data-column="Edit" class="notToExport">
                    <a routerLink="/create-questionnaire/{{ questionnaire.id}}">Edit</a>
                  </td>
                  <td data-column="Add Qquestion" class="notToExport">
                    <a routerLink="/create-question/{{ questionnaire.id}}">Add/Edit Question</a>
                  </td>
                  <td data-column="Delete" class="notToExport">
                    <a [routerLink]="" (click)="removeQuestionnaire(questionnaire.id)" style="color:'red'">Delete</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->


</body>

</html>
