<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Portal Users'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="'View Portal Users'" [subTitle]="'You can view all portal users from here.'">
            </app-top-title>
          </div>

          <div class="mt-1 p-2" style="background-color: 'white';">

            <div class="mt-2 mb-3">
              <a routerLink="/create-portal-user"
                class="btn btn-info rounded-pill pl-sm-1 pr-sm-1 pl-md-4 pr-md-4">Create</a> &nbsp;
              <!-- <app-top-title [title]="'Create Field Worker'" [subTitle]="'Create New Field Worker Now'" ></app-top-title> -->
            </div>
          
            <!-- end row -->
            <table  class="custom-table"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="portalusers">
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Full Name</th>
                  <th>Nationality</th>
                  <th>Position</th>
                  <th>Role</th>
                  <th>Contact Number</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of portalUsers">
                  <td>{{ user.id }}</td>
                  <td>{{ user.fullname }}</td>
                  <td>{{ user.nationality }}</td>
                  <td>{{ user.position }}</td>
                  <td>{{ user.role }}</td>
                  <td>{{ user.contact_number }}</td>
                  <td>
                    <a [routerLink]="['/edit-portal-user',user.id]"> Edit </a>
                  </td>
                  <td>
                    <a [routerLink]="" (click)="removePortalUser(user.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
          </div>

          <br /><br />
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>