<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add SKUs to Operations'"
              [subTitle]="'You can add SKUs to the operations from here'"></app-top-title>
          </div>
          <div class="bg-white mb-5">
            <div class="card-box">

              <form class="" role="form" [formGroup]="skuForm" (ngSubmit)="updateSku()"
                onkeydown="return event.key != 'Enter';">

                <div class="row d-flex px-4 pt-4">
                  <div class="col-md-4" style="width: 100%;">
                    <div class="form-group">
                      <label class=" col-form-label">Country<span style="color:#ff0000">*</span></label>
                      <ng-multiselect-dropdown style="max-width: fit-content;" name="country" id="selCountry"
                        [placeholder]="'Select Country'" [data]="countries" [settings]="countryDropdownSettings"
                        [formControl]="skuForm.controls['country']"
                        [class.valid]="skuForm.get('country').valid && 
                                                                (skuForm.get('country').dirty || skuForm.get('country').touched)"
                        [class.invalid]="skuForm.get('country').invalid && 
                                                                (skuForm.get('country').dirty || skuForm.get('country').touched)">
                      </ng-multiselect-dropdown>
                      <div
                        *ngIf="skuForm.get('country').invalid && 
                                                                   skuForm.get('country').errors && 
                                                                   (skuForm.get('country').dirty || skuForm.get('country').touched)">
                        <small class="text-danger" *ngIf="skuForm.get('country').hasError('required')">
                          Country is required. </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" style="width: 100%;">
                    <div class="form-group">
                      <label class="col-form-label" for="category">Categories <span
                          style="color:#ff0000">*</span></label>
                      <ng-multiselect-dropdown name="category" id="selCategory" [placeholder]="'Select Category'"
                        [data]="categories" [settings]="dropdownSettings" (onSelect)="loadBrandsByCategory($event)"
                        (onDeSelect)="resetCategories($event)" [formControl]="skuForm.controls['category']"
                        [class.valid]="skuForm.get('category').valid && 
                                                                (skuForm.get('category').dirty || skuForm.get('category').touched)"
                        [class.invalid]="skuForm.get('category').invalid && 
                                                                (skuForm.get('category').dirty || skuForm.get('category').touched)">
                      </ng-multiselect-dropdown>
                      <div
                        *ngIf="skuForm.get('category').invalid && 
                                                                   skuForm.get('category').errors && 
                                                                   (skuForm.get('category').dirty || skuForm.get('category').touched)">
                        <small class="text-danger" *ngIf="skuForm.get('category').hasError('required')">
                          Category is required. </small>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4" style="width: 100%;">
                    <div class="form-group">
                      <label class="col-form-label" for="brand">Brand <span style="color:#ff0000">*</span></label>
                      <ng-multiselect-dropdown name="brand" id="selBrand" [placeholder]="'Select Brand'" [data]="brands"
                        [settings]="dropdownSettings" [formControl]="skuForm.controls['brand']"
                        [class.valid]="skuForm.get('brand').valid && 
                                                                (skuForm.get('brand').dirty || skuForm.get('brand').touched)"
                        [class.invalid]="skuForm.get('brand').invalid && 
                                                                (skuForm.get('brand').dirty || skuForm.get('brand').touched)">
                      </ng-multiselect-dropdown>
                      <div
                        *ngIf="skuForm.get('brand').invalid && 
                                                                   skuForm.get('brand').errors && 
                                                                   (skuForm.get('brand').dirty || skuForm.get('brand').touched)">
                        <small class="text-danger" *ngIf="skuForm.get('brand').hasError('required')">
                          Brand is required. </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-flex px-4 pb-4">

                  <div class="col-md-6">


                    <div class="form-group">
                      <label class="col-form-label">SKU List<span style="color:#ff0000">*</span></label>
                      <input placeholder="Enter updated value" class="form-control" type="text" formControlName="name"
                        [class.valid]="skuForm.get('name').valid && 
                                                                (skuForm.get('name').dirty || skuForm.get('name').touched)"
                        [class.invalid]="skuForm.get('name').invalid && 
                                                                (skuForm.get('name').dirty || skuForm.get('name').touched)">
                      <div
                        *ngIf="skuForm.get('name').invalid && 
                                                                   skuForm.get('name').errors && 
                                                                   (skuForm.get('name').dirty || skuForm.get('name').touched)">
                        <small class="text-danger" *ngIf="skuForm.get('name').hasError('required')">
                          SKU is required. </small>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row px-5 pb-3 d-flex">
                  <div class="form-group">
                    <button type="submit" class="btn btn-success px-5 mr-2">Update</button>
                    <button (click)="toggleUpdateMode()" type="button" class="btn btn-danger px-5">Cancel</button>
                  </div>
                </div>

              </form>
            </div>

            <!-- end row -->
          </div>
          <div class="mt-1 p-2 bg-white mb-5">

            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>SKU Id</th>
                  <!-- <th>Country</th> -->
                  <th>Cateogry</th>
                  <th>Brand</th>
                  <th>SKUs</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sku of skus">
                  <td>{{ sku.id }}</td>
                  <!-- <td>{{ formatTableViews(sku?.country).join(', ') }}</td> -->
                  <td>{{ sku.category?.name }}</td>
                  <td>{{ sku.brand?.name }}</td>
                  <td>{{ sku.name }}</td>
                  <td>
                    <a [routerLink]="" (click)="fetchSKU(sku)" style="color:'blue';">Edit</a>
                  </td>
                  <td>
                    <a [routerLink]="" (click)="removeSKU(sku.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>
