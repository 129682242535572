import { SnapOperationService } from './../../_services/snap-operation.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-snap-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class SnapCityComponent implements OnInit {

  cities: Array<any> = [];
  cityForm: FormGroup;
  accessToken: string;

  cityIdToUpdate: Number = null;
  countryDropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public countries: Array<any> = [];


  constructor(
    private fb: FormBuilder,
    private operationService: SnapOperationService
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');
    this.loadCountries();

    this.cityForm = this.fb.group({
      name: ['', [Validators.required]],
      country: ['', [Validators.required]],
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }

    this.countryDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.listAllCities();
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  loadCountries() {
    Swal.showLoading();
    this.operationService.getCountries().subscribe(response => {
      Swal.close();
      if (response.success) {
        this.countries = response.data?.results;
        this.rerender();
      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching countries!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching countries!"
      });
    })
  }

  listAllCities() {
    Swal.showLoading();
    this.operationService.getCities()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.cities = response.data?.results;
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching cities!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching cities!"
        });
      })
  }

  removeCity(cityId) {
    this.operationService.removeCity(cityId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "City deleted successfully!"
        }).then(res => {
          this.listAllCities();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting city!"
        })
      })
  }

  updateCity() {
    if (this.cityForm.invalid) {
      for (var i in this.cityForm.controls) {
        this.cityForm.controls[i].markAsDirty();
        this.cityForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.cityIdToUpdate) {
        this.operationService.updateCity(this.cityIdToUpdate, this.cityForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "City updated successfully!"
                }).then(() => {
                  this.cityIdToUpdate = null;
                  this.listAllCities();
                  this.cityForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating city!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding city!"
              });
            }
          )
      }
    }

  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
  // helper methods
  fetchCity(city) {
    this.cityIdToUpdate = city.id || null;
    this.cityForm = this.fb.group({
      name: [city.name, [Validators.required]],
      country: [[{
        'id': city.country_id,
        'name': city.country_name,
      }], [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.cityForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
