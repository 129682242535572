<div class="row">
    <div class="col-12">
        <div class="card-box">
            <div class="row">
                <div class="col-12">
                    <div class="p-2">
                        <form class="form-horizontal" role="form">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="example-fileinput">Profile Picture</label>
                                <div class="col-md-9">
                                    <div >
                                        <img src="../../assets/images/user-1.jpg" alt="user-img" title="Mat Helme" class="rounded-circle img-thumbnail avatar-md">&nbsp;
                                        <input type="file" />
                                      </div>

                                    <!-- <input type="file" class="form-control" id="example-fileinput"> -->
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="simpleinput">User ID<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <input type="text" id="simpleinput" class="form-control" value="Some text value...">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="example-placeholder">Field Worker User<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <input type="text" id="example-placeholder" class="form-control" placeholder="placeholder">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Nationality<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <select class="form-control">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="example-date">Date of Birth<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <input class="form-control" id="example-date" type="date" name="date">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="example-placeholder">Contact Number<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <input type="text" id="example-placeholder" class="form-control" placeholder="placeholder">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Country of Operation<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <ng-multiselect-dropdown
                                        [placeholder]="'Country of Operation'"
                                        [settings]="dropdownSettings"
                                        [data]="dropdownList"
                                        [(ngModel)]="selectedItems"
                                        (onSelect)="onItemSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"
                                        >
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">City Of Operation <span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <ng-multiselect-dropdown
                                    [placeholder]="'Country of City'"
                                    [settings]="dropdownSettings"
                                    [data]="cityList"
                                    [(ngModel)]="selectedcity"
                                    (onSelect)="onItemSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                    >
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="example-email">Email<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <input type="email" id="example-email" name="example-email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="example-password">Password<span style="color:#ff0000">*</span></label>
                                <div class="col-md-9">
                                    <input type="password" id="example-password" class="form-control" value="password">
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
            <!-- end row -->

        </div> <!-- end card-box -->
    </div><!-- end col -->
</div>
<!-- end row -->
