import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SharedService } from '../_shared/shared.service';
export interface LoginData {
  email: string;
  password: string;
}

export interface ChangePassword {
  old_password: string;
}


@Injectable()
export class AuthService {
  constructor(private http: HttpClient,
    public sharedService: SharedService) {
  }

  userLogin(loginData: LoginData) {
    return this.http.post<any>(`${environment.baseUrl}/user/portal-user/login`, loginData);
  }

  getCurrentUserRole(accessToken: String) {
    try {
      const payload = JSON.parse(atob(accessToken.split('.')[1]));
      return payload.role;
      // return "Admin";
    } catch (e) {
      return null;
    }
  }

  getRoles() {
    return this.sharedService.get(`${environment.baseUrl}/user/role/list`);
  }

  forgotPassword(email) {
    return this.http.post<any>(`${environment.baseUrl}/user/password/reset`, email)
  }

  confirmResetPassword(pwdData) {
    return this.http.post<any>(`${environment.baseUrl}/user/password/reset/confirm`, pwdData)
  }

  verifyToken(token) {
    return new Promise<Boolean>((resolve, reject) => {
      this.sharedService.post(`${environment.baseUrl}/user/login-verify`, { token })
        .subscribe(res => {
          if (res.success) {
            return resolve(true)
          }
          return resolve(false)
        }, err => {
          return reject(err)
        })
    })
  }

  refreshLogin(token) {
    return this.sharedService.post(`${environment.baseUrl}/user/login-refresh`, token)
  }

}