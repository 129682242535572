<!DOCTYPE html>
<html>

<body>
    <div id="wrapper">
        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
        <!-- end Topbar -->
    </div>
    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">



        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div class="mb-0">
                        <app-top-title [title]="'Add Data to your Database'"
                            [subTitle]="'You can add data to the portal from here'"></app-top-title>
                    </div>
                    <div class="bg-white">

                        <form class="" role="form" [formGroup]="categoryForm" (ngSubmit)="updateCategory()">
                            <div class="row p-4">

                                <div class="col-md-3" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="category">Category<span
                                                style="color:#ff0000">*</span></label>
                                        <input placeholder="Enter Category Name" type="text" class="form-control"
                                            id="category" formControlName="name"
                                            [class.valid]="categoryForm.get('name').valid && 
                                                                (categoryForm.get('name').dirty || categoryForm.get('name').touched)"
                                            [class.invalid]="categoryForm.get('name').invalid && 
                                                                (categoryForm.get('name').dirty || categoryForm.get('name').touched)">

                                        <div
                                            *ngIf="categoryForm.get('name').invalid && categoryForm.get('name').errors && (categoryForm.get('name').dirty || categoryForm.get('name').touched)">
                                            <small class="text-danger"
                                                *ngIf="categoryForm.get('name').hasError('required')">
                                                Category name is required. </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-5 pb-3 d-flex">
                                <div class="form-group">
                                    <button type="submit"
                                        class="btn btn-success px-5 mr-2">{{updating?'Update':'Add'}}</button>
                                    <button (click)="toggleUpdateMode()" type="button"
                                        class="btn btn-danger px-5">Cancel</button>
                                </div>
                            </div>
                        </form>
                        <!-- end row -->
                    </div>


                    <div class="mt-3 p-3 bg-white">
                        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>Category Id</th>
                                    <th>Category Name</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let category of categories">
                                    <td>{{ category.id }}</td>
                                    <td>{{ category.name }}</td>
                                    <td>
                                        <a [routerLink]="" (click)="fetchCategory(category)"
                                            style="color:'blue';">Edit</a>
                                    </td>
                                    <td>
                                        <a [routerLink]="" (click)="removeCategory(category.id)" style="color:'red';">
                                            Delete </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->
</body>

</html>