import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../_services';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {

  public invalidForm: boolean = null;
  public errorMessage: string;
  public successMessage: string;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  forgotPwdForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])]
  })

  forgotPassword() {
    this.invalidForm = null;
    if (this.forgotPwdForm.valid) {
      Swal.showLoading();
      this.authService.forgotPassword(this.forgotPwdForm.value)
        .subscribe(res => {
          Swal.close();
          if (res.success) {
            this.invalidForm = false;
            this.successMessage = "Please check your email to reset your new password."
            this.forgotPwdForm.reset()
          } else {
            this.invalidForm = true;
            this.errorMessage = "Something went wrong.";
          }
        }, err => {
          Swal.close();
          this.invalidForm = true;
          this.errorMessage = err.error?.error_message || "Invalid email";
        })
    } else {
      this.forgotPwdForm.controls['email'].markAsDirty();
      this.forgotPwdForm.controls['email'].updateValueAndValidity();
    }
  }
}
