<div style="background-color: 'white';margin-bottom: '10px';;padding:'10px';">
  <div style="display:'flex';justify-content: 'space-between';">
    <div></div>
    <div>
      <button style="font-size: '1rem'; " class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div>
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </div>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item btn btn-info" data-toggle="modal" [attr.data-target]="dataTargetName" href="#">View CSV
          Data</a>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="!loaded">
      <h3 class="title">{{chartTitle}}</h3>
    <div class="loader-div" >
      <img class="loader-icon" src="../../assets/images/ajaxloader.gif">
    </div>
  </div>
    <div *ngIf="loaded" style="display: block;">
      <div *ngIf="barChartData[0]?.label === '';else elseblock">
      <h3 class="title">{{chartTitle}}</h3>
      <div class="loader-div" >
        <h4 class="h4-font">No Data Available</h4>
      </div>
    </div>
    <ng-template #elseblock>
        <canvas *ngIf="barChartData.length > 0" class="chart" baseChart [height]="canvasHeight" 
          [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
          [chartType]="barChartType" [datasets]="barChartData" [colors]="barChartColors">
        </canvas>
      </ng-template>
        <!-- <ng-template #elseblock>
           <canvas class="chart" [height]="canvasHeight" baseChart 
          [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins"  [legend]="barChartLegend" 
          [chartType]="barChartType" [datasets]="barChartData" [colors]="barChartColors">
          </canvas> 
        </ng-template> -->
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="{{dataModalId}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">CSV Table Data</h5>
        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
      <div class="modal-body p-4">
        <div class="float-right">
          <button class="btn btn-primary" (click)="exportDataToExcel()"> Export Excel </button>
        </div>
        <table class="custom-table" datatable [dtOptions]="dtBarOptions" *ngIf="barChartData.length > 0" [id]="dataTableId">
          <thead>
            <tr>
              <th *ngFor="let label of barChartExcelData?.labels">{{label}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of barChartExcelData?.data">
              <td *ngFor="let label of barChartExcelData?.labels">{{data[label] || 0}}</td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
