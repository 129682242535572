import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merch-sub-category',
  templateUrl: './merch-sub-category.component.html',
  styleUrls: ['./merch-sub-category.component.scss']
})
export class MerchSubCategoryComponent implements OnInit {

  subCategorys: Array<any> = [];
  subcategoryForm: FormGroup;
  accessToken: string;

  updating: boolean = false;
  subcategoryIdToUpdate: Number = null;
  categoryDropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, {
    static: false,
  })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public categorys: Array<any> = [];


  constructor(
    private fb: FormBuilder,
    private operationService: MerchOperationService
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');
    this.loadCategories();

    this.subcategoryForm = this.fb.group({
      name: ['', [Validators.required]],
      name_ar: [''],
      category: ['', [Validators.required]],
      categoryshow: ['', [Validators.required]]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }

    this.categoryDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.listAllsubCategorys();
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  getappropriatename(id) {
    return this.categorys.filter(item => item.id === id)[0].name;
  }
  loadCategories() {
    Swal.showLoading();
    this.operationService.getCategory().subscribe(response => {
      Swal.close();
      if (response.success) {
        this.categorys = response.data?.results;
        console.log(this.categorys)
        this.rerender();
      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching categorys!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching categorys!"
      });
    })
  }

  listAllsubCategorys() {
    Swal.showLoading();
    this.operationService.getsubCategories()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.subCategorys = response.data?.results;

          console.log(this.subCategorys)
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching subCategorys!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching subCategorys!"
        });
      })
  }

  removesubcategory(subcategoryId) {
    this.operationService.removesubCategory(subcategoryId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "subcategory deleted successfully!"
        }).then(res => {
          this.listAllsubCategorys();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting subcategory!"
        })
      })
  }

  updatesubcategory() {
    if (this.subcategoryForm.invalid) {
      for (var i in this.subcategoryForm.controls) {
        this.subcategoryForm.controls[i].markAsDirty();
        this.subcategoryForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.subcategoryIdToUpdate) {
        this.operationService.updatesubCategory(this.subcategoryIdToUpdate, this.subcategoryForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "subcategory updated successfully!"
                }).then(() => {
                  this.subcategoryIdToUpdate = null;
                  this.listAllsubCategorys();
                  this.updating = false;
                  this.subcategoryForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating subcategory!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding subcategory!"
              });
            }
          )
      }
    }

  }

  addsubCategory() {
    this.subcategoryForm.controls['category'].setValue(this.subcategoryForm.controls['categoryshow'].value[0].id)
    if (this.subcategoryForm.invalid) {
      for (var i in this.subcategoryForm.controls) {
        this.subcategoryForm.controls[i].markAsDirty();
        this.subcategoryForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      this.operationService.addsubCategory(this.subcategoryForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "subcategory added successfully!"
              }).then(() => {
                this.listAllsubCategorys();
                this.updating = false;
                this.subcategoryForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding subcategory!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding subcategory!"
            });
          }
        )
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
  // helper methods
  fetchsubcategory(subcategory) {
    this.updating = true;
    this.subcategoryIdToUpdate = subcategory.id || null;
    this.subcategoryForm = this.fb.group({
      name: [subcategory.name, [Validators.required]],
      name_ar: [subcategory.name_ar],
      category: [subcategory.category, [Validators.required]],
      categoryshow: [[{
        'id': subcategory.category,
        'name': this.getappropriatename(subcategory.category),
      }], [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.subcategoryForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
