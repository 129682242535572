<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add out of stock data to SNAP dashboard'"
              [subTitle]="'You can import out of stock data to here.'"></app-top-title>
          </div>
          <div class="mt-3 p-3 bg-white mb-5">
            <div class="mt-2 mb-3">
              <div class="float-left">
                <button class="btn btn-danger mr-2" (click)="deleteSnapData()">
                  Delete
                </button>
                <div class="mt-2 mb-2">
                  <!-- Date range picker -->
                  <label class=" col-form-label">Date Range: <span style="color:#ff0000">*</span> </label>
                  <input id="daterangepicker" class="form-control" startKey="date_after" endKey="date_before"
                          opens="right" drops="down" emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd
                         [formControl]="dashboardForm.controls['date_range']" [locale]="{'format': 'MM/DD/YYYY'}"
                         (change)="applyDateFilter()"
                         [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
                         [showDropdowns]="true" [showRangeLabelOnInput]="showRangeLabelOnInput"
                         placeholder="Select the date range..." />
                </div>
              </div>
              <div class="float-right">
                <div id="export_csv" class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2" style="font-size: 0.875rem;"
                  (click)="exportData()">
                  Export
                </div>
                <div id="import_csv" class="file btn btn-lg btn-sm btn-success rounded-pill py-2 px-4 mr-3 mt-2"
                  style="font-size: 0.875rem;">
                  Import
                  <input id="input_csv" type="file" name="file" (change)="importSnapOOSData($event)" accept=".csv" />
                </div>
              </div>
            </div>

            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th> <input type="checkbox" name="id" (click)="selectAll()" [checked]="isSelectAllChecked"> </th>
                  <th>Id</th>
                  <th>Date</th>
                  <th>Country</th>
                  <th>City</th>
                  <th>Channel</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>SKU</th>
                  <th>Count</th>
                  <th>Not Available in Month</th>
                  <th>Less Available in Month</th>
                  <th>Available in Month</th>
                  <th>Not available by store</th>
                  <th>Less available by store</th>
                  <th>Available by store</th>
                  <th>Not available by city</th>
                  <th>Less available by city</th>
                  <th>Available by City</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let snapDatum of OOSSnapData">
                  <td>
                    <input type="checkbox" name="id" (change)='rowSelected(snapDatum?.id)'
                      [checked]="snapDatum.isChecked">
                  </td>
                  <td> {{ snapDatum?.id }}</td>
                  <td> {{ snapDatum?.date }}</td>
                  <td> {{ snapDatum?.country }}</td>
                  <td> {{ snapDatum?.city }}</td>
                  <td> {{ snapDatum?.channel }}</td>
                  <td> {{ snapDatum?.category }}</td>
                  <td> {{ snapDatum?.brand }}</td>
                  <td> {{ snapDatum?.sku }}</td>
                  <td> {{ snapDatum?.count }}</td>
                  <td> {{ snapDatum?.not_available_in_month }}</td>
                  <td> {{ snapDatum?.less_available_in_month }}</td>
                  <td> {{ snapDatum?.available_in_month }}</td>
                  <td> {{ snapDatum?.not_available_by_store }}</td>
                  <td> {{ snapDatum?.less_available_by_store }}</td>
                  <td> {{ snapDatum?.available_by_store }}</td>
                  <td> {{ snapDatum?.not_available_by_city }}</td>
                  <td> {{ snapDatum?.less_available_by_city }}</td>
                  <td> {{ snapDatum?.available_by_city }}</td>
                </tr>
              </tbody>
            </table>
            <div class="row ml-2 flex d-flex justify-content-start mt-2">
             <!-- Showing {{ gOffset || 0 }} to {{ gOffset + gLimit || 0 }} of {{ count || 0}}.-->
              Showing {{ gOffset || 0 }} to {{ gOffset + gLimit || 0 }}.
            </div>

            <div class="row mr-2 flex d-flex justify-content-end" style="margin-top: -15px;">
              <button class="btn btn-warning mr-2" (click)="handlePagination('previous')" [disabled]="!previousUrl">
                Previous</button>
              <button class="btn btn-primary" (click)="handlePagination('next')" [disabled]="!nextUrl"> Next</button>
            </div>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->
  </div>
  <!-- END wrapper -->

</body>

</html>
