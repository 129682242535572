<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Questionnaire Detail'"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="qnnTitle"
              [subTitle]="'Single questionnaire detail view'"></app-top-title>
          </div>
          <div class="mt-4 p-4" style="background-color: white;">
            <!-- end row -->
            <table  class="custom-table"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>User Name</th>
                  <th>Completion Date</th>
                  <th>No. Answers</th>
                  <th>Starting Time</th>
                  <th>Finishing Time</th>
                  <th>Country</th>
                  <th>City</th>
                  <th>Channel</th>
                  <th>Store</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let questionsReport of questionsReports">
                  <td data-column="User ID">
                    <a routerLink="/single-field-work/{{ questionsReport.user_id }}">{{questionsReport.user_id}}</a>
                   </td>
                  <td data-column="User Name">
                    <a routerLink="/single-field-work/{{ questionsReport.user_id }}">{{questionsReport.username}}</a>
                  </td>
                  <td data-column="Completion Date">{{questionsReport.completed_date}}</td>
                  <td data-column="No. Answers">
                    <a routerLink="/single-questionnaire/{{questionsReport.response}}">
                      {{questionsReport.number_of_answers}}
                    </a></td>
                  <td data-column="Starting Time">{{questionsReport.start_time}}</td>
                  <td data-column="Finishing Time">{{questionsReport.finish_time}}</td>
                  <td data-column="Country">{{questionsReport.country}}</td>
                  <td data-column="City">{{questionsReport.city}}</td>
                  <td data-column="Channel">{{questionsReport.channel}}</td>
                  <td data-column="Store">{{questionsReport.store}}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->


</body>

</html>
