<!DOCTYPE html>
<html lang="en">

<body>

    <!-- Begin page -->
    <div id="wrapper">

        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Terms'"></app-top-bar>
        <!-- end Topbar -->

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div>
                        <app-top-title [title]="'Terms and conditions'"
                            [subTitle]="'Create and Edit Terms and Conditions'"></app-top-title>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box shadow p-5">
                                <form role="form" [formGroup]="tncUpdate" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <textarea class="form-control" id="termsAndCondition" name="termsAndCondition"
                                            rows="15" cols="5" placeholder="Message" formControlName="text"
                                            [class.valid]="tncUpdate.get('text').valid && 
                                                                (tncUpdate.get('text').dirty || tncUpdate.get('text').touched)"
                                            [class.invalid]="tncUpdate.get('text').invalid && 
                                                                (tncUpdate.get('text').dirty || tncUpdate.get('text').touched)">
                                            </textarea>
                                        <div *ngIf="tncUpdate.get('text').invalid && tncUpdate.get('text').errors && 
                                                    (tncUpdate.get('text').dirty || tncUpdate.get('text').touched)">
                                            <small class="text-danger"
                                                *ngIf="tncUpdate.get('text').hasError('required')">
                                                Terms and Conditions is required. </small>
                                        </div>
                                    </div>
                                    <hr />
                                    <button type="submit" class="btn btn-success rounded-pill pl-4 pr-4">Save</button>
                                    &nbsp;
                                    <button type="button" (click)="clear()"
                                        class="btn btn-danger rounded-pill pl-4 pr-4">Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->







                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->


</body>

</html>