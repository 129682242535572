import { OperationService } from './../_services/operation.service';
import { LocalizeService } from './../_services/localize.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from './../_services/dashboard.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Moment } from 'moment'
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AuthService } from '../_services';

@Component({
  selector: 'app-top-dashboard',
  templateUrl: './top-dashboard.component.html',
  styleUrls: ['./top-dashboard.component.scss']
})
export class TopDashboardComponent implements OnInit {
  @Output() filterAppliedEvent = new EventEmitter<any>();
  @Output() qnnTypeChange = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<any>();

  faEllipsisV = faEllipsisV;
  overviewData: any;

  countries: Array<any> = [];
  cities: Array<any> = [];
  countryIds: Array<number> = [];

  channels: Array<any> = [];
  retailers: Array<any> = [];
  branches: Array<any> = [];
  categories: Array<any> = [];

  brands: Array<any> = [];
  skus: Array<any> = [];

  questionnaire_types: Array<any> = [];

  filterString: string
  dropdownSettings: IDropdownSettings;
  dropdownMultiselectSettings: IDropdownSettings;

  dashboardForm: FormGroup;
  currentQuestionnaireType: any;

  isAdmin: boolean;
  // calenders varaibles

  date_range: any;
  alwaysShowCalendars: boolean;
  minDate = moment().subtract(3, 'years');
  maxDate = moment().add(0, 'days');
  showRangeLabelOnInput = true;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  }

  constructor(
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private localizeService: LocalizeService,
    private operationService: OperationService,
    private authService: AuthService
  ) {
    this.alwaysShowCalendars = true;
  }
  /**
   * @returns void
   * ! as of now setting default filter is by id which may changes during production
   * TODO: set default filters -> 'This Month' & 'Price Monitor' during ngOnInit and resetFilters
   */

  ngOnInit(): void {

    this.loadCountries();
    this.loadChannels();
    this.loadCategories();
    this.loadRetailers();
    this.loadBrands();
    this.loadOverviewData();
    this.loadQuestionnaireTypes();

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownMultiselectSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.dashboardForm = this.fb.group({
      date_range: [{ date_after: moment().startOf('month'), date_before: moment().endOf('month') }], // default This Month
      country: [''],
      city: [''],
      channel: [''],
      retailer: [''],
      store: [''],
      category: [''],
      brand: [''],
      sku: [''],
      questionnaire_type: ["7"], // default questionnaire
    });

    const accessToken = localStorage.getItem('accessToken');
    if (this.authService.getCurrentUserRole(accessToken) == "Admin") {
      this.isAdmin = true
    }
  }

  // main methods

  onFilterSubmit() {

    let filterDataObj = { ...this.dashboardForm.value };
    this.filterString = '?'
    for (let filterDataKey in filterDataObj) {

      // if it's date_range, format it
      if (filterDataKey == 'date_range') {
        let dateRange = filterDataObj?.date_range || null;
        if (dateRange) {
          let fromDate: Moment = this.dashboardForm.value?.date_range?.date_after;
          let tillDate: Moment = this.dashboardForm.value?.date_range?.date_before;
          filterDataObj['date_after'] = this.customDateFormat(fromDate, 'YYYY-MM-DD');
          filterDataObj['date_before'] = this.customDateFormat(tillDate, 'YYYY-MM-DD');
        }
      }
      delete filterDataObj['date_range'];

      // don't to anything for questionnaire type
      // for all other filters we extract ids
      if (filterDataKey != 'questionnaire_type') {
        let otherFormValues = (filterDataObj[filterDataKey]) as Array<any>;
        if (otherFormValues) {
          filterDataObj[filterDataKey] = otherFormValues.map(eachValue => eachValue.id);
        }
      }
    }

    for (let filter in filterDataObj) {
      if (filter == 'questionnaire_type' || filter == 'date_after' || filter == 'date_before') {
        this.filterString = this.filterString + `${filter}=${filterDataObj[filter]}&`
      } else {
        if (filterDataObj[filter]) {
          this.filterString = this.filterString + `${filter}=${filterDataObj[filter].join(',')}&`
        }
      }
    }

    // emit filter string here
    this.filterAppliedEvent.emit(this.filterString);

  }

  onQnnTypeChange($event) {
    this.onFilterSubmit();
    this.currentQuestionnaireType = $event.target.options[$event.target.options.selectedIndex].text;
    this.qnnTypeChange.emit({ currentOptionText: this.currentQuestionnaireType, filterString: this.filterString });
  }

  loadOverviewData() {
    Swal.showLoading();

    this.dashboardService.getOverview()
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            this.overviewData = response.data;
          } else {
            this.handleNotification(response.error_message || "Problem in fetching overview data!", true);
          }
        },
        error => {
          Swal.close();
          this.handleNotification(error.error.error_message || "Problem in fetching overview data!", true);
        })
  }

  resetFilters() {

    this.branches = [];
    this.skus = [];
    this.cities = [];

    this.dashboardForm = this.fb.group({
      date_range: [{ date_after: moment().startOf('month'), date_before: moment().endOf('month') }], // default this month
      country: [''],
      city: [''],
      channel: [''],
      retailer: [''],
      store: [''],
      category: [''],
      brand: [''],
      sku: [''],
      questionnaire_type: ["7"], // default questionnaire
    });

    this.resetFilter.emit(this.currentQuestionnaireType);

  }

  // helper methods

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  async onCountrySelect(event?) {
    Swal.showLoading();
    if (event) {
      this.countryIds.push(event.id);
      this.loadRetailers(this.countryIds);
      this.loadBrands(this.countryIds);
    }

    this.cities = [];
    let countryIdString = this.dashboardForm.value.country.map(country => country.id).join(',');
    let cities = countryIdString && await this.localizeService.getCitiesByCountryId(countryIdString);
    this.cities = [...cities];
    Swal.close();
  }

  async onCountryDeSelect(event) {
    let countryIdToRemove = this.countryIds.indexOf(event.id);
    if (countryIdToRemove !== -1) {

      this.countryIds.splice(countryIdToRemove, 1);
      await this.loadRetailers(this.countryIds);
      await this.loadBrands(this.countryIds);

      if (this.countryIds.length == 0) {
        this.dashboardForm.controls.sku.setValue(null);
        this.dashboardForm.controls.brand.setValue(null);
        this.dashboardForm.controls.retailer.setValue(null);
        this.dashboardForm.controls.store.setValue(null);

        let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
        element.click();
      } else {
        // change value dynamicall when country deselects for brand
        let operationBrands: any[] = this.dashboardForm.get('brand').value;
        let newListOfBrands = [];
        if (!operationBrands) {
          Swal.close();
          return
        };
        let tempBrands = this.brands ? [...this.brands] : [];
        newListOfBrands = operationBrands.filter(function (checkBrand) {
          return tempBrands.find(function (allowedBrand) {
            return checkBrand.id === allowedBrand.id
          })
        })
        this.dashboardForm.controls['brand'].setValue(newListOfBrands);

        // change value dynamicall when country deselects for retailer
        let operationRetailers: any[] = this.dashboardForm.get('retailer').value;
        let newListOfRetailers = [];
        if (!operationRetailers) {
          Swal.close();
          return
        };
        let tempRetailers = this.retailers ? [...this.retailers] : [];
        newListOfRetailers = operationRetailers.filter(function (checkRetailer) {
          return tempRetailers.find(function (allowedRetailer) {
            return checkRetailer.id === allowedRetailer.id
          })
        })
        this.dashboardForm.controls['retailer'].setValue(newListOfRetailers);
        let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
        element.click();
        this.onBrandDeSelect();
        this.onRetailerDeSelect();

      }

    }

    Swal.showLoading();
    await this.onCountrySelect();
    let operationCities: any[] = this.dashboardForm.get('city').value;
    let newListOfCities = [];
    if (!operationCities) {
      Swal.close();
      return
    };
    let tempCities = this.cities ? [...this.cities] : [];
    newListOfCities = operationCities.filter(function (checkCity) {
      return tempCities.find(function (allowedCity) {
        return checkCity.id === allowedCity.id
      })
    })
    this.dashboardForm.controls['city'].setValue(newListOfCities);
    Swal.close();
  }

  loadCountries() {
    Swal.showLoading();
    this.localizeService.getCountries()
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.countries = data
        } else {
          this.countries = [];
        }
      }).catch(err => {
      Swal.close();
      this.handleNotification('Encountered error while fetching countries!', true)
    })
  }

  loadChannels() {
    Swal.showLoading();
    this.operationService.getChannels()
      .subscribe(
        response => {
          Swal.close();
          let channels = response.data?.results;
          this.channels = channels.length > 0 ? channels : [];
        },
        error => {
          Swal.close();
          this.handleNotification('Problem in fetching channels', true);
        }
      )
  }

  loadCategories() {
    Swal.showLoading();
    this.operationService.getCategories()
      .subscribe(
        response => {
          Swal.close();
          let categories = response.data?.results;
          this.categories = categories.length > 0 ? categories : [];
        },
        error => {
          Swal.close();
          this.handleNotification('Problem in fetching categories', true);
        }
      )
  }

  loadRetailers(countryIds?) {
    Swal.showLoading()
    return new Promise((resolve, reject) => {
      this.operationService.getRetailers(countryIds)
        .subscribe(
          response => {
            Swal.close();
            let retailers = response && response.data  ?response.data.results : [];
            if(retailers.length > 0) {
              this.retailers = [...retailers]
            } else {
              this.retailers = []
            }
            resolve(true)
          },
          error => {
            Swal.close();
            this.handleNotification('Problem in fetching retaiers', true);
            reject()
          }
        )
    })

  }

  async onRetailerSelect() {
    this.branches = [];
    let retailerIdString = this.dashboardForm.value.retailer && this.dashboardForm.value.retailer.map(retailer => retailer.id).join(',');
    let branches = retailerIdString && await this.operationService.getBranchByRetailerId(retailerIdString, this.countryIds);
    this.branches = [...branches];
  }

  async onRetailerDeSelect() {
    Swal.showLoading();
    await this.onRetailerSelect();
    let branches: any[] = this.dashboardForm.get('store').value;
    let newListOfBranches;
    if (!branches) {
      Swal.close();
      return
    }
    let tempBranches = this.branches ? [...this.branches] : [];
    newListOfBranches = branches.filter(function (checkBranch) {
      return tempBranches.find(function (allowedBranch) {
        return checkBranch.id === allowedBranch.id
      })
    })
    this.dashboardForm.controls['store'].setValue(newListOfBranches);
    Swal.close();
  }

  async onRetailerSelectAll(event) {
    this.branches = [];
    let retailerIdString = event.map(retailer => retailer.id).join(',');
    let branches = retailerIdString && await this.operationService.getBranchByRetailerId(retailerIdString, this.countryIds);
    this.branches = [...branches];
  }

  loadBrands(countryIds?) {

    return new Promise((resolve, reject) => {
      Swal.showLoading();
      this.operationService.getBrands(countryIds)
        .subscribe(response => {
          Swal.close()
          let brands = response && response.data ? response.data.results : [];
          if (brands.length > 0) {
            this.brands = [...brands]
          } else {
            this.brands = [];
          }
          resolve(true)
        }, err => {
          Swal.close();
          this.handleNotification('Encountered error while fetching brands!', true)
          reject()
        })
    })

  }

  async onBrandSelect() {
    Swal.showLoading();
    this.skus = [];
    let brandIdString = this.dashboardForm.value.brand && this.dashboardForm.value.brand.map(brand => brand.id).join(',');
    let skus = brandIdString && await this.operationService.getSkusByBrandId(brandIdString, this.countryIds);
    this.skus = [...skus];
    Swal.close();
  }

  async onBrandDeSelect() {
    Swal.showLoading();
    await this.onBrandSelect();
    let skus: any[] = this.dashboardForm.get('sku').value;
    if (!skus) {
      Swal.close()
      return
    }
    let newListOfSkus
    let tempSkus = this.skus ? [...this.skus] : [];
    newListOfSkus = skus.filter(function (checkSku) {
      return tempSkus.find(function (allowedSku) {
        return checkSku.id === allowedSku.id
      })
    })
    this.dashboardForm.controls['sku'].setValue(newListOfSkus);
    Swal.close();
  }

  async onBrandSelectAll(event) {
    Swal.showLoading();
    this.skus = [];
    let brandIdString = event.map(brand => brand.id).join(',');
    let skus = brandIdString && await this.operationService.getSkusByBrandId(brandIdString, this.countryIds);
    this.skus = [...skus];
    Swal.close();
  }

  loadQuestionnaireTypes() {
    Swal.showLoading();
    this.operationService.getQTs()
      .subscribe(
        response => {
          Swal.close();
          let questionnaireTypes = response.data ? response.data.results : [];
          questionnaireTypes.map((e) => {
           return {
              id: e.id,
              name: e.name === 'Consumer Question' ? e.name = 'Brand Health Tracker' : e.name
            };
          });
          console.log('77777 questionnaireType 777777', questionnaireTypes);
          this.questionnaire_types = [...questionnaireTypes];
        },
        error => {
          Swal.close();
          this.handleNotification('Problem in fetching questionnaire type!', true);
        }
      )
  }

  customDateFormat(dateToFormat: Moment, format: string) {
    return dateToFormat.format(format);
  }

}
