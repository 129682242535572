import { QuestionnnaireService } from './../_services/questionnnaire.service';
import { QuestionService } from './../_services/question.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss']
})
export class QuestionnairesComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  questionnaires: Array<any> = [];
  accessToken: String;

  constructor(
    private qs: QuestionnnaireService
  ) { }

  ngOnInit(): void {

    this.listQuestionnaire();

    this.accessToken = localStorage.getItem('accessToken');
  }

  ngAfterViewInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: '<"top"<"left-col"B><"center-col"l><"right-col">>rtip',
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export',
          title: 'questionnaires',
          className: ' mr-1 btn btn-info float-right',
          exportOptions: {
            columns: [0,1,2,3,4,5,6,7]
          }
        }
      ],
      stateSave: true,
      stateDuration: -1,
      stateSaveCallback: function (settings, data) {
          const ls =  JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance + window.location.pathname ))
          
          if(ls){
              if(ls.start !== data.start)
                  localStorage.setItem( 'DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data) )
          }else {
              localStorage.setItem( 'DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data) )
          }
          
      },
      stateLoadCallback: function (settings) {
          return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance + window.location.pathname ) )
      }
    };
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  listQuestionnaire() {
    Swal.showLoading();
    this.qs.getQuestionnaires()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.questionnaires = response.data?.results;
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching questionnaires!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching questionnnaires!"
        });
      })
  }

  removeQuestionnaire(qnnId) {
    this.qs.removeQuestionnaire(qnnId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Questionnaire deleted successfully!"
        }).then(res => {
          this.listQuestionnaire();
        })
      })
      .catch(error => {
        this.handleNotification("Something went wrong while deleting questionnaire!", true);
      })
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  // helper methods

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  formatTableViews(arrayData) {
    return arrayData.map(eachData => eachData.name)
  }


}
