<!DOCTYPE html>
<html lang="en">

<body>

    <!-- Begin page -->
    <div id="wrapper">

        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Support'"></app-top-bar>
        <!-- end Topbar -->

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div>
                        <app-top-title [title]="'Support'"
                            [subTitle]="'Send Us A Message, and we will get back shortly'"></app-top-title>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box shadow p-5">
                                <form [formGroup]="supportEmailForm" (ngSubmit)="sendEmail()">
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <input type="text" formControlName="fullname" class="form-control" id="name"
                                                placeholder="Name"
                                                [class.valid]="supportEmailForm.get('fullname').valid && 
                                                                (supportEmailForm.get('fullname').dirty || supportEmailForm.get('fullname').touched)"
                                                [class.invalid]="supportEmailForm.get('fullname').invalid && 
                                                                (supportEmailForm.get('fullname').dirty || supportEmailForm.get('fullname').touched)">
                                            <div *ngIf="supportEmailForm.get('fullname').invalid && supportEmailForm.get('fullname').errors && 
                                                    (supportEmailForm.get('fullname').dirty || supportEmailForm.get('fullname').touched)">
                                                <small class="text-danger"
                                                    *ngIf="supportEmailForm.get('fullname').hasError('required')">
                                                   Name is required. </small>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="email" formControlName="email" class="form-control" id="email"
                                                placeholder="Email"
                                                [class.valid]="supportEmailForm.get('email').valid && 
                                                                (supportEmailForm.get('email').dirty || supportEmailForm.get('email').touched)"
                                                [class.invalid]="supportEmailForm.get('email').invalid && 
                                                                (supportEmailForm.get('email').dirty || supportEmailForm.get('email').touched)">
                                            <div *ngIf="supportEmailForm.get('email').invalid && supportEmailForm.get('email').errors && 
                                                    (supportEmailForm.get('email').dirty || supportEmailForm.get('email').touched)">
                                                <small class="text-danger"
                                                    *ngIf="supportEmailForm.get('email').hasError('required')">
                                                    Email is required. </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" id="inputAddress" formControlName="text"
                                            name="w3review" rows="4" cols="50" placeholder="Message"
                                            [class.valid]="supportEmailForm.get('text').valid && 
                                                                (supportEmailForm.get('text').dirty || supportEmailForm.get('text').touched)"
                                            [class.invalid]="supportEmailForm.get('text').invalid && 
                                                                (supportEmailForm.get('text').dirty || supportEmailForm.get('text').touched)"></textarea>
                                        <div *ngIf="supportEmailForm.get('text').invalid && supportEmailForm.get('text').errors && 
                                                    (supportEmailForm.get('text').dirty || supportEmailForm.get('text').touched)">
                                            <small class="text-danger"
                                                *ngIf="supportEmailForm.get('text').hasError('required')">
                                                Message is required. </small>
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Send</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->







                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->


</body>

</html>