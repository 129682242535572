import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Moment } from 'moment'
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/_services/dashboard.service';
import { AuthService, LocalizeService } from 'src/app/_services';
import { SnapOperationService } from 'src/app/_services/snap-operation.service';
import { DataTableDirective } from 'angular-datatables';
import { SnapDashboardService } from 'src/app/_services/snap-dashboard.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-merch-filter',
  templateUrl: './merch-filter.component.html',
  styleUrls: ['./merch-filter.component.scss']
})
export class MerchFilterComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();
  public previousUrl: string;
  public nextUrl: string;

  public gLimit = 10;
  public gOffset = 0;
  @Output() filterAppliedEvent = new EventEmitter<any>();
  @Output() qnnTypeChange = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<any>();

  faEllipsisV = faEllipsisV;
  overviewData: any;

  months: Array<any> = [];
  regions: Array<any> = [];
  branch_name: Array<any> = [];
  branch_no: Array<any> = [];
  gov: Array<any> = [];
  subCategories: Array<any> = [];
  countries: Array<any> = [];
  cities: Array<any> = [];
  countryIds: Array<number> = [];
  monthIds: Array<number> = [];
  channelIds: Array<number> = [];
  categoryIds: Array<number> = [];
  brandIds: Array<number> = [];
  skuIds: Array<number> = [];
  channels: Array<any> = [];
  retailers: Array<any> = [];
  branches: Array<any> = [];
  categories: Array<any> = [];

  brands: Array<any> = [];
  skus: Array<any> = [];

  questionnaire_types: Array<any> = [];

  filterString: string
  dropdownSettings: IDropdownSettings;
  datedropdownSettings: IDropdownSettings;
  dropdownMultiselectSettings: IDropdownSettings;

  dashboardForm: FormGroup;
  currentQuestionnaireType: any = "Price Monitor";

  isAdmin: boolean;
  // calenders varaibles

  date_range: any;
  alwaysShowCalendars: boolean;
  // minDate = moment('2018-01-01');
  minDate = moment().subtract(3, 'years'); snapData: any;
  maxDate = moment().add(0, 'days');
  showRangeLabelOnInput = true;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  }

  accessToken: string;
  constructor(
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private localizeService: LocalizeService,
    private operationService: SnapOperationService,
    private authService: AuthService,
    private snapDashboardService: SnapDashboardService,
    private http: HttpClient
  ) {
    this.alwaysShowCalendars = true;
  }
  /**
   * @returns void
   * ! as of now setting default filter is by id which may changes during production
   * TODO: set default filters -> 'This Month' & 'Price Monitor' during ngOnInit and resetFilters
   */
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      stateSave: true
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.datedropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownMultiselectSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.dashboardForm = this.fb.group({
      //  date_range: ['',Validators.required],
      // date_range: [{ date_after: moment("01-01-2018", "DD-MM-YYYY"), date_before: moment("31-01-2018", "DD-MM-YYYY"), }, Validators.required], // default This Month
      country: [''],
      channel: [''],
      retailer: [''],
      store: [''],
      category: [''],
      brand: [''],
      sku: [''],
      month: [''],
      region:[''],
      questionnaire_type: ["7"], // default questionnaire
    });

    const accessToken = localStorage.getItem('accessToken');
    if (this.authService.getCurrentUserRole(accessToken) == "Admin") {
      this.isAdmin = true
    }

    this.accessToken = localStorage.getItem('accessToken');
    this.getFilterString();
    this.getCountryList();
    // this.loadChannels();
    // this.loadCategories();
    // this.loadRetailers();
    //this.loadBrands();
    this.loadOverviewData();
    this.loadQuestionnaireTypes();
  }

  // main methods

  onFilterSubmit() {
    if (this.dashboardForm.invalid) {
      for (var i in this.dashboardForm.controls) {
        this.dashboardForm.controls[i].markAsDirty();
        this.dashboardForm.controls[i].updateValueAndValidity();
      }
    } else {
      let filterDataObj = { ...this.dashboardForm.value };
      this.filterString = '?'
      for (let filterDataKey in filterDataObj) {

        // if it's date_range, format it
        // if (filterDataKey == 'date_range') {
        //   let dateRange = filterDataObj?.date_range || null;
        //   if (dateRange) {
        //     let fromDate: Moment = this.dashboardForm.value?.date_range?.date_after;
        //     let tillDate: Moment = this.dashboardForm.value?.date_range?.date_before;
        //     filterDataObj['date_after'] = this.customDateFormat(fromDate, 'YYYY-MM-DD');
        //     filterDataObj['date_before'] = this.customDateFormat(tillDate, 'YYYY-MM-DD');
        //   }
        // }
        // delete filterDataObj['date_range'];

        // don't to anything for questionnaire type
        // for all other filters we extract ids
        if (filterDataKey != 'questionnaire_type') {
          let otherFormValues = (filterDataObj[filterDataKey]) as Array<any>;
          if (otherFormValues) {
            filterDataObj[filterDataKey] = otherFormValues.map(eachValue => eachValue.id);
          }
        }
      }

      for (let filter in filterDataObj) {
        if (filter == 'questionnaire_type' || filter == 'date_after' || filter == 'date_before') {
          this.filterString = this.filterString + `${filter}=${filterDataObj[filter]}&`
        } else {
          if (filterDataObj[filter]) {
            this.filterString = this.filterString + `${filter}=${filterDataObj[filter].join(',')}&`
          }
        }
      }

      // emit filter string here
      this.filterAppliedEvent.emit(this.filterString);

    }
  }

  onQnnTypeChange($event) {
    this.onFilterSubmit();
    this.currentQuestionnaireType = $event.target.options[$event.target.options.selectedIndex].text;
    console.log(this.currentQuestionnaireType, "type");
    this.qnnTypeChange.emit({ currentOptionText: this.currentQuestionnaireType, filterString: this.filterString });
  }

  loadOverviewData() {
    Swal.showLoading();

    this.dashboardService.getOverview()
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            this.overviewData = response.data;
          } else {
            this.handleNotification(response.error_message || "Problem in fetching overview data!", true);
          }
        },
        error => {
          Swal.close();
          this.handleNotification(error.error.error_message || "Problem in fetching overview data!", true);
        })
  }
  datesUpdated(event) {
    this.brands = [];
    this.skus = [];
    this.cities = [];
    this.countries = [];
    this.channels = [];
    this.categories = [];
    this.dashboardForm.controls['country'].setValue([]);
    this.dashboardForm.controls['channel'].setValue([]);
    this.dashboardForm.controls['category'].setValue([]);
    this.dashboardForm.controls['brand'].setValue([]);
    this.dashboardForm.controls['sku'].setValue([]);
    this.getFilterString();
    setTimeout(() => { this.getCountryList() }, 500)
  }
  resetFilters() {
    this.months = [];
    this.regions = [];
    this.gov = [];
    this.subCategories = [];
    this.branch_name = [];
    this.branch_no = [];
    this.brands = [];
    this.skus = [];
    this.cities = [];
    this.countries = [];
    this.channels = [];
    this.categories = [];


    this.dashboardForm = this.fb.group({
      // date_range: [{ date_after: moment("01-01-2018", "DD-MM-YYYY"), date_before: moment("31-01-2018", "DD-MM-YYYY") }], // default this month
      country: [''],
      channel: [''],
      category: [''],
      brand: [''],
      sku: [''],
      month: [''],
      questionnaire_type: ["7"], // default questionnaire
    });
    this.getFilterString();
    setTimeout(() => { this.getCountryList() }, 500)
    // this.resetFilter.emit(this.currentQuestionnaireType);
    this.qnnTypeChange.emit({ currentOptionText: this.currentQuestionnaireType, filterString: this.filterString });

  }

  // helper methods

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  async onCountrySelect(event?) {
    Swal.showLoading();
    if (event) {
      this.countryIds.push(event.id);
      this.dashboardForm.controls.month.setValue(null);
      this.dashboardForm.controls.channel.setValue(null);
      this.dashboardForm.controls.category.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.getFilterString();
      this.loadMonths();
    }

    this.cities = [];
    Swal.close();
  }
  async onCountryDeSelect(event) {
    let countryIdToRemove = this.countryIds.indexOf(event.id);
    if (countryIdToRemove !== -1) {
      this.countryIds.splice(countryIdToRemove, 1);
    }
    if (this.countryIds.length == 0) {
      this.dashboardForm.controls.month.setValue(null);
      this.dashboardForm.controls.channel.setValue(null);
      this.dashboardForm.controls.category.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.cities = [];
      this.channels = [];
      this.categories = [];
      this.brands = [];
      this.skus = [];
      this.months = [];
      this.regions = [];
      this.gov = [];
      this.subCategories = [];
      this.branch_name = [];
      this.branch_no = [];
      let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
      element.click();
    }
    else {
      this.getFilterString();
      this.loadMonths();
    }

  }
  async onCountrySelectAll(event?) {
    console.log(event, "event");
    Swal.showLoading();
    if (event) {
      this.countryIds = event.map(country => country.id);
      setTimeout(() => {
        this.getFilterString();
        this.loadMonths()
      }, 500);
    }

    this.months = [];
    Swal.close();
  }
  async onCountryDeSelectAll(event) {
    this.countryIds = [];
    this.dashboardForm.controls.month.setValue(null);
    this.dashboardForm.controls.channel.setValue(null);
    this.dashboardForm.controls.category.setValue(null);
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.cities = [];
    this.channels = [];
    this.categories = [];
    this.brands = [];
    this.skus = [];
    this.months = [];
    this.regions = [];
    this.gov = [];
    this.subCategories = [];
    this.branch_name = [];
    this.branch_no = [];
    let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  async onMonthSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.monthIds.push(event.id);
      this.dashboardForm.controls.channel.setValue(null);
      this.dashboardForm.controls.category.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.getFilterString();
      this.getCategoryList()
    }

    this.countries = [];
    Swal.close();
  }
  async onMonthDeSelect(event) {
    let monthIdToRemove = this.monthIds.indexOf(event.id);
    if (monthIdToRemove !== -1) {
      this.monthIds.splice(monthIdToRemove, 1);
    }
    if (this.monthIds.length == 0) {
      this.dashboardForm.controls.channel.setValue(null);
      this.dashboardForm.controls.category.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.cities = [];
      this.channels = [];
      this.categories = [];
      this.brands = [];
      this.skus = [];
      let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
      element.click();
    }
    else {
      this.getFilterString();
      this.getCategoryList()
    }

  }
  async onMonthSelectAll(event?) {
    console.log(event, "event");
    Swal.showLoading();
    if (event) {
      this.monthIds = event.map(month => month.id);
      setTimeout(() => {
        this.getFilterString();
        this.getCategoryList()
      }, 500);
    }

    this.categories = [];
    Swal.close();
  }
  async onMonthDeSelectAll(event) {
    this.monthIds = [];
    this.dashboardForm.controls.channel.setValue(null);
    this.dashboardForm.controls.category.setValue(null);
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.cities = [];
    this.channels = [];
    this.categories = [];
    this.brands = [];
    this.skus = [];
    let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }


  async onChannelSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.channelIds.push(event.id);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.getFilterString();
      this.getBrandList();
    }

    this.brands = [];
    Swal.close();
  }
  async onChannelDeSelect(event) {
    let channelIdToRemove = this.channelIds.indexOf(event.id);
    if (channelIdToRemove !== -1) {
      this.channelIds.splice(channelIdToRemove, 1);
    }
    if (this.channelIds.length == 0) {
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.brands = [];
      this.skus = [];
      let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
      element.click();
    }
    else {
      this.getFilterString();
      this.getBrandList();
    }

  }
  async onChannelSelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.channelIds = event.map(channel => channel.id);
      setTimeout(() => {
        this.getFilterString();
        this.getBrandList()
      }, 500);
    }

    this.brands = [];
    Swal.close();
  }
  async onChannelDeSelectAll(event) {
    this.channelIds = [];
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.brands = [];
    this.skus = [];
    let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  async onCategorySelect(event?) {
    Swal.showLoading();
    if (event) {
      this.categoryIds.push(event.id);
      this.dashboardForm.controls.channel.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.getFilterString();
    }

    this.cities = [];
    this.channels = [];
    this.brands = [];
    this.skus = [];
    Swal.close();
  }
  async onCategoryDeSelect(event) {
    let categoryIdToRemove = this.categoryIds.indexOf(event.id);
    if (categoryIdToRemove !== -1) {
      this.categoryIds.splice(categoryIdToRemove, 1);
    }
    if (this.categoryIds.length == 0) {
      this.dashboardForm.controls.channel.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.cities = [];
      this.channels = [];
      this.brands = [];
      this.skus = [];
      let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
      element.click();
    }
    else {
      this.getFilterString();
    }

  }
  async onCategorySelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.categoryIds = event.map(category => category.id);
      setTimeout(() => {
        this.getFilterString();
      }, 500);
    }

    this.cities = [];
    this.channels = [];
    this.brands = [];
    this.skus = [];
    Swal.close();
  }
  async onCategoryDeSelectAll(event) {
    this.categoryIds = [];
    this.dashboardForm.controls.channel.setValue(null);
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.cities = [];
    this.channels = [];
    this.brands = [];
    this.skus = [];
    let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  async onBrandSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.brandIds.push(event.id);
      this.dashboardForm.controls.sku.setValue(null);
      this.getFilterString();
      this.getSkuList();
    }

    this.skus = [];
    Swal.close();
  }
  async onBrandDeSelect(event) {
    let brandIdToRemove = this.brandIds.indexOf(event.id);
    if (brandIdToRemove !== -1) {
      this.brandIds.splice(brandIdToRemove, 1);
    }
    if (this.brandIds.length == 0) {
      this.dashboardForm.controls.sku.setValue(null);
      this.skus = [];
      let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
      element.click();
    }
    else {
      this.getFilterString();
      this.getSkuList();
    }

  }
  async onBrandSelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.brandIds = event.map(brand => brand.id);
      setTimeout(() => {
        this.getFilterString();
        this.getSkuList()
      }, 500);
    }

    this.skus = [];
    Swal.close();
  }
  async onBrandDeSelectAll(event) {
    this.brandIds = [];
    this.dashboardForm.controls.sku.setValue(null);
    this.skus = [];
    let element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  async onSkuSelect(event?) { this.getFilterString(); }
  async onSkuDeSelect(event) { this.getFilterString(); }
  async onSkuSelectAll(event?) { }
  async onSkuDeSelectAll(event) { }

  loadQuestionnaireTypes() {
    Swal.showLoading();
    let questionnaireTypes = [
      // {
      //   "id": "2",
      //   "name": "Brand Health Tracker"
      // },
      {
        "id": "3",
        "name": "Out Of Stock"
      },
      {
        "id": "4",
        "name": "Distribution Check"
      },
      {
        "id": "7",
        "name": "Price Monitor"
      }
    ];
    this.questionnaire_types = [...questionnaireTypes];
    // this.operationService.getQTs()
    //   .subscribe(
    //     response => {
    //       Swal.close();
    //       let questionnaireTypes = response.data ? response.data.results : [];
    //       this.questionnaire_types = [...questionnaireTypes];
    //     },
    //     error => {
    //       Swal.close();
    //       this.handleNotification('Problem in fetching questionnaire type!', true);
    //     }
    //   )
  }
  loadMonths() {

    if (this.currentQuestionnaireType == 'Price Monitor') {

      this.snapDashboardService.pmListMonths(this.filterString)
        .subscribe(
          response => {
            Swal.close();
            if (response && response.success && response.data.length > 0) {
              this.months = response.data

            } else {
              this.months = ['']
            }
          },
          error => {
            Swal.close();
            this.handleNotification("Error fetching months!", true);
          }
        )
    }
    else if (this.currentQuestionnaireType == 'Out Of Stock') {

      this.snapDashboardService.OOSListMonths(this.filterString)
        .subscribe(
          response => {
            Swal.close();
            if (response && response.success && response.data.length > 0) {
              this.months = response.data

            } else {
              this.months = ['']
            }
          },
          error => {
            Swal.close();
            this.handleNotification("Error fetching months!", true);
          }
        )
    }
    else if (this.currentQuestionnaireType == 'Distribution Check') {
      this.snapDashboardService.DCListMonths(this.filterString)
        .subscribe(
          response => {
            Swal.close();
            if (response && response.success && response.data.length > 0) {
              this.months = response.data

            } else {
              this.months = ['']
            }
          },
          error => {
            Swal.close();
            this.handleNotification("Error fetching months!", true);
          }
        )
    }
  }

  customDateFormat(dateToFormat: Moment, format: string) {
    return dateToFormat.format(format);
  }

  getCountryList() {
    this.localizeService.getCountriesForSnap(this.filterString)
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.countries = data
        } else {
          this.countries = [];
        }
      }).catch(err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching countries!', true)
      })
  }
  getChannelList() {
    this.operationService.getChannelsForSnap(this.filterString)
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.channels = data
        } else {
          this.channels = [];
        }
      }).catch(err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching channels!', true)
      })
  }
  getCategoryList() {
    this.operationService.getCategoriesForSnap(this.filterString)
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.categories = data
        } else {
          this.categories = [];
        }
      }).catch(err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching categories!', true)
      })
  }
  getBrandList() {
    this.operationService.getBrandsForSnap(this.filterString)
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.brands = data
        } else {
          this.brands = [];
        }
      }).catch(err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching cities!', true)
      })
  }
  getSkuList() {
    this.operationService.getSkusForSnap(this.filterString)
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.skus = data
        } else {
          this.skus = [];
        }
      }).catch(err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching skus!', true)
      })
  }
  getFilterString() {
    let filterDataObj = { ...this.dashboardForm.value };
    this.filterString = '?'
    for (let filterDataKey in filterDataObj) {

      // if it's date_range, format it
      // if (filterDataKey == 'date_range') {
      //   let dateRange = filterDataObj?.date_range || null;
      //   if (dateRange) {
      //     let fromDate: Moment = this.dashboardForm.value?.date_range?.date_after;
      //     let tillDate: Moment = this.dashboardForm.value?.date_range?.date_before;
      //     filterDataObj['date_after'] = this.customDateFormat(fromDate, 'YYYY-MM-DD');
      //     filterDataObj['date_before'] = this.customDateFormat(tillDate, 'YYYY-MM-DD');
      //   }
      // }
      // delete filterDataObj['date_range'];

      // don't to anything for questionnaire type
      // for all other filters we extract ids
      if (filterDataKey != 'questionnaire_type') {
        let otherFormValues = (filterDataObj[filterDataKey]) as Array<any>;
        if (otherFormValues) {
          filterDataObj[filterDataKey] = otherFormValues.map(eachValue => eachValue.id);
        }
      }
    }

    for (let filter in filterDataObj) {
      if (filter == 'questionnaire_type' || filter == 'date_after' || filter == 'date_before') {
        this.filterString = this.filterString + `${filter}=${filterDataObj[filter]}&`
      } else {
        if (filterDataObj[filter]) {
          this.filterString = this.filterString + `${filter}=${filterDataObj[filter].join(',')}&`
        }
      }
    }
  }
  viewCSV() {
    var type;
    if (this.currentQuestionnaireType === "Out Of Stock") {
      type = "out-of-stock"
    }
    else if (this.currentQuestionnaireType === "Brand Health Tracker") {
      type = "consumer"
    }
    else if (this.currentQuestionnaireType === "Distribution Check") {
      type = "distribution"
    }
    else {
      type = "price-monitor"
    }
    this.viewPMSnapData(this.gLimit, type, this.filterString);
  }
  viewPMSnapData(limit?: number, type?: string, filter?: string) {
    Swal.showLoading();
    this.snapDashboardService.viewSnapDataFilter(limit, type, filter)
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.snapData = res && res.data ? res.data.results : [];

          this.previousUrl = res && res.data ? res.data.previous : null;
          this.nextUrl = res && res.data ? res.data.next : null;

          // this.count = res && res.data ? res.data.count : 0;

        } else {
          this.snapData = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Something went wrong in fetching snap data!',
          timer: 1500
        });
      });
  }
  handlePagination(paginationString: string) {

    Swal.showLoading();

    if (paginationString == 'next') {
      this.http.get<any>(this.nextUrl)
        .subscribe(response => {
          Swal.close();
          this.snapData = response && response.data ? response.data.results : [];
          this.snapData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }

    if (paginationString == 'previous') {
      this.http.get<any>(this.previousUrl)
        .subscribe(response => {
          Swal.close();
          this.snapData = response && response.data ? response.data.results : [];
          this.snapData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;


        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }
  }
  exportData(type: string, file: string) {
    Swal.showLoading();
    this.http.get(`${environment.baseUrl}/snap/${type}/export${this.filterString}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders()
        .set('Authorization', this.accessToken)
    })
      .subscribe(
        (response: HttpResponse<Blob>) => {
          Swal.close();
          console.log(response.headers);
          const filename = file;
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error => {
          Swal.close();
          console.log(error);
        }
      );
  }
}
