<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add Branches to Operations'"
              [subTitle]="'You can add branches to the operations from here'"></app-top-title>
          </div>
          <div class="bg-white mb-5">
            <div class="card-box">

              <form class="" role="form" [formGroup]="branchForm"
                (ngSubmit)="isUpdateMode ? updateBranch() : addStore()"
                onkeydown="return event.key != 'Enter';">

                <div class="row px-4 pt-3">

                  <div class="col-md-6" style="width: 100%;">
                    <div class="form-group">
                      <label class=" col-form-label">Country<span style="color:#ff0000">*</span></label>
                      <ng-multiselect-dropdown style="max-width: fit-content;" name="country" id="selCountry"
                        [placeholder]="'Select Country'" (onSelect)="getCitiesByCountryId($event)"
                        (onDeSelect)="resetCities($event)" [data]="countries" [settings]="dropdownSettings"
                        [formControl]="branchForm.controls['country']"
                        [class.valid]="branchForm.get('country').valid && 
                                                                (branchForm.get('country').dirty || branchForm.get('country').touched)"
                        [class.invalid]="branchForm.get('country').invalid && 
                                                                (branchForm.get('country').dirty || branchForm.get('country').touched)">
                      </ng-multiselect-dropdown>
                      <div
                        *ngIf="branchForm.get('country').invalid && 
                                                                   branchForm.get('country').errors && 
                                                                   (branchForm.get('country').dirty || branchForm.get('country').touched)">
                        <small class="text-danger" *ngIf="branchForm.get('country').hasError('required')">
                          Country is required. </small>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6" style="width: 100%;">
                    <div class="form-group">
                      <label class="col-form-label" for="city">City List<span style="color:#ff0000">*</span></label>
                      <ng-multiselect-dropdown name="city" id="selCity" [placeholder]="'Select City'" [data]="cities"
                        [settings]="dropdownSettings" [formControl]="branchForm.controls['city']"
                        [class.valid]="branchForm.get('city').valid && 
                                                                (branchForm.get('city').dirty || branchForm.get('city').touched)"
                        [class.invalid]="branchForm.get('city').invalid && 
                                                                (branchForm.get('city').dirty || branchForm.get('city').touched)">
                      </ng-multiselect-dropdown>
                      <div
                        *ngIf="branchForm.get('city').invalid && 
                                                                   branchForm.get('city').errors && 
                                                                   (branchForm.get('city').dirty || branchForm.get('city').touched)">
                        <small class="text-danger" *ngIf="branchForm.get('city').hasError('required')">
                          City is required. </small>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row px-4 pt-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label" for="country">Channel <span style="color:#ff0000">*</span></label>
                      <ng-multiselect-dropdown name="channel" id="selCity" [placeholder]="'Select Channel'"
                        [data]="channels" [settings]="dropdownSettings" [formControl]="branchForm.controls['channel']"
                        [class.valid]="branchForm.get('channel').valid && 
                                                                (branchForm.get('channel').dirty || branchForm.get('channel').touched)"
                        [class.invalid]="branchForm.get('channel').invalid && 
                                                                (branchForm.get('channel').dirty || branchForm.get('channel').touched)">
                      </ng-multiselect-dropdown>
                      <div
                        *ngIf="branchForm.get('channel').invalid && 
                                                                   branchForm.get('channel').errors && 
                                                                   (branchForm.get('channel').dirty || branchForm.get('channel').touched)">
                        <small class="text-danger" *ngIf="branchForm.get('channel').hasError('required')">
                          Channel is required. </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" style="width: 100%;">
                    <div class="form-group">
                      <label class="col-form-label" for="retailer">Retailers <span
                          style="color:#ff0000">*</span></label>
                      <ng-multiselect-dropdown name="retailer" id="selRetailer" [placeholder]="'Select Retailer'"
                        [data]="retailers" [settings]="dropdownSettings" [formControl]="branchForm.controls['retailer']"
                        [class.valid]="branchForm.get('retailer').valid && 
                                                                (branchForm.get('retailer').dirty || branchForm.get('retailer').touched)"
                        [class.invalid]="branchForm.get('retailer').invalid && 
                                                                (branchForm.get('retailer').dirty || branchForm.get('retailer').touched)">
                      </ng-multiselect-dropdown>
                      <div
                        *ngIf="branchForm.get('retailer').invalid && 
                                                                   branchForm.get('retailer').errors && 
                                                                   (branchForm.get('retailer').dirty || branchForm.get('retailer').touched)">
                        <small class="text-danger" *ngIf="branchForm.get('retailer').hasError('required')">
                          Retailer is required. </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row px-4 pb-3">

                  <div class="col-md-6" style="width: 100%;">
                    <div *ngIf="!isUpdateMode" class="form-group">
                      <label class="col-form-label">Branch List<span style="color:#ff0000">*</span></label>
                      <div class="editor-tag" (click)="focusTagInput()">
                        <span class="tag-item" *ngFor="let branch of branches">
                          {{branch}}
                          <span class="remove-tag" (click)="removeTag(branch)">×</span>
                        </span>
                        <input placeholder="Type then press enter.." #branchInput class="input-tag" type="text"
                        (ngModelChange)="updateValidator()"
                          id="branch" (keyup)="onKeyUp($event, 'branch')" formControlName="name" [class.valid]="branchForm.get('name').valid && 
                          (branchForm.get('name').dirty || branchForm.get('name').touched)" [class.invalid]="branchForm.get('name').invalid && 
                          (branchForm.get('name').dirty || branchForm.get('name').touched)">
                      </div>
                      <div *ngIf="invalidBranchLength">
                        <small class="text-danger">
                          Branch name is required. </small>
                      </div>
                      <small style="color:#53a7db"><i class="fa fa-info-circle" aria-hidden="true"></i><em>&nbsp;Type and press Enter to add branch.</em></small>
                    </div>

                    <div *ngIf="isUpdateMode">
                      <div class="form-group">
                        <label class="col-form-label">Branch <span style="color:#ff0000">*</span></label>
                        <input placeholder="Enter branch to update" class="form-control" type="text" id="branch"
                          formControlName="name" [class.valid]="branchForm.get('name').valid && 
                          (branchForm.get('name').dirty || branchForm.get('name').touched)" [class.invalid]="branchForm.get('name').invalid && 
                          (branchForm.get('name').dirty || branchForm.get('name').touched)">
                        <div
                          *ngIf="branchForm.get('name').invalid && 
                                                                   branchForm.get('name').errors && 
                                                                   (branchForm.get('name').dirty || branchForm.get('name').touched)">
                          <small class="text-danger" *ngIf="branchForm.get('name').hasError('required')">
                            Branch name is required. </small>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row px-5 pb-3 d-flex">
                  <div class="form-group">
                    <button *ngIf="!isUpdateMode" type="submit" class="btn btn-success px-5 mr-2">Add</button>
                    <button *ngIf="isUpdateMode" type="submit" class="btn btn-success px-5 mr-2">Update</button>
                    <button (click)="toggleUpdateMode()" type="button" class="btn btn-danger px-5">Cancel</button>
                  </div>
                </div>
              </form>
            </div>

            <!-- end row -->
          </div>
          <div class="mt-1 p-2 bg-white mb-5">
            <table  class="custom-table"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="branchTable">
              <thead>
                <tr>
                  <th>Branch Id</th>
                  <th>Channel</th>
                  <th>Retailer</th>
                  <th>Branch</th>
                  <th>Country</th>
                  <th>City</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let store of stores">
                  <td>{{ store.id }}</td><td>{{ store?.channel?.name }}</td>
                  <td>{{ store.retailer?.name }}</td>
                  <td>{{ store?.name }}</td>
                  <td>{{ store?.country?.name }}</td>
                  <td>{{ store?.city?.name }}</td>
                  <td>
                    <a [routerLink]="" (click)="fetchBranch(store)" style="color:'blue';">Edit</a>
                  </td>
                  <td>
                    <a [routerLink]="" (click)="removeStore(store.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>