import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ExtraHeader, SharedService } from '../_shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class FieldWorkerService {

  private extraHeader: ExtraHeader[] = [
    {
      headerName: "Content-Type",
      headerValue: "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
    }
  ]
  constructor(
    private shareService: SharedService) {

  }

  getFieldUsers() {
    return this.shareService.get(`${environment.baseUrl}/user/agent-user/list`);
  }

  getSingleFieldUser(user_id) {
    return this.shareService.get(`${environment.baseUrl}/user/agent-user/${user_id}/detail`)
  }

  registerFieldUser(userData) {
    return this.shareService.post(`${environment.baseUrl}/user/agent-user/register`, userData, this.extraHeader);
  }

  updateFieldUser(userData, user_id) {
    return this.shareService.put(`${environment.baseUrl}/user/agent-user/${user_id}/update`, userData, this.extraHeader);
  }

  deleteFieldWorker(user_id) {
    return this.shareService.delete(`${environment.baseUrl}/user/agent-user/${user_id}/delete`)
  }

  deleteFieldWorkerById(userId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: { 'Authorization': `JWT ${accessToken}` },
            url: `${environment.baseUrl}/user/agent-user/${userId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  listAgentQuestionnaireReportById(user_id) {
    return this.shareService.get(`${environment.baseUrl}/response/agent-user/${user_id}/list`)
  }  
  
  parseCsvData(fileData) {
    return this.shareService.post(`${environment.baseUrl}/user/agent-user/import`, fileData)
  }

}
