import { EmailService } from './../_services/email.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor(
    public formBuilder: FormBuilder,
    public emailService: EmailService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  supportEmailForm = this.formBuilder.group({
    fullname: ['', [Validators.required]],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    text: ['', [Validators.required]]
  })

  sendEmail() {
    if(this.supportEmailForm.valid) {
      Swal.showLoading();
      // hit email service
      this.emailService.sendEmail(this.supportEmailForm.value)
      .subscribe(res => {
        Swal.close();
        if(res.success) {
          Swal.fire({
            icon: 'success',
            titleText: 'Email sent successfully!'
          });
          this.router.navigate(['/dashboard'])
        } else {
          Swal.fire({
            icon: 'error',
            titleText: 'Problem in sending email!'
          })
        }
      }, err => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          titleText: err.error.error_message
        })
      })
    } else {
      // show validation error
      for (var i in this.supportEmailForm.controls) {
        this.supportEmailForm.controls[i].markAsDirty();
        this.supportEmailForm.controls[i].updateValueAndValidity();
      }
    }
  }

}
