import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Moment } from 'moment'
import * as moment from 'moment';
import { SnapDashboardService } from 'src/app/_services/snap-dashboard.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-out-of-stock',
  templateUrl: './out-of-stock.component.html',
  styleUrls: ['./out-of-stock.component.scss']
})
export class OutOfStockComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  public previousUrl: string;
  public nextUrl: string;

  public gLimit: number = 10;
  public gOffset: number = 0;
  // public count: number;

  isSelectAllChecked: boolean = false;

  dashboardForm: FormGroup;
  dateRange: any;
  dateAfter: any;
  dateBefore: any;
  alwaysShowCalendars: boolean;
  // minDate = moment('2018-01-01');
  minDate = moment().subtract(3, 'years');;
  maxDate = moment().add(0, 'days');
  showRangeLabelOnInput = true;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  }

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private snapDashboardService: SnapDashboardService
  ) { }

  OOSSnapData: any;
  accessToken: string;

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: '<"top"<"center-col"l><"right-col">>rt',
      stateSave: true,
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export',
          title: 'snap_out_of_stock',
          className: ' btn btn-lg btn-sm btn-info rounded-pill py-2 px-4 mr-3 float-right',
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
          }
        }
      ],
      columnDefs: [{
        targets: [10, 11, 12, 13, 14, 15, 16, 17, 18], visible: false
      }],
      stateSaveCallback: function (settings, data) {
        settings._iDisplayLength != that.gLimit ? that.changePageLength(settings._iDisplayLength) : '';
        localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance));
      },
    }

    this.dashboardForm = this.fb.group({
      // date_range: [{ date_after: moment('2018-01-01'), date_before: moment('2018-03-30') }], // default This Month
      date_range: [], // default This Month
    });

    this.viewOOSnapData(this.gLimit, this.gOffset, '', '');
    this.accessToken = localStorage.getItem('accessToken')

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  applyDateFilter () {
    this.dateAfter = `${this.dashboardForm.value.date_range.date_after.year()}-${this.dashboardForm.value.date_range.date_after.month() + 1}-${this.dashboardForm.value.date_range.date_after.date()}`;
    this.dateBefore = `${this.dashboardForm.value.date_range.date_before.year()}-${this.dashboardForm.value.date_range.date_before.month() + 1}-${this.dashboardForm.value.date_range.date_before.date()}`;
    this.viewOOSnapData(this.gLimit, this.gOffset, this.dateBefore, this.dateAfter);
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  searchText(searchText: any) {
    Swal.showLoading();
    this.snapDashboardService.searchSnapOOSData(searchText?.data)
      .subscribe(res => {
        Swal.close()
        this.OOSSnapData = [];
        if (res && res.success) {
          this.OOSSnapData = res && res.data ? res.data.results : [];
          this.isSelectAllChecked = false;
          this.OOSSnapData.map(snapDatum => snapDatum['isChecked'] = false)

          this.previousUrl = res && res.data ? res.data.previous : null;
          this.nextUrl = res && res.data ? res.data.next : null;

         // this.count = res && res.data ? res.data.count : 0;

          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error.error_message,
            timer: 1500
          });
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Something went wrong in fetching snap data!',
          timer: 1500
        });
      })
  }

  viewOOSnapData(limit?: number, offset?: number, dateBefore?: string, dateAfter?: string) {
    Swal.showLoading();
    this.snapDashboardService.viewSnapOOSData(limit, offset, dateBefore, dateAfter)
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.OOSSnapData = res && res.data ? res.data.results : [];
          this.isSelectAllChecked = false;
          this.OOSSnapData.map(snapDatum => snapDatum['isChecked'] = false)

          this.previousUrl = res && res.data ? res.data.previous : null;
          this.nextUrl = res && res.data ? res.data.next : null;

         // this.count = res && res.data ? res.data.count : 0;

          this.rerender();
        } else {
          this.OOSSnapData = []
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Something went wrong in fetching snap data!',
          timer: 1500
        });
      })
  }

  importSnapOOSData(event) {
    let file = event.target.files[0];
    if (file) {

      Swal.showLoading();
      // check if file has valid extension
      let fileExtension = file.name.split('.')[1];
      if (fileExtension !== 'csv') {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please upload csv file!'
        });
        return false;
      }

      // check valid file size
      let size = file.size / (1024 * 1024);
      if (size > 4) {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please only upload file less than 4MB !'
        });
        return false;
      }

      // send data to api
      let csvData = new FormData();
      csvData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
      this.snapDashboardService.importOOSSnapData(csvData)
        .subscribe(res => {
          Swal.close()
          if (res.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message
            }).then(() => {
              this.viewOOSnapData(10, 0, '', '');
              event.target.value = '';
            })
          } else {
            event.target.value = '';
            this.handleNotification(res.error_message || "Something went wrong while importing the file!", true);
          }
        }, err => {
          event.target.value = '';
          Swal.close()
          this.handleNotification(err.error.error_message || "Something went wrong while importing the file!", true);
        });
    }
  }

  exportData() {
    Swal.showLoading();
    this.http.get(`${environment.baseUrl}/snap/out-of-stock/export`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders()
        .set('Authorization', this.accessToken)
    })
      .subscribe(
        (response: HttpResponse<Blob>) => {
          Swal.close()
          console.log(response.headers);
          let filename: string = "out_of_stock_snap.csv"
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error => {
          Swal.close()
          console.log(error);
        }
      )
  }

  // helper methods

  changePageLength(limit: number) {
    this.gLimit = limit;
    this.viewOOSnapData(this.gLimit, this.gOffset, this.dateBefore, this.dateAfter);
  }

  handlePagination(paginationString: string) {

    Swal.showLoading();

    if (paginationString == 'next') {
      this.http.get<any>(this.nextUrl)
        .subscribe(response => {
          Swal.close();
          this.OOSSnapData = response && response.data ? response.data.results : [];
          this.OOSSnapData.map(snapDatum => snapDatum['isChecked'] = false)

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          let { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        })
    }

    if (paginationString == 'previous') {
      this.http.get<any>(this.previousUrl)
        .subscribe(response => {
          Swal.close();
          this.OOSSnapData = response && response.data ? response.data.results : [];
          this.OOSSnapData.map(snapDatum => snapDatum['isChecked'] = false)

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          let { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        })
    }
  }

  selectAll() {
    this.isSelectAllChecked = !this.isSelectAllChecked;

    if (this.isSelectAllChecked) {
      this.OOSSnapData.map(snapDatum => snapDatum['isChecked'] = true)
    } else {
      this.OOSSnapData.map(snapDatum => snapDatum['isChecked'] = false)
    }
  }

  deleteSnapData() {

    let recordsToBeDeleted: Array<any> = this.OOSSnapData.filter(snapDatum => snapDatum.isChecked == true);
    let deleteIds: Array<number> = recordsToBeDeleted.map(record => record.id);

    this.snapDashboardService.bulkSnapDelete(this.accessToken, { snap_ids: [...deleteIds] }, 'out-of-stock')
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Selected records deleted successfully!"
        }).then(() => {
          if (this.previousUrl) {
            let { limit, offset } = this.extractQueryParams(this.nextUrl);
            let currentOffset = parseInt(offset || '0') - parseInt(limit || '0');
            this.viewOOSnapData(parseInt(limit || '10'), currentOffset, this.dateBefore, this.dateAfter);
          } else {
            this.viewOOSnapData(10, 0, '', '');
          }
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: error.message
        })
      })
  }

  rowSelected(id) {
    this.OOSSnapData.map(data => {
      if (data.id == id) {
        data['isChecked'] = !data['isChecked']
      }
    });
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  extractQueryParams(url: string): { limit: string, offset: string } {

    let queryParamsString = url.split('?')[1];

    let limitString = queryParamsString.split('&')[0];
    let limit = limitString.split('=')[1];

    let offsetString = queryParamsString.split('&')[1];
    let offset = offsetString.split('=')[1] || '0';

    return {
      limit: limit,
      offset: offset
    }
  }
}
