<div style="background-color: 'white';margin-bottom: '10px';;padding:'10px'" >
  <div style="display:'flex';justify-content: 'space-between';" >
    <div>
      <b>Radar Chart</b>
    </div>
      <div>
        <button style="font-size: '1rem'; " class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div>
            <fa-icon [icon]="faEllipsisV"></fa-icon>
        </div>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </div>
  </div>
    <div>
      <div style="display: block">
        <canvas baseChart
          [datasets]="radarChartData"
          [options]="radarChartOptions"
          [labels]="radarChartLabels"
          [chartType]="radarChartType">
        </canvas>
      </div>
    </div>
  </div>