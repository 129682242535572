<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Field Work'"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="'Create field work agent'"
              [subTitle]="'You can  create a field work agent and initiate his account on the app'">
            </app-top-title>
          </div>

          <div class="mt-4 p-2" style="background-color: white;">
            <!-- end row -->

            <div class="row">
              <div class="col-12">
                <div class="card-box">
                  <div class="row">
                    <div class="col-12">
                      <div class="p-2">
                        <form class="form-horizontal" role="form" [formGroup]="fieldWorkForm" (ngSubmit)="onSubmit()"
                          enctype="multipart/form-data">
                          <div class="card-box">
                            <div class="form-group row">
                              <label class="col-md-3">Profile Picture</label>
                              <div class="col-md-9">
                                <label class="col-form-label" for="profileImage">
                                  <img alt="Profile Image" class="rounded-circle img-thumbnail avatar-md"
                                    [src]="fileUrl ? fileUrl : '/assets/images/user_icon.jpg'" />
                                </label>
                                <div>
                                  <input type="file" class="form-control-file" id="profileImage"
                                    (change)="handleFileInput($event)" placeholder="Placeholder" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="field-worker">Field
                              Worker Name<span style="color:#ff0000">*</span></label>
                            <div class="col-md-9">
                              <input type="text" id="field-worker" class="form-control" placeholder="Field worker name"
                                formControlName="fullname"
                                [class.valid]="fieldWorkForm.get('fullname').valid && 
                                                                (fieldWorkForm.get('fullname').dirty || fieldWorkForm.get('fullname').touched)"
                                [class.invalid]="fieldWorkForm.get('fullname').invalid && 
                                                                (fieldWorkForm.get('fullname').dirty || fieldWorkForm.get('fullname').touched)">
                              <div
                                *ngIf="fieldWorkForm.get('fullname').invalid && 
                                                                   fieldWorkForm.get('fullname').errors && 
                                                                   (fieldWorkForm.get('fullname').dirty || fieldWorkForm.get('fullname').touched)">
                                <small class="text-danger" *ngIf="fieldWorkForm.get('fullname').hasError('required')">
                                  Worker name is required. </small>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label">Nationality</label>
                            <div class="col-md-9">
                              <ng-multiselect-dropdown name="nationality" id="selNationality"
                                [placeholder]="'Select Country'" [data]="listOfCountriesForNationality"
                                [settings]="nationalityDDSettings"
                                [formControl]="fieldWorkForm.controls['nationality']">
                              </ng-multiselect-dropdown>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="fw-contact">Contact Number</label>
                            <div class="col-md-9">
                              <input type="text" formControlName="contact_number" id="fw-contact" class="form-control"
                                placeholder="Enter contact number"
                                [class.valid]="fieldWorkForm.get('contact_number').valid && 
                                                              (fieldWorkForm.get('contact_number').dirty || fieldWorkForm.get('contact_number').touched)"
                                [class.invalid]="fieldWorkForm.get('contact_number').invalid && 
                                                              (fieldWorkForm.get('contact_number').dirty || fieldWorkForm.get('contact_number').touched)">

                              <div
                                *ngIf="fieldWorkForm.get('contact_number').invalid && 
                                      fieldWorkForm.get('contact_number').errors && 
                                      (fieldWorkForm.get('contact_number').dirty || fieldWorkForm.get('contact_number').touched)">
                                <small class="text-danger"
                                  *ngIf="fieldWorkForm.get('contact_number').hasError('pattern')">
                                  Contact Number should be number. </small>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="date-of-birth">Date
                              of Birth</label>
                            <div class="col-md-9">
                              <input class="form-control" id="date-of-birth" type="date"
                                formControlName="date_of_birth">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label">Country of Operation<span style="color:#ff0000">*</span></label>
                            <div class="col-md-9">
                              <ng-multiselect-dropdown [placeholder]="'Choose country of operation'"
                                [settings]="dropdownSettings" [data]="listOfCountries"
                                (onSelect)="onCountrySelect($event)" (onSelectAll)="onCountrySelectAll($event)"
                                (onDeSelect)="onCountryDeSelect($event)"
                                [formControl]="fieldWorkForm.controls['operation_country']" [class.valid]="fieldWorkForm.get('operation_country').valid &&
                                (fieldWorkForm.get('operation_country').dirty || fieldWorkForm.get('operation_country').touched)"
                                  [class.invalid]="fieldWorkForm.get('operation_country').invalid &&
                                (fieldWorkForm.get('operation_country').dirty || fieldWorkForm.get('operation_country').touched)">
                                </ng-multiselect-dropdown>
  
                                <div *ngIf="fieldWorkForm.get('operation_country').invalid && 
                                   fieldWorkForm.get('operation_country').errors && 
                                   (fieldWorkForm.get('operation_country').dirty || fieldWorkForm.get('operation_country').touched)">
                                  <small class="text-danger" *ngIf="fieldWorkForm.get('operation_country').hasError('required')">
                                    Operation Country is required. </small>
                                </div>
                              
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label">City Of Operation<span style="color:#ff0000">*</span></label>
                            <div class="col-md-9">
                              <ng-multiselect-dropdown [placeholder]="'Choose cities of operation'"
                                [settings]="dropdownSettings" [data]="listOfCities" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                                [formControl]="fieldWorkForm.controls['operation_city']" [class.valid]="fieldWorkForm.get('operation_city').valid &&
                              (fieldWorkForm.get('operation_city').dirty || fieldWorkForm.get('operation_city').touched)"
                                [class.invalid]="fieldWorkForm.get('operation_city').invalid &&
                              (fieldWorkForm.get('operation_city').dirty || fieldWorkForm.get('operation_city').touched)">
                              </ng-multiselect-dropdown>

                              <div *ngIf="fieldWorkForm.get('operation_city').invalid && 
                                 fieldWorkForm.get('operation_city').errors && 
                                 (fieldWorkForm.get('operation_city').dirty || fieldWorkForm.get('operation_city').touched)">
                                <small class="text-danger" *ngIf="fieldWorkForm.get('operation_city').hasError('required')">
                                  Operation City is required. </small>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="field-email">Email<span
                                style="color:#ff0000">*</span></label>
                            <div class="col-md-9">
                              <input type="email" id="field-email" formControlName="email" class="form-control"
                                placeholder="Enter email"
                                [class.valid]="fieldWorkForm.get('email').valid && 
                                                                (fieldWorkForm.get('email').dirty || fieldWorkForm.get('email').touched)"
                                [class.invalid]="fieldWorkForm.get('email').invalid && 
                                                                (fieldWorkForm.get('email').dirty || fieldWorkForm.get('email').touched)">
                              <div
                                *ngIf="fieldWorkForm.get('email').invalid && 
                                                                   fieldWorkForm.get('email').errors && 
                                                                   (fieldWorkForm.get('email').dirty || fieldWorkForm.get('email').touched)">
                                <small class="text-danger" *ngIf="fieldWorkForm.get('email').hasError('required')">
                                  Email is required. </small>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="username">Username</label>
                            <div class="col-md-9">
                              <input type="text" id="username" formControlName="username" class="form-control"
                                placeholder="Enter username">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="password">
                              <sapn *ngIf="!isUpdateMode">Password</sapn>
                              <sapn *ngIf="isUpdateMode">New Password</sapn><span style="color:#ff0000"
                                *ngIf="!isUpdateMode">*</span>
                            </label>
                            <div class="col-md-9">
                              <input type="password" id="password" class="form-control" formControlName="password"
                                placeholder="Enter password"
                                [class.valid]="fieldWorkForm.get('password').valid && 
                                                                (fieldWorkForm.get('password').dirty || fieldWorkForm.get('password').touched)"
                                [class.invalid]="fieldWorkForm.get('password').invalid && 
                                                                (fieldWorkForm.get('password').dirty || fieldWorkForm.get('password').touched)">
                              <div
                                *ngIf="fieldWorkForm.get('password').invalid && 
                                                                   fieldWorkForm.get('password').errors && 
                                                                   (fieldWorkForm.get('password').dirty || fieldWorkForm.get('password').touched)">
                                <small class="text-danger" *ngIf="fieldWorkForm.get('password').hasError('required')">
                                  Password is required. </small>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="re-enter-password">Re-Enter Password<span
                                style="color:#ff0000" *ngIf="!isUpdateMode">*</span></label>
                            <div class="col-md-9">
                              <input type="password" id="re-enter-password" formControlName="confirm_password"
                                class="form-control" value="password" placeholder="Confirm Password">
                              <div>
                                <small class="text-danger" *ngIf="fieldWorkForm.hasError('notSame')">
                                  Passwords do not match. </small>
                              </div>
                            </div>
                          </div>
                          <button *ngIf="!isUpdateMode" type="submit"
                            class="btn btn-success rounded-pill pl-4 pr-4">Save</button>
                          <button *ngIf="isUpdateMode" type="submit"
                            class="btn btn-success rounded-pill pl-4 pr-4">Update</button>
                          &nbsp;
                          <button type="button" (click)="cancelFieldWork()"
                            class="btn btn-danger rounded-pill pl-4 pr-4">Cancel</button>
                        </form>
                      </div>
                    </div>

                  </div>
                  <!-- end row -->

                  <!-- end row -->

                </div>
                <!-- end card-box -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <br /><br />
          </div>
        </div>
        <!-- container-fluid -->

      </div>
      <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

  </div>
  <!-- END wrapper -->

</body>

</html>
