import { SnapOperationService } from './../../_services/snap-operation.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-snap-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class SnapChannelComponent implements OnInit {

  channels: Array<any> = [];
  channelForm: FormGroup;
  accessToken: string;

  channelIdToUpdate: Number = null;

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private operationService: SnapOperationService
  ) { }

  ngOnInit(): void {

    this.accessToken = localStorage.getItem('accessToken');

    this.channelForm = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true
    }

    this.listAllChannels();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listAllChannels() {
    Swal.showLoading();
    this.operationService.getChannels()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.channels = response.data?.results;
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching channels!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching channels!"
        });
      })
  }

  removeChannel(channelId) {
    this.operationService.removeChannel(channelId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Channel deleted successfully!"
        }).then(res => {
          this.listAllChannels();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting channel!"
        })
      })
  }

  updateChannel() {
    if (this.channelForm.invalid) {
      this.channelForm.controls['name'].markAsDirty();
      this.channelForm.controls['name'].updateValueAndValidity();
    } else {
      Swal.showLoading();
      if (this.channelIdToUpdate) {
        this.operationService.updateChannel(this.channelIdToUpdate, this.channelForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "Channel updated successfully!"
                }).then(() => {
                  this.channelIdToUpdate = null;
                  this.listAllChannels();
                  this.channelForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating channel!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding channel!"
              });
            }
          )
      }
    }

  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
  // helper methods
  fetchChannel(channel) {
    this.channelIdToUpdate = channel.id || null;
    this.channelForm = this.fb.group({
      name: [channel.name, [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.channelForm.reset();
  }

}
