<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Dashboard'"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">
        <app-top-dashboard (filterAppliedEvent)="filterApplied($event)" (qnnTypeChange)="onQnnTypeChange($event)"
          (resetFilter)="resetFilter()">
        </app-top-dashboard>

        <!-- Start Content-->
        <div class="price-monitor-chart-view" *ngIf="currentQnnView == 'pm'">

          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="minMaxBarChartData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="minMaxBarChartLabels" [chartTitle]="'Price Measure Total Country'"
                [barChartExcelData]="barChartExcelData" [barChartExcelFileName]="'price_monitor_min_max_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'pmMinMaxBarChartData'" [dataModalId]="'pmModalMinMaxBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthMaxBarChartData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="monthMaxBarChartLabels" [chartTitle]="'Price Monitor -  Month, Maximum Price'"
                [barChartExcelData]="barChartMonthMaxExcelData"
                [barChartExcelFileName]="'price_monitor_month_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'center'" [scoreAnchor]="'center'" [dataTableId]="'pmMonthMaxBarChartData'"
                [dataModalId]="'pmModalMonthMaxBarChartData'"></app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthMinBarChartData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="monthMinBarChartLabels" [chartTitle]="'Price Monitor – Month, Minimum Price'"
                [barChartExcelData]="barChartMonthMinExcelData"
                [barChartExcelFileName]="'price_monitor_month_min_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'center'" [scoreAnchor]="'center'" [dataTableId]="'pmMonthMinBarChartData'"
                [dataModalId]="'pmModalMonthMinBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandVsAvgChartData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="brandVsAvgChartLabels"
                [chartTitle]="'Price Monitor – Total Brand, All Price Measures'"
                [barChartExcelData]="brandVsAvgExcelData"
                [barChartExcelFileName]="'price_monitor_brand_vs_average_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmBrandVsAvgChartData'"
                [dataModalId]="'pmModalBrandVsAvgChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="countryMinBarChartData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="countryMinBarChartLabels" [chartTitle]="'Price Monitor – Minimum Price by country'"
                [barChartExcelData]="countryMinExcelData" [barChartExcelFileName]="'price_monitor_country_min_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'pmCountryMinBarChartData'" [dataModalId]="'pmModalCountryMinBarChartData'">
              </app-barchart>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="monthModeBarChartData" [canvasHeight]="doubleHeight" [loaded]="true"
                [barChartLabels]="monthModeBarChartLabels" [chartTitle]="'Price Monitor – Month, Mode Price'"
                [barChartExcelData]="barChartMonthModeExcelData"
                [barChartExcelFileName]="'price_monitor_month_mode_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'center'" [scoreAnchor]="'center'" [dataTableId]="'pmMonthModeBarChartData'"
                [dataModalId]="'pmModalMonthModeBarChartData'"></app-barchart>
            </div>
            <div class="col-sm-12">
              <app-barchart [barChartData]="monthMeanBarChartData" [canvasHeight]="doubleHeight" [loaded]="true"
                [barChartLabels]="monthMeanBarChartLabels" [chartTitle]="'Price Monitor – Month, Mean Price'"
                [barChartExcelData]="barChartMonthMeanExcelData"
                [barChartExcelFileName]="'price_monitor_month_mean_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'center'" [scoreAnchor]="'center'" [dataTableId]="'pmMonthMeanBarChartData'"
                [dataModalId]="'pmModalMonthMeanBarChartData'"></app-barchart>
            </div>

          </div>

          <div class="row">
            <div class="col-sm-12">
              <app-line-chart [lineChartData]="answerPerCityChartData" [lineChartLabels]="answerPerCityChartLabels"
                [canvasHeight]="doubleHeight" [chartTitle]="'Price Monitor – Outlets Visited per City'" [loaded]="true"
                [lineChartExcelData]="answerPerCityExcelData"
                [lineChartExcelFileName]="'price_monitor_answer_per_city_report'"
                [dataTableId]="'pmAnswerPerCityChartData'" [dataModalId]="'pmModalAnswerPerCityChartData'">
              </app-line-chart>
            </div>
            <div class="col-sm-12">
              <app-barchart [barChartData]="countryMaxBarChartData" [canvasHeight]="doubleHeight" [loaded]="true"
                [barChartLabels]="countryMaxBarChartLabels" [chartTitle]="'Price Monitor – Maximum Price by total country'"
                [barChartExcelData]="countryMaxExcelData" [barChartExcelFileName]="'price_monitor_country_max_report'"
                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'pmCountryMaxBarChartData'" [dataModalId]="'pmModalCountryMaxBarChartData'">
              </app-barchart>
            </div>

          </div>

          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="countryModeBarChartData" [canvasHeight]="doubleHeight" [loaded]="true"
                [barChartLabels]="countryModeBarChartLabels" [chartTitle]="'Price Monitor – Total Country Mode Price'"
                [barChartExcelData]="countryModeExcelData" [barChartExcelFileName]="'price_monitor_country_mode_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'pmCountryModeBarChartData'" [dataModalId]="'pmModalCountryModeBarChartData'">
              </app-barchart>
            </div>
            <div class="col-sm-12">
              <app-barchart [barChartData]="countryMeanBarChartData" [canvasHeight]="doubleHeight" [loaded]="true"
                [barChartLabels]="countryMeanBarChartLabels" [chartTitle]="'Price Monitor – Total Country Mean Price'"
                [barChartExcelData]="countryMeanExcelData" [barChartExcelFileName]="'price_monitor_country_mean_report'"
                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'pmCountryMeanBarChartData'" [dataModalId]="'pmModalCountryMeanBarChartData'">
              </app-barchart>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <app-pie-chart [pieChartLabels]="totalAnswersPerCountryPieChartLabels" [canvasHeight]="doubleHeight"
                [pieChartData]="totalAnswersPerCountryPieChartData" [loaded]="true"
                [pieChartExcelData]="totalAnswersPerCountryPieChartExcelData"
                [pieChartExcelFileName]="'price_monitor_answers_by_country'"
                [chartTitle]="'Price Monitor – Number of Outlets Visited by total Country'"
                [dataTableId]="'pmAnswerByCountry'" [dataModalId]="'pmModalAnswerByCountry'">
              </app-pie-chart>
            </div>
            <div class="col-sm-12">
              <app-pie-chart [pieChartLabels]="totalAnswersPerSkuPieChartLabels" [canvasHeight]="doubleHeight"
                [pieChartData]="totalAnswersPerSkuPieChartData" [loaded]="true"
                [pieChartExcelData]="totalAnswersPerSkuPieChartExcelData"
                [pieChartExcelFileName]="'price_monitor_answers_per_sku'" [chartTitle]="'SKU count, by channel'"
                [dataTableId]="'pmAnswerPerSku'" [dataModalId]="'pmModalAnswerPerSku'">
              </app-pie-chart>
            </div>
          </div>

        </div> <!-- container-fluid -->

        <div class="out-of-stock-view" *ngIf="currentQnnView == 'oos'">
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="overallOOSBLData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="overallOOSBLLabels" [chartTitle]="'Out of Stock, All Measures.'"
                [barChartExcelData]="overallOOSBLExcelData" [barChartExcelFileName]="'out_of_stock_overall_Report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosOverAll'"
                [dataModalId]="'oosModalOverall'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthAvailableOOSData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="monthAvailableOOSLabels" [chartTitle]="'Out of Stock, Available SKUs.'"
                [barChartExcelData]="monthAvailableOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_month_available_Report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosMonthAvailable'"
                [dataModalId]="'oosModalMonthAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthNotAvailableOOSData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="monthNotAvailableOOSLabels" [chartTitle]="'Out of Stock, Not Available SKUs.'"
                [barChartExcelData]="monthNotAvailableOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_month_not_available_Report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosMonthNotAvailable'"
                [dataModalId]="'oosModalNotMonthAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="lessMonthNotAvailableOOSData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="lessMonthOOSLabels" [chartTitle]="'Out of Stock, Less than 6 packs SKUs.'"
                [barChartExcelData]="lessMonthOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_less_month_available_Report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosLessMonthAvailable'"
                [dataModalId]="'oosLessMonthAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="storeLessOOSData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="storeLessOOSLabels" [chartTitle]="'Out of Stock, Not Available SKUs, by store.'"
                [barChartExcelData]="storeLessOOSExcelData" [barChartExcelFileName]="'out_of_stock_store_less_Report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosStoreLess'"
                [dataModalId]="'oosModalStoreLess'" [isPercentageData]="true">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-line-chart [lineChartData]="cityAvailableOOSData" [lineChartLabels]="cityAvailableOOSLabels"
                [canvasHeight]="height" [chartTitle]="'Out of Stock, Available SKUs, by City.'" [loaded]="true"
                [lineChartExcelData]="cityAvailableOOSExcelData"
                [lineChartExcelFileName]="'out_of_stock_city_available_report'" [scoreAlign]="'end'"
                [scoreAnchor]="'top'" [dataTableId]="'oosCityAvailable'" [dataModalId]="'oosModalCityAvailable'"
                [isPercentageData]="true">
              </app-line-chart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-line-chart [lineChartData]="cityNotAvailableOOSData" [lineChartLabels]="cityNotAvailableOOSLabels"
                [canvasHeight]="height" [chartTitle]="'Out of Stock, Not Available SKUs, by city.'" [loaded]="true"
                [lineChartExcelData]="cityNotAvailableOOSExcelData"
                [lineChartExcelFileName]="'out_of_stock_city_not_available_report'"
                [dataTableId]="'oosCityNotAvailable'" [dataModalId]="'oosModalCityNotAvailable'"
                [isPercentageData]="true">
              </app-line-chart>
            </div>
            <div class="col-lg-12">
              <app-pie-chart [pieChartLabels]="totalVisitsInCityOOSLabels" [canvasHeight]="height" [loaded]="true"
                [pieChartData]="totalVisitsInCityOOSData" [pieChartExcelData]="totalVisitsInCityOOSExcelData"
                [pieChartExcelFileName]="'out_of_stock_total_visits_by_city'"
                [chartTitle]="'Out of Stock, Total Outlets Visited, by city.'" [dataTableId]="'oosTotalVisitsByCity'"
                [dataModalId]="'oosModalTotalVisitsByCity'">
              </app-pie-chart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-line-chart [lineChartData]="lessSKUInCityOOSData" [lineChartLabels]="lessSKUInCityOOSLabels"
                [canvasHeight]="height" [chartTitle]="'Out of Stock, Less than 6 packs SKUs, by city'" [loaded]="true"
                [lineChartExcelData]="lessSKUInCityOOSExcelData"
                [lineChartExcelFileName]="'out_of_stock_less_than_six_sku_city__report'" [scoreAlign]="'end'"
                [scoreAnchor]="'top'" [dataTableId]="'oosCityLessData'" [dataModalId]="'oosModalCityLess'"
                [isPercentageData]="true">
              </app-line-chart>
            </div>
          </div>

          <div class="row p-3">
            <div class="col-lg-12 p-3" style="background-color: 'white';">

              <div class="text-center">
                <p style="font-weight: bold; color: gray;">Not Available SKU for last 4 weeks</p>
              </div>

              <table class="custom-table" style="float: left;width: 25%;"
                *ngFor="let week of weekNotAvailableOOSLabels">
                <thead>
                  <tr>
                    <th colspan="3"> {{'Week ' + week }}</th>
                  </tr>
                  <tr>
                    <th>SKU Name</th>
                    <th>Retailer Name</th>
                    <th>Retailer Branch Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let weekData of weekNotAvailableOOSData[week]">
                    <td>{{ weekData.sku }}</td>
                    <td>{{ weekData.retailer }}</td>
                    <td>{{ weekData.store }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="consumer-questionnaire-view" *ngIf="currentQnnView == 'cq'">
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="yesNoQuestionCQData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="yesNoQuestionCQLabels" [chartTitle]="'Brand Health Tracker - Yes or No'"
                [barChartExcelData]="yesNoQuestionCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_yes_no_question_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqYesNoQuestionModeBarChartData'"
                [dataModalId]="'cqModalYesNoQuestionBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="ratingOneToThreeQuestionCQData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="ratingOneToThreeCQLabels" [chartTitle]="'Brand Health Tracker - Rating Three Points'"
                [barChartExcelData]="ratingOneToThreeCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_rating_one_to_three_question_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'cqRatingOneToThreeModeBarChartData'"
                [dataModalId]="'cqModalRatingOneToThreeQuestionBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="ratingOneToFiveQuestionCQData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="ratingOneToFiveCQLabels" [chartTitle]="'Brand Health Tracker- Rating Five Points'"
                [barChartExcelData]="ratingOneToFiveCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_rating_one_to_five_question_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'cqRatingOneTofiveModeBarChartData'"
                [dataModalId]="'cqModalRatingOneToFiveQuestionBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="ratingOneToTenCQData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="ratingOneToTenCQLabels" [chartTitle]="'Brand Health Tracker - Rating Ten Points'"
                [barChartExcelData]="ratingOneToTenCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_rating_one_to_ten'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqRatingOneToTenModeBarChartData'"
                [dataModalId]="'cqModalRatingOneToTenBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="numericQuestionCQData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="numericCQLabels" [showLegend]="false" [chartTitle]="'Brand Health Tracker – Average'"
                [barChartExcelData]="numericCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_numeric_question_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqNumericQuestionModeBarChartData'"
                [dataModalId]="'cqModalNumericQuestionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row mx-1 py-5" style="background-color: 'white';">
            <div class="col-sm-12">
              <div style="display:'flex';justify-content: 'center';">
                <a (click)="navigateToOptionQuestionChart()" target="_blank" class="btn btn-primary px-4 py-2"> View
                  Options Questions Charts </a>
              </div>
            </div>
          </div>
        </div>

        <div class="distribution-check-view" *ngIf="currentQnnView == 'dc'">
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="submittedQnnByChannelDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="submittedQnnByChannelDCLabels" [chartTitle]="'Total Outlets visited by Channel'"
                [barChartExcelData]="submittedQnnByChannelDCExcelData" [showLegend]="false"
                [barChartExcelFileName]="'total_visit_by_channel_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'visitByChannel'"
                [dataModalId]="'dcModalVisitByChannel'">
              </app-barchart>
            </div>

            <div class="col-sm-12">
              <app-barchart [barChartData]="submittedQnnByCityDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="submittedQnnByCityDCLabels" [chartTitle]="'Total Outlets visited by City'"
                [barChartExcelData]="submittedQnnByCityDCExcelData" [showLegend]="false"
                [barChartExcelFileName]="'total_visit_by_city_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'visitByCity'" [dataModalId]="'dcModalVisitByCity'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="submittedQnnByCountryDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="submittedQnnByCountryDCLabels" [chartTitle]="'Total outlets visited by Country'"
                [barChartExcelData]="submittedQnnByCountryDCExcelData" [showLegend]="false"
                [barChartExcelFileName]="'total_visit_by_country_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'visitByCountry'"
                [dataModalId]="'dcModalVisitByCountry'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="totalOutletsOfSkuInCityDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="totalOutletsOfSkuInCityDCLabels" [chartTitle]="'SKU distribution by City'"
                [barChartExcelData]="totalOutletsOfSkuInCityDCExcelData" [showLegend]="true"
                [barChartExcelFileName]="'total_sku_per_city_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'skuPerCity'" [dataModalId]="'dcModalSkuPerCity'"
                [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="totalOutletsOfSkuInCountryDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="totalOutletsOfSkuInCountryDCLabels" [chartTitle]="'SKU distribution by Total Country'"
                [barChartExcelData]="totalOutletsOfSkuInCountryDCExcelData" [showLegend]="true"
                [barChartExcelFileName]="'total_sku_per_country_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'skuPerCounty'"
                [dataModalId]="'dcModalSkuPerCountry'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="totalOutletsOfSkuInChannelDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="totalOutletsOfSkuInChannelDCLabels" [chartTitle]="'SKU distribution by Channel'"
                [barChartExcelData]="totalOutletsOfSkuInChannelDCExcelData" [showLegend]="true"
                [barChartExcelFileName]="'total_sku_per_channel_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'skuPerChannel'"
                [dataModalId]="'dcModalSkuPerChannel'" [isPercentageData]="true">
              </app-barchart>
            </div>
            <div class="col-sm-12">
              <app-barchart [barChartData]="sumOfOneBrandByCityDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="sumOfOneBrandByCityDCLabels"
                [chartTitle]="'Sum of SKU (SKU count in outlets) of one brand'"
                [barChartExcelData]="sumOfOneBrandByCityDCExcelData"
                [barChartExcelFileName]="'distribution_check_brand_by_city_report'"
                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'center'" [scoreAnchor]="'center'"
                [dataTableId]="'dcTotalBrandByCityData'" [dataModalId]="'dcModalTotalBrandByCityDData'"
                [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="sumOfOneBrandByCountryDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="sumOfOneBrandByCountryDCLabels" [chartTitle]="'Sum of SKU by Country of one brand'"
                [barChartExcelData]="sumOfOneBrandByCountryDCExcelData"
                [barChartExcelFileName]="'distribution_check_brand_by_country_report'"
                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'center'" [scoreAnchor]="'center'"
                [dataTableId]="'dcTotalBrandByCountryData'" [dataModalId]="'dcModalTotalBrandByCountryData'"
                [isPercentageData]="true">
              </app-barchart>
            </div>
            <div class="col-sm-12">
              <app-barchart [barChartData]="sumOfOneBrandByChannelDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="sumOfOneBrandByChannelDCLabels" [chartTitle]="'Sum of SKU by Channel of one brand'"
                [barChartExcelData]="sumOfOneBrandByChannelDCExcelData"
                [barChartExcelFileName]="'distribution_check_brand_by_channel_report'"
                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'center'" [scoreAnchor]="'center'"
                [dataTableId]="'dcTotalBrandByChannelData'" [dataModalId]="'dcModalTotalBrandByChannelData'"
                [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-pie-chart [pieChartLabels]="avgPerSkuDCLabels" [canvasHeight]="height" [loaded]="true"
                [pieChartData]="avgPerSkuDCData" [pieChartExcelData]="avgPerSkuDCExcelData"
                [pieChartExcelFileName]="'distribution_check_avg_per_sku'"
                [chartTitle]="'Shelf Share by SKU, total country'" [dataTableId]="'dcAvgPerSku'"
                [dataModalId]="'dcModalAvgPerSku'" [isPercentageData]="true">
              </app-pie-chart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-pie-chart [pieChartLabels]="avgPerBrandDCLabels" [canvasHeight]="height" [loaded]="true"
                [pieChartData]="avgPerBrandDCData" [pieChartExcelData]="avgPerBrandDCExcelData"
                [pieChartExcelFileName]="'distribution_check_ss_total_country'"
                [chartTitle]="'Shelf Share by Brand, total country'" [dataTableId]="'dcAvgPerBrand'"
                [dataModalId]="'dcModalAvgPerBrand'" [isPercentageData]="true">
              </app-pie-chart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="avgSKUPerChannelDC" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="avgSKUPerChannelDCLabels" [chartTitle]="'Shelf Share of SKU, by Channel'"
                [barChartExcelData]="avgSKUPerChannelDCExcelData"
                [barChartExcelFileName]="'distribution_check_ss_sku_channel'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'center'" [scoreAnchor]="'center'" [dataTableId]="'dcModalAvgsku_channel'"
                [dataModalId]="'dcAvgsku_channel'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="avgBrandByChannelDCData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="avgBrandByChannelDCLabels" [chartTitle]="'Shelf Share of Brand, by Channel'"
                [barChartExcelData]="avgBrandByChannelDCExcelData"
                [barChartExcelFileName]="'distribution_check_ss_brand_channel'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'center'" [scoreAnchor]="'center'" [dataTableId]="'dcModalAvgPerbrand_channel'"
                [dataModalId]="'dcAvgPerbrand_channel'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>

        </div>



      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->


</body>

</html>
