import { Component, Input, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  faEllipsisV = faEllipsisV;

  public pieChartOptions: any;
  @Input() public pieChartLabels;
  @Input() public pieChartData;
  @Input() pieChartExcelData: any;
  @Input() pieChartExcelFileName: string;
  @Input() chartTitle: string;
  @Input() canvasHeight;
  @Input() dataTableId: string;
  @Input() dataModalId: string;
  @Input() isPercentageData: Boolean = false;
  @Input() loaded: Boolean = false;

  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [
    {
      backgroundColor: [
        'rgba(255,0,0,0.3)',
        'rgba(78,150,166,0.3)',
        'rgba(110,20,255,0.3)',
        'rgba(123,255,0,0.3)',
        'rgba(123,233,255,0.3)',
        'rgba(223,0,255,0.3)',
        'rgba(225,233,0,0.3)',
        'rgba(141, 74, 117, 0.3)',
        'rgba(225,123,123,0.3)',
        'rgba(78,78,255,0.3)',
        'rgba(45,233,45,0.3)',
        'rgba(69,69,255,0.3)'],
    },
  ];


  // datatable option
  public dtElement: DataTableDirective;
  public dtBarOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  public dataTargetName: string;

  constructor() { }

  ngOnInit(): void {
    let flag = this.isPercentageData;
    this.pieChartOptions = {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20
        }
      },
      responsive: true,
      legend: {
        position: 'top',
        labels: {
          padding: 10,
          fontSize:15
        }
      },
      title: {
        display: true,
        text: this.chartTitle,
        fontSize: 25
      },
      layout: {
        padding: 20
      },
      plugins: {
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'end',
          formatter: function (value, context) {
            if (typeof value == 'object') {
              return null
            }
            if (flag) {
              return value + '%';
            }
            return value;

          }
        }
      }
    };
    this.pieChartPlugins = [pluginDataLabels, {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 40
        }
      },

    }]
    this.dtBarOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: "<'row'<'col-md-6'l>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-md-6'i><'col-md-6'p>>",
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export as CSV',
          title: this.pieChartExcelFileName,
          className: ' mr-1 btn btn-info float-right'
        }
      ]
    };
    this.dataTargetName = '#' + this.dataModalId;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  exportDataToExcel() {
    this.downloadFile(this.pieChartExcelData.data, this.pieChartExcelData.labels, this.pieChartExcelFileName);
  }

  downloadFile(data: Array<Object>, headerList: Array<String>, filename: String) {
    let csvData = this.convertToCSV(data, headerList);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=UTF-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

}
