import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { OperationService } from 'src/app/_services/operation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-questionnaire-type',
  templateUrl: './questionnaire-type.component.html',
  styleUrls: ['./questionnaire-type.component.scss']
})
export class QuestionnaireTypeComponent implements OnInit {

  qts: Array<any> = [];
  qtForm: FormGroup;
  accessToken: string;


  isUpdateMode: Boolean = false;
  qtIdToUpdate: Number = null;

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private operationService: OperationService
  ) { }

  ngOnInit(): void {

    this.listQTs();

    this.accessToken = localStorage.getItem('accessToken');

    this.qtForm = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  // CRUD methods

  listQTs() {
    Swal.showLoading();
    this.operationService.getQTs()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.qts = response.data?.results;
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching questionnaire types!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching questionnaire types!"
        });
      })
  }

  addQT() {
    if(this.qtForm.invalid){
      this.qtForm.controls['name'].markAsDirty();
      this.qtForm.controls['name'].updateValueAndValidity();
      return;
    }
    Swal.showLoading();
    this.operationService.addQT(this.qtForm.value)
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          Swal.fire({
            icon: 'success',
            title: "Questionnaire type added successfully!"
          }).then(() => {
            this.listQTs();
            this.qtForm.reset();
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error_message || "Problem in adding questionnaire type!"
          });
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in adding questionnaire type!"
        });
      })
  }

  // updateQT() {
  //   if(this.qtForm.invalid){
  //     this.qtForm.controls['name'].markAsDirty();
  //     this.qtForm.controls['name'].updateValueAndValidity();
  //     return;
  //   }
    
  //   Swal.showLoading();
  //   if (this.qtIdToUpdate) {
  //     this.operationService.updateQT(this.qtIdToUpdate, this.qtForm.value)
  //       .subscribe(
  //         response => {
  //           Swal.close();
  //           if (response.success) {
  //             Swal.fire({
  //               icon: 'success',
  //               title: "Questionnaire type updated successfully!"
  //             }).then(() => {
  //               this.isUpdateMode = false;
  //               this.qtIdToUpdate = null;
  //               this.listQTs();
  //               this.qtForm.reset();
  //             })
  //           } else {
  //             Swal.fire({
  //               icon: 'error',
  //               title: response.error_message || "Problem in updating questionnaire type!"
  //             });
  //           }
  //         },
  //         error => {
  //           Swal.close();
  //           Swal.fire({
  //             icon: 'error',
  //             title: error.error.error_message || "Problem in adding questionnaire type!"
  //           });
  //         }
  //       )
  //   }
  // }

  removeQT(qtId) {
    this.operationService.removeQT(qtId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Questionnaire type deleted successfully!"
        }).then(res => {
          this.listQTs();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting questionnaire type!"
        })
      })
  }

  searchText(searchText:any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }


  // helper methods

  fetchQT(qt) {
    this.isUpdateMode = true;
    this.qtIdToUpdate = qt.id || null;
    this.qtForm = this.fb.group({
      name: [qt.name, [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.isUpdateMode = false;
    this.qtForm.reset();
  }

}
