import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { OperationService } from 'src/app/_services/operation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  form: FormGroup;
  categories: Array<any> = [];
  accessToken: string; 
  categoryForm: FormGroup;
  isUpdateMode: Boolean = false;
  categoryIdToUpdate: Number = null;
  constructor(private fb: FormBuilder, private operationService: OperationService) { }

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');

    this.categoryForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true
    }

    this.listAllCategories();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  addCategory(){
    if(this.categoryForm.invalid){
      this.categoryForm.controls['name'].markAsDirty();
      this.categoryForm.controls['name'].updateValueAndValidity();
    }else {
      Swal.showLoading();
      this.operationService.addCategory(this.categoryForm.value)
          .subscribe(response => {
            Swal.close();
            if(response.success){
              Swal.fire({
                icon: 'success',
                title: 'Category added successfully!'
              }).then(() => {
                this.listAllCategories();
                this.categoryForm.reset();
              })
            }else{
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding category. Please try again later."
              });
            }
          }, error => {
            Swal.close();
             Swal.fire({
               icon: 'error',
               title: error.error.error_message || "Problem in adding category!"
             });
           })
    }

   
  }

  listAllCategories(){
    Swal.showLoading();
    this.operationService.getCategories()
        .subscribe(response => {
          Swal.close();
          if(response.success){
            this.categories =  response.data?.results;
            this.rerender();
          }else{
            this.handleNotification(response.error.error_message || "Problem in fetching category.", true)
          }
        }, error => {
          Swal.close()
          this.handleNotification(error.error.error_message || "Problem in fetching category.", true);
        })
  }

  removeCategory(categoryId) {
    this.operationService.removeCategory(categoryId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Category deleted successfully!"
        }).then(res => {
          this.listAllCategories();
        })
      })
      .catch(error => {
        this.handleNotification("Something went wrong while deleting channel!", true)
      })
  }

  updateCategory() {
    if(this.categoryIdToUpdate) {
      Swal.showLoading();
      if (this.categoryIdToUpdate) {
        this.operationService.updateCategory(this.categoryIdToUpdate, this.categoryForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "Category updated successfully!"
                }).then(() => {
                  this.isUpdateMode = false;
                  this.categoryIdToUpdate = null;
                  this.listAllCategories();
                  this.categoryForm.reset();
                })
              } else {
                this.handleNotification(response.error_message || "Problem in updating category!", true)
              }
            },
            error => {
              Swal.close();
              this.handleNotification(error.error.error_message || "Problem in updating category!", true);
            }
          )
      }
    }
  }

  searchText(searchText:any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  // helper functions

  fetchCategory(category) {
    this.isUpdateMode = true;
    this.categoryIdToUpdate = category.id || null;
    this.categoryForm = this.fb.group({
      name: [category.name, [Validators.required]]
    });
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  toggleUpdateMode() {
    this.isUpdateMode = false;
    this.categoryForm.reset();
  }

}
