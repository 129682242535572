<!DOCTYPE html>
<html lang="en">

<body>

    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">

        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Dashboard'"></app-top-bar>
        <!-- end Topbar -->

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <div class="row">
                    <div class="col-sm-6" *ngFor="let pieChartData of pieChartDataAll">
                        <app-pie-chart [pieChartLabels]="pieChartData.pieChartLabels" [canvasHeight]="height"
                            [pieChartData]="pieChartData.pieChartValues" [pieChartExcelData]="pieChartData.excelData"
                            [pieChartExcelFileName]="pieChartData?.question.question_statement.toLowerCase().split(' ').join('_')"
                            [chartTitle]="pieChartData.question.question_statement"
                            [dataTableId]="pieChartData.question.question"
                            [dataModalId]="pieChartData.question.question">
                        </app-pie-chart>
                    </div>
                    <div *ngIf="pieChartDataAll.length == 0">
                        <div style="background-color: white; color: black; align-content: center;" class="p-5 mx-3">
                            There are no any charts to display!
                        </div>
                    </div>

                </div>

            </div>
            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->
        </div>
    </div>
</body>

</html>