import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { find, get, pull } from 'lodash';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  @ViewChild('channelInput') channelInputRef: ElementRef;
  @ViewChild('categoryInput') categoryInputRef: ElementRef;
  @ViewChild('retailerInput') retailerInputRef: ElementRef;
  @ViewChild('brandInput') brandInputRef: ElementRef;
  @ViewChild('skuInput') skuInputRef: ElementRef;
  @ViewChild('QTypeInput') qTypeInputRef: ElementRef;
  
  tags: string[] = ['html', 'Angular'];
  countries: string[] = ['UAE', 'KSA'];
  cities: string[] = ['Dubai', 'AbuDhabi','Jeddah'];
  channels: string[] = ['Supermarket', 'Hypermarket'];
  retailers: string[] = ['Panda', 'Lulu'];
  categories: string[] = ['Food'];
  brands: string[] = ['ASI','MSS'];
  skus: string[] = ['500x500','250x250'];
  QTypes: string[] = ['Multiple','One choice'];

  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      channel:[undefined],
      retailer:[undefined],
      brand:[undefined],
      category:[undefined],
      sku:[undefined],
      QType:[undefined],
    });
  }

  focusTagInput(whichInput:string): void {
    //this.tagInputRef.nativeElement.focus();
    switch(whichInput){
      case 'channel':
        this.channelInputRef.nativeElement.focus();
      break;
      case 'retailer':
        this.retailerInputRef.nativeElement.focus();
      break;
      case 'category':
        this.categoryInputRef.nativeElement.focus();
      break;
      case 'brand':
        this.brandInputRef.nativeElement.focus();
      break;
      case 'sku':
        this.skuInputRef.nativeElement.focus();
      break;
      case 'QType':
        this.qTypeInputRef.nativeElement.focus();
      break;
    }
  }

  onKeyUp(event: KeyboardEvent, whichTag: string): void {
    let inputValue: string = null;
    switch(whichTag){
      case 'channel':
        inputValue = this.form.controls.channel.value;
        if (event.code === 'Backspace' && !inputValue) {
          this.removeTag(whichTag);
          return;
        } else {
          if (event.code === 'Comma' || event.code === 'Space') {
            this.addTag(inputValue, whichTag);
            this.form.controls.channel.setValue('');
          }
        }
      break;
      case 'retailer':
        inputValue = this.form.controls.retailer.value;
        if (event.code === 'Backspace' && !inputValue) {
          this.removeTag(whichTag);
          return;
        } else {
          if (event.code === 'Comma' || event.code === 'Space') {
            this.addTag(inputValue, whichTag);
            this.form.controls.retailer.setValue('');
          }
        }
      break;
      case 'category':
        inputValue = this.form.controls.category.value;
        if (event.code === 'Backspace' && !inputValue) {
          this.removeTag(whichTag);
          return;
        } else {
          if (event.code === 'Comma' || event.code === 'Space') {
            this.addTag(inputValue, whichTag);
            this.form.controls.category.setValue('');
          }
        }
      break;
      case 'brand':
        inputValue = this.form.controls.brand.value;
        if (event.code === 'Backspace' && !inputValue) {
          this.removeTag(whichTag);
          return;
        } else {
          if (event.code === 'Comma' || event.code === 'Space') {
            this.addTag(inputValue, whichTag);
            this.form.controls.brand.setValue('');
          }
        }
      break;
      case 'sku':
        inputValue = this.form.controls.sku.value;
        if (event.code === 'Backspace' && !inputValue) {
          this.removeTag(whichTag);
          return;
        } else {
          if (event.code === 'Comma' || event.code === 'Space') {
            this.addTag(inputValue, whichTag);
            this.form.controls.sku.setValue('');
          }
        }
      break;
      case 'QType':
        inputValue = this.form.controls.QType.value;
        if (event.code === 'Backspace' && !inputValue) {
          this.removeTag(whichTag);
          return;
        } else {
          if (event.code === 'Comma' || event.code === 'Space') {
            this.addTag(inputValue, whichTag);
            this.form.controls.QType.setValue('');
          }
        }
      break;
    }
    
  }

  addTag(tag: string, whichTag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    switch(whichTag){
      case 'channel':
        if (tag.length > 0 && !find(this.channels, tag)) {
          this.channels.push(tag);
        }
      break;
      case 'retailer':
        if (tag.length > 0 && !find(this.retailers, tag)) {
          this.retailers.push(tag);
        }
      break;
      case 'category':
        if (tag.length > 0 && !find(this.categories, tag)) {
          this.categories.push(tag);
        }
      break;
      case 'brand':
        if (tag.length > 0 && !find(this.brands, tag)) {
          this.brands.push(tag);
        }
      break;
      case 'sku':
        if (tag.length > 0 && !find(this.skus, tag)) {
          this.skus.push(tag);
        }
      break;
      case 'QType':
        if (tag.length > 0 && !find(this.QTypes, tag)) {
          this.QTypes.push(tag);
        }
      break;
    }
    
  }

  removeTag(whichTag: string, tag?: string): void {
    switch(whichTag){
      case 'channel':
        if (!!tag) {
          pull(this.channels, tag);
        } else {
          this.channels.splice(-1);
        }
      break;
      case 'retailer':
        if (!!tag) {
          pull(this.retailers, tag);
        } else {
          this.retailers.splice(-1);
        }
      break;
      case 'category':
        if (!!tag) {
          pull(this.categories, tag);
        } else {
          this.categories.splice(-1);
        }
      break;
      case 'brand':
        if (!!tag) {
          pull(this.brands, tag);
        } else {
          this.brands.splice(-1);
        }
      break;
      case 'sku':
        if (!!tag) {
          pull(this.skus, tag);
        } else {
          this.skus.splice(-1);
        }
      break;
      case 'QType':
        if (!!tag) {
          pull(this.QTypes, tag);
        } else {
          this.QTypes.splice(-1);
        }
      break;
    }
  }


}
