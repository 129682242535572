import { AuthService } from './../_services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCog , faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})

export class TopBarComponent implements OnInit {
  @Output("searchText") searchText: EventEmitter<any> = new EventEmitter();
  faCog = faCog;
  faUser = faUser;
  faSignOutAlt = faSignOutAlt;
  profileImage: string;
  pattern = /[a-zA-Z0-9-_ ]/;
  firstname: string;
  searchFor:string = "";

  filterText: string = "";
  isAdmin: Boolean = false;

  @Input() pageTitle: string;
  
  constructor( 
    private router: Router,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.firstname = localStorage.getItem('fullName').split(' ')[0];
    this.profileImage = localStorage.getItem('avatar');

    const accessToken = localStorage.getItem('accessToken');
    if (this.authService.getCurrentUserRole(accessToken) == "Admin") {
      this.isAdmin = true
    }
  }

  userLogout() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  sendSearchText() {
    this.searchText.emit({ data: this.filterText})
  }

}
