import { SnapOperationService } from 'src/app/_services/snap-operation.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { find, pull } from 'lodash';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LocalizeService } from 'src/app/_services';
import { faPray } from '@fortawesome/free-solid-svg-icons';

export interface Category {
  id: Number,
  name: String
}

export interface Country {
  id: Number,
  name: String
}
@Component({
  selector: 'app-snap-sku',
  templateUrl: './sku.component.html',
  styleUrls: ['./sku.component.scss']
})
export class SnapSkuComponent implements OnInit {
  @ViewChild('skuInput') branchInputRef: ElementRef;
  skuForm: FormGroup;
  skus: Array<any> = [];
  skuList: Array<any> = [];
  brands: Array<any> = [];
  categories: Array<any> = [];
  countries: Array<any> = [];
  selectedCountries = []
  formattedCountries = []

  dropdownSettings: IDropdownSettings;
  countryDropdownSettings: IDropdownSettings;

  skuIdToUpdate: Number = null;

  accessToken: String;
  public invalidSkuLength: boolean = false;

  constructor(
    private fb: FormBuilder,
    private operationService: SnapOperationService,
    private localizeService: LocalizeService
  ) { }

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {

    this.loadCategories();
    this.loadCountries();
    this.listSKUs();

    this.accessToken = localStorage.getItem('accessToken');

    this.skuForm = this.fb.group({
      country: ['', Validators.required],
      category: ['', Validators.required],
      brand: ['', Validators.required],
      name: ['']
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true
    }

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.countryDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listSKUs() {
    Swal.showLoading();
    this.operationService.getSKUs()
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            let skus = response.data?.results;
            this.skus = skus.length > 0 ? skus : [];
            this.rerender();
          } else {
            this.handleNotification(response.error.error_message || "Problem in fetching SKUs!", true);
          }
        },
        error => {
          Swal.close()
          this.handleNotification(error.error.error_message || "Problem in fetching SKUs!", true)
        }
      )
  }

  updateValidator() {
    if (this.skuList.length <= 0) {
      this.invalidSkuLength = true;
      return false;
    }
    this.invalidSkuLength = false;
    return true;
  }

  removeSKU(skuId) {
    this.operationService.removeSKUs(skuId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "SKU(s) deleted successfully!"
        }).then(res => {
          this.listSKUs();
        })
      })
      .catch(error => {
        this.handleNotification("Something went wrong while deleting SKU!", true);
      })
  }

  async updateSku() {

    if (this.skuForm.invalid) {
      for (var i in this.skuForm.controls) {
        this.skuForm.controls[i].markAsDirty();
        this.skuForm.controls[i].updateValueAndValidity();
      }
    }
    else {

      Swal.showLoading();
      // format values as required by api
      let formattedFormValues = { ...this.skuForm.value };
      this.selectedCountries = this.skuForm.value?.country;
      this.selectedCountries && await this.formatCountries();
      console.log(this.selectedCountries);
      for (let formValue in formattedFormValues) {
        if (formValue == "category" || formValue == "brand") {
          formattedFormValues[formValue] = formattedFormValues[formValue] && formattedFormValues[formValue][0].id;
        } else if (formValue == "country") {
          formattedFormValues[formValue] = [...this.formattedCountries]
        }
      }
      if (this.skuIdToUpdate) {
        this.operationService.updateSku(this.skuIdToUpdate, formattedFormValues)
          .subscribe(response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "SKU updated successfully!"
              }).then(() => {
                this.skuIdToUpdate = null;
                this.listSKUs();
                this.skuForm.reset();
                this.skuForm.markAsPristine();
                this.skuForm.markAsUntouched();
              })
            } else {
              this.handleNotification(response.error_message || "Problem in updating SKU!", true);
            }
          }, error => {
            Swal.close();
            this.handleNotification(error.error.error_message || "Problem in updating SKU!", true);
          })
      }
    }
  }


  // helper methods
  public skuCountry = [];
  fetchSKU(sku) {
    this.loadCategories();
    this.loadCountries();
    this.loadBrandsByCategory(sku.category);
    this.skuIdToUpdate = sku.id || null;
    this.skuForm = this.fb.group({
      country: [sku.country, Validators.required],
      category: [[sku.category], Validators.required],
      brand: [[sku.brand], Validators.required],
      name: [sku.name, Validators.required]
    });
  }

  loadCategories() {
    Swal.showLoading();
    this.operationService.getCategories()
      .subscribe(response => {
        Swal.close()
        let categories = response.data ? response.data.results : [];
        if (categories.length > 0) {
          this.categories = categories
        } else {
          this.categories = [];
        }
      }, err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching categories!', true)
      })
  }

  loadCountries() {
    Swal.showLoading();
    this.localizeService.getCountries()
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.countries = data
        } else {
          this.countries = [];
        }
      }).catch(err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching countries!', true)
      })
  }

  loadBrandsByCategory(category) {
    Swal.showLoading();
    if (this.skuForm.controls.brand.value) {
      this.skuForm.controls.brand.setValue('');
    }
    this.operationService.getBrandsByCategory(category.id)
      .subscribe(response => {
        Swal.close()
        let brands = response.data ? response.data.results : [];
        if (brands.length > 0) {
          this.brands = brands
        } else {
          this.brands = [];
        }
      }, err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching brands!', true)
      })
  }

  resetCategories(category) {
    this.brands = [];
  }

  async formatCountries() {
    this.selectedCountries.forEach(selectedCountry => {
      this.formattedCountries.push(selectedCountry.id)
    })
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  // UI helpers method

  toggleUpdateMode() {
    this.skuList = [];
    this.skuForm.reset();
    this.skuForm.markAsPristine();
    this.skuForm.markAsUntouched();
    this.invalidSkuLength = false;
  }

  focusTagInput(): void {
    this.branchInputRef.nativeElement.focus();
  }

  formatTableViews(arrayData) {
    return arrayData.map(eachData => eachData.name)
  }

  onKeyUp(event: KeyboardEvent, whichTag: string): void {
    let inputValue: string = null;
    inputValue = this.skuForm.controls.name.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      this.updateValidator();
      return;
    } else if (event.code === 'Enter') {
      this.addTag(inputValue);
      this.skuForm.controls.name.setValue('');
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.skuList, tag)) {
      this.skuList.push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.skuList, tag);
    } else {
      this.skuList.splice(-1);
    }
  }

}
