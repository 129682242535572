import { SharedService } from '../_shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
export interface City {
  id?: string;
  name?: string;
}
@Injectable({
  providedIn: 'root'
})
export class LocalizeService {
  public countries: Array<any>
  // TODO: Services for APIs like nationality, country, cities
  constructor(private http: HttpClient,
    public sharedService: SharedService) { }

    getCountries() {
      return new Promise<any>((resolve, reject) => {
        this.sharedService.get(`${environment.baseUrl}/localize/country/list?ordering=name`).subscribe(res => {
          if (res.success) {
            resolve(res.data.results)
          } else {
            resolve([])
          }
        }, err => {
          reject([])
        });
      })
    }
    getmerchCountries() {
      return new Promise<any>((resolve, reject) => {
        this.sharedService.get(`${environment.baseUrl}/merch/country/list`).subscribe(res => {
          if (res.success) {
            resolve(res.data.results)
          } else {
            resolve([])
          }
        }, err => {
          reject([])
        });
      })
    }
  getCountriesForSnap(filters?) {
    return new Promise<any>((resolve, reject) => {
      if (filters) {
        this.sharedService.get(`${environment.baseUrl}/snap/filters/country-list${filters}ordering=name`).subscribe(res => {
          if (res.success) {
            resolve(res.data)
          } else {
            resolve([])
          }
        }, err => {
          reject([])
        });
      } else {
        this.sharedService.get(`${environment.baseUrl}/snap/filters/country-list?ordering=name`).subscribe(res => {
          if (res.success) {
            resolve(res.data)
          } else {
            resolve([])
          }
        }, err => {
          reject([])
        });
      }


    })
  }
  getNationalities() {
    return new Promise<any>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/localize/country/nationality/list`).subscribe(res => {
        if (res.success) {
          resolve(res.data.results)
        } else {
          resolve([])
        }
      }, err => {
        reject([])
      });
    })
  }

  getCitiesByCountryId(id) {
    return new Promise<City[]>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/localize/city/list?ordering=name&country=${id}`).subscribe(res => {
        if (res && res.success) {
          let cities = res.data.results;
          cities = cities.filter(city => {
            delete city.country
            return city
          });
          resolve(cities)
        }
      }, err => {
        reject([])
      });
    })
  }
  getCitiesForSnap(filters) {
    return new Promise<any>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/filters/city-list${filters}ordering=name`).subscribe(res => {
        if (res.success) {
          resolve(res.data)
        } else {
          resolve([])
        }
      }, err => {
        reject([])
      });
    })
  }
}
