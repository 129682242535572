import { SharedService } from '../_shared/shared.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  constructor(
    public sharedService: SharedService
  ) { }

  getAgentUserDetail(userId) {
    return this.sharedService.get(`${environment.baseUrl}/user/portal-user/${userId}/detail`)
  }

  updateProfileFields(userId, updateProfileData) {
    return this.sharedService.patch(`${environment.baseUrl}/user/portal-user/${userId}/update`, updateProfileData)
  }
}
