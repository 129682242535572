import { SharedService } from './../_shared/shared.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

export interface Questionnaire {
  name: string,
  questionnaire_type: number,
  city: Array<number>,
  category: number,
  country: Array<number>,
  tags: Array<string>
}

@Injectable({
  providedIn: 'root'
})
export class QuestionnnaireService {

  constructor(
    private sharedService: SharedService
  ) { }

  getQuestionnaires() {
    return this.sharedService.get(`${environment.baseUrl}/questionnaire/list`);
  }

  addQuestionnaire(questionnaireData: Questionnaire) {
    return this.sharedService.post(`${environment.baseUrl}/questionnaire/add`, questionnaireData);
  }

  removeQuestionnaire(questionnaireId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/questionnaire/${questionnaireId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  getQnnDetail(qnnId) {
    return this.sharedService.get(`${environment.baseUrl}/response/questionnaire/${qnnId}/list`);
  }

  getQnnDetailById(qnnId) {
    return this.sharedService.get(`${environment.baseUrl}/questionnaire/${qnnId}/detail`);
  }

  getFieldWorkersInfo() {
    return this.sharedService.get(`${environment.baseUrl}/user/agent-user/basic-list`);
  }

  updateQuestionnaire(qnnId, qnnData) {
    return this.sharedService.put(`${environment.baseUrl}/questionnaire/${qnnId}/update`, qnnData);
  }


}
