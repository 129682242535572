import { QuestionnnaireService } from './../_services/questionnnaire.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { QuestionService } from '../_services/question.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  questionnaireId: String = null;
  questionsReports: Array<any> = [];

  qnnTitle: string;

  constructor(
    private route: ActivatedRoute,
    private qs: QuestionnnaireService
  ) { }

  ngOnInit(): void {

   this.questionnaireId = this.route.snapshot.params['questionnaireId'] || null;
    this.qnnTitle = 'Questionnaire Id: ' + this.questionnaireId
    this.listQuestionsByQnnId();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: '<"top"<"left-col"B><"center-col"l><"right-col">>rtip',
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export',
          title: 'single_questionnaire_details',
          className: ' mr-1 btn btn-info float-right',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7,8,9]
          }
        }
      ]
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listQuestionsByQnnId() {
    if (this.questionnaireId) {
      Swal.showLoading();
      this.qs.getQnnDetail(this.questionnaireId)
        .subscribe(response => {
          Swal.close();
          if(!response) {
            return;
          }
          if (response.success) {
            this.questionsReports = [...response.data?.results];
            this.rerender();
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error.error_message || "Problem in fetching questions!"
            });
          }
        }, error => {
          Swal.close()
          Swal.fire({
            icon: 'error',
            title: error.error.error_message || "Problem in fetching questionnnaires!"
          });
        })

    }
  }
}
