import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SnapDashboardService } from './../../_services/snap-dashboard.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-price-monitor',
  templateUrl: './price-monitor.component.html',
  styleUrls: ['./price-monitor.component.scss']
})
export class PriceMonitorComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  public previousUrl: string;
  public nextUrl: string;

  public gLimit = 10;
  public gOffset = 0;
  // public count: number;

  isSelectAllChecked = false;

  public snapData: any;
  accessToken: string;

  dashboardForm: FormGroup;

  dateStart: string;
  date_range: any;
  format: 'MM/DD/YYYY';
  filterValue: string;
  alwaysShowCalendars: boolean;
  // minDate = moment('2018-01-01');
  minDate = moment();
  maxDate = moment().add(0, 'days');
  showRangeLabelOnInput = true;
  public dateAfter: any;
  public dateBefore: any;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  };
  selected = {start: moment().toDate(), end: moment().toDate()};
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private snapDashboardService: SnapDashboardService
  ) { }

  ngOnInit(): void {

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      stateSave: true,
      stateSaveCallback(settings, data) {
        settings._iDisplayLength != that.gLimit ? that.changePageLength(settings._iDisplayLength) : '';
        localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data));
      },
      stateLoadCallback(settings) {
        return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance));
      },
      dom: '<"top"<"center-col"l><"right-col">>rt',
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export',
          title: 'snap_price_monitor',
          className: ' btn btn-lg btn-sm btn-info rounded-pill py-2 px-4 mr-3 float-right',
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
          }
        }
      ]
    };

    this.dashboardForm = this.fb.group({
      // date_range: [{ date_after: moment('2018-01-01'), date_before: moment('2018-03-30') }], // default This Month
      date_range: [ ]
      // date_range: [{ date_after: moment().toDate(), date_before: moment().endOf('month').toDate() }], // default This Month
    });
    this.viewPMSnapData(this.gLimit, this.gOffset, '', '');
    this.accessToken = localStorage.getItem('accessToken');
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  applyDateFilter() {
    console.log('apply date-------------------')
    this.dateAfter = `${this.dashboardForm.value.date_range.date_after.year()}-${this.dashboardForm.value.date_range.date_after.month() + 1}-${this.dashboardForm.value.date_range.date_after.date()}`;
    this.dateBefore = `${this.dashboardForm.value.date_range.date_before.year()}-${this.dashboardForm.value.date_range.date_before.month() + 1}-${this.dashboardForm.value.date_range.date_before.date()}`;
    this.viewPMSnapData(this.gLimit, this.gOffset, this.dateBefore, this.dateAfter);

  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  searchText(searchText: any) {
    Swal.showLoading();
    this.snapDashboardService.searchPMSnapData(searchText?.data)
      .subscribe(res => {
        Swal.close();
        this.snapData = [];
        if (res && res.success) {
          this.snapData = res && res.data ? res.data.results : [];
          this.isSelectAllChecked = false;
          this.snapData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = res && res.data ? res.data.previous : null;
          this.nextUrl = res && res.data ? res.data.next : null;

          // this.count = res && res.data ? res.data.count : 0;

          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error.error_message,
            timer: 1500
          });
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Something went wrong in fetching snap data!',
          timer: 1500
        });
      });
  }

  // PM Methods

  viewPMSnapData(limit?: number, offset?: number, dateBefore?: string, dateAfter?: string) {
    Swal.showLoading();
    this.snapDashboardService.viewPMSnapData(limit, offset, dateBefore, dateAfter)
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.snapData = res && res.data ? res.data.results : [];
          this.isSelectAllChecked = false;
          this.snapData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = res && res.data ? res.data.previous : null;
          this.nextUrl = res && res.data ? res.data.next : null;

          // this.count = res && res.data ? res.data.count : 0;

          this.rerender();
        } else {
          this.snapData = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Something went wrong in fetching snap data!',
          timer: 1500
        });
      });
  }

  importSnapPMData(event) {
    const file = event.target.files[0];
    if (file) {

      Swal.showLoading();
      // check if file has valid extension
      const fileExtension = file.name.split('.')[1];
      if (fileExtension !== 'csv') {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Please upload csv file!'
        });
        return false;
      }

      // check valid file size
      const size = file.size / (1024 * 1024);
      if (size > 4) {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Please only upload file less than 4MB !'
        });
        return false;
      }

      // send data to api
      const csvData = new FormData();
      csvData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
      this.snapDashboardService.importPMSnapData(csvData)
        .subscribe(res => {
          Swal.close();
          if (res.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message
            }).then(() => {
              this.viewPMSnapData();
              event.target.value = '';
            });
          } else {
            event.target.value = '';
            this.handleNotification(res.error_message || 'Something went wrong while importing the file!', true);
          }
        }, err => {
          event.target.value = '';
          Swal.close();
          this.handleNotification(err.error.error_message || 'Something went wrong while importing the file!', true);
        });
    }
  }

  exportData() {
    Swal.showLoading();
    this.http.get(`${environment.baseUrl}/snap/price-monitor/export`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders()
        .set('Authorization', this.accessToken)
    })
      .subscribe(
        (response: HttpResponse<Blob>) => {
          Swal.close();
          console.log(response.headers);
          const filename = 'price_monitor_snap.csv';
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error => {
          Swal.close();
          console.log(error);
        }
      );
  }


  // helper methods

  changePageLength(limit: number) {
    this.gLimit = limit;
    this.viewPMSnapData(this.gLimit, this.gOffset, this.dateBefore, this.dateAfter);
  }

  handlePagination(paginationString: string) {

    Swal.showLoading();

    if (paginationString == 'next') {
      this.http.get<any>(this.nextUrl)
        .subscribe(response => {
          Swal.close();
          this.snapData = response && response.data ? response.data.results : [];
          this.snapData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          const { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }

    if (paginationString == 'previous') {
      this.http.get<any>(this.previousUrl)
        .subscribe(response => {
          Swal.close();
          this.snapData = response && response.data ? response.data.results : [];
          this.snapData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          const { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }
  }

  selectAll() {
    this.isSelectAllChecked = !this.isSelectAllChecked;

    if (this.isSelectAllChecked) {
      this.snapData.map(snapDatum => snapDatum.isChecked = true);
    } else {
      this.snapData.map(snapDatum => snapDatum.isChecked = false);
    }
  }

  deleteSnapData() {

    const recordsToBeDeleted: Array<any> = this.snapData.filter(snapDatum => snapDatum.isChecked == true);
    const deleteIds: Array<number> = recordsToBeDeleted.map(record => record.id);

    this.snapDashboardService.bulkSnapDelete(this.accessToken, { snap_ids: [...deleteIds] }, 'price-monitor')
      .then(res => {
        Swal.fire({
          icon: 'success',
          titleText: 'Selected records deleted successfully!'
        }).then(() => {
          if (this.previousUrl) {
            const { limit, offset } = this.extractQueryParams(this.nextUrl);
            const currentOffset = parseInt(offset || '0') - parseInt(limit || '0');
            this.viewPMSnapData(parseInt(limit || '10'), currentOffset, this.dateBefore, this.dateAfter);
          } else {
            this.viewPMSnapData(10, 0, this.dateBefore, this.dateAfter);
          }
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          titleText: error.message
        });
      });
  }

  rowSelected(id) {
    this.snapData.map(data => {
      if (data.id == id) {
        data.isChecked = !data.isChecked;
      }
    });
  }


  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  extractQueryParams(url: string): { limit: string, offset: string } {

    const queryParamsString = url.split('?')[1];

    const limitString = queryParamsString.split('&')[0];
    const limit = limitString.split('=')[1];

    const offsetString = queryParamsString.split('&')[1];
    const offset = offsetString.split('=')[1] || '0';

    return {
      limit,
      offset
    };
  }
}
