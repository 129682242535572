<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Questionnaire'"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="pageTitle"
              [subTitle]="'View answers for the specific questionnaire'">
            </app-top-title>
          </div>

          <div class="mt-5 p-4" style="background-color: white;">



            <!-- <div class="mt-2 mb-4">
              
              <button type="submit" style="background-color:'#be3d36';color:'white'"
                class="btn rounded-pill pl-sm-1 pr-sm-1 pl-md-4 pr-md-4">Export</button>
              
            </div> -->


            <!-- end row -->
            <table  class="custom-table"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>Question ID</th>
                  <th>Types of Question</th>
                  <th>Question</th>
                  <th>Answers</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let answer of answers">
                  <td data-column="Question ID">{{ answer?.question_id}}</td>
                  <td data-column="Types of Question">{{ answer?.question_type}}</td>
                  <td data-column="Question">{{ answer?.question }}</td>
                  <td data-column="Answers" *ngIf="answer.question_type != 'Image'">{{ answer?.answer.join(', ')}}</td>
                  <td data-column="Answers" *ngIf="answer.question_type == 'Image'" >
                    <div style="max-height: 120px;overflow-y: scroll;overflow-x: hidden;">
                      <img 
                      class=" mr-2 mb-2"
                      *ngFor="let url of answer?.answer" 
                      [src]="url" 
                      [width]="80"
                      [height]="60"
                      alt="Answer image" 
                      (click)="displayFullImage(url)"
                      data-toggle="modal"
                      data-target=".fullImageModal">
                    </div>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->



  <!-- imge modal -->
  <div *ngIf="showModal" class="modal fade bd-example-modal-lg fullImageModal" tabindex="-1" role="dialog" aria-labelledby="fullImageModal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content p-3">
        <img [src]="answerImageUrl" class="img img-fluid" alt="Answer Image">
      </div>
    </div>
  </div>


</body>

</html>
