<!DOCTYPE html>
<html lang="en">
    <body>

        <!-- Begin page -->
        <div id="wrapper" style="background-color: '#ebeff2';" >

            <!-- Topbar Start -->
            <app-top-bar [pageTitle]="'Field Work'" ></app-top-bar>
            <!-- end Topbar -->

            <!-- ========== Left Sidebar Start ========== -->
            <app-left-side-bar></app-left-side-bar>
            <!-- Left Sidebar End -->

            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="content-page">
                <div class="content">

                    <!-- Start Content-->
                    <div class="container-fluid">

                        <div>
                            <app-top-title [title]="'Edit Field Worker'" [subTitle]="'Edit Field Worker Now'" ></app-top-title>
                        </div>

                        <div class="mt-1 p-2" style="background-color: white;" >
                        <!-- end row -->

                        <app-field-work></app-field-work>
                        <button type="submit" class="btn btn-success rounded-pill pl-4 pr-4">Save</button> &nbsp;
                        <button type="submit" class="btn btn-danger rounded-pill pl-4 pr-4">Cancel</button>
                        <br /><br />
                    </div> <!-- container-fluid -->
</div>
                </div> <!-- content -->

                <!-- Footer Start -->
                <app-footer></app-footer>
                <!-- end Footer -->

            </div>

            <!-- ============================================================== -->
            <!-- End Page content -->
            <!-- ============================================================== -->


        </div>
        <!-- END wrapper -->

        
    </body>
</html>