import { AuthService } from './../_services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isAdmin: Boolean;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const accessToken = localStorage.getItem('accessToken');
    if (this.authService.getCurrentUserRole(accessToken) == "Admin") {
      this.isAdmin = true
    }
  }

}
