import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { LocalizeService } from '../_services';
import { FieldWorkerService } from '../_services/field-worker.service';



@Component({
  selector: 'app-field-work-create',
  templateUrl: './field-work-create.component.html',
  styleUrls: ['./field-work-create.component.scss']
})
export class FieldWorkCreateComponent implements OnInit {

  public countries: Array<any> = [];

  listOfCountries = [];
  listOfCountriesForNationality = []
  listOfCities = [];
  selectedNationality: any;
  selectedCountries = [];
  selectedCities = [];
  formattedCountries = []
  formattedCities = []
  fileToUpload: File = null
  fileUrl: any;
  public storeNationality: any;

  ShowFilter = true;
  limitSelection = false;
  dropdownSettings: IDropdownSettings;
  nationalityDDSettings: IDropdownSettings;

  id: string;
  isUpdateMode: boolean;

  constructor(private localizeService: LocalizeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private fieldService: FieldWorkerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.localizeService.getCountries().then(data => {
      if (data.length > 0) {
        this.listOfCountries = data
      } else {
        this.listOfCountries = [];
      }
    }).catch(err => {
      this.handleNotification('Error Fetching Countries for Operation', true)
    })

    this.localizeService.getNationalities().then(data => {
      if (data.length > 0) {
        this.listOfCountriesForNationality = data
      } else {
        this.listOfCountriesForNationality = [];
      }
    }).catch(err => {
      this.handleNotification('Error Fetching Countries for Nationality', true)
    })

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.id = this.route.snapshot.params['id'] || null;
    this.isUpdateMode = this.id ? true : false;
    if (this.isUpdateMode) {
      Swal.showLoading();
      this.getFieldUserById(this.id);
    }

    this.nationalityDDSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  fieldWorkForm = this.formBuilder.group({
    avatar: [''],
    fullname: ['', Validators.required],
    nationality: [''],
    date_of_birth: [''],
    operation_country: ['', Validators.required],
    operation_city: ['', Validators.required],
    contact_number: ['', Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')],
    username: [''],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required],
    confirm_password: ['']
  },{
    validator: this.checkPasswords})

  checkPasswords(group: FormGroup) { 
    const password = group.get('password').value;
    const confirmPassword = group.get('confirm_password').value;
  
    return password === confirmPassword ? null : { notSame: true }     
  }

  getFieldUserById(id) {
    this.fieldService.getSingleFieldUser(id)
      .subscribe(res => {
        Swal.close();
        if (res.success) {
          this.fieldWorkForm = this.formBuilder.group({
            fullname: [res.data?.fullname, Validators.required],
            nationality: [[res.data?.nationality]],
            date_of_birth: [res.data?.date_of_birth],
            contact_number: [res.data?.contact_number, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')],
            operation_country: [res.data?.operation_country, Validators.required],
            operation_city: [res.data?.operation_city, Validators.required],
            username: [res.data?.username],
            email: [res.data?.email, Validators.required],
            password: [''],
            confirm_password: ['']
          },{
            validator: this.checkPasswords})
          this.onCountrySelect(this.fieldWorkForm.value?.operation_country);
          this.fileUrl = res.data.avatar;
          this.selectedNationality = this.fieldWorkForm.value?.nationality;
        } else {
          let errorMessage = res.error_message || "Problem in fetching user details!";
          this.handleNotification(errorMessage, true);
        }
      }, error => {
        Swal.close();
        let errorMessage = error.error.error_message || "Problem in fetching user details!";
        this.handleNotification(errorMessage, true);
      })
  }

  async onSubmit() {

    this.selectedNationality = this.fieldWorkForm.value?.nationality;
    if (this.fieldWorkForm.invalid) {
      for (var i in this.fieldWorkForm.controls) {
        this.fieldWorkForm.controls[i].markAsDirty();
        this.fieldWorkForm.controls[i].updateValueAndValidity();
      }
      this.fieldWorkForm.controls['nationality'].setValue(this.fieldWorkForm.value?.nationality[0]?.id ? this.fieldWorkForm.value?.nationality[0]?.id : '') 
      return;
    }

    this.fieldWorkForm.controls['nationality'].setValue(this.fieldWorkForm.value?.nationality[0]?.id ? this.fieldWorkForm.value?.nationality[0]?.id : '');
    this.selectedCities = this.fieldWorkForm.value?.operation_city
    this.selectedCountries = this.fieldWorkForm.value?.operation_country

    this.selectedCountries && this.selectedCities && await this.formatCountriesAndCities()
    if (!this.isUpdateMode) {
      this.createAgentUser();
    } else {
      if (this.fieldWorkForm.value.nationality == null) {
        this.fieldWorkForm.controls['nationality'].markAsDirty();
      } else {
        await this.updateAgentUser()
      }
    }
  }

  createAgentUser() {
    if (this.fieldWorkForm.valid) {

      let formData = new FormData();

      if (this.fileToUpload) {
        formData.append('avatar', this.fileToUpload, this.fileToUpload.name)
      }

      for (var i = 0; i < this.formattedCountries.length; i++) {
        formData.append('operation_country', this.formattedCountries[i])
      }

      for (var i = 0; i < this.formattedCities.length; i++) {
        formData.append('operation_city', this.formattedCities[i])
      }

      let otherFormData = this.fieldWorkForm.value;
      for (let formControlValue in otherFormData) {
        if (formControlValue !== 'operation_country' && formControlValue !== 'operation_city' && formControlValue !== 'confirm_password') {
          formData.append(formControlValue, otherFormData[formControlValue])
        }
      }
      this.fieldService.registerFieldUser(formData).subscribe(res => {
        if (res.success) {
          this.handleNotification(res.data.message, false);
          this.router.navigate(['view-field-work']);
        } else {
          this.handleNotification(res.error.error_message, true);
          this.fieldWorkForm.controls['nationality'].setValue(this.selectedNationality)
        }
      }, err => {
        this.handleNotification(err.error.error_message || "There was problem submitting this record, please try again.", true);
        this.fieldWorkForm.controls['nationality'].setValue(this.selectedNationality);
      })
    } else {
      this.handleNotification("There was validation error while submitting form. Please recheck.", true);
      this.fieldWorkForm.controls['nationality'].setValue(this.selectedNationality);
    }
  }

  async updateAgentUser() {

    Swal.showLoading();
    let formData = new FormData();

    if (this.fileToUpload) {
      formData.append('avatar', this.fileToUpload, this.fileToUpload.name)
    }

    for (var i = 0; i < this.formattedCountries.length; i++) {
      formData.append('operation_country', this.formattedCountries[i])
    }

    for (var i = 0; i < this.formattedCities.length; i++) {
      formData.append('operation_city', this.formattedCities[i])
    }

    let otherFormData = this.fieldWorkForm.value;
    for (let formControlValue in otherFormData) {
      if (formControlValue !== 'operation_country' && formControlValue !== 'operation_city' && formControlValue !== 'confirm_password') {
        formData.append(formControlValue, otherFormData[formControlValue])
      }
    }

    this.fieldService.updateFieldUser(formData, this.id)
      .subscribe(
        res => {
          Swal.hideLoading()
          this.handleNotification(res.data.message, false);
        },
        err => {
          Swal.hideLoading()
          this.handleNotification(err.error.error_message || "There was problem submitting this record, please try again.", true)
          this.fieldWorkForm.controls['nationality'].setValue(this.selectedNationality);
        }
      )
  }

  cancelFieldWork() {
    this.router.navigate(['view-field-work'])
  }

  async formatCountriesAndCities() {
    this.selectedCountries.forEach(selectedCountry => {
      this.formattedCountries.push(selectedCountry.id)
    })
    this.selectedCities.forEach(selectedCity => {
      this.formattedCities.push(selectedCity.id)
    })
  }

  async onCountrySelect(country?: any) {
    this.listOfCities = [];
    let countryIdString = this.fieldWorkForm.value.operation_country.map(country => country.id).join(',');
    let cities = countryIdString && await this.localizeService.getCitiesByCountryId(countryIdString);
    this.listOfCities = [...cities];
  }

  async onCountryDeSelect(country: any) {
    await this.onCountrySelect();

    let operationCities: any[] = this.fieldWorkForm.get('operation_city').value;
    let tempCities = this.listOfCities
    let newListOfCities =  operationCities.filter(function(checkCity) {
       return tempCities.find(function(allowedCity) {
        return checkCity.id === allowedCity.id
      })
    })

    this.fieldWorkForm.controls['operation_city'].setValue(newListOfCities)
  }

  onItemSelect(item: any) {
  }

  onCountrySelectAll(countries: any) {
    this.listOfCities = [];
    countries.forEach(country => {
      this.localizeService.getCitiesByCountryId(country.id).then(data => {
        this.listOfCities = this.listOfCities.concat(...data)
      })
    })
  }

  onSelectAll(event: any) {

  }

  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      }).then(() => {
        this.router.navigate(['view-field-work'])
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.fileToUpload = <File>event.target.files[0];
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.fileUrl = event.target.result;

      }
    }
  }
}


