import { SharedService } from '../_shared/shared.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

export interface TermsAndConditions {
  text: String;
}

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  constructor( public sharedService: SharedService) { }

  createTermsAndConditions(tncData) {
    return this.sharedService.post(`${environment.baseUrl}/terms-and-conditions/add`, tncData);
  }

  removeTermsAndConditions() {
    return this.sharedService.delete(`${environment.baseUrl}/terms-and-conditions/delete`);
  }

  updateTermsAndConditions(tncData) {
    return this.sharedService.put(`${environment.baseUrl}/terms-and-conditions/update`, tncData);
  }

  getTermsAndConditionsDetail() {
    return this.sharedService.get(`${environment.baseUrl}/terms-and-conditions/detail`);
  }
}
