import { FieldWorkerService } from './../_services/field-worker.service';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestionnnaireService } from './../_services/questionnnaire.service';
import { LocalizeService } from './../_services/localize.service';
import { OperationService } from './../_services/operation.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { find, get, pull } from 'lodash';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

export interface Country {
  id: Number,
  name: String
}

@Component({
  selector: 'app-create-questionnaire',
  templateUrl: './create-questionnaire.component.html',
  styleUrls: ['./create-questionnaire.component.scss']
})
export class CreateQuestionnaireComponent implements OnInit {

  @ViewChild('tagInput') tagInputRef: ElementRef;
  tags: string[] = [];
  questionnaireForm: FormGroup;

  dropdownSettings: IDropdownSettings;
  dropdownCitySettings: IDropdownSettings;
  dropdownTagsSettings: IDropdownSettings;


  countries: Array<any> = [];
  cities: Array<any> = [];
  categories: Array<any> = [];
  questionnaireTypes: Array<any> = [];

  isUpdateMode: Boolean = false;
  qnnIdToUpdate: string = null;

  repeat_cycles = [
    {
      id: 1,
      name: '1 week'
    },
    {
      id: 2,
      name: '2 weeks'
    },
    {
      id: 3,
      name: '3 weeks'
    },
    {
      id: 4,
      name: '4 weeks'
    },
    {
      id: 5,
      name: '5 weeks'
    },
    {
      id: 6,
      name: '6 weeks'
    },
    {
      id: 7,
      name: '7 weeks'
    },
    {
      id: 8,
      name: '8 weeks'
    },
    {
      id: 9,
      name: '9 weeks'
    },
    {
      id: 10,
      name: '10 weeks'
    },
    {
      id: 11,
      name: '11 weeks'
    },
    {
      id: 12,
      name: '12 weeks'
    }
  ];

  constructor(
    private fb: FormBuilder,
    private operationService: OperationService,
    private localizeService: LocalizeService,
    private router: Router,
    private questionnaireService: QuestionnnaireService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.loadQuestionnaireTypes();
    this.loadCountries();
    this.loadCategories();
    this.loadFieldWorkers();

    this.qnnIdToUpdate = this.route.snapshot.params['id'] || null;
    if (this.qnnIdToUpdate) {
      Swal.showLoading();
      this.isUpdateMode = true;
      this.getQnnById(this.qnnIdToUpdate);
    }

    this.questionnaireForm = this.fb.group({
      name: ['', [Validators.required]],
      questionnaire_type: ['', [Validators.required]],
      repeat_cycle: [''],
      country: [''],
      city: ['', [Validators.required]],
      tags: [''],
      category: ['', [Validators.required]]
    });

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownTagsSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownCitySettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    }
  }

  // CRUD method

  addQuestionnaire() {
    if (this.questionnaireForm.invalid) {
      for (var i in this.questionnaireForm.controls) {
        this.questionnaireForm.controls[i].markAsDirty();
        this.questionnaireForm.controls[i].updateValueAndValidity();
      }
      return;
    }

    Swal.showLoading();
    // format values as required by api
    let formattedFormValues = { ...this.questionnaireForm.value };
    for (let formValue in formattedFormValues) {

      if (formValue == "repeat_cycle" && formattedFormValues[formValue] == "") {
        delete formattedFormValues[formValue]
      }

      if (formValue == "country")
        delete formattedFormValues[formValue];

      if (formValue == "city")
        formattedFormValues[formValue] = formattedFormValues[formValue].map(city => city.id);

      if (formValue == "tags") {
        formattedFormValues[formValue] = formattedFormValues[formValue] ? formattedFormValues[formValue].map(tag => tag.id) : [];
      }

      if (formValue == "category")
        formattedFormValues[formValue] = formattedFormValues[formValue] && formattedFormValues[formValue][0]?.id;
    }

    this.questionnaireService.addQuestionnaire(formattedFormValues)
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: "Questionnaire added successfully!"
            }).then(() => {
              this.questionnaireForm.reset();
              this.router.navigate(['view-questionnaire']);
            })
          } else {
            this.handleNotification(response.error_message || "Problem in adding questionnaire!", true);
          }
        },
        error => {
          Swal.close();
          this.handleNotification(error.error.error_message || "Problem in adding questionnaire!", true);
        }
      )
  }

  resetQuestionnaire() {
    this.router.navigate(['view-questionnaire'])
  }

  getQnnById(qnnId) {
    Swal.showLoading();
    // list questionnaire by Id
    this.questionnaireService.getQnnDetailById(qnnId)
      .subscribe(
        response => {
          Swal.close();
          if (response?.success) {

            // disable inputs
            this.questionnaireForm.controls['questionnaire_type'].disable();

            // load cities of a country
            this.getCitiesByCountryId();
            // this.getCitiesByCountryId(response?.data?.country[0]);

            this.questionnaireForm = this.fb.group({
              name: [response?.data?.name, [Validators.required]],
              questionnaire_type: [{ value: response?.data?.questionnaire_type, disabled: true }],
              repeat_cycle: [response?.data?.repeat_cycle, [Validators.required]],
              country: [response?.data?.country],
              city: [response?.data?.city, [Validators.required]],
              tags: [response?.data?.tags],
              category: [{ value: [response?.data?.category], disabled: true }, [Validators.required]]
            });


          } else {
            let errorMessage = response?.error_message || "Problem in fetching questionnaire!";
            this.handleNotification(errorMessage, true);
          }
        },
        error => {
          Swal.close();
          let errorMessage = error.error.error_message || "Problem in fetching questionnaire!";
          this.handleNotification(errorMessage, true);
        }
      )
  }


  updateQuestionnaire() {

    if (this.questionnaireForm.invalid) {
      for (var i in this.questionnaireForm.controls) {
        this.questionnaireForm.controls[i].markAsDirty();
        this.questionnaireForm.controls[i].updateValueAndValidity();
      }
      return;
    }

    Swal.showLoading();
    // format values as required by api
    let formattedFormValues = { ...this.questionnaireForm.value };

    for (let formValue in formattedFormValues) {

      if (formValue == "country" ||
        formValue == "questionnaire_type" ||
        formValue == "category") {
        delete formattedFormValues[formValue];
      }

      if (formValue == "city")
        formattedFormValues[formValue] = formattedFormValues[formValue].map(city => city.id);

      if (formValue == "tags")
        formattedFormValues[formValue] = formattedFormValues[formValue].map(tag => tag.id);
    }

    this.questionnaireService.updateQuestionnaire(this.qnnIdToUpdate, formattedFormValues)
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: "Questionnaire updated successfully!"
            }).then(() => {
              this.questionnaireForm.reset();
              this.router.navigate(['view-questionnaire']);
            })
          } else {
            this.handleNotification(response.error_message || "Problem in updating questionnaire!", true);
          }
        },
        error => {
          Swal.close();
          this.handleNotification(error.error.error_message || "Problem in updating questionnaire!", true);
        }
      )
  }

  // Helper method

  loadQuestionnaireTypes() {
    Swal.showLoading();
    this.operationService.getQTs()
      .subscribe(
        response => {
          Swal.close();
          let questionnaireTypes = response.data ? response.data.results : [];
          this.questionnaireTypes = [...questionnaireTypes];
        },
        error => {
          Swal.close();
          this.handleNotification('Problem in fetching questionnaire type!', true);
        }
      )
  }

  loadCountries() {
    Swal.showLoading();
    this.localizeService.getCountries()
      .then(data => {
        Swal.close()
        if (data.length > 0) {
          this.countries = data
        } else {
          this.countries = [];
        }
      }).catch(err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching countries!', true)
      })
  }

  async getCitiesByCountryId() {

    Swal.showLoading();
    this.cities = [];
    let countryIdString = this.questionnaireForm.value.country.map(country => country.id).join(',');
    let cities = countryIdString && await this.localizeService.getCitiesByCountryId(countryIdString);
    this.cities = [...cities];
    Swal.close();
  }

  loadCategories() {
    Swal.showLoading();
    this.operationService.getCategories()
      .subscribe(response => {
        Swal.close()
        let categories = response.data ? response.data.results : [];
        if (categories.length > 0) {
          this.categories = categories
        } else {
          this.categories = [];
        }
      }, err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching categories!', true)
      })
  }

  loadFieldWorkers() {
    Swal.showLoading();
    this.questionnaireService.getFieldWorkersInfo()
      .subscribe(res => {
        Swal.close();
        if (res.success) {
          this.tags = [...res.data.results];
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error.error_message || "Problem fetching field workers!"
          });
        }
      }, (error) => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong in fetching field works!'
        });
      })
  }

  async resetCities(country: Country) {
    Swal.showLoading();
    await this.getCitiesByCountryId();
    let operationCities: any[] = this.questionnaireForm.get('city').value;
    let newListOfCities = [];
    if (!operationCities) { return };
    let tempCities = this.cities ? [...this.cities] : [];
    newListOfCities = operationCities.filter(function (checkCity) {
      return tempCities.find(function (allowedCity) {
        return checkCity.id === allowedCity.id
      })
    })
    this.questionnaireForm.controls['city'].setValue(newListOfCities);
    Swal.close();
    // this.questionnaireForm.controls.city.setValue('');
    // this.cities = [];
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  // UI helpers

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.questionnaireForm.controls.tags.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma') {
        this.addTag(inputValue);
        this.questionnaireForm.controls.tags.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.tags, tag)) {
      this.tags.push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.tags, tag);
    } else {
      this.tags.splice(-1);
    }
  }


}
