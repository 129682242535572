import { QuestionService } from './../_services/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-single-questionnaire',
  templateUrl: './single-questionnaire.component.html',
  styleUrls: ['./single-questionnaire.component.scss']
})
export class SingleQuestionnaireComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  questionnaireId: String = null;
  agentUserId: String = null;
  responseId: String = null;
  pageTitle: string;

  answers: Array<any> = [];

  showModal: Boolean = false;
  answerImageUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private qs: QuestionService
  ) { }

  ngOnInit(): void {
    this.responseId = this.route.snapshot.params["responseId"];

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: '<"top"<"left-col"B><"center-col"l><"right-col">>rtip',
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export',
          title: 'questionnaire_answers',
          className: ' mr-1 btn btn-info float-right',
          exportOptions: {
            columns: [0, 1, 2, 3]
          }
        }
      ]
    }

    this.getAnswersByQnnIdAndUserId();

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  getAnswersByQnnIdAndUserId() {

    Swal.showLoading();

    if (!(this.responseId)) {
      Swal.fire({
        icon: 'error',
        title: "Invalid Response ID for this Answer!"
      }).then(() => {
        this.router.navigate(["view-questionnaire"]);
        return;
      })
    }

    this.qs.getAnswerByQnnIdAndUserId(this.responseId)
    .subscribe(
      response => {
        Swal.close();
        if (response && response.success) {
          this.answers = [...response.data?.results];
          let { agent_id, questionnaire_id } = response.data?.info;
          this.pageTitle = `Agent ID: ${agent_id} & Questionnaire ID: ${questionnaire_id}`;
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "No Data Available!"
          });
        }
      },
      error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching answers!"
        });
      }
    )

  }


  // helper methods

  displayFullImage(imageUrl) {
    this.answerImageUrl = imageUrl;
    this.showModal = true;
  }

}
