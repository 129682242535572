import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SharedService } from '../_shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class SnapOperationService {

  constructor(
    private sharedService: SharedService
  ) { }

  // channel APIs
  getChannels() {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-channel/list?ordering=name`);
  }
  getChannelsForSnap(filters) {
    return new Promise<any>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/filters/channel-list${filters}ordering=name`).subscribe(res => {
        if (res.success) {
          resolve(res.data)
        } else {
          resolve([])
        }
      }, err => {
        reject([])
      });
    })
  }
  updateChannel(channelId, channelData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-channel/${channelId}/update`, channelData);
  }

  removeChannel(channelId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-channel/${channelId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  // city APIs
  getCities() {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-city/list?ordering=name`);
  }
  getCountries() {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-country/list?ordering=name`);
  }

  updateCity(cityId, cityData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-city/${cityId}/update`, cityData);
  }

  removeCity(cityId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-channel/${cityId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }


  // retailer APIs

  getRetailers(countryIds?) {
    if (countryIds && countryIds.length > 0) {
      return this.sharedService.get(`${environment.baseUrl}/snap/snap-retailer/list?ordering=name&country=${countryIds.join(',')}`);
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-retailer/list?ordering=name`);
  }

  getRetailerById(retailerId) {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-retailer/${retailerId}`)
  }

  updateReatilerById(retailerId, retailerData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-retailer/${retailerId}/update`, retailerData);
  }

  removeRetailer(retailerId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-retailer/${retailerId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  // branch APIs

  getBranches() {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-store/list?ordering=name`);
  }

  getBranchByRetailerId(retailerId, countryIds) {
    Swal.showLoading();
    return new Promise<any[]>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/store/basic-list?ordering=name&retailer=${retailerId}&country=${countryIds.join(',')}`).subscribe(res => {
        Swal.close();
        if (res && res.success) {
          let branches = res.data.results;
          branches = branches.filter(branch => {
            delete branch.retailer
            delete branch.country
            delete branch.city
            return branch
          });
          resolve(branches)
        }
      }, err => {
        Swal.close();
        reject([])
      });
    })
  }

  updateStore(storeId, StoreData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-store/${storeId}/update`, StoreData);
  }

  removeStore(storeId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-store/${storeId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  // Category APIs

  getCategories() {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-category/list?ordering=name`);
  }
  getCategoriesForSnap(filters) {
    return new Promise<any>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/filters/category-list${filters}ordering=name`).subscribe(res => {
        if (res.success) {
          resolve(res.data)
        } else {
          resolve([])
        }
      }, err => {
        reject([])
      });
    })
  }
  updateCategory(categoryId, categoryData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-category/${categoryId}/update`, categoryData);
  }

  removeCategory(categoryId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-category/${categoryId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  // Brand APIs
  getBrands(countryIds?) {
    if (countryIds && countryIds.length > 0) {
      return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?ordering=name&country=${countryIds.join(',')}`);
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?ordering=name`);
  }
  getBrandsForSnap(filters) {
    return new Promise<any>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/filters/brand-list${filters}ordering=name`).subscribe(res => {
        if (res.success) {
          resolve(res.data)
        } else {
          resolve([])
        }
      }, err => {
        reject([])
      });
    })
  }
  updateBrand(brandId, brandData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-brand/${brandId}/update`, brandData);
  }

  removeBrand(brandId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-brand/${brandId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  getBrandsByCategory(categoryId) {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?category=${categoryId}`);
  }
  getBrandsInSnap(countryIds,categoryIds) {

    if (categoryIds && categoryIds.length > 0 && countryIds && countryIds.length > 0) {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?category=${categoryIds}&country=${countryIds}`);
  }
  else  if (categoryIds && categoryIds.length > 0) {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?category=${categoryIds}`);
  }
  else  if (countryIds && countryIds.length > 0) {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?country=${countryIds}`);
  }
  else {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?ordering=name`);
  }
  }
  getBrandsByCategories(categoryIds?) {
    if (categoryIds && categoryIds.length > 0) {
      return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?ordering=name&category=${categoryIds.join(',')}`);
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-brand/list?ordering=name`);
  }

  // SKU APIs

  getSKUs() {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-sku/list?ordering=name`);
  }
  getSkusForSnap(filters) {
    return new Promise<any>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/filters/sku-list${filters}ordering=name`).subscribe(res => {
        if (res.success) {
          resolve(res.data)
        } else {
          resolve([])
        }
      }, err => {
        reject([])
      });
    })
  }
  removeSKUs(skuId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-sku/${skuId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  updateSku(skuId, skuData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-sku/${skuId}/update`, skuData);
  }

  getSKUsByBrand(brandId) {
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-sku/list?brand=${brandId}`)
  }

  getSkusByBrandId(brandId, countryIds) {
    return new Promise<any[]>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/snap-sku/list?ordering=name&brand=${brandId}&country=${countryIds.join(',')}`)
        .subscribe(res => {
          Swal.close()
          if (res && res.success) {
            let skus = res.data.results;
            skus = skus.filter(sku => {
              delete sku.brand
              delete sku.category
              return sku
            });
            resolve(skus)
          }
        }, err => {
          Swal.close()
          reject([])
        });
    })
  }

  getSkusByCategories(categoryIds?, brandIds?,countryIds?) {
    return new Promise<any[]>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/snap/snap-sku/list?ordering=name&category=${categoryIds.join(',')}&country=${countryIds.join(',')}&brand=${brandIds}`)
        .subscribe(res => {
          Swal.close()
          if (res && res.success) {
            let skus = res.data.results;
            skus = skus.filter(sku => {
              delete sku.brand
              delete sku.category
              return sku
            });
            resolve(skus)
          }
        }, err => {
          Swal.close()
          reject([])
        });
    })
  }

}
