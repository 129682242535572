<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Add Question'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="'Create Question'" [subTitle]="'Add or Import new Questions to Questionnaire'">
            </app-top-title>
          </div>

          <!-- start row -->
          <div class="row">

            <div class="col-12 card-box">
              <div class="p-4" style="background-color: #dfdfdf;">

                <div class="row pl-4 mb-3">
                  <div class="float-right">
                    <div id="import_csv" class="file btn btn-lg btn-sm btn-success rounded-pill py-2 px-4"
                      style="font-size: 16px;">
                      Import Questions
                      <input id="input_csv" type="file" name="file" (change)="importQuestions($event)" accept=".csv" />
                    </div>
                  </div>
                </div>

                <div class="question-row">
                  <form class="form-horizontal" role="form" [formGroup]="questionForm" (ngSubmit)="addQuestions()"
                    onkeydown="return event.key != 'Enter';">

                    <!-- Normal question creation -->
                    <div class="card shadow-lg p-4 mb-3" formArrayName="questions"
                      *ngFor="let questionCard of questionForm.get('questions')['controls']; last as isLast; let i = index">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group" [formGroupName]=i>
                            <label class="col-form-label">Question Type<span style="color:#ff0000">*</span></label>
                            
                            <select #questionTypeRef class="form-control"
                              (change)="changeQuestionType(questionTypeRef.value, questionCard)"
                              formControlName="question_type"
                              [class.valid]="questionForm.get('questions')['controls'][i]['controls'].question_type.valid && 
                            (questionForm.get('questions')['controls'][i]['controls'].question_type.dirty || questionForm.get('questions')['controls'][i]['controls'].question_type.touched)"
                              [class.invalid]="questionForm.get('questions')['controls'][i]['controls'].question_type.invalid && 
                            (questionForm.get('questions')['controls'][i]['controls'].question_type.dirty ||questionForm.get('questions')['controls'][i]['controls'].question_type.touched)">

                              <option [attr.selected]="true" *ngIf="isSingleOption; else elseBlock" [value]="questionsType[0]?.id">
                                {{ questionsType[0]?.name }} </option>
                              <ng-template #elseBlock>
                                <option disabled value="" selected>Select question type</option>
                                <option *ngFor="let qt of questionsType" [value]="qt.id"> {{ qt.name }} </option>
                              </ng-template>
                            </select>
                            <div
                              *ngIf="questionForm.get('questions')['controls'][i]['controls'].question_type.invalid && 
                            questionForm.get('questions')['controls'][i]['controls'].question_type.errors && 
                                                                 (questionForm.get('questions')['controls'][i]['controls'].question_type.dirty || questionForm.get('questions')['controls'][i]['controls'].question_type.touched)">
                              <small class="text-danger"
                                *ngIf="questionForm.get('questions')['controls'][i]['controls'].question_type.hasError('required')">
                                Question Type is required. </small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group" [formGroupName]=i>
                            <label class="col-form-label" for="question">Question<span
                                style="color:#ff0000">*</span></label>

                            <input type="text" id="question" class="form-control" formControlName="statement"
                              placeholder="Enter Question"
                              [class.valid]="questionForm.get('questions')['controls'][i]['controls'].statement.valid && 
                              (questionForm.get('questions')['controls'][i]['controls'].statement.dirty || questionForm.get('questions')['controls'][i]['controls'].statement.touched)"
                              [class.invalid]="questionForm.get('questions')['controls'][i]['controls'].statement.invalid && 
                              (questionForm.get('questions')['controls'][i]['controls'].statement.dirty || questionForm.get('questions')['controls'][i]['controls'].statement.touched)">
                            <div
                              *ngIf="questionForm.get('questions')['controls'][i]['controls'].statement.invalid && 
                                                                   questionForm.get('questions')['controls'][i]['controls'].statement.errors && 
                                                                   (questionForm.get('questions')['controls'][i]['controls'].statement.dirty || questionForm.get('questions')['controls'][i]['controls'].statement.touched)">
                              <small class="text-danger"
                                *ngIf="questionForm.get('questions')['controls'][i]['controls'].statement.hasError('required')">
                                Question is required. </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="questionCard.isChoiceQuestion">
                        <div class="col-md-12" style="width: 100%;">
                          <div class="form-group" [formGroupName]=i>
                            <label class="col-form-label">Options</label>
                            <div class="editor-tag">
                              <span class="tag-item"
                                *ngFor="let option of questionForm.value.questions[i]['question_options']">
                                {{ option }}
                                <span class="remove-tag" (click)="removeTag(i, option)">×</span>
                              </span>
                              <input placeholder="Type then press Enter.." #optionInput class="input-tag" type="text"
                                id="option" (keyup)="onKeyUp($event, optionInput.value, i)"
                                formControlName="question_option">
                            </div>
                          </div>
                          <small style="color:#53a7db"><i class="fa fa-info-circle"
                              aria-hidden="true"></i><em>&nbsp;Type and press Enter to add option.</em></small>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group" [formGroupName]=i>
                            <label class="col-form-label">Brand</label>

                            <ng-multiselect-dropdown [settings]="dropdownSettings" name="brand"
                              [placeholder]="'Select Brand'" id="selBrand" [data]="brands"
                              (onSelect)="loadSkusByBrand($event, i)" (onDeSelect)="resetSkus($event, i)"
                              formControlName="brand">
                            </ng-multiselect-dropdown>

                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group" [formGroupName]=i>
                            <label class="col-form-label">SKU<span style="color:#ff0000">*</span></label>

                            <ng-multiselect-dropdown [settings]="dropdownSettings" name="sku" id="selSku"
                              [placeholder]="'Select Sku'" [data]="skus" formControlName="sku"
                              [class.valid]="questionForm.get('questions')['controls'][i]['controls'].sku.valid && 
                            (questionForm.get('questions')['controls'][i]['controls'].sku.dirty || questionForm.get('questions')['controls'][i]['controls'].sku.touched)"
                              [class.invalid]="questionForm.get('questions')['controls'][i]['controls'].sku.invalid && 
                            (questionForm.get('questions')['controls'][i]['controls'].sku.dirty || questionForm.get('questions')['controls'][i]['controls'].sku.touched)">
                            </ng-multiselect-dropdown>
                            <div
                              *ngIf="questionForm.get('questions')['controls'][i]['controls'].sku.invalid && 
                                                                   questionForm.get('questions')['controls'][i]['controls'].sku.errors && 
                                                                   (questionForm.get('questions')['controls'][i]['controls'].sku.dirty || questionForm.get('questions')['controls'][i]['controls'].sku.touched)">
                              <small class="text-danger"
                                *ngIf="questionForm.get('questions')['controls'][i]['controls'].sku.hasError('required')">
                                SKU is required. </small>
                            </div>

                          </div>
                        </div>
                        <div class="col-md-4 align-self-center pt-3">
                          <button *ngIf="questionForm.value.questions.length != 1" type="button"
                            class="btn btn-danger px-4 py-1 mr-2" style="font-weight:bold;font-size: large;"
                            (click)="removeQuestionCard(i)">-</button>

                          <button *ngIf="isLast" type="button" class="btn btn-success px-4 py-1"
                            style="font-weight:bold;font-size: large;" (click)="addQuestionCard(i)">+</button>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <button type="submit" class="btn btn-success rounded-pill pl-4 pr-4">Submit</button> &nbsp;
                    <button type="button" class="btn btn-danger rounded-pill pl-4 pr-4"
                      (click)="cancelQuestionCreation()">Cancel</button>
                  </form>
                </div>

              </div>
            </div><!-- end card-box -->
            <div class="col-12 card-box">
              <div class="p-4" style="background-color: #ffffff;">

                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  id="questionTable">
                  <thead>
                    <tr>
                      <th>Question ID</th>
                      <th>Question Type</th>
                      <th>Question</th>
                      <th>Options</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let qn of questionList">
                      <td data-column="Question ID">{{ qn.id }}</td>
                      <td data-column="Question Type">{{ qn.question_type }}</td>
                      <td data-column="Question">{{ qn.statement }}</td>
                      <td data-column="Options">
                        <div *ngIf="qn.question_options">
                          <span *ngFor="let obj of qn.question_options">
                            {{ obj.name }},&nbsp;
                          </span>
                        </div>
                      </td>
                      <td>
                        <a [routerLink]="" (click)="editQuestionStatement(qn.id, qn.statement)"> Edit </a>
                      </td>
                      <td>
                        <a [routerLink]="" (click)="removeQuestion(qn.id)" style="color:'red';"> Delete </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- end row -->

        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>