import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { DashboardService } from '../_services/dashboard.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  faEllipsisV = faEllipsisV;
  // Bar data
  @Input() lineChartData: ChartDataSets[];
  @Input() lineChartLabels: Label[];
  @Input() chartTitle: string;
  @Input() lineChartExcelData: any;
  @Input() scoreAlign;
  @Input() scoreAnchor;
  @Input() canvasHeight;
  @Input() lineChartExcelFileName: string;
  @Input() dataTableId: string;
  @Input() dataModalId: string;
  @Input() loaded: Boolean = false;

  public lineChartOptions: any;
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations, pluginDataLabels];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  // datatable option
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();
  public dataTargetName: string;
  
  constructor() { }

  ngOnInit(): void {

    this.lineChartOptions = {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20
        }
      },
      responsive: true,
      layout: {
        padding: 20
      },
      title: {
        display: true,
        text: this.chartTitle,
        fontSize: 20
      },
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
          }
        }],
        xAxes: [{
          ticks: {
            min: 0,
          }
        }]
      },
      legend: {
        display:true,
        position: 'top'     
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      }
    };

    this.lineChartPlugins = [pluginDataLabels, {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 40
        }
      },
      
    }]

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: "<'row'<'col-md-6'l>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-md-6'i><'col-md-6'p>>",
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export Data in CSV',
          title: this.lineChartExcelFileName,
          className: ' mr-1 btn btn-info float-right'
        }
      ]
    };
    this.dataTargetName = '#' + this.dataModalId;
    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  exportDataToExcel() {
    this.downloadFile(this.lineChartExcelData.data, this.lineChartExcelData.labels, this.lineChartExcelFileName);
  }

  downloadFile(data: Array<Object>, headerList: Array<String>, filename: String) {
    let csvData = this.convertToCSV(data, headerList);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=UTF-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

}
