import { SharedService } from './../_shared/shared.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private sharedService: SharedService
  ) { }

  getOverview() {
    return this.sharedService.get(`${environment.baseUrl}/report/overview`);
  }

  // Price Monitor APIs

  getPMMinMax(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/min-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/min-max`);
  }

  getPMMonthMax(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-max`);
  }

  getPMMonthMin(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-min`);
  }

  getPMMonthMode(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-mode`);
  }

  getPMMonthMean(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/month-mean`);
  }

  getPMCountryMin(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-min`);
  }

  getPMCountryMax(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-max`);
  }

  getPMCountryMode(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-mode`);
  }

  getPMCountryMean(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/country-mean`);
  }

  getPMCountryAnswerPerCountry(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/answer-per-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/answer-per-country`);
  }

  getPMCountryAnswerPerCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/answer-per-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/answer-per-city`);
  }

  getPMCountryAnswerPerSku(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/answer-per-sku${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/answer-per-sku`);
  }

  getPMTotalVisits(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/total-visit${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/total-visit`);
  }

  getPMBrandVsAvg(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/brand-min-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/price-monitor/brand-min-max`);
  }

  // Out of stock APIs

  getOOSCityAvailable(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/city-available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/city-available`);
  }

  getOOSCityNotAvailable(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/city-not-available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/city-not-available`);
  }

  getOOSCityLess(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/city-less${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/city-less`);
  }

  getOOSMonthAvailable(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/month-available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/month-available`);
  }

  getOOSMonthNotAvailable(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/month-not-available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/month-not-available`);
  }

  getOOSMonthLess(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/month-less${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/month-less`);
  }

  getOOSOverall(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/overall${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/overall`);
  }

  getOOSRetailerLess(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/retailer-less${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/retailer-less`);
  }

  getOOSStoreNotAvailable(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/store-not-available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/store-not-available`);
  }

  getOOSTotalVisit(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/total-visit${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/total-visit`);
  }

  getOOSWeekNotAvailable(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/week-not-available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/out-of-stock/week-not-available`);
  }

  // Distribution Check APIs
  /**
   * @param  {} filterString
   * 1) Total Submitted Questionnaire By City
   */
  getDCSubmittedQnnByCity(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/visit-per-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/visit-per-city`);
  }

  /**
   * @param  {} filterString
   * 2) Total Submitted Questionnaire By Country
   */
  getDCSubmittedQnnByCountry(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/visit-per-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/visit-per-country`);

  }

  /**
   * @param  {} filterString
   * 3) Total Visit By Channel
   */
  getSubmittedQnnByChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/visit-per-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/visit-per-channel`);
  }

  /**
   * @param  {} filterString
   * 4) Total SKU by City
   */

  getTotalOutletsInCity(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/sku-per-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/sku-per-city`);
  }

  /**
   * @param  {} filterString
   * 5) Total SKU by country
   */

  getTotalOutletsInCountry(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/sku-per-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/sku-per-country`);
  }

  /**
   * @param  {} filterString
   * 6) Total SKU by channel
   */

  getTotalOutletsInChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/sku-per-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/sku-per-channel`);
  }

  /**
   * @param  {} filterString
   * 7) Total Brand by City
   */

  getSumOfOneBrandByCity(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-city`);
  }

  /**
  * @param  {} filterString
  * 8) Total Brand by Country
  */

  getSumOfOneBrandByCountry(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-country`);
  }

  /**
   * @param  {} filterString
   * 9) Total Brand by Channel
   */

  getSumOfOneBrandByChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-channel`);
  }

  /**
   * @param  {} filterString
   * 10) Total Average Per SKU
   */
  getDCAvgPerSku(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-per-sku${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-per-sku`);
  }

  /**
   * @param  {} filterString
   * 11) Total Average per Brand
   */
  getDCAvgPerBrand(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-per-brand${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-per-brand`);
  }

  /**
   * @param  {} filterString
   * 12) Shelf Share of SKU, by Channel 
   */
  getDCShelfShareSkuPerChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-sku-per-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-sku-per-channel`);
  }

  /**
   * @param  {} filterString
   * 13) Shelf Share of Brand, by Channel
   */
  getDCShelfShareBrandPerChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-brand-per-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/avg-brand-per-channel`);
  }

  //Consumer Questionnaire methods

  /**
   * @param  {} filterString
   * 1) Total Yes/No Questions
   */
  getCQYesNoQuestion(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/yes-no-question${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/yes-no-question`);
  }
  
  /**
   * @param  {} filterString
   * 2) Rating one to three questions
   */
  getCQRatingOneToThreeQuestion(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/rating-one-to-three${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/rating-one-to-three`);
  }

  /**
   * @param  {} filterString
   * 3) Rating one to five questions
   */
  getCQRatingOneToFiveQuestion(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/rating-one-to-five${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/rating-one-to-five`);
  }

  /**
   * @param  {} filterString
   * 4) Total Rating One to 10
   */
  getCQRatingOneToTen(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/rating-one-to-ten${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/rating-one-to-ten`);

  }

  /**
   * @param  {} filterString
   * 5) Numeric questions
   */
  getCQNumericQuestion(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/numeric-question${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/numeric-question`);
  }

  /**
   * @param  {} filterString
   * 6) Option Questions
   */
  getCQOptionQuestions(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/options-question${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/options-question`);
  }
}
