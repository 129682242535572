<!DOCTYPE html>
<html lang="en">

<body>
    <div id="wrapper">
        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Merchandizing User audits'" (searchText)="searchText($event)"></app-top-bar>
        <!-- end Topbar -->
    </div>
    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div class="mb-0">
                        <app-top-title [title]="'User audits history'"
                            [subTitle]="'You can View Users Audit\'s history data here.'"></app-top-title>
                    </div>
                    <div class="mt-3 p-3 bg-white mb-5">
                        <div class="mt-2 mb-3">
                            <div class="float-left">
                                <div>
                                    <button class="btn btn-danger mr-2" (click)="deleteSnapData()">
                                        Delete
                                    </button>
                                </div>
                                <!-- <div class="mt-2 mb-2">
                <!-- Date range picker -->
                                <!--<label class=" col-form-label">Date Range: <span style="color:#ff0000">*</span> </label>
                <input id="daterangepicker" class="form-control" startKey="date_after" endKey="date_before"
                        opens="right" drops="down" emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd
                       [formControl]="dashboardForm.controls['date_range']" [locale]="{'format': 'MM/DD/YYYY'}"
                       (change)="applyDateFilter()"
                       [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
                       [showDropdowns]="true" [showRangeLabelOnInput]="showRangeLabelOnInput"
                       placeholder="Select the date range..." />
              </div> -->
                            </div>
                            <!-- <div class="float-right mb-3">
                                <!-- Date range picker -->
                              <!--  <label class=" col-form-label">Date Range: <span style="color:#ff0000">*</span> </label>
                                <input id="daterangepicker" class="form-control" startKey="date_after"
                                    endKey="date_before" opens="right" drops="down"
                                    emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd
                                    [formControl]="dashboardForm.controls['date_range']"
                                    [locale]="{'format': 'MM/DD/YYYY'}" (change)="applyDateFilter()"
                                    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                                    [linkedCalendars]="true" [showDropdowns]="true"
                                    [showRangeLabelOnInput]="showRangeLabelOnInput"
                                    placeholder="Select the date range..." />


                            </div> -->
                        </div>

                        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" name="id" (click)="selectAll()"
                                            [checked]="isSelectAllChecked">
                                    </th>
                                    <th>Date and time</th>
                                    <th>Survey ID</th>
                                    <th>Agent ID</th>
                                    <th>number of total questions</th>
                                    <th> No of answered questions</th>
                                    <th> City Name</th>
                                    <th>Store Name</th>
                                    <th>Branch Name</th>
                                    <th> Store type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let snapDatum of snapData">
                                    <td>
                                        <input type="checkbox" name="id" (change)='rowSelected(snapDatum?.id)'
                                            [checked]="snapDatum.isChecked">
                                    </td> 
                                    <td> {{generateRandomDate()}}</td>
                                    <td> {{ generateRandomId()}}</td>
                                    <td> {{ generateRandomAId() }}</td>
                                    <td> {{ generateRandomNumber2() }}</td>
                                    <td> {{  generateRandomNumber()  }}</td>
                                    <td> {{ generateRandomNumber()}}</td>
                                    <td> {{ snapDatum?.max }}</td>
                                    <td> {{ snapDatum?.mean }}</td>
                                    <td> {{ snapDatum?.mode }}</td>
                                </tr>
                            </tbody>


                        </table>

                        <div class="row ml-2 flex d-flex justify-content-start mt-2">
                            Showing {{ gOffset || 0 }} to {{ gOffset + gLimit || 0 }}.
                        </div>

                        <div class="row mr-2 flex d-flex justify-content-end" style="margin-top: -15px;">
                            <button class="btn btn-warning mr-2" (click)="handlePagination('previous')"
                                [disabled]="!previousUrl">
                                Previous</button>
                            <button class="btn btn-primary" (click)="handlePagination('next')" [disabled]="!nextUrl">
                                Next</button>
                        </div>
                    </div>
                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->
    </div>
    <!-- END wrapper -->
</body>

</html>