import { AuthService } from './../_services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TermsAndConditionsService } from '../_services/terms-and-conditions.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  isTncCreated: boolean;
  tncData: string;

  constructor(private fb: FormBuilder,
    private tncService: TermsAndConditionsService,
    public router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.getTermsAndConditions()
  }

  tncUpdate = this.fb.group({
    text: ['', [Validators.required]]
  })

  onSubmit() {
    if(!this.tncUpdate.valid){
      this.tncUpdate.controls['text'].markAsDirty();
        this.tncUpdate.controls['text'].updateValueAndValidity();
      //this.handleNotification('There was problem with validation', true)
    }

    if(!this.isTncCreated){
      this.createTermsAndConditions(this.tncUpdate.value)
    } else {
      this.updateTermsAndConditions(this.tncUpdate.value)
    }  
  }

  clear() {
    this.router.navigate(['dashboard']);
  }

  getTermsAndConditions(){
    this.tncService.getTermsAndConditionsDetail().subscribe(res => {
      if(res.success){
        this.isTncCreated = true
        this.tncUpdate = this.fb.group({
          text: [res.data.text, Validators.required]
        });
      } else {
        this.isTncCreated = false
        this.tncUpdate = this.fb.group({
          text: ['', Validators.required]
        });
      }
    })
  }

  createTermsAndConditions(data){
    this.tncService.createTermsAndConditions(data).subscribe(res => {
      if(res.success){
        this.handleNotification('Terms and Condition Created', false);
        this.isTncCreated = true;
        this.router.navigate(['/'])
      }
    }, err => {
      this.handleNotification(err.error.error_message, true)
    })
  }

  updateTermsAndConditions(data){
    this.tncService.updateTermsAndConditions(data).subscribe(res => {
      if(res.success){
        this.handleNotification('Terms and Condition Updated', false);
        this.router.navigate(['/'])
      }
    }, err => {
      this.handleNotification(err.error.error_message, true)
    })
  }

  handleNotification(message: string, isError:boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message,
        timer: 1500
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
