import { SharedService } from '../_shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface EmailData {
  name: string,
  email: string,
  text: string
}
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient,
    public sharedService: SharedService) { 
  }

  sendEmail(emailData: EmailData) {
    return this.sharedService.post(`${environment.baseUrl}/user/support`, emailData);
  }


}
