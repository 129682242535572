import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

export interface ExtraHeader {
  headerName: string,
  headerValue: string
}

@Injectable({
  providedIn: 'root',
})
export class SharedService implements HttpInterceptor {
  authorizedToken: string;
  authorizedHeader = new HttpHeaders();
  constructor(public http: HttpClient,
    private router: Router) { }
  getAuthToken() {
    const authToken = localStorage.getItem('accessToken');
    this.authorizedToken = authToken ? `JWT ${authToken}` : '';
    this.authorizedHeader = new HttpHeaders()
      .set('Authorization', this.authorizedToken);
  }

  post(url, dataToSend, extraHeaders?: ExtraHeader[]): any {

    if (extraHeaders && extraHeaders.length > 0) {
      extraHeaders.forEach(extraHeader => {
        this.authorizedHeader.set(extraHeader.headerName, extraHeader.headerValue)
      });
    }
    this.getAuthToken();
    return this.http.post(url, dataToSend, { headers: this.authorizedHeader });
  }

  get(url): any {
    this.getAuthToken();
    return this.http.get(url, { headers: this.authorizedHeader });
  }

  put(url, data, extraHeaders?: ExtraHeader[]): any {
    if (extraHeaders && extraHeaders.length > 0) {
      extraHeaders.forEach(extraHeader => {
        this.authorizedHeader.set(extraHeader.headerName, extraHeader.headerValue)
      });
    }
    this.getAuthToken();
    return this.http.put(url, data, { headers: this.authorizedHeader });
  }

  patch(url, data): any {
    this.getAuthToken();
    return this.http.patch(url, data, { headers: this.authorizedHeader });
  }

  delete(url): any {
    this.getAuthToken();
    return this.http.delete(url, { headers: this.authorizedHeader });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('No-Auth') === 'True')
      return next.handle(req.clone());
    // Todo: What to do next when my session is about to expire in 5 minutes.
    if (localStorage.getItem('accessToken')) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'JWT ' + localStorage.getItem('accessToken'))
      });
    }
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
      if ((error.status === 401 || error.status === 403) && this.router.routerState.snapshot.url !== '') {
        Swal.close()
        localStorage.clear();
        this.router.navigate(['']);
      } else {
        return throwError(error);
      }
    }));
  }


}
