<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
               Copyrights &copy; All rights reserved to SMS Connect
            </div>
            <div class="col-md-6">
                <div class="text-md-right footer-links d-none d-sm-block">
                    <a *ngIf="isAdmin" routerLink="/terms" >Terms</a>
                    <a routerLink="/support" >Support</a>
                </div>
            </div>
        </div>
    </div>
</footer>
