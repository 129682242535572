<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8" />
    <title>Log in | SMS Connect</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="A fully featured admin theme which can be used to build CRM, CMS, etc." name="description" />
    <meta content="Coderthemes" name="author" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />


</head>


<div class="hero-image" style="height: 100%;">

    <div class="account-pages mt-5 mb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5 center-div">
                    <div class="card">

                        <div class="card-body p-4">

                            <div class="text-center mb-4">
                                <h4 class="mt-0 font-weight-bold"><span class="green-color">SMS</span> Connect</h4>
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="invalidLogin">
                                {{errorMessage}}
                            </div>
                            <div class="alert alert-success" role="alert" *ngIf="msg">
                                {{msg}}
                            </div>
                            <form action="#" (ngSubmit)="userLogin()" [formGroup]="loginForm">

                                <div class="form-group mb-3">
                                    <label for="emailaddress" class="font-weight-bold"> Email address</label>
                                    <input class="form-control" type="email" id="emailaddress" required=""
                                        formControlName="email" placeholder="Enter your email"
                                        [class.valid]="loginForm.get('email').valid && 
                                                                (loginForm.get('email').dirty || loginForm.get('email').touched)"
                                        [class.invalid]="loginForm.get('email').invalid && 
                                                                (loginForm.get('email').dirty || loginForm.get('email').touched)">
                                    <div
                                        *ngIf="loginForm.get('email').invalid && 
                                                                   loginForm.get('email').errors && 
                                                                   (loginForm.get('email').dirty || loginForm.get('email').touched)">
                                        <small class="text-danger" *ngIf="loginForm.get('email').hasError('required')">
                                            Email is required. </small>
                                        <small class="text-danger" *ngIf="loginForm.get('email').hasError('email')">
                                            Enter a valid email. </small>
                                    </div>
                                </div>


                                <div class="form-group mb-3">
                                    <label for="password" class="font-weight-bold">Password</label>
                                    <input class="form-control" type="password" required="" id="password"
                                        formControlName="password" placeholder="Enter your password"
                                        [class.valid]="loginForm.get('password').valid && 
                                                                (loginForm.get('password').dirty || loginForm.get('password').touched)"
                                        [class.invalid]="loginForm.get('password').invalid && 
                                                                (loginForm.get('password').dirty || loginForm.get('password').touched)">
                                    <div
                                        *ngIf="loginForm.get('password').invalid && 
                                                                   loginForm.get('password').errors && 
                                                                   (loginForm.get('password').dirty || loginForm.get('password').touched)">
                                        <small class="text-danger"
                                            *ngIf="loginForm.get('password').hasError('required')">
                                            Password is required. </small>
                                    </div>
                                </div>

                                <div class="form-group mb-3">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" formControlName="remember_me"
                                            class="custom-control-input sms-color" id="checkbox-signin" checked>
                                        <span class="checkmark"></span>
                                        <label class="custom-control-label font-weight-bold remember-check"
                                            for="checkbox-signin">Remember me</label>
                                    </div>
                                </div>

                                <div class="form-group mb-0 text-center">
                                    <button class="btn btn-block" type="submit" style="background-color: '#afcb3a';">
                                        Log In </button>
                                </div>

                            </form>

                        </div> <!-- end card-body -->
                    </div>
                    <!-- end card -->

                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            <p> <a routerLink="/reset-password" class="text-muted ml-1">
                                    <fa-icon [icon]="faLock"></fa-icon>&nbsp; Forgot your password?
                                </a></p>
                        </div> <!-- end col -->
                    </div>
                    <!-- end row -->

                </div> <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end page -->


    <!-- Vendor js -->
    <!-- <script src="assets/js/vendor.min.js"></script> -->

    <!-- App js -->
    <!-- <script src="assets/js/app.min.js"></script> -->

</div>

</html>