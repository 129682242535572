<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add region to Operations'"
              [subTitle]="'You can add Regions to operations from here'"></app-top-title>
          </div>
          <div class="bg-white">

            <form class="" role="form" [formGroup]="regionForm" (ngSubmit)="updating?updateregion():addRegion()">
              <div class="row p-4">

                <div class="col-md-4" style="width: 100%;">
                  <div class="form-group">
                    <label class="col-form-label" for="country">Country <span style="color:#ff0000">*</span></label>
                    <ng-multiselect-dropdown name="country" id="selCountry" [placeholder]="'Select Country'"
                      [data]="countries" [settings]="countryDropdownSettings"
                      [formControl]="regionForm.controls['country']"
                      [class.valid]="regionForm.get('country').valid &&
                                                                (regionForm.get('country').dirty || regionForm.get('country').touched)"
                      [class.invalid]="regionForm.get('country').invalid &&
                                                                (regionForm.get('country').dirty || regionForm.get('country').touched)">
                    </ng-multiselect-dropdown>
                    <div
                      *ngIf="regionForm.get('country').invalid &&
                                                                   regionForm.get('country').errors &&
                                                                   (regionForm.get('country').dirty || regionForm.get('country').touched)">
                      <small class="text-danger" *ngIf="regionForm.get('country').hasError('required')">
                        Country is required. </small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4" style="width: 100%;">
                  <div class="form-group">
                    <label class="col-form-label" for="region">region<span style="color:#ff0000">*</span></label>
                    <input placeholder="Enter Region Name" type="text" class="form-control" id="region"
                      formControlName="name" [class.valid]="regionForm.get('name').valid &&
                        (regionForm.get('name').dirty || regionForm.get('name').touched)" [class.invalid]="regionForm.get('name').invalid &&
                        (regionForm.get('name').dirty || regionForm.get('name').touched)">
                    <div
                      *ngIf="regionForm.get('name').invalid && regionForm.get('name').errors && (regionForm.get('name').dirty || regionForm.get('name').touched)">
                      <small class="text-danger" *ngIf="regionForm.get('name').hasError('required')">
                        region name is required. </small>
                    </div>
                  </div>
                </div>
                <div class="col-md-3" style="width: 100%;">
                  <div class="form-group">
                    <label class="col-form-label" for="region">region Arabic</label>
                    <input placeholder="Enter Region Name" type="text" class="form-control" id="region"
                      formControlName="name_ar" [class.valid]="regionForm.get('name_ar').valid &&
                        (regionForm.get('name_ar').dirty || regionForm.get('name_ar').touched)" [class.invalid]="regionForm.get('name_ar').invalid &&
                        (regionForm.get('name_ar').dirty || regionForm.get('name_ar').touched)">
                  </div>
                </div>
              </div>

              <div class="row px-5 pb-3 d-flex" style="justify-content: space-between;">
                <div class="form-group">
                  <button type="submit" class="btn btn-success px-5 mr-2">{{updating?'Update':'Add'}}</button>
                  <button (click)="toggleUpdateMode()" type="button" class="btn btn-danger px-5">Cancel</button>
                </div>
                <div class="form-group">
                  <div class="float-right">
                    <div id="import_csv" class="file btn btn-lg btn-sm btn-success rounded-pill py-2 px-4 mr-3"
                      style="font-size: 16px;" (click)="locationUpload.click()">
                      Import Locations
                      <input id="input_csv" type="file" style="display: none;" #locationUpload
                        (change)="importLocations($event)" name="file" accept=".csv" />
                    </div>
                    <div id="import_csv" class="file btn btn-lg btn-sm btn-success rounded-pill py-2 px-4 mr-3"
                      style="font-size: 16px;" (click)="ProductUpload.click()">
                      Import Brands
                      <input id="input_csv" type="file" style="display: none;" #ProductUpload
                        (change)="importProducts($event)" name="file" accept=".csv" />
                    </div>
                  </div>

                </div>
              </div>


            </form>
            <!-- end row -->
          </div>


          <div class="mt-3 p-3 bg-white mb-5">
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>region Id</th>
                  <th>region Name</th>
                  <th>region Name (Arabic)</th>
                  <th>Country Id</th>
                  <th>Country Name</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let region of Regions">
                  <td>{{ region.id }}</td>
                  <td>{{ region.name }}</td>
                  <td>{{ region.name_ar?region.name_ar:'Not Provided'}}</td>
                  <td>{{ region.country }}</td>
                  <td>{{ countries[0].id === region.country?countries[0].name:countries[0].name}}</td>
                  <td>
                    <a [routerLink]="" (click)="fetchregion(region)" style="color:'blue';">Edit</a>
                  </td>
                  <td>
                    <a [routerLink]="" (click)="removeregion(region.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>