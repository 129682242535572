<div class="left-side-menu">
  <!-- User box -->
  <div class="user-box text-center">
    <img src="{{profileImage}}" onerror="this.src='../../assets/images/user_icon.jpg';" alt="user-img" title="Mat Helme"
      class="rounded-circle img-thumbnail avatar-md">
    <br />
    <p class="mb-0 mt-2"><b>{{ fullname }}</b></p>
    <p class="text-muted">{{ role }}</p>

    <ul class="list-inline">
      <li class="list-inline-item">
        <a href="#" class="text-muted">
          <i class="mdi mdi-cog"></i>
        </a>
      </li>

      <li class="list-inline-item">
        <a href="#">
          <i class="mdi mdi-power"></i>
        </a>
      </li>
    </ul>
  </div>

  <!--- Sidemenu -->
  <div id="sidebar-menu" class="nav-side-menu">

    <ul class="menu-list" id="side-menu">
      <li class="menu-title">Navigation</li>
      <li *ngIf="isAdmin">
        <a routerLink="/dashboard">
          <img src="../../assets/images/Dashboard-icon.png" width="20" height="20" />
          <span> Dashboard </span>
        </a>
      </li>
      <li>
        <a routerLink="/snap-dashboard">
          <img src="../../assets/images/Snap-Dashboard-icon.png" width="20" height="20" />
          <span> SNAP Dashboard </span>
        </a>
      </li> 
      <!-- <li>
        <a routerLink="/merch-dashboard">
          <img src="../../assets/images/merch-Dashboard-icon.png" width="20" height="20" />
          <span> SMS Merchandizing</span>
        </a>
      </li> -->
      <li *ngIf="isAdmin">
        <a routerLink="/view-field-work">
          <img src="../../assets/images/Field-Work-Icon.png" width="20" height="20" />
          <span> Fieldwork </span>
        </a>
      </li>
      <li *ngIf="isAdmin">
        <a routerLink="/view-questionnaire">
          <img src="../../assets/images/Questionnaire-Icon.png" width="20" height="20" />
          <span> Questionnaires </span>
        </a>
      </li>
      <li data-toggle="collapse" data-target="#service" class="collapsed" *ngIf="isAdmin">
        <a>
          <img src="../../assets/images/Operations-icon.png" width="20" height="20" />
          <span> Operations </span> <span style="color: #333333;" class="arrow"></span>
        </a>
      </li>
      <ul class="sub-menu collapse" style="color: white;" id="service">
        <li [routerLink]="['/operations','channel']"><a>Channel</a></li>
        <li [routerLink]="['/operations','retailer']"><a>Retailer</a></li>
        <li [routerLink]="['/operations','branch']"><a>Branch</a></li>
        <li [routerLink]="['/operations','category']"><a>Category</a></li>
        <li [routerLink]="['/operations','brand']"><a>Brand</a></li>
        <li [routerLink]="['/operations','sku']"><a>SKU</a></li>
        <li [routerLink]="['/operations','questionnaire-type']"><a>Questionnaire Type</a></li>
      </ul>
      <li data-toggle="collapse" data-target="#snapmenus" class="collapsed" *ngIf="isAdmin">
        <a>
          <img src="../../assets/images/Snap-Operations-icon.png" width="20" height="20" />
          <span> SNAP Data </span> <span style="color: #333333;" class="arrow"></span>
        </a>
      </li>
      <ul class="sub-menu collapse" style="color: white;" id="snapmenus">
        <li [routerLink]="['/snap-data','price-monitor']"><a>Price Monitor</a></li>
        <li [routerLink]="['/snap-data','out-of-stock']"><a>Out of Stock </a></li>
        <li [routerLink]="['/snap-data','distribution-check']"><a>Distribution Check</a></li>
        <li [routerLink]="['/snap-data','consumer-check']"><a>Brand health Tracker</a></li>
      </ul>
      <li data-toggle="collapse" data-target="#snapOp" class="collapsed" *ngIf="isAdmin">
        <a>
          <img src="../../assets/images/Operations-icon.png" width="20" height="20" />
          <span> SNAP Operations </span> <span style="color: #333333;" class="arrow"></span>
        </a>
      </li>
      <ul class="sub-menu collapse" style="color: white;" id="snapOp">
        <li [routerLink]="['/snap-operations','city']"><a>City</a></li>
        <li [routerLink]="['/snap-operations','channel']"><a>Channel</a></li>
        <li [routerLink]="['/snap-operations','retailer']"><a>Retailer</a></li>
        <li [routerLink]="['/snap-operations','branch']"><a>Branch</a></li>
        <li [routerLink]="['/snap-operations','category']"><a>Category</a></li>
        <li [routerLink]="['/snap-operations','brand']"><a>Brand</a></li>
        <li [routerLink]="['/snap-operations','sku']"><a>SKU</a></li>
      </ul>  
    <!-- <li data-toggle="collapse" data-target="#merchOp" class="collapsed" *ngIf="isAdmin">
      <a>
        <img src="../../assets/images/Operations-merch.png" width="20" height="20" />
        <span> Merch Operations </span> <span style="color: #333333;" class="arrow"></span>
      </a>
    </li> -->
    <!-- <ul #menu class="sub-menu" [ngClass]="open_menu?'':'collapse'" style="color: white;" id="merchOp">
      <li [routerLink]="['/merch','users']" style="margin:20px 0px !important" [ngClass]="users?'grey':''"><a>Agents</a></li>
      <li [routerLink]="['/merch','audits']" style="margin:20px 0px !important" [ngClass]="audits?'grey':''"><a>Audits</a></li>
      <!-- <li [routerLink]="['/merch-operations','country']"><a>Country</a></li> -->
      <!-- <li [routerLink]="['/merch-operations','regions']" [ngClass]="regions?'grey':''"><a>Regions</a></li>
      <li [routerLink]="['/merch-operations','gov']" [ngClass]="gov?'grey':''"><a>GOV</a></li>
      <li [routerLink]="['/merch-operations','city']" [ngClass]="city?'grey':''"><a>City</a></li>
      <li [routerLink]="['/merch-operations','storename']" [ngClass]="store?'grey':''"><a>Store Name</a></li> 
      <li [routerLink]="['/merch-operations','branchname']" [ngClass]="branchna?'grey':''"><a>Branch name</a></li>
      <li [routerLink]="['/merch-operations','branchno']" [ngClass]="branchno?'grey':''"><a>Branch No</a></li>
      <li [routerLink]="['/merch-operations','storetype']" [ngClass]="storetype?'grey':''"><a>Store Type</a></li>
      <li [routerLink]="['/merch-operations','category']" [ngClass]="category?'grey':''"><a>Category</a></li>
      <li [routerLink]="['/merch-operations','subCategory']" [ngClass]="subcategory?'grey':''"><a>Subcategory</a></li>
      <li [routerLink]="['/merch-operations','brand']" [ngClass]="brand?'grey':''"><a>Brand</a></li>
      <li [routerLink]="['/merch-operations','SKU']" [ngClass]="sku?'grey':''"><a>SKU</a></li>
    </ul>   -->
    </ul>



  </div>
  <!-- End Sidebar -->

  <div class="clearfix"></div>
  <!-- Sidebar -left -->

</div>
