import { Component, OnInit, Output } from '@angular/core';
import { AuthService, LocalizeService, PortalService } from '../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-create-portal-user',
  templateUrl: './create-portal-user.component.html',
  styleUrls: ['./create-portal-user.component.scss']
})
export class CreatePortalUserComponent implements OnInit {

  public roles: Array<any> = [];
  public countries: Array<any> = [];

  public errorStatus: Boolean;
  public errorMessage: string;
  public successStatus: Boolean;
  public successMessage: string;

  public selectedRole: number;
  public selectedCountry = [];

  public fileToUpload: File = null;
  public fileUrl: any;

  id: string;
  isUpdateMode: boolean;
  submitted = false;
  dropdownSettings: IDropdownSettings;
  public storeNationality: any;


  constructor(private authService: AuthService,
    private localizeService: LocalizeService,
    private formBuilder: FormBuilder,
    private portalService: PortalService,
    private router: Router,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.getRoles()
    this.localizeService.getNationalities().then(data => {
      if (data.length > 0) {
        this.countries = data;
      } else {
        this.countries = [];
      }
    }).catch(err => {
      this.handleNotification('Error Fetching Countries.', true)
    });
    this.id = this.route.snapshot.params['id'] || null;
    this.isUpdateMode = this.id ? true : false;
    if (this.isUpdateMode) {
      this.getPortalUserById(this.id)
    }

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

  }

  getPortalUserById(id) {
    this.portalService.getSinglePortalUser(id)
      .subscribe(res => {
        if (res.success) {
          this.createPortalUser = this.formBuilder.group({
            username: [res.data?.username, Validators.required],
            email: [res.data?.email, Validators.compose([Validators.required, Validators.email])],
            fullname: [res.data?.fullname, Validators.required],
            nationality: [[res.data.nationality], Validators.required],
            contact_number: [res.data?.contact_number, Validators.compose([Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')])],
            date_of_birth: [this.formatDate(res.data?.date_of_birth), Validators.required],
            position: [res.data?.position, Validators.required],
            role: [res.data?.role?.id, Validators.required]
          });
          this.fileUrl = res.data.avatar;
          this.storeNationality = this.createPortalUser.value?.nationality;
          //this.createPortalUser.controls['nationality'].setValue(res.data.nationality[0].id);
        } else {
          this.handleNotification("Problem in fetching portal users!", true)
        }
      }, err => {
        this.handleNotification(err.error.error_message, true)
      })
  }

  createPortalUser = this.formBuilder.group({
    username: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required],
    fullname: ['', Validators.required],
    nationality: ['', Validators.required],
    contact_number: ['', Validators.compose([Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')])],
    date_of_birth: ['', Validators.required],
    position: ['', Validators.required],
    role: ['', Validators.required],
    confirm_password: ['']
  }, {
    validator: this.checkPasswords});

    checkPasswords(group: FormGroup) { 
      const password = group.get('password').value;
      const confirmPassword = group.get('confirm_password').value;
    
      return password === confirmPassword ? null : { notSame: true }     
    }

  onSubmit() {
    if (this.createPortalUser.invalid) {
      if (this.createPortalUser.value.nationality[0] == null) {
        this.createPortalUser.controls['nationality'].reset()
      }
      for (var i in this.createPortalUser.controls) {
        this.createPortalUser.controls[i].markAsDirty();
        this.createPortalUser.controls[i].updateValueAndValidity();
      }
      return;
    }
    this.storeNationality = this.createPortalUser.value?.nationality;
    this.createPortalUser.controls['nationality'].setValue(this.createPortalUser.value?.nationality[0]?.id);
    if (!this.isUpdateMode) {
      this.registerPortalUser();
    } else {
      if (this.createPortalUser.value.nationality == null) {
        this.createPortalUser.controls['nationality'].markAsDirty();
      } else {
        this.updatePortalUser();
      }

    }
  }

  resetForm() {
    this.createPortalUser.reset();
    this.router.navigate(['portal-users']);
  }

  registerPortalUser() {
    Swal.showLoading();

    // add profile image to form data
    let formData = new FormData();

    if (this.fileToUpload) {
      formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
    }

    // add other form fields to form data
    let otherFormData = this.createPortalUser.value;
    for (let formControlValue in otherFormData) {
      formData.append(formControlValue, otherFormData[formControlValue]);
    }

    this.portalService.registerPortalUser(formData)
      .subscribe(res => {
        Swal.hideLoading();
        if (res.success) {
          this.handleNotification(res.data.message, false);
          this.router.navigate(['portal-users']);
        } else {
          this.handleNotification(res.error.error_message, true);
          this.createPortalUser.controls['nationality'].setValue(this.storeNationality);
        }
      }, err => {
        Swal.hideLoading();
        this.handleNotification(err.error.error_message, true);
        this.createPortalUser.controls['nationality'].setValue(this.storeNationality);
      });
  }

  updatePortalUser() {
    Swal.showLoading();
    // add profile image to form data
    let formData = new FormData();

    if (this.fileToUpload) {
      formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
    }

    // add other form fields to form data
    let otherFormData = this.createPortalUser.value;
    for (let formControlValue in otherFormData) {
      formData.append(formControlValue, otherFormData[formControlValue]);
    }
    
    this.portalService.updatePortalUser(formData, this.id)
      .subscribe(
        res => {
          Swal.hideLoading()
          this.handleNotification(res.data.message, false);
        },
        err => {
          Swal.hideLoading()
          this.handleNotification(err.error.error_message, true)
          this.createPortalUser.controls['nationality'].setValue(this.storeNationality);
        }
      )
  }

  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.fileToUpload = <File>event.target.files[0];
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.fileUrl = event.target.result;

      }
    }
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message,
      }).then(() => {
        this.router.navigate(['portal-users'])
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  public getRoles() {
    this.authService.getRoles().subscribe(res => {
      if (res.success) {
        this.roles = res.data.results;
      } else {
        this.errorStatus = true;
        this.errorMessage = "Error fetching Roles";
      }
    })
  }

  formatDate(date: string) {
    if (date) {
      let year = date.split('-')[2]
      let month = date.split('-')[1]
      let day = date.split('-')[0]

      return `${year}-${month}-${day}`;
    }
  }
}
