import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject } from 'rxjs';
import { SnapOperationService } from 'src/app/_services/snap-operation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-snap-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class SnapBrandComponent implements OnInit {

  brandForm: FormGroup;
  brandIdToUpdate: Number = null;

  categories: Array<any> = [];
  brands: Array<any> = [];

  accessToken: String;

  constructor(
    private fb: FormBuilder,
    private operationService: SnapOperationService
  ) { }

  dropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {
    this.listBrands();

    this.accessToken = localStorage.getItem('accessToken');

    this.brandForm = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.dtOptions = {
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true
    }

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listBrands() {
    Swal.showLoading();
    this.operationService.getBrands()
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            let brands = response.data?.results;
            this.brands = brands.length > 0 ? brands : [];
            this.rerender()
          } else {
            this.handleNotification(response.error.error_message || "Problem in fetching brands!", true);
          }
        },
        error => {
          Swal.close()
          this.handleNotification(error.error.error_message || "Problem in fetching stores!", true)
        }
      )
  }

  updateBrand() {
    if (this.brandForm.invalid) {
      for (var i in this.brandForm.controls) {
        this.brandForm.controls[i].markAsDirty();
        this.brandForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      // format values as required by api
      let formattedFormValues = this.brandForm.value;
      for (let formValue in formattedFormValues) {
        if (formValue == "category")
          formattedFormValues[formValue] = formattedFormValues[formValue] && formattedFormValues[formValue][0].id;
      }

      if (this.brandIdToUpdate) {
        this.operationService.updateBrand(this.brandIdToUpdate, this.brandForm.value)
          .subscribe(response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "Brand updated successfully!"
              }).then(() => {
                this.brandIdToUpdate = null;
                this.listBrands();
                this.brandForm.reset();
                this.brandForm.markAsPristine();
                this.brandForm.markAsUntouched();
              })
            } else {
              this.handleNotification(response.error_message || "Problem in updating brand!", true);
            }
          }, error => {
            Swal.close();
            this.handleNotification(error.error.error_message || "Problem in updating brand!", true);
          })
      }
    }
  }

  removeBrand(brandId) {
    this.operationService.removeBrand(brandId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Retailer deleted successfully!"
        }).then(res => {
          this.listBrands();
        })
      })
      .catch(error => {
        this.handleNotification("Something went wrong while deleting retailer!", true);
      })
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  // helper methods

  fetchBrand(brand) {
    this.brandIdToUpdate = brand.id || null;
    this.brandForm = this.fb.group({
      name: [brand.name, [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.brandForm.reset();
    this.brandForm.markAsPristine();
    this.brandForm.markAsUntouched();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
