<!DOCTYPE html>
<html lang="en">

<body>
    <div id="wrapper">
        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
        <!-- end Topbar -->
    </div>
    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">



        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div class="mb-0">
                        <app-top-title [title]="'Add branchno to Operations'"
                            [subTitle]="'You can add Branchno to operations from here'"></app-top-title>
                    </div>
                    <div class="bg-white">

                        <form  class="" role="form" [formGroup]="branchnoForm"
                            (ngSubmit)="updating?updatebranchno():addBranchno()">
                            <div class="row p-4">

                                <div class="col-md-4" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="branch"> Branch <span
                                                style="color:#ff0000">*</span></label>
                                        <ng-multiselect-dropdown name="branch" id="selBranch"
                                            [placeholder]="'Select Branch'" [data]="branches"
                                            [settings]="branchDropdownSettings"
                                            [formControl]="branchnoForm.controls['brancheshow']"
                                            [class.valid]="branchnoForm.get('brancheshow').valid &&
                                                                (branchnoForm.get('brancheshow').dirty || branchnoForm.get('brancheshow').touched)"
                                            [class.invalid]="branchnoForm.get('brancheshow').invalid &&
                                                                (branchnoForm.get('brancheshow').dirty || branchnoForm.get('brancheshow').touched)">
                                        </ng-multiselect-dropdown>

                                        <div
                                            *ngIf="branchnoForm.get('brancheshow').invalid &&
                                                                   branchnoForm.get('brancheshow').errors &&
                                                                   (branchnoForm.get('brancheshow').dirty || branchnoForm.get('brancheshow').touched)">
                                            <small class="text-danger"
                                                *ngIf="branchnoForm.get('brancheshow').hasError('required')">
                                                Branch is required. </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="branchno">branchno<span
                                                style="color:#ff0000">*</span></label>
                                        <input placeholder="Enter Branchno Name" type="text" class="form-control"
                                            id="branchno" formControlName="name" [class.valid]="branchnoForm.get('name').valid &&
                        (branchnoForm.get('name').dirty || branchnoForm.get('name').touched)" [class.invalid]="branchnoForm.get('name').invalid &&
                        (branchnoForm.get('name').dirty || branchnoForm.get('name').touched)">
                                        <div
                                            *ngIf="branchnoForm.get('name').invalid && branchnoForm.get('name').errors && (branchnoForm.get('name').dirty || branchnoForm.get('name').touched)">
                                            <small class="text-danger"
                                                *ngIf="branchnoForm.get('name').hasError('required')">
                                                branchno name is required. </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="branchno">branchno (Arabic)</label>
                                        <input placeholder="Enter Branchno Name (Arabic)" type="text" class="form-control"
                                            id="branchno" formControlName="name_ar" [class.valid]="branchnoForm.get('name_ar').valid &&
                        (branchnoForm.get('name_ar').dirty || branchnoForm.get('name_ar').touched)"
                                            [class.invalid]="branchnoForm.get('name_ar').invalid &&
                        (branchnoForm.get('name_ar').dirty || branchnoForm.get('name_ar').touched)">
                                    </div>
                                </div>
                            </div>

                            <div class="row px-5 pb-3 d-flex">
                                <div class="form-group">
                                    <button type="submit"
                                        class="btn btn-success px-5 mr-2">{{updating?'Update':'Add'}}</button>
                                    <button (click)="toggleUpdateMode()" type="button"
                                        class="btn btn-danger px-5">Cancel</button>
                                </div>
                            </div>

                        </form>
                        <!-- end row -->
                    </div>


                    <div class="mt-3 p-3 bg-white mb-5">
                        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>branchno Id</th>
                                    <th>Branch Name</th>
                                    <th>branchno Name</th>
                                    <th>branchno Name (Arabic)</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let branchno of Branchnos">
                                    <td>{{ branchno.id }}</td>
                                    <td>{{ getappropriatename(branchno.branch)}}</td>  
                                    <td>{{ branchno.name }}</td>
                                    <td>{{ branchno.name_ar }}</td>
                                    <td>
                                        <a [routerLink]="" (click)="fetchbranchno(branchno)"
                                            style="color:'blue';">Edit</a>
                                    </td>
                                    <td>
                                        <a [routerLink]="" (click)="removebranchno(branchno.id)"
                                            style="color:'red';"> Delete </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->

</body>

</html>