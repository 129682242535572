<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Field Work'"  (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="pageTitle"
              [subTitle]="'You can view list of questionnaires for the agent.'"></app-top-title>
          </div>
          <div class="mt-2 p-2" style="background-color: white;">
            <!-- end row -->
            <table  class="myTable wrap"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="position: relative;">
              <thead>
                <tr>
                  <th>Questionnaire ID</th>
                  <th>Questionnaire</th>
                  <th>Questionnaire Type</th>
                  <th>Country</th>
                  <th>City</th>
                  <th>Completion Date</th>
                  <th>Starting Time</th>
                  <th>Finishing Time</th>
                  <th>Duration</th>
                  <th>GPS</th>
                  <th>Channel</th>
                  <th>Retailer</th>
                  <th>Store</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fwq of fieldWorkersQuestionnaire">
                  <td data-column="Questionnaire ID"><a routerLink="/single-questionnaire/{{ fwq.response }}/">{{ fwq.questionnaire_id }}</a></td>
                  <td data-column="Questionnaire"><a routerLink="/single-questionnaire/{{ fwq.response }}/">{{ fwq.questionnaire_name }}</a></td>
                  <td data-column="Questionnaire Type">{{ fwq.questionnaire_type }}</td>
                  <td data-column="Country">{{ fwq.country}}</td>
                  <td data-column="City">{{ fwq.city }}</td>
                  <td data-column="Completion Date">{{ fwq.completed_date == null ? 'Not Completed' : fwq.completed_date }}</td>
                  <td data-column="Starting Time">{{ fwq.start_time == null ? 'Not Started' : fwq.start_time }}</td>
                  <td data-column="Finishing Time">{{ fwq.finish_time == null ? 'Not Completed' : fwq.finish_time }}</td>
                  <td data-column="Dutration">{{ fwq.completed_duration == null ? 'No Duration' : fwq.completed_duration }}</td>
                  <td data-column="GPS">{{ fwq.gps == null ? 'No GPS' : fwq.gps }}</td>
                  <td data-column="Channel">{{ fwq.channel }}</td>
                  <td data-column="Retailer Name">{{ fwq.retailer }}</td>
                  <td data-column="Store">{{ fwq.store }}</td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
          <br /><br />

        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->


</body>

</html>
