import { ExtraHeader } from '../_shared/shared.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SharedService } from '../_shared/shared.service';
import Swal from 'sweetalert2';


export interface PortalUser {
  id?: number;
  email: string;
  username: string;
  fullname: string;
  nationality: number;
  contact_number: string;
  date_of_birth: string;
  position: string;
  role: number;
}

export interface UploadData {
  data: Object,
  agent_user_id : string;
}

@Injectable()
export class PortalService {
  private extraHeader: ExtraHeader[] = [
  {
    headerName: "Content-Type",
    headerValue: "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
  }
]
  constructor(
    private http: HttpClient,
    public shareService: SharedService) { }

  getPortalUser() {
    return this.shareService.get(`${environment.baseUrl}/user/portal-user/list`);
  }

  getSinglePortalUser(user_id) {
    return this.shareService.get(`${environment.baseUrl}/user/portal-user/${user_id}/detail`)
  }

  registerPortalUser(userData) {
    
    return this.shareService.post(`${environment.baseUrl}/user/portal-user/register`, userData, this.extraHeader);
  }

  updatePortalUser(userData, user_id) {
    return this.shareService.put(`${environment.baseUrl}/user/portal-user/${user_id}/update`, userData, this.extraHeader);
  }

  removePortalUser(user_id) {
    return this.shareService.delete(`${environment.baseUrl}/user/portal-user/${user_id}/delete`)
  }

  deletePortalUser(userId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/user/portal-user/${userId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

}