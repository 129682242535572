import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SharedService } from '../_shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class MerchOperationService {

  constructor(
    private sharedService: SharedService
  ) { }


  importLocations(Data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/csv/add`, Data)
  }
  importProducts(brandData) {
    return this.sharedService.post(`${environment.baseUrl}/merch/csv/add/product`, brandData)
  }
  getaudits() {
    return this.sharedService.get(`${environment.baseUrl}/merch/submit-servey/general-list`)
  }

  //country
  getCountries() {
    return this.sharedService.get(`${environment.baseUrl}/merch/country/list`);
  }

  //city APIs

  getRegions() {
    return this.sharedService.get(`${environment.baseUrl}/merch/region/list`);
  }

  getSKUs() {
    return this.sharedService.get(`${environment.baseUrl}/merch/sku/list`);
  }


  removeSKUs(skuId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/sku/${skuId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  updateSku(skuId, data) {
    return this.sharedService.put(`${environment.baseUrl}/merch/sku/${skuId}/update`, data);
  }
  addSku(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/sku/add`, data);
  }


  updateRegion(regionId, cityData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/region/${regionId}/update`, cityData);
  }

  addRegion(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/region/add`, data)
  }
  removeRegion(regionId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/region/${regionId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  //governates APIs

  getGovernorates() {
    return this.sharedService.get(`${environment.baseUrl}/merch/governorate/list`);
  }

  updateGovernorate(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/governorate/${governorateId}/update`, governorateData);
  }

  addGovernorate(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/governorate/add`, data)
  }
  removeGovernorate(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/governorate/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  //city APIs
  getCities() {
    return this.sharedService.get(`${environment.baseUrl}/merch/city/list`);
  }

  updateCity(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/city/${governorateId}/update`, governorateData);
  }

  addCity(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/city/add`, data)
  }
  removeCity(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/city/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  //city APIs
  getbrand() {
    return this.sharedService.get(`${environment.baseUrl}/merch/brand/list`);
  }

  updatebrand(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/brand/${governorateId}/update`, governorateData);
  }

  addbrand(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/brand/add`, data)
  }
  removebrand(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/brand/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  //store APIs
  getStores() {
    return this.sharedService.get(`${environment.baseUrl}/merch/store/list`);
  }

  updateStore(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/store/${governorateId}/update`, governorateData);
  }

  addStore(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/store/add`, data)
  }
  removeStore(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/store/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  //store APIs
  getStoretypes() {
    return this.sharedService.get(`${environment.baseUrl}/merch/storetype/list`);
  }

  updateStoretype(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/storetype/${governorateId}/update`, governorateData);
  }

  addStoretype(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/storetype/add`, data)
  }
  removeStoretype(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/storetype/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  //store APIs
  getCategory() {
    return this.sharedService.get(`${environment.baseUrl}/merch/category/list`);
  }

  updateCategory(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/category/${governorateId}/update`, governorateData);
  }

  addCategory(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/category/add`, data)
  }
  removeCategory(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/category/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  //store APIs
  getsubCategories() {
    return this.sharedService.get(`${environment.baseUrl}/merch/subcategory/list`);
  }

  updatesubCategory(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/subcategory/${governorateId}/update`, governorateData);
  }

  addsubCategory(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/subcategory/add`, data)
  }
  removesubCategory(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/subcategory/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  // // branch APIs
  getbranchs() {
    return this.sharedService.get(`${environment.baseUrl}/merch/branch/list`);
  }

  updatebranch(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/branch/${governorateId}/update`, governorateData);
  }

  addbranch(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/branch/add`, data)
  }
  removebranch(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/branch/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  // // branch APIs
  getbranchnos() {
    return this.sharedService.get(`${environment.baseUrl}/merch/branchno/list`);
  }

  updatebranchno(governorateId, governorateData) {
    return this.sharedService.put(`${environment.baseUrl}/merch/branchno/${governorateId}/update`, governorateData);
  }

  addbranchno(data) {
    return this.sharedService.post(`${environment.baseUrl}/merch/branchno/add`, data)
  }
  removebranchno(governorateId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/merch/branchno/${governorateId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }
  // getBranches() {
  //   return this.sharedService.get(`${environment.baseUrl}/snap/snap-store/list?ordering=name`);
  // }

  // removeStore(storeId, accessToken) {
  //   return new Promise((resolve, reject) => {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Are you sure want to delete?',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'Delete',
  //       showLoaderOnConfirm: true,
  //       preConfirm: function () {
  //         $.ajax({
  //           headers: {
  //             'Authorization': `JWT ${accessToken}`
  //           },
  //           url: `${environment.baseUrl}/snap/snap-store/${storeId}/delete`,
  //           type: 'DELETE',
  //           dataType: 'json'
  //         })
  //           .done(function (response) {
  //             resolve(true);
  //           })
  //           .fail(function () {
  //             reject(false);
  //           });
  //       }
  //     });
  //   })
  // }
  // updateStore(storeId, StoreData) {
  //   return this.sharedService.put(`${environment.baseUrl}/snap/snap-store/${storeId}/update`, StoreData);
  // }
}