import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from "sweetalert2";

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FieldWorkerService } from 'src/app/_services/field-worker.service';

@Component({
    selector: 'app-merch-agents',
    templateUrl: './merch-agents.component.html',
    styleUrls: ['./merch-agents.component.scss']
})
export class MerchAgentsComponent implements OnInit {

    @ViewChild(DataTableDirective, { static: false })
    public dtElement: DataTableDirective;
    public dtOptions: any = {};
    public dtTrigger: Subject<any> = new Subject();

    public erroStatus: Boolean;
    public successStatus: Boolean;
    public fieldWorkers = [];
    accessToken: string;

    // variables used during exporting user agents data

    agentUsersData: any[];
    fileHeaders = ['fullname', 'id', 'operation_city', 'operation_country', 'total_completed_questionnaire', 'username'];
    csvExportFileName = 'fieldwork_agents';

    constructor(
        private fieldService: FieldWorkerService
    ) { }

    ngOnInit(): void {
        this.getFieldWorkers()
        this.accessToken = localStorage.getItem('accessToken')
    }

    random() {
        var randomNumber = Math.floor(Math.random() * 11) + 10;
        return randomNumber;
    }

    ngAfterViewInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            dom: '<"top"<"left-col"B><"center-col"l><"right-col">>rtip',
            buttons: [
                {
                    extend: 'csvHtml5',
                    text: 'Export',
                    title: 'field_worker_agents',
                    className: ' mr-1 btn btn-info float-right',
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4]
                    }
                }
            ],
            order: true,
            sort: false, //this will completely disable sorting for later uses as well
            columnDefs: [
                {
                    targets: [0], //first column / numbering column
                    orderable: false, //set not orderable
                    sortable: false
                },
            ],
            stateSave: true,
            stateDuration: -1,
            stateSaveCallback: function (settings, data) {
                const ls = JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance + window.location.pathname))
                if (ls) {
                    if (ls.start !== data.start)
                        localStorage.setItem('DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data))
                } else {
                    localStorage.setItem('DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data))
                }

            },
            stateLoadCallback: function (settings) {
                return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance + window.location.pathname))
            }
        };
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }

    getFieldWorkers() {
        Swal.showLoading();
        this.fieldService.getFieldUsers()
            .subscribe(res => {
                Swal.close();
                if (res.success) {
                    this.fieldWorkers = res.data.results;
                    this.rerender();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: res.error.error_message,
                        timer: 1500
                    });
                }
            }, error => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: error.error_message || 'Something went wrong in fetching field works!',
                    timer: 1500
                });
            })
    }

    removeFieldWorkerById(userId) {
        this.fieldService.deleteFieldWorkerById(userId, this.accessToken)
            .then(res => {

                Swal.fire({
                    icon: "success",
                    titleText: "Field work agent deleted successfully!"
                }).then(() => {
                    this.getFieldWorkers();
                })
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    titleText: "Something went wrong while deleting field work agent!"
                })
            })
    }



    searchText(searchText: any) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search(searchText.data).draw();
        });
    }

    importFieldWorkAgents(event) {

        let file = event.target.files[0];
        if (file) {

            Swal.showLoading();
            // check if file has valid extension
            let fileExtension = file.name.split('.')[1];
            if (fileExtension !== 'csv') {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Please upload csv file!'
                });
                return;
            }

            // check valid file size
            let size = file.size / (1024 * 1024);
            if (size > 4) {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Please only upload file less than 4MB !'
                });
                return;
            }

            // send data to api
            let csvData = new FormData();
            csvData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
            this.fieldService.parseCsvData(csvData)
                .subscribe(res => {
                    Swal.close()
                    if (res.success) {
                        Swal.fire({
                            icon: 'success',
                            title: res.data.message
                        }).then(() => {
                            this.getFieldWorkers();
                            event.target.value = '';
                        })
                    } else {
                        event.target.value = '';
                        Swal.fire({
                            icon: 'error',
                            text: res.error_message || "Something went wrong while importing the file!"
                        });
                    }
                }, err => {
                    event.target.value = '';
                    Swal.close()
                    Swal.fire({
                        icon: 'error',
                        text: err.error.error_message || "Something went wrong while importing the file!"
                    });
                });
        }

    }
}
