<div class="hero-image" style="height: 100%;">
    <!-- <div class="hero-text">
      <h1 style="font-style: Georgia; color: #fff; font-size:50px;">Canada Goose</h1>
      <p>spring styles have arrived</p>
      <button>SHOP NOW</button>
    </div> -->
    <div class="hero-exclusions">

        <div class="account-pages mt-5 mb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5 center-div">
                        <div class="text-center">
                            <!-- <a href="index.html" class="logo">
                                <img src="assets/images/logo-light.png" alt="" height="22" class="logo-light mx-auto">
                               <img src="assets/images/logo-dark.png" alt="" height="22" class="logo-dark mx-auto">
                            </a> -->
                            <!-- <p class="text-muted mt-2 mb-4">Responsive Admin Dashboard</p> -->
                        </div>
                        <div class="card">

                            <div class="card-body p-4">

                                <div class="text-center mb-4">
                                    <h4 class="mt-0 mb-3">Reset Password</h4>
                                    <p class="text-muted mb-0 font-13">Enter your email address and we'll send you an
                                        email with instructions to reset your password. </p>
                                </div>
                                <div class="alert alert-danger" role="alert" *ngIf="invalidForm">
                                    {{errorMessage}}
                                </div>
                                <div class="alert alert-info" role="alert" *ngIf="!invalidForm && invalidForm != null">
                                    {{successMessage}}
                                </div>
                                <form action="#" (ngSubmit)="forgotPassword()" [formGroup]="forgotPwdForm">

                                    <div class="form-group mb-3">
                                        <label for="emailaddress" class="font-weight-bold">Email address</label>
                                        <input class="form-control" type="email" id="emailaddress" required=""
                                            placeholder="Enter your email" formControlName="email"
                                            [class.valid]="forgotPwdForm.get('email').valid && 
                                                                (forgotPwdForm.get('email').dirty || forgotPwdForm.get('email').touched)"
                                            [class.invalid]="forgotPwdForm.get('email').invalid && 
                                                                (forgotPwdForm.get('email').dirty || forgotPwdForm.get('email').touched)">
                                        <div
                                            *ngIf="forgotPwdForm.get('email').invalid && 
                                                                   forgotPwdForm.get('email').errors && 
                                                                   (forgotPwdForm.get('email').dirty || forgotPwdForm.get('email').touched)">
                                            <small class="text-danger"
                                                *ngIf="forgotPwdForm.get('email').hasError('required')">
                                                Email is required. </small>
                                        </div>
                                    </div>

                                    <div class="form-group mb-0 text-center">
                                        <button class="btn btn-block" type="submit"
                                            style="background-color: '#afcb3a';color:white"> Reset Password </button>
                                    </div>

                                </form>

                            </div> <!-- end card-body -->
                        </div>
                        <!-- end card -->

                        <div class="row mt-3">
                            <div class="col-12 text-center">
                                <p class="text-muted">Back to <a routerLink="/" class="ml-1"><b class="green-color">Log
                                            in</b></a></p>
                            </div> <!-- end col -->
                        </div>
                        <!-- end row -->

                    </div> <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- <div id="popup1" class="overlay">
      <div class="popup">
        <a class="close" href="#">&times;</a>
        <div class="content">
          <br><br>
          <h2>Promotional Information & Exclusions</h2>
          This is a list of exclusions
          <br><br><br>
        </div>
      </div>
    </div> -->
        <!--lightbox style popup goes here-->
    </div>