<div class="py-2" style="background-color: 'white';margin-bottom: '10px';">

    <form action="" [formGroup]="dashboardForm" (ngSubmit)="onFilterSubmit()">
        <div class="row p-3">
            <!-- <div class="col-md-6">
  
          <label class=" col-form-label">Date Range: <span style="color:#ff0000">*</span> </label>
          <input id="daterangepicker" class="form-control" startKey="date_after" endKey="date_before"
             opens="right" drops="down" emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd (datesUpdated)="datesUpdated($event)"
            [formControl]="dashboardForm.controls['date_range']" [locale]="{'format': 'MM/DD/YYYY'}"
             [alwaysShowCalendars]="true"  [linkedCalendars]="true"
            [showDropdowns]="true" [showRangeLabelOnInput]="showRangeLabelOnInput"
            placeholder="Select the date range..." [class.valid]="dashboardForm.get('date_range').valid &&
            (dashboardForm.get('date_range').dirty || dashboardForm.get('date_range').touched)" [class.invalid]="dashboardForm.get('date_range').invalid &&
            (dashboardForm.get('date_range').dirty || dashboardForm.get('date_range').touched)">
          <div
            *ngIf="dashboardForm.get('date_range').invalid && dashboardForm.get('date_range').errors && (dashboardForm.get('date_range').dirty || dashboardForm.get('date_range').touched)">
            <small class="text-danger" *ngIf="dashboardForm.get('date_range').hasError('required')">
              Date is required. </small>
          </div>
        </div> -->

            <!--      project type-->
            <div class=" col-md-3" style="width: 100%">
                <div class="form-group">
                    <label class="col-form-label" for="questionnaire_type">Project Type: <span
                            style="color:#ff0000">*</span></label>
                    <select class="form-control" formControlName="questionnaire_type"
                        (change)="onQnnTypeChange($event)">
                        <option *ngFor="let questionnaireType of questionnaire_types" [value]="questionnaireType.id">
                            {{questionnaireType.name}}</option>
                    </select>
                </div>
            </div>

            <!--country-->
            <div class=" col-md-3" style="width: 100%">
                <div class="form-group">
                    <label class=" col-form-label">Country: </label>
                    <ng-multiselect-dropdown style="max-width: fit-content;" name="country" id="selCountry"
                        [placeholder]="'Select Country'" (onSelect)="onCountrySelect($event)"
                        (onDeSelect)="onCountryDeSelect($event)" [data]="countries" [settings]="datedropdownSettings"
                        [formControl]="dashboardForm.controls['country']" (onSelectAll)="onCountrySelectAll($event)"
                        (onDeSelectAll)="onCountryDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>  

            <!-- Region-->
            <div class=" col-md-3" style="width: 100%">
                <div class="form-group">
                    <label class=" col-form-label">Region: </label>
                    <ng-multiselect-dropdown style="max-width: fit-content;" name="region" id="selRegion"
                        [placeholder]="'Select Region'" (onSelect)="onMonthSelect($event)"
                        (onDeSelect)="onMonthDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="regions"
                        [formControl]="dashboardForm.controls['region']" (onSelectAll)="onMonthSelectAll($event)"
                        (onDeSelectAll)="onMonthDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
             <!-- Gov-->
             <div class=" col-md-3" style="width: 100%">
                <div class="form-group">
                    <label class=" col-form-label">Governate:</label>
                    <ng-multiselect-dropdown style="max-width: fit-content;" name="gov" id="selGov"
                        [placeholder]="'Select Gov'" (onSelect)="onMonthSelect($event)"
                        (onDeSelect)="onMonthDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="gov"
                        [formControl]="dashboardForm.controls['gov']" (onSelectAll)="onMonthSelectAll($event)"
                        (onDeSelectAll)="onMonthDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <!--      month-->
            <div class=" col-md-3" style="width: 100%">
                <div class="form-group">
                    <label class=" col-form-label">Month: </label>
                    <ng-multiselect-dropdown style="max-width: fit-content;" name="month" id="selMonth"
                        [placeholder]="'Select Month'" (onSelect)="onMonthSelect($event)"
                        (onDeSelect)="onMonthDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="months"
                        [formControl]="dashboardForm.controls['month']" (onSelectAll)="onMonthSelectAll($event)"
                        (onDeSelectAll)="onMonthDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <!-- Branch name-->
            <div class=" col-md-3" style="width: 100%">
                <div class="form-group">
                    <label class=" col-form-label">Branch name: </label>
                    <ng-multiselect-dropdown style="max-width: fit-content;" name="branch_name" id="selBranch"
                        [placeholder]="'Select Branch name'" (onSelect)="onMonthSelect($event)"
                        (onDeSelect)="onMonthDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="branch_name"
                        [formControl]="dashboardForm.controls['brach']" (onSelectAll)="onMonthSelectAll($event)"
                        (onDeSelectAll)="onMonthDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <!-- Branch no-->
            <div class=" col-md-3" style="width: 100%">
                <div class="form-group">
                    <label class=" col-form-label">Branch number: </label>
                    <ng-multiselect-dropdown style="max-width: fit-content;" name="branch_no" id="selBranch_no"
                        [placeholder]="'Select branch number'" (onSelect)="onMonthSelect($event)"
                        (onDeSelect)="onMonthDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="branch_no"
                        [formControl]="dashboardForm.controls['branch_no']" (onSelectAll)="onMonthSelectAll($event)"
                        (onDeSelectAll)="onMonthDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <!--      category-->
            <div class="col-md-3">
                <div class="form-group">
                    <label class="col-form-label" for="category">Category: </label>
                    <ng-multiselect-dropdown name="category" id="selCategory" [placeholder]="'Select Category'"
                        [data]="categories" [settings]="dropdownSettings" (onSelect)="onCategorySelect($event)"
                        (onSelectAll)="onCategorySelectAll($event)" (onDeSelectAll)="onCategoryDeSelectAll($event)"
                        (onDeSelect)="onCategoryDeSelect($event)" [formControl]="dashboardForm.controls['category']">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <!-- sub category-->
            <div class="col-md-3">
                <div class="form-group">
                    <label class="col-form-label" for="subcategory">SubCategory: </label>
                    <ng-multiselect-dropdown name="subcategory" id="selsubCategory" [placeholder]="'Select SubCategory'"
                        [data]="subCategories" [settings]="dropdownSettings" (onSelect)="onCategorySelect($event)"
                        (onSelectAll)="onCategorySelectAll($event)" (onDeSelectAll)="onCategoryDeSelectAll($event)"
                        (onDeSelect)="onCategoryDeSelect($event)" [formControl]="dashboardForm.controls['subcategory']">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <!--      channel-->
            <div class="col-md-3">
                <div class="form-group">
                    <label class="col-form-label" for="channel">Store type: </label>
                    <ng-multiselect-dropdown name="channel" id="selChannel" [placeholder]="'Select store type'"
                        [data]="channels" (onSelect)="onChannelSelect($event)" (onDeSelect)="onChannelDeSelect($event)"
                        (onSelectAll)="onChannelSelectAll($event)" (onDeSelectAll)="onChannelDeSelectAll($event)"
                        [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['channel']">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <!-- brand -->
            <div class="col-md-3">
                <div class="form-group">
                    <label class="col-form-label" for="brand">Brand: </label>
                    <ng-multiselect-dropdown name="brand" id="selBrand" [placeholder]="'Select Brand'" [data]="brands"
                        [settings]="dropdownMultiselectSettings" (onSelect)="onBrandSelect($event)"
                        (onDeSelect)="onBrandDeSelect($event)" (onSelectAll)="onBrandSelectAll($event)"
                        (onDeSelectAll)="onBrandDeSelectAll($event)" [formControl]="dashboardForm.controls['brand']">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <!--      sku-->
            <div class="col-md-3">
                <div class="form-group">
                    <label class="col-form-label" for="sku">SKU: </label>
                    <ng-multiselect-dropdown name="sku" id="selSKU" [placeholder]="'Select SKU'" [data]="skus"
                        (onSelect)="onSkuSelect($event)" (onDeSelect)="onSkuDeSelect($event)"
                        (onSelectAll)="onSkuSelectAll($event)" (onDeSelectAll)="onSkuDeSelectAll($event)"
                        [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['sku']">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <div class="col-md-3 d-flex align-items-center pt-3">
                <button type="submit" class="btn btn-info mr-2">Apply Filters</button>
                <button type="button" (click)="resetFilters()" class="btn btn-danger">Reset Filters</button>
            </div>

            <div class="col-md-3 d-flex align-items-center pt-3"></div>
            <div class="col-md-3 d-flex align-items-center pt-3"></div>
            <div class="col-md-3 d-flex align-items-center justify-content-center pt-3">
                <button type="button" (click)="viewCSV()" class="btn btn-primary" data-toggle="modal"
                    data-target="#csvmodal" href="#">View CSV</button>
            </div>

        </div>
    </form>

</div>

<div class="modal fade" id="csvmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width:90%">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">CSV Table Data</h5>
                <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body p-4">
                <div class="float-right">

                    <div *ngIf="currentQuestionnaireType === 'Price Monitor'" id="export_csv"
                        class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2"
                        style="font-size: 0.875rem;margin-bottom: 10px;"
                        (click)="exportData('price-monitor','price_monitor_merch.csv')">
                        Export
                    </div>
                    <div *ngIf="currentQuestionnaireType === 'Out Of Stock'" id="export_csv"
                        class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2"
                        style="font-size: 0.875rem;margin-bottom: 10px"
                        (click)="exportData('out-of-stock','out_of_stock_merch.csv')">
                        Export
                    </div>
                    <div *ngIf="currentQuestionnaireType === 'Distribution Check'" id="export_csv"
                        class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2"
                        style="font-size: 0.875rem;margin-bottom: 10px"
                        (click)="exportData('distribution','distribution_merch.csv')">
                        Export
                    </div>
                    <div *ngIf="currentQuestionnaireType === 'Brand Health Tracker'" id="export_csv"
                        class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2"
                        style="font-size: 0.875rem;margin-bottom: 10px"
                        (click)="exportData('consumer','consumer_merch.csv')">
                        Export
                    </div>
                </div>
                <table class="custom-table" *ngIf="currentQuestionnaireType === 'Price Monitor'" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>Channel</th>
                            <th>Category</th>
                            <th>Brand</th>
                            <th>SKU</th>
                            <th>Count</th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Mean</th>
                            <th>Mode</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let snapDatum of snapData">
                            <td> {{ snapDatum?.id }}</td>
                            <td> {{ snapDatum?.date }}</td>
                            <td> {{ snapDatum?.country }}</td>
                            <td> {{ snapDatum?.city }}</td>
                            <td> {{ snapDatum?.channel }}</td>
                            <td> {{ snapDatum?.category }}</td>
                            <td> {{ snapDatum?.brand }}</td>
                            <td> {{ snapDatum?.sku }}</td>
                            <td> {{ snapDatum?.count }}</td>
                            <td> {{ snapDatum?.min }}</td>
                            <td> {{ snapDatum?.max }}</td>
                            <td> {{ snapDatum?.mean }}</td>
                            <td> {{ snapDatum?.mode }}</td>
                        </tr>
                    </tbody>


                </table>
                <table class="custom-table" *ngIf="currentQuestionnaireType === 'Out Of Stock'" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>Channel</th>
                            <th>Category</th>
                            <th>Brand</th>
                            <th>SKU</th>
                            <th>Count</th>
                            <th>Not Available in Month</th>
                            <th>Less Available in Month</th>
                            <th>Available in Month</th>
                            <th>Not available by store</th>
                            <th>Less available by store</th>
                            <th>Available by store</th>
                            <th>Not available by city</th>
                            <th>Less available by city</th>
                            <th>Available by City</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let snapDatum of snapData">
                            <td> {{ snapDatum?.id }}</td>
                            <td> {{ snapDatum?.date }}</td>
                            <td> {{ snapDatum?.country }}</td>
                            <td> {{ snapDatum?.city }}</td>
                            <td> {{ snapDatum?.channel }}</td>
                            <td> {{ snapDatum?.category }}</td>
                            <td> {{ snapDatum?.brand }}</td>
                            <td> {{ snapDatum?.sku }}</td>
                            <td> {{ snapDatum?.count }}</td>
                            <td> {{ snapDatum?.not_available_in_month }}</td>
                            <td> {{ snapDatum?.less_available_in_month }}</td>
                            <td> {{ snapDatum?.available_in_month }}</td>
                            <td> {{ snapDatum?.not_available_by_store }}</td>
                            <td> {{ snapDatum?.less_available_by_store }}</td>
                            <td> {{ snapDatum?.available_by_store }}</td>
                            <td> {{ snapDatum?.not_available_by_city }}</td>
                            <td> {{ snapDatum?.less_available_by_city }}</td>
                            <td> {{ snapDatum?.available_by_city }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="custom-table" *ngIf="currentQuestionnaireType === 'Distribution Check'" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>Channel</th>
                            <th>Category</th>
                            <th>Brand</th>
                            <th>SKU</th>
                            <th>Number of Outlet</th>
                            <th>Shelf Share</th>
                            <th>Total Distribution</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let snapDatum of snapData">
                            <td> {{ snapDatum?.id }}</td>
                            <td> {{ snapDatum?.date }}</td>
                            <td> {{ snapDatum?.country }}</td>
                            <td> {{ snapDatum?.city }}</td>
                            <td> {{ snapDatum?.channel }}</td>
                            <td> {{ snapDatum?.category }}</td>
                            <td> {{ snapDatum?.brand }}</td>
                            <td> {{ snapDatum?.sku }}</td>
                            <td> {{ snapDatum?.number_of_outlet }}</td>
                            <td> {{ snapDatum?.shelf_share }}</td>
                            <td> {{ snapDatum?.total_distribution }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="custom-table" *ngIf="currentQuestionnaireType === 'Brand Health Tracker'" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>Channel</th>
                            <th>Category</th>
                            <th>Brand</th>
                            <th>SKU</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let snapDatum of snapData">
                            <td> {{ snapDatum?.id }}</td>
                            <td> {{ snapDatum?.date }}</td>
                            <td> {{ snapDatum?.country }}</td>
                            <td> {{ snapDatum?.city }}</td>
                            <td> {{ snapDatum?.channel }}</td>
                            <td> {{ snapDatum?.category }}</td>
                            <td> {{ snapDatum?.brand }}</td>
                            <td> {{ snapDatum?.sku }}</td>
                            <td> {{ snapDatum?.count }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="row mr-2 flex d-flex justify-content-end" style="margin-top: 15px;">
                    <button class="btn btn-warning mr-2" (click)="handlePagination('previous')"
                        [disabled]="!previousUrl">
                        Previous</button>
                    <button class="btn btn-primary" (click)="handlePagination('next')" [disabled]="!nextUrl">
                        Next</button>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>