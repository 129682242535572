import { OperationService } from './../_services/operation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestionService } from './../_services/question.service';
import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { find, pull } from 'lodash';
import { nanoid } from 'nanoid'
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss']
})
export class CreateQuestionComponent implements OnInit {
  @ViewChild('optionInput') optionInputRef: ElementRef;

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  dropdownSettings: IDropdownSettings;

  questionnaireId: String = null;
  accessToken: string;

  questionForm: FormGroup;
  questions: FormArray;
  options: Array<any> = [];
  brands: Array<any> = [];
  skus: Array<any> = [];
  questionsType: Array<any> = [];
  questionsCardCount: number = 1;

  quesOption: string = "";

  isSingleOption: Boolean = false;

  public questionList = []

  // Question type variable

  questionsCard: Array<any> = [{
    id: nanoid(),
    isChoiceQuestion: false,
    isNumericQuestion: false,
    isYesNoQuestion: false
  }];

  constructor(
    private fb: FormBuilder,
    private questionService: QuestionService,
    private router: Router,
    private route: ActivatedRoute,
    private operationService: OperationService
  ) { }

  ngOnInit(): void {

    this.questionnaireId = this.route.snapshot.params['id'];
    this.listQuestionTypes(this.questionnaireId);
    this.loadBrands();
    this.listQuestionsByQuestionnaireId(this.questionnaireId);

    this.questionForm = this.fb.group({
      questions: this.fb.array([])
    });

    this.accessToken = localStorage.getItem('accessToken');

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };


  }

  ngAfterViewInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: '<"top"<"left-col"B><"center-col"l><"right-col">>rtip',
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export',
          title: 'question',
          className: ' mr-1 btn btn-info float-right',
          exportOptions: {
            columns: [0, 1, 2, 3]
          }
        }
      ],
      stateSave: true,
      stateDuration: -1,
      stateSaveCallback: function (settings, data) {
        const ls = JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance + window.location.pathname))
        if (ls) {
          if (ls.start !== data.start) {
            localStorage.setItem('DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data))
          }
        } else {
          localStorage.setItem('DataTables_' + settings.sInstance + window.location.pathname, JSON.stringify(data))
        }
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance + window.location.pathname))
      }
    };
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  // CRUD methods

  addQuestions() {
    if (this.questionForm.controls.questions.invalid) {
      for (var i in this.questionForm.controls.questions['controls']) {
        for (var j in this.questionForm.controls.questions['controls'][i].controls) {
          this.questionForm.controls.questions['controls'][i].controls[j].markAsDirty();
          this.questionForm.controls.questions['controls'][i].controls[j].updateValueAndValidity();
        }
      }
      return;
    }

    if (!this.questionnaireId) {
      this.handleNotification('No or invalid questionnaire id!', true);
      return;
    }


    // format values as required by api
    let formattedFormValues = [...this.questionForm.getRawValue().questions];

    formattedFormValues.map(eachQuestion => {
      for (let formValue in eachQuestion) {

        if (formValue == "brand")
          delete eachQuestion[formValue];

        if (formValue == "question_option")
          delete eachQuestion[formValue];

        if (formValue == "sku") {
          if (!eachQuestion[formValue]) {
            return;
          }
          eachQuestion[formValue] = eachQuestion[formValue][0]?.id
        }
      }
    })

    Swal.showLoading();
    this.questionService.addBulkQuestions(this.questionnaireId, { "data": formattedFormValues })
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: "Questions added successfully!"
            }).then(() => {
              this.questionForm.reset();
              this.router.navigate(['view-questionnaire']);
            })
          } else {
            this.handleNotification(response.error_message || "Problem in adding questions!", true);
          }
        },
        error => {
          Swal.close();
          this.handleNotification(error.error.error_message || "Problem in adding questions!", true);
        }
      )
  }

  removeQuestion(questionId) {
    this.questionService.removeQuestion(questionId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Question deleted successfully!"
        }).then(res => {
          this.listQuestionsByQuestionnaireId(this.questionnaireId);
        })
      })
      .catch(error => {
        this.handleNotification("Something went wrong while deleting question!", true);
      })
  }

  importQuestions(event) {
    let file = event.target.files[0];
    if (file) {

      Swal.showLoading();
      // check if file has valid extension
      let fileExtension = file.name.split('.')[1];
      if (fileExtension !== 'csv') {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please upload csv file!'
        });
        return;
      }

      // check valid file size
      let size = file.size / (1024 * 1024);
      if (size > 4) {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please only upload file less than 4MB !'
        });
        return;
      }

      // send data to api
      let csvData = new FormData();
      csvData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
      this.questionService.importQuestions(this.questionnaireId, csvData)
        .subscribe(res => {
          Swal.close()
          if (res.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message
            }).then(() => {
              this.router.navigate(['view-questionnaire']);
            })
          } else {
            event.target.value = '';
            Swal.fire({
              icon: 'error',
              text: res.error_message || "Something went wrong while importing the file!"
            });
          }
        }, err => {
          event.target.value = '';
          Swal.close()
          Swal.fire({
            icon: 'error',
            text: err.error.error_message || "Something went wrong while importing the file!"
          });
        });
    }
  }

  editQuestionStatement(qnId, qnStatement) {
    Swal.fire({
      title: 'Change Statement',
      showCancelButton: true,
      confirmButtonText: 'Change Statement',
      html:
        '<hr>' +
        `<input type="text" name="statement" value="${qnStatement}" id="statement" placeholder="New Statement" class="swal2-input" />`,
      preConfirm: (res => {
        return new Promise(function (resolve) {
          let statement = $('#statement').val();
          let accessToken = localStorage.getItem('accessToken');

          if (!statement) {
            Swal.showValidationMessage('Please type a valid question statement!');
            Swal.enableButtons();
          } else {
            Swal.showLoading();
            $.ajax({
              headers: {
                'Authorization': `JWT ${accessToken}`
              },
              method: 'put',
              url: `${environment.baseUrl}/question/${qnId}/update`,
              data: JSON.stringify({
                statement
              }),
              processData: true,
              contentType: 'application/json',
              success: function (response) {
                resolve(response)
              }, error: function (error) {
                Swal.hideLoading()
                Swal.showValidationMessage(
                  `${error.responseJSON?.error_message}`
                );
                Swal.enableButtons();
              }
            })
          }
        });
      })
    }).then((response: any) => {
      if (response.isConfirmed && response.value) {
        Swal.hideLoading();
        if (response.value?.success) {
          const successmessage = "Statement has been changed successfully!"
          Swal.fire('Updated', successmessage, 'success').then(() => {
            this.listQuestionsByQuestionnaireId(this.questionnaireId);
          })
          
        } else {
          this.handleNotification('Something went wrong while updating statement!', true);
        }
      }
    })
  }


  // Helper methods
  loadBrands() {
    Swal.showLoading();
    this.operationService.getBrands()
      .subscribe(response => {
        Swal.close()
        let brands = response.data ? response.data.results : [];
        if (brands.length > 0) {
          this.brands = [...brands]
        } else {
          this.brands = [];
        }
      }, err => {
        Swal.close();
        this.handleNotification('Encountered error while fetching brands!', true)
      })
  }

  loadSkusByBrand(brand, currentControl) {
    Swal.showLoading();

    if (!brand) {
      Swal.fire({
        icon: 'error',
        title: "Please select the brand first!"
      });
      return;
    }

    // clean up
    this.skus = [];
    this.resetSkuControl(currentControl);

    this.operationService.getSKUsByBrand(brand.id)
      .subscribe(
        response => {
          Swal.close();
          if (response?.success) {
            let skus = response.data?.results;
            this.skus = skus.length > 0 ? [...skus] : [];
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error.error_message || "Problem in fetching SKU(s)!"
            });
          }
        },
        error => {
          Swal.close()
          Swal.fire({
            icon: 'error',
            title: error.error.error_message || "Problem in fetching SKU(s)!"
          });
        })
  }

  resetSkuControl(currentControl) {
    this.questionForm.controls.questions['controls'][currentControl]['controls'].sku.setValue('');
  }

  resetSkus(sku, currentControl) {
    this.questionForm.controls.questions['controls'][currentControl]['controls'].sku.setValue('');
    this.skus = [];
  }

  listQuestionTypes(questionnaireId) {
    Swal.showLoading();
    this.questionService.getQuestionsType(questionnaireId)
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            let questionsTypes = response.data?.results;
            this.questionsType = questionsTypes.length > 0 ? [...questionsTypes] : [];
            this.questionsType.length == 1 ? this.isSingleOption = true : this.isSingleOption = false;
            this.questionForm = this.fb.group({
              questions: this.fb.array([this.createQuestion()])
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error.error_message || "Problem in fetching stores!"
            });
          }
        },
        error => {
          Swal.close()
          Swal.fire({
            icon: 'error',
            title: error.error.error_message || "Problem in fetching stores!"
          });
        }
      )
  }

  listQuestionsByQuestionnaireId(qnId: String) {
    Swal.showLoading();
    this.questionService.getQuestionsByQuestionnaireId(qnId)
      .subscribe(res => {
        Swal.close();
        if (res.success) {
          this.questionList = res.data.results;
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error.error_message,
            timer: 1500
          })
        }
      }, (error) => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong in fetching field works!',
          timer: 1500
        });
      })
  }

  changeQuestionType(qtValue, questionCard) {

    if (!qtValue) {
      return;
    }

    // set if numeric question
    if (qtValue == 1) {
      questionCard.isNumericQuestion = true;
    } else {
      questionCard.isNumericQuestion = false;
    }

    // set if choice question
    if (qtValue == 6 || qtValue == 7) {
      questionCard.isChoiceQuestion = true;
    } else {
      questionCard.isChoiceQuestion = false;
    }


    //set if yes/no question
    if (qtValue == 9) {
      questionCard.isYesNoQuestion = true;
    } else {
      questionCard.isYesNoQuestion = false;
    }
  }

  cancelQuestionCreation() {
    this.router.navigate(['view-questionnaire']);
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }


  // UI helpers

  addQuestionCard(index) {
    this.questions = this.questionForm.get('questions') as FormArray;
    this.questions.push(this.createQuestion());
  }

  createQuestion(): FormGroup {

    if(this.isSingleOption) {
      return this.fb.group({
        question_type: [{value: this.questionsType[0]?.id, disabled: true}, [Validators.required]],
        statement: ['', [Validators.required]],
        question_options: [new Array()],
        question_option: [''],
        brand: [''],
        sku: ['', [Validators.required]]
      })
    }

    return this.fb.group({
      question_type: ['', [Validators.required]],
      statement: ['', [Validators.required]],
      question_options: [new Array()],
      question_option: [''],
      brand: [''],
      sku: ['', [Validators.required]]
    })
  }

  removeQuestionCard(controlIndex: number) {
    const questionFormArrays = <FormArray>this.questionForm.controls['questions'];
    questionFormArrays.removeAt(controlIndex);
  }

  focusTagInput(): void {
    this.optionInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent, whichTag: string, currentControlIndex: number): Boolean {

    if (event.code === 'Backspace' && !whichTag) {
      // this.removeTag();
      this.removeTag(currentControlIndex, whichTag);
      return;
    } else if (event.code === 'Enter') {

      // this.addTag(whichTag);
      this.addTag(currentControlIndex, whichTag);
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  addTag(currentControl, tagToAdd) {
    let currentFormOptions: Array<string> = this.questionForm.value['questions'][currentControl]['question_options'];
    if(currentFormOptions.includes(tagToAdd)) {
      this.handleNotification('Duplicate option entry is not allowed!', true)
      return;
    }
    currentFormOptions.push(tagToAdd);
    this.questionForm.controls.questions['controls'][currentControl]['controls'].question_option.setValue('');
  }

  removeTag(currentControl, tagToRemove) {
    let currentFormOptions: Array<string> = this.questionForm.value['questions'][currentControl]['question_options'];
    const indexToRemove = currentFormOptions.indexOf(tagToRemove);
    currentFormOptions.splice(indexToRemove, 1);
  }

}
