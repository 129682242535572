import { AuthService } from './../_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  faLock = faLock;
  public invalidLogin: boolean = false;
  public errorMessage: string;
  msg: string;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.msg = this.route.snapshot.params['msg'] || null;
    // redirect to dashboard if already logged
    try {
      let accessToken = localStorage.getItem('accessToken')
      let isValidToken = accessToken && await this.authService.verifyToken(accessToken)
      
      if (isValidToken) {
        this.router.navigate(['dashboard']);
      } 
    } catch (err){
      this.router.navigate(['']);
    }
  }

  loginForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required],
    remember_me: ['']
  })

  userLogin() {
    if(this.loginForm.valid) {
      Swal.showLoading();
      this.authService.userLogin(this.loginForm.value)
      .subscribe(res => {
        Swal.close();
        if(res.success) {
          localStorage.setItem('accessToken', res.data.access_token);
          localStorage.setItem('refreshToken', res.data.refresh_token);
          localStorage.setItem('fullName', res.data.detail.fullname);
          localStorage.setItem('role', res.data.role);
          localStorage.setItem('avatar', res.data.detail.avatar);
          localStorage.setItem('userId', res.data.detail.id);
          
          this.router.navigate(["/dashboard"]);
          this.loginForm.reset();
          this.invalidLogin = false;

        } else {
          this.invalidLogin = true;
          this.errorMessage = "Login error";
        }
      }, err => {
        Swal.close();
        this.invalidLogin = true;
        this.errorMessage = err.error.error_message ? err.error.error_message : 'There was problem logging in. Please try again.';
        ;
      })

    } else {
      for (var i in this.loginForm.controls) {
        this.loginForm.controls[i].markAsDirty();
        this.loginForm.controls[i].updateValueAndValidity();
      }
    }
  }

}
