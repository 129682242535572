<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Merchandizing dashboard'"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">
        <app-merch-filter (filterAppliedEvent)="filterApplied($event)" (qnnTypeChange)="onQnnTypeChange($event)"
          (resetFilter)="resetFilter()">
        </app-merch-filter>

        <!-- Start Content-->
        <div class="price-monitor-chart-view" *ngIf="currentQnnView == 'pm'">

          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="minMaxBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.minMaxBarChartDataLoaded" [barChartLabels]="minMaxBarChartLabels"
                [chartTitle]="'Price Monitor -  All Price Measures'" [barChartExcelData]="barChartExcelData"
                [barChartExcelFileName]="'price_monitor_min_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmMinMaxBarChartData'"
                [dataModalId]="'pmModalMinMaxBarChartData'">
              </app-barchart>
            </div>
          </div>
         <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthMaxBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.monthMaxBarChartDataLoaded" [barChartLabels]="monthMaxBarChartLabels"
                [chartTitle]="'Price Monitor -  Month, Maximum Price'" [barChartExcelData]="barChartMonthMaxExcelData"
                [barChartExcelFileName]="'price_monitor_month_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmMonthMaxBarChartData'"
                [dataModalId]="'pmModalMonthMaxBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthMinBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.monthMinBarChartDataLoaded" [barChartLabels]="monthMinBarChartLabels"
                [chartTitle]="'Price Monitor – Month, Minimum Price'" [barChartExcelData]="barChartMonthMinExcelData"
                [barChartExcelFileName]="'price_monitor_month_min_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmMonthMinBarChartData'"
                [dataModalId]="'pmModalMonthMinBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthModeBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.monthModeBarChartDataLoaded" [barChartLabels]="monthModeBarChartLabels"
                [chartTitle]="'Price Monitor – Month, Mode Price'" [barChartExcelData]="barChartMonthModeExcelData"
                [barChartExcelFileName]="'price_monitor_month_mode_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmMonthModeBarChartData'"
                [dataModalId]="'pmModalMonthModeBarChartData'"></app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthMeanBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.monthMeanBarChartDataLoaded" [barChartLabels]="monthMeanBarChartLabels"
                [chartTitle]="'Price Monitor – Month, Mean Price'" [barChartExcelData]="barChartMonthMeanExcelData"
                [barChartExcelFileName]="'price_monitor_month_mean_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmMonthMeanBarChartData'"
                [dataModalId]="'pmModalMonthMeanBarChartData'"></app-barchart>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="countryMaxBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.countryMaxBarChartDataLoaded" [barChartLabels]="countryMaxBarChartLabels"
                [chartTitle]="'Price Monitor – Total country, Maximum Price'" [barChartExcelData]="countryMaxExcelData"
                [barChartExcelFileName]="'price_monitor_country_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCountryMaxBarChartData'"
                [dataModalId]="'pmModalCountryMaxBarChartData'">
              </app-barchart>

            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="countryMinBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.countryMinBarChartDataLoaded" [barChartLabels]="countryMinBarChartLabels"
                [chartTitle]="'Price Monitor – Total country, Minimum Price'" [barChartExcelData]="countryMinExcelData"
                [barChartExcelFileName]="'price_monitor_country_min_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCountryMinBarChartData'"
                [dataModalId]="'pmModalCountryMinBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="countryModeBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.countryModeBarChartDataLoaded" [barChartLabels]="countryModeBarChartLabels"
                [chartTitle]="'Price Monitor – Total Country, Mode Price'" [barChartExcelData]="countryModeExcelData"
                [barChartExcelFileName]="'price_monitor_country_mode_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCountryModeBarChartData'"
                [dataModalId]="'pmModalCountryModeBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="countryMeanBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.countryMeanBarChartDataLoaded" [barChartLabels]="countryMeanBarChartLabels"
                [chartTitle]="'Price Monitor – Total Country, Mean Price'" [barChartExcelData]="countryMeanExcelData"
                [barChartExcelFileName]="'price_monitor_country_mean_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCountryMeanBarChartData'"
                [dataModalId]="'pmModalCountryMeanBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandVsAvgChartData" [canvasHeight]="height"
                [loaded]="pmLoader.brandVsAvgChartDataLoaded" [barChartLabels]="brandVsAvgChartLabels"
                [chartTitle]="'Price Monitor – Total Brand, All Price Measures'"
                [barChartExcelData]="brandVsAvgExcelData"
                [barChartExcelFileName]="'price_monitor_brand_vs_average_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmBrandVsAvgChartData'"
                [dataModalId]="'pmModalBrandVsAvgChartData'">
              </app-barchart>
              <!-- <app-line-chart [lineChartData]="answerPerCityChartData" [lineChartLabels]="answerPerCityChartLabels"
                            [canvasHeight]="doubleHeight" [chartTitle]="'Price Monitor – Outlets Visited per City'"
                            [lineChartExcelData]="answerPerCityExcelData"
                            [lineChartExcelFileName]="'price_monitor_answer_per_city_report'"
                            [dataTableId]="'pmAnswerPerCityChartData'" [dataModalId]="'pmModalAnswerPerCityChartData'">
            </app-line-chart> -->
            </div>


          </div>
          <!-- City chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityMaxBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.cityMaxBarChartDataLoaded" [barChartLabels]="cityMaxBarChartLabels"
                [chartTitle]="'Price Monitor – City, Maximum Price'" [barChartExcelData]="cityMaxExcelData"
                [barChartExcelFileName]="'price_monitor_city_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCityMaxBarChartData'"
                [dataModalId]="'pmModalCityMaxBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityMinBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.cityMinBarChartDataLoaded" [barChartLabels]="cityMinBarChartLabels"
                [chartTitle]="'Price Monitor – City, Minimum Price'" [barChartExcelData]="cityMinExcelData"
                [barChartExcelFileName]="'price_monitor_city_min_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCityMinBarChartData'"
                [dataModalId]="'pmModalCityMinBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityMeanBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.cityMeanBarChartDataLoaded" [barChartLabels]="cityMeanBarChartLabels"
                [chartTitle]="'Price Monitor – City, Mean Price'" [barChartExcelData]="cityMeanExcelData"
                [barChartExcelFileName]="'price_monitor_city_mean_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCityMeanBarChartData'"
                [dataModalId]="'pmModalCityMeanBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityModeBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.cityModeBarChartDataLoaded" [barChartLabels]="cityModeBarChartLabels"
                [chartTitle]="'Price Monitor – City, Mode Price'" [barChartExcelData]="cityModeExcelData"
                [barChartExcelFileName]="'price_monitor_city_mode_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmCityModeBarChartData'"
                [dataModalId]="'pmModalCityModeBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Channel chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelMaxBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelMaxBarChartDataLoaded" [barChartLabels]="channelMaxBarChartLabels"
                [chartTitle]="'Price Monitor – Channel, Maximum Price'" [barChartExcelData]="channelMaxExcelData"
                [barChartExcelFileName]="'price_monitor_channel_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelMaxBarChartData'"
                [dataModalId]="'pmModalChannelMaxBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelMinBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelMinBarChartDataLoaded" [barChartLabels]="channelMinBarChartLabels"
                [chartTitle]="'Price Monitor – Channel, Minimum Price'" [barChartExcelData]="channelMinExcelData"
                [barChartExcelFileName]="'price_monitor_channel_min_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelMinBarChartData'"
                [dataModalId]="'pmModalChannelMinBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelMeanBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelMeanBarChartDataLoaded" [barChartLabels]="channelMeanBarChartLabels"
                [chartTitle]="'Price Monitor – Channel, Mean Price'" [barChartExcelData]="channelMeanExcelData"
                [barChartExcelFileName]="'price_monitor_channel_mean_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelMeanBarChartData'"
                [dataModalId]="'pmModalChannelMeanBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelModeBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelModeBarChartDataLoaded" [barChartLabels]="channelModeBarChartLabels"
                [chartTitle]="'Price Monitor – Channel, Mode Price'" [barChartExcelData]="channelModeExcelData"
                [barChartExcelFileName]="'price_monitor_channel_mode_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelModeBarChartData'"
                [dataModalId]="'pmModalChannelModeBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- ChannelCity chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityMaxBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelCityMaxBarChartDataLoaded" [barChartLabels]="channelCityMaxBarChartLabels"
                [chartTitle]="'Price Monitor – Channel City, Maximum Price'" [barChartExcelData]="channelCityMaxExcelData"
                [barChartExcelFileName]="'price_monitor_channel_city_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelCityMaxBarChartData'"
                [dataModalId]="'pmModalChannelCityMaxBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityMinBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelCityMinBarChartDataLoaded" [barChartLabels]="channelCityMinBarChartLabels"
                [chartTitle]="'Price Monitor – Channel City, Minimum Price'" [barChartExcelData]="channelCityMinExcelData"
                [barChartExcelFileName]="'price_monitor_channel_city_min_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelCityMinBarChartData'"
                [dataModalId]="'pmModalChannelCityMinBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityMeanBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelCityMeanBarChartDataLoaded" [barChartLabels]="channelCityMeanBarChartLabels"
                [chartTitle]="'Price Monitor – Channel City, Mean Price'" [barChartExcelData]="channelCityMeanExcelData"
                [barChartExcelFileName]="'price_monitor_channel_city_mean_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelCityMeanBarChartData'"
                [dataModalId]="'pmModalChannelCityMeanBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityModeBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.channelCityModeBarChartDataLoaded" [barChartLabels]="channelCityModeBarChartLabels"
                [chartTitle]="'Price Monitor – Channel City, Mode Price'" [barChartExcelData]="channelCityModeExcelData"
                [barChartExcelFileName]="'price_monitor_channel_city_mode_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmChannelCityModeBarChartData'"
                [dataModalId]="'pmModalChannelCityModeBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Brand chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandMaxBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.brandMaxBarChartDataLoaded" [barChartLabels]="brandMaxBarChartLabels"
                [chartTitle]="'Price Monitor – Brand, Maximum Price'" [barChartExcelData]="brandMaxExcelData"
                [barChartExcelFileName]="'price_monitor_brand_max_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmBrandMaxBarChartData'"
                [dataModalId]="'pmModalBrandMaxBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandMinBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.brandMinBarChartDataLoaded" [barChartLabels]="brandMinBarChartLabels"
                [chartTitle]="'Price Monitor – Brand, Minimum Price'" [barChartExcelData]="brandMinExcelData"
                [barChartExcelFileName]="'price_monitor_brand_min_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmBrandMinBarChartData'"
                [dataModalId]="'pmModalBrandMinBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandMeanBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.brandMeanBarChartDataLoaded" [barChartLabels]="brandMeanBarChartLabels"
                [chartTitle]="'Price Monitor – Brand, Mean Price'" [barChartExcelData]="brandMeanExcelData"
                [barChartExcelFileName]="'price_monitor_brand_mean_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmBrandMeanBarChartData'"
                [dataModalId]="'pmModalBrandMeanBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandModeBarChartData" [canvasHeight]="height"
                [loaded]="pmLoader.brandModeBarChartDataLoaded" [barChartLabels]="brandModeBarChartLabels"
                [chartTitle]="'Price Monitor – Brand, Mode Price'" [barChartExcelData]="brandModeExcelData"
                [barChartExcelFileName]="'price_monitor_brand_mode_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmBrandModeBarChartData'"
                [dataModalId]="'pmModalBrandModeBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="answerPerCityChartData" [canvasHeight]="height"
                [loaded]="pmLoader.answerPerCityChartDataLoaded" [barChartLabels]="answerPerCityChartLabels"
                [chartTitle]="'Price Monitor – Outlets Visited per City'" [barChartExcelData]="answerPerCityExcelData"
                [barChartExcelFileName]="'price_monitor_answer_per_city_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'pmModalAnswerPerCityChartData'"
                [dataModalId]="'pmAnswerPerCityChartData'"></app-barchart>

            </div>
          </div>
          
          <!-- <div class="row">
            <div class="col-lg-12 col-sm-12">
              <app-pie-chart [pieChartLabels]="totalAnswersPerCountryPieChartLabels" [canvasHeight]="height"
                [loaded]="pmLoader.totalAnswersPerCountryPieChartDataLoaded"
                [pieChartData]="totalAnswersPerCountryPieChartData"
                [pieChartExcelData]="totalAnswersPerCountryPieChartExcelData"
                [pieChartExcelFileName]="'price_monitor_answers_by_country'"
                [chartTitle]="'Price Monitor – Number of Outlets Visited'" [dataTableId]="'pmAnswerByCountry'"
                [dataModalId]="'pmModalAnswerByCountry'">
              </app-pie-chart>
            </div>
            <div class="col-lg-12 col-sm-12">
              <app-pie-chart [pieChartLabels]="totalAnswersPerSkuPieChartLabels" [canvasHeight]="height"
                [loaded]="pmLoader.totalAnswersPerSkuPieChartDataLoaded" [pieChartData]="totalAnswersPerSkuPieChartData"
                [pieChartExcelData]="totalAnswersPerSkuPieChartExcelData"
                [pieChartExcelFileName]="'price_monitor_answers_per_sku'" [chartTitle]="'SKU count, by channel'"
                [dataTableId]="'pmAnswerPerSku'" [dataModalId]="'pmModalAnswerPerSku'">
              </app-pie-chart>
            </div>
          </div> -->
          <!-- <div class="row">
            <div class="col-lg-12 col-sm-12">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Country</th>
                    <th>City</th>
                    <th>Channel</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>SKU</th>
                    <th>Count</th>
                    <th>Min</th>
                    <th>Max</th>
                    <th>Mean</th>
                    <th>Mode</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let snapDatum of snapData">
                    <td> {{ snapDatum?.id }}</td>
                    <td> {{ snapDatum?.date }}</td>
                    <td> {{ snapDatum?.country }}</td>
                    <td> {{ snapDatum?.city }}</td>
                    <td> {{ snapDatum?.channel }}</td>
                    <td> {{ snapDatum?.category }}</td>
                    <td> {{ snapDatum?.brand }}</td>
                    <td> {{ snapDatum?.sku }}</td>
                    <td> {{ snapDatum?.count }}</td>
                    <td> {{ snapDatum?.min }}</td>
                    <td> {{ snapDatum?.max }}</td>
                    <td> {{ snapDatum?.mean }}</td>
                    <td> {{ snapDatum?.mode }}</td>
                  </tr>
                </tbody>


              </table>
              <div class="row mr-2 flex d-flex justify-content-end" style="margin: 15px 0px;">
                <button class="btn btn-warning mr-2" (click)="handlePagination('previous')" [disabled]="!previousUrl">
                  Previous</button>
                <button class="btn btn-primary" (click)="handlePagination('next')" [disabled]="!nextUrl"> Next</button>
              </div>
            </div>
          </div> -->
        </div>
        <div class="out-of-stock-view" *ngIf="currentQnnView == 'oos'">
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="overallOOSBLData" [canvasHeight]="height"
                [loaded]="oosLoader.overallOOSBLDataLoaded" [barChartLabels]="overallOOSBLLabels"
                [chartTitle]="'Out of Stock, All Measures.'" [barChartExcelData]="overallOOSBLExcelData"
                [barChartExcelFileName]="'out_of_stock_overall_Report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'oosOverAll'" [dataModalId]="'oosModalOverall'"
                [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthAvailableOOSData" [canvasHeight]="height"
                [loaded]="oosLoader.monthAvailableOOSDataLoaded" [barChartLabels]="monthAvailableOOSLabels"
                [chartTitle]="'Out of Stock, Available SKUs.'" [barChartExcelData]="monthAvailableOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_month_available_Report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosMonthAvailable'"
                [dataModalId]="'oosModalMonthAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthNotAvailableOOSData" [canvasHeight]="height"
                [loaded]="oosLoader.monthNotAvailableOOSDataLoaded" [barChartLabels]="monthNotAvailableOOSLabels"
                [chartTitle]="'Out of Stock, Not Available SKUs.'" [barChartExcelData]="monthNotAvailableOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_month_not_available_Report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosMonthNotAvailable'"
                [dataModalId]="'oosModalNotMonthAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="lessMonthNotAvailableOOSData" [canvasHeight]="height"
                [loaded]="oosLoader.lessMonthNotAvailableOOSDataLoaded" [barChartLabels]="lessMonthOOSLabels"
                [chartTitle]="'Out of Stock, Less than 6 packs SKUs.'" [barChartExcelData]="lessMonthOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_less_month_available_Report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosLessMonthAvailable'"
                [dataModalId]="'oosLessMonthAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-lg-12">
                          <app-barchart [barChartData]="storeLessOOSData" [canvasHeight]="height"
                              [barChartLabels]="storeLessOOSLabels" [chartTitle]="'Out of Stock, Not Available SKUs, by store.'"
                              [barChartExcelData]="storeLessOOSExcelData" [barChartExcelFileName]="'out_of_stock_store_less_Report'"
                              [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosStoreLess'"
                              [dataModalId]="'oosModalStoreLess'" [isPercentageData]="true">
                          </app-barchart>
                      </div>
            <div class="col-lg-12">
              <app-line-chart [lineChartData]="cityAvailableOOSData" [lineChartLabels]="cityAvailableOOSLabels"
                            [canvasHeight]="height" [chartTitle]="'Out of Stock, Available SKUs, by City.'"
                            [lineChartExcelData]="cityAvailableOOSExcelData"
                            [lineChartExcelFileName]="'out_of_stock_city_available_report'" [scoreAlign]="'end'"
                            [scoreAnchor]="'top'" [dataTableId]="'oosCityAvailable'" [dataModalId]="'oosModalCityAvailable'"
                            [isPercentageData]="true">
            </app-line-chart>
              <app-barchart [barChartData]="cityAvailableOOSData" [canvasHeight]="height"
                [loaded]="oosLoader.cityAvailableOOSDataLoaded" [barChartLabels]="cityAvailableOOSLabels"
                [chartTitle]="'Out of Stock, Available SKUs, by City.'" [barChartExcelData]="cityAvailableOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_city_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityAvailable'"
                [dataModalId]="'oosModalCityAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-line-chart [lineChartData]="cityNotAvailableOOSData" [lineChartLabels]="cityNotAvailableOOSLabels"
                            [canvasHeight]="height" [chartTitle]="'Out of Stock, Not Available SKUs, by city.'"
                            [lineChartExcelData]="cityNotAvailableOOSExcelData"
                            [lineChartExcelFileName]="'out_of_stock_city_not_available_report'"
                            [dataTableId]="'oosCityNotAvailable'" [dataModalId]="'oosModalCityNotAvailable'"
                            [isPercentageData]="true">
            </app-line-chart>
              <app-barchart [barChartData]="cityNotAvailableOOSData" [canvasHeight]="height"
                [loaded]="oosLoader.cityNotAvailableOOSDataLoaded" [barChartLabels]="cityNotAvailableOOSLabels"
                [chartTitle]="'Out of Stock, Not Available SKUs, by City.'"
                [barChartExcelData]="cityNotAvailableOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_city_not_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityNotAvailable'"
                [dataModalId]="'oosModalCityNotAvailable'" [isPercentageData]="true">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="lessSKUInCityOOSData" [canvasHeight]="height"
                [loaded]="oosLoader.lessSKUInCityOOSDataLoaded" [barChartLabels]="lessSKUInCityOOSLabels"
                [chartTitle]="'Out of Stock, Less than 6 packs SKUs, by city.'"
                [barChartExcelData]="lessSKUInCityOOSExcelData"
                [barChartExcelFileName]="'out_of_stock_city_not_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityLessData'"
                [dataModalId]="'oosModalCityLess'" [isPercentageData]="true">
              </app-barchart>

            </div>
          </div> -->

          <!-- City chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityOOSAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.cityAvailableBarChartDataLoaded" [barChartLabels]="cityOOSAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – City, Available SKUs'" [barChartExcelData]="cityOOSAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_city_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityAvailableBarChartData'"
                [dataModalId]="'oosModalCityAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityOOSNotAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.cityNotAvailableBarChartDataLoaded" [barChartLabels]="cityOOSNotAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – City, Not Available SKUs'" [barChartExcelData]="cityOOSNotAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_city_not_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityNotAvailableBarChartData'"
                [dataModalId]="'oosModalCityNotAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityOOSLessThanBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.cityLessThanBarChartDataLoaded" [barChartLabels]="cityOOSLessThanBarChartLabels"
                [chartTitle]="'Out of Stock – City, Less Than 6 Packs'" [barChartExcelData]="cityOOSLessThanExcelData"
                [barChartExcelFileName]="'out_of_stock_city_less_than_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityLessThanBarChartData'"
                [dataModalId]="'oosModalCityLessThanBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- ChannelCity chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityOOSAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.channelCityAvailableBarChartDataLoaded" [barChartLabels]="channelCityOOSAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – Channel City, Available SKUs'" [barChartExcelData]="channelCityOOSAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_channel_city_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosChannelCityAvailableBarChartData'"
                [dataModalId]="'oosModalCityAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityOOSNotAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.channelCityNotAvailableBarChartDataLoaded" [barChartLabels]="channelCityOOSNotAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – Channel City, Not Available SKUs'" [barChartExcelData]="channelCityOOSNotAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_channel_city_not_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityNotAvailableBarChartData'"
                [dataModalId]="'oosModalCityNotAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityOOSLessThanBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.channelCityLessThanBarChartDataLoaded" [barChartLabels]="channelCityOOSLessThanBarChartLabels"
                [chartTitle]="'Out of Stock – Channel City, Less Than 6 Packs'" [barChartExcelData]="channelCityOOSLessThanExcelData"
                [barChartExcelFileName]="'out_of_stock_channel_city_less_than_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosCityLessThanBarChartData'"
                [dataModalId]="'oosModalCityLessThanBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Month chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthOOSAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.monthAvailableBarChartDataLoaded" [barChartLabels]="monthOOSAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – Month, Available SKUs'" [barChartExcelData]="monthOOSAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_month_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosMonthAvailableBarChartData'"
                [dataModalId]="'oosModalMonthAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthOOSNotAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.monthNotAvailableBarChartDataLoaded" [barChartLabels]="monthOOSNotAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – Month, Not Available SKUs'" [barChartExcelData]="monthOOSNotAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_month_not_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosMonthNotAvailableBarChartData'"
                [dataModalId]="'oosModalMonthNotAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="monthOOSLessThanBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.monthLessThanBarChartDataLoaded" [barChartLabels]="monthOOSLessThanBarChartLabels"
                [chartTitle]="'Out of Stock – Month, Less Than 6 Packs'" [barChartExcelData]="monthOOSLessThanExcelData"
                [barChartExcelFileName]="'out_of_stock_month_less_than_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosMonthLessThanBarChartData'"
                [dataModalId]="'oosModalMonthLessThanBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Store chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="storeOOSAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.storeAvailableBarChartDataLoaded" [barChartLabels]="storeOOSAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – Store, Available SKUs'" [barChartExcelData]="storeOOSAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_store_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosStoreAvailableBarChartData'"
                [dataModalId]="'oosModalStoreAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="storeOOSNotAvailableBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.storeNotAvailableBarChartDataLoaded" [barChartLabels]="storeOOSNotAvailableBarChartLabels"
                [chartTitle]="'Out of Stock – Store, Not Available SKUs'" [barChartExcelData]="storeOOSNotAvailableExcelData"
                [barChartExcelFileName]="'out_of_stock_store_not_available_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosStoreNotAvailableBarChartData'"
                [dataModalId]="'oosModalStoreNotAvailableBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="storeOOSLessThanBarChartData" [canvasHeight]="height"
                [loaded]="oosLoader.storeLessThanBarChartDataLoaded" [barChartLabels]="storeOOSLessThanBarChartLabels"
                [chartTitle]="'Out of Stock – Store, Less Than 6 Packs'" [barChartExcelData]="storeOOSLessThanExcelData"
                [barChartExcelFileName]="'out_of_stock_store_less_than_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'oosStoreLessThanBarChartData'"
                [dataModalId]="'oosModalStoreLessThanBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <!-- <app-line-chart [lineChartData]="lessSKUInCityOOSData" [lineChartLabels]="lessSKUInCityOOSLabels"
                            [canvasHeight]="height" [chartTitle]="'Out of Stock, Less than 6 packs SKUs, by city'"
                            [lineChartExcelData]="lessSKUInCityOOSExcelData"
                            [lineChartExcelFileName]="'out_of_stock_less_than_six_sku_city__report'" [scoreAlign]="'end'"
                            [scoreAnchor]="'top'" [dataTableId]="'oosCityLessData'" [dataModalId]="'oosModalCityLess'"
                            [isPercentageData]="true">
            </app-line-chart> -->
              <app-pie-chart [pieChartLabels]="totalVisitsInCityOOSLabels" [canvasHeight]="height"
                [loaded]="oosLoader.totalVisitsInCityOOSDataLoaded" [pieChartData]="totalVisitsInCityOOSData"
                [pieChartExcelData]="totalVisitsInCityOOSExcelData"
                [pieChartExcelFileName]="'out_of_stock_total_visits_by_city'"
                [chartTitle]="'Out of Stock, Total Outlets Visited, by city.'" [dataTableId]="'oosTotalVisitsByCity'"
                [dataModalId]="'oosModalTotalVisitsByCity'">
              </app-pie-chart>
            </div>
          </div>
          <div class="row p-3">
            <div class="col-lg-12 p-3" style="background-color: 'white';">

              <div class="text-end">
                <p style="font-weight: bold; color: gray;">Not Available SKU for last 4 weeks</p>
              </div>

              <table class="custom-table" style="float: left;width: 25%;"
                *ngFor="let week of weekNotAvailableOOSLabels">
                <thead>
                  <tr>
                    <th colspan="3"> {{'Week ' + week }}</th>
                  </tr>
                  <tr>
                    <th>SKU Name</th>
                    <th>Retailer Name</th>
                    <th>Retailer Branch Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let weekData of weekNotAvailableOOSData[week]">
                    <td>{{ weekData.sku }}</td>
                    <td>{{ weekData.retailer }}</td>
                    <td>{{ weekData.store }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="distribution-check-view" *ngIf="currentQnnView == 'dc'">
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="totalDistributionDCData" [canvasHeight]="height"
                [loaded]="dcLoader.totalDistributionDCDataLoaded" [barChartLabels]="totalDistributionDCLabels"
                [chartTitle]="'Total Distribution in All Cities and All Channel'" [barChartExcelData]="totalDistributionDCExcelData"
                [barChartExcelFileName]="'total_distribution_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [showLegend]="false" [dataTableId]="'dcTotalDistribution'"
                [dataModalId]="'dcModalTotalDistribution'">
              </app-barchart>
              <!-- <app-pie-chart [pieChartLabels]="totalDistributionDCLabels" [canvasHeight]="height"
                           [pieChartData]="totalDistributionDCData" [pieChartExcelData]="totalDistributionDCExcelData"
                           [pieChartExcelFileName]="'total_distribution_report'"
                           [chartTitle]="'Total Distribution'" [dataTableId]="'dcTotalDistribution'"
                           [dataModalId]="'dcModalTotalDistribution'" [isPercentageData]="true">
            </app-pie-chart> -->
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="shelfShareByCityDCData" [canvasHeight]="height"
                [loaded]="dcLoader.shelfShareByCityDCDataLoaded" [barChartLabels]="shelfShareByCityDCLabels"
                [chartTitle]="'Shelf Share by city'" [barChartExcelData]="shelfShareByCityDCExcelData"
                [barChartExcelFileName]="'shelf_share_by_city_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'dcShelfShareByCity'" [dataModalId]="'dcModalShelfShareByCity'">
              </app-barchart>
              <!-- <app-pie-chart [pieChartLabels]="shelfShareByCityDCLabels" [canvasHeight]="height"
                           [pieChartData]="shelfShareByCityDCData" [pieChartExcelData]="shelfShareByCityDCExcelData"
                           [pieChartExcelFileName]="'shelf_share_by_city_report'"
                           [chartTitle]="'Shelf Share by city'" [dataTableId]="'dcShelfShareByCity'"
                           [dataModalId]="'dcModalShelfShareByCity'" [isPercentageData]="true">
            </app-pie-chart> -->
            </div>
          </div>
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="submittedQnnByCountryDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="submittedQnnByCountryDCLabels" [chartTitle]="'Total outlets visited by Country'"-->
          <!--                [barChartExcelData]="submittedQnnByCountryDCExcelData" [showLegend]="false"-->
          <!--                [barChartExcelFileName]="'total_visit_by_country_report'" [barChartTypeToUse]="'bar'"-->
          <!--                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'visitByCountry'"-->
          <!--                [dataModalId]="'dcModalVisitByCountry'">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="totalOutletsOfSkuInCityDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="totalOutletsOfSkuInCityDCLabels" [chartTitle]="'SKU distribution by City'"-->
          <!--                [barChartExcelData]="totalOutletsOfSkuInCityDCExcelData" [showLegend]="true"-->
          <!--                [barChartExcelFileName]="'total_sku_per_city_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"-->
          <!--                [scoreAnchor]="'end'" [dataTableId]="'skuPerCity'" [dataModalId]="'dcModalSkuPerCity'"-->
          <!--                [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="totalOutletsOfSkuInCountryDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="totalOutletsOfSkuInCountryDCLabels" [chartTitle]="'SKU distribution by Total Country'"-->
          <!--                [barChartExcelData]="totalOutletsOfSkuInCountryDCExcelData" [showLegend]="true"-->
          <!--                [barChartExcelFileName]="'total_sku_per_country_report'" [barChartTypeToUse]="'bar'"-->
          <!--                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'skuPerCounty'"-->
          <!--                [dataModalId]="'dcModalSkuPerCountry'" [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="totalOutletsOfSkuInChannelDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="totalOutletsOfSkuInChannelDCLabels" [chartTitle]="'SKU distribution by Channel'"-->
          <!--                [barChartExcelData]="totalOutletsOfSkuInChannelDCExcelData" [showLegend]="true"-->
          <!--                [barChartExcelFileName]="'total_sku_per_channel_report'" [barChartTypeToUse]="'bar'"-->
          <!--                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'skuPerChannel'"-->
          <!--                [dataModalId]="'dcModalSkuPerChannel'" [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="sumOfOneBrandByCityDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="sumOfOneBrandByCityDCLabels"-->
          <!--                [chartTitle]="'Sum of SKU (SKU count in outlets) of one brand'"-->
          <!--                [barChartExcelData]="sumOfOneBrandByCityDCExcelData"-->
          <!--                [barChartExcelFileName]="'distribution_check_brand_by_city_report'"-->
          <!--                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"-->
          <!--                [dataTableId]="'dcTotalBrandByCityData'" [dataModalId]="'dcModalTotalBrandByCityDData'"-->
          <!--                [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="sumOfOneBrandByCountryDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="sumOfOneBrandByCountryDCLabels" [chartTitle]="'Sum of SKU by Country of one brand'"-->
          <!--                [barChartExcelData]="sumOfOneBrandByCountryDCExcelData"-->
          <!--                [barChartExcelFileName]="'distribution_check_brand_by_country_report'"-->
          <!--                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"-->
          <!--                [dataTableId]="'dcTotalBrandByCountryData'" [dataModalId]="'dcModalTotalBrandByCountryData'"-->
          <!--                [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="sumOfOneBrandByChannelDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="sumOfOneBrandByChannelDCLabels" [chartTitle]="'Sum of SKU by Channel of one brand'"-->
          <!--                [barChartExcelData]="sumOfOneBrandByChannelDCExcelData"-->
          <!--                [barChartExcelFileName]="'distribution_check_brand_by_channel_report'"-->
          <!--                [barChartTypeToUse]="'horizontalBar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"-->
          <!--                [dataTableId]="'dcTotalBrandByChannelData'" [dataModalId]="'dcModalTotalBrandByChannelData'"-->
          <!--                [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-pie-chart [pieChartLabels]="avgPerSkuDCLabels" [canvasHeight]="height"-->
          <!--                [pieChartData]="avgPerSkuDCData" [pieChartExcelData]="avgPerSkuDCExcelData"-->
          <!--                [pieChartExcelFileName]="'distribution_check_avg_per_sku'"-->
          <!--                [chartTitle]="'Shelf Share by SKU, total country'" [dataTableId]="'dcAvgPerSku'"-->
          <!--                [dataModalId]="'dcModalAvgPerSku'" [isPercentageData]="true">-->
          <!--              </app-pie-chart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-pie-chart [pieChartLabels]="avgPerBrandDCLabels" [canvasHeight]="height"-->
          <!--                [pieChartData]="avgPerBrandDCData" [pieChartExcelData]="avgPerBrandDCExcelData"-->
          <!--                [pieChartExcelFileName]="'distribution_check_ss_total_country'"-->
          <!--                [chartTitle]="'Shelf Share by Brand, total country'" [dataTableId]="'dcAvgPerBrand'"-->
          <!--                [dataModalId]="'dcModalAvgPerBrand'" [isPercentageData]="true">-->
          <!--              </app-pie-chart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="avgSKUPerChannelDC" [canvasHeight]="height"-->
          <!--                [barChartLabels]="avgSKUPerChannelDCLabels" [chartTitle]="'Shelf Share of SKU, by Channel'"-->
          <!--                [barChartExcelData]="avgSKUPerChannelDCExcelData"-->
          <!--                [barChartExcelFileName]="'distribution_check_ss_sku_channel'" [barChartTypeToUse]="'bar'"-->
          <!--                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcModalAvgsku_channel'"-->
          <!--                [dataModalId]="'dcAvgsku_channel'" [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-12">-->
          <!--              <app-barchart [barChartData]="avgBrandByChannelDCData" [canvasHeight]="height"-->
          <!--                [barChartLabels]="avgBrandByChannelDCLabels" [chartTitle]="'Shelf Share of Brand, by Channel'"-->
          <!--                [barChartExcelData]="avgBrandByChannelDCExcelData"-->
          <!--                [barChartExcelFileName]="'distribution_check_ss_brand_channel'" [barChartTypeToUse]="'bar'"-->
          <!--                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcModalAvgPerbrand_channel'"-->
          <!--                [dataModalId]="'dcAvgPerbrand_channel'" [isPercentageData]="true">-->
          <!--              </app-barchart>-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- Country chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="countryDCShelfBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.countryShelfBarChartDataLoaded" [barChartLabels]="countryDCShelfBarChartLabels"
                [chartTitle]="'Distribution Check – Country, Shelf Share'" [barChartExcelData]="countryDCShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_country_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcCountryShelfBarChartData'"
                [dataModalId]="'dcModalCountryShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="countryDCDistributionBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.countryDistributionBarChartDataLoaded"
                [barChartLabels]="countryDCDistributionBarChartLabels"
                [chartTitle]="'Distribution Check – Country, Distribution'"
                [barChartExcelData]="countryDCDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_country_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcCountryDistributionBarChartData'"
                [dataModalId]="'dcModalCountryDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- City chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityDCShelfBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.cityShelfBarChartDataLoaded" [barChartLabels]="cityDCShelfBarChartLabels"
                [chartTitle]="'Distribution Check – City, Shelf Share'" [barChartExcelData]="cityDCShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_city_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcCityShelfBarChartData'"
                [dataModalId]="'dcModalCityShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityDCDistributionBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.cityDistributionBarChartDataLoaded"
                [barChartLabels]="cityDCDistributionBarChartLabels"
                [chartTitle]="'Distribution Check – City, Distribution'"
                [barChartExcelData]="cityDCDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_city_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcCityDistributionBarChartData'"
                [dataModalId]="'dcModalCityDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Channel chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelDCShelfBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.channelShelfBarChartDataLoaded" [barChartLabels]="channelDCShelfBarChartLabels"
                [chartTitle]="'Distribution Check – Channel, Shelf Share'"
                [barChartExcelData]="channelDCShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_Channel_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcChannelShelfBarChartData'"
                [dataModalId]="'dcModalChannelShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelDCDistributionBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.channelDistributionBarChartDataLoaded"
                [barChartLabels]="channelDCDistributionBarChartLabels"
                [chartTitle]="'Distribution Check – Channel, Distribution'"
                [barChartExcelData]="channelDCDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_Channel_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcChannelDistributionBarChartData'"
                [dataModalId]="'dcModalChannelDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Channel City chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityDCShelfBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.channelCityShelfBarChartDataLoaded" [barChartLabels]="channelCityDCShelfBarChartLabels"
                [chartTitle]="'Distribution Check – Channel City, Shelf Share'"
                [barChartExcelData]="channelCityDCShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_channel_city_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcchannelCityShelfBarChartData'"
                [dataModalId]="'dcModalchannelCityShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCityDCDistributionBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.channelCityDistributionBarChartDataLoaded"
                [barChartLabels]="channelCityDCDistributionBarChartLabels"
                [chartTitle]="'Distribution Check – Channel City, Distribution'"
                [barChartExcelData]="channelCityDCDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_channel_city_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcchannelCityDistributionBarChartData'"
                [dataModalId]="'dcModalchannelCityDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Brand chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandDCShelfBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.brandShelfBarChartDataLoaded" [barChartLabels]="brandDCShelfBarChartLabels"
                [chartTitle]="'Distribution Check – Brand, Shelf Share'" [barChartExcelData]="brandDCShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_brand_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcBrandShelfBarChartData'"
                [dataModalId]="'dcModalBrandShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandDCDistributionBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.brandDistributionBarChartDataLoaded"
                [barChartLabels]="brandDCDistributionBarChartLabels"
                [chartTitle]="'Distribution Check – Brand, Distribution'"
                [barChartExcelData]="brandDCDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_brand_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcBrandDistributionBarChartData'"
                [dataModalId]="'dcModalBrandDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <!-- Sku chart -->
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="skuDCShelfBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.skuShelfBarChartDataLoaded" [barChartLabels]="skuDCShelfBarChartLabels"
                [chartTitle]="'Distribution Check – SKU, Shelf Share'" [barChartExcelData]="skuDCShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_sku_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcSkuShelfBarChartData'"
                [dataModalId]="'dcModalSkuShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="skuDCDistributionBarChartData" [canvasHeight]="height"
                [loaded]="dcLoader.skuDistributionBarChartDataLoaded" [barChartLabels]="skuDCDistributionBarChartLabels"
                [chartTitle]="'Distribution Check – SKU, Distribution'"
                [barChartExcelData]="skuDCDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_sku_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'dcSkuDistributionBarChartData'"
                [dataModalId]="'dcModalSkuDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="totalOutletVisitedDCData" [canvasHeight]="height"
                [loaded]="dcLoader.totalOutletVisitedDCDataLoaded" [barChartLabels]="totalOutletVisitedDCLabels"
                [chartTitle]="'Number of Outlet Visited'" [barChartExcelData]="totalOutletVisitedDCExcelData"
                [showLegend]="false" [barChartExcelFileName]="'total_outlet_visited_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'totalOutletVisited'"
                [dataModalId]="'dcTotalOutletVisited'">
              </app-barchart>
            </div>
          </div>
        </div>
        <div class="consumer-questionnaire-view" *ngIf="currentQnnView == 'cq'">
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="yesNoQuestionCQData" [canvasHeight]="height"
                [loaded]="cqLoader.yesNoQuestionCQDataLoaded" [barChartLabels]="yesNoQuestionCQLabels"
                [chartTitle]="'Brand Health Tracker - Yes or No'" [barChartExcelData]="yesNoQuestionCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_yes_no_question_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqYesNoQuestionModeBarChartData'"
                [dataModalId]="'cqModalYesNoQuestionBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="ratingOneToThreeQuestionCQData" [canvasHeight]="height"
                [loaded]="cqLoader.ratingOneToThreeQuestionCQDataLoaded" [barChartLabels]="ratingOneToThreeCQLabels"
                [chartTitle]="'Brand Health Tracker - Rating Three Points'"
                [barChartExcelData]="ratingOneToThreeCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_rating_one_to_three_question_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'cqRatingOneToThreeModeBarChartData'"
                [dataModalId]="'cqModalRatingOneToThreeQuestionBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="ratingOneToFiveQuestionCQData" [canvasHeight]="height"
                [loaded]="cqLoader.ratingOneToFiveQuestionCQDataLoaded" [barChartLabels]="ratingOneToFiveCQLabels"
                [chartTitle]="'Brand Health Tracker- Rating Five Points'"
                [barChartExcelData]="ratingOneToFiveCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_rating_one_to_five_question_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'cqRatingOneTofiveModeBarChartData'"
                [dataModalId]="'cqModalRatingOneToFiveQuestionBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="ratingOneToTenCQData" [canvasHeight]="height"
                [loaded]="cqLoader.ratingOneToTenCQDataLoaded" [barChartLabels]="ratingOneToTenCQLabels"
                [chartTitle]="'Brand Health Tracker - Rating Ten Points'"
                [barChartExcelData]="ratingOneToTenCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_rating_one_to_ten'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqRatingOneToTenModeBarChartData'"
                [dataModalId]="'cqModalRatingOneToTenBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-barchart [barChartData]="numericQuestionCQData" [canvasHeight]="height"
                [loaded]="cqLoader.numericQuestionCQDataLoaded" [barChartLabels]="numericCQLabels" [showLegend]="false"
                [chartTitle]="'Brand Health Tracker – Average'" [barChartExcelData]="numericCQExcelData"
                [barChartExcelFileName]="'consumer_questionnaire_numeric_question_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqNumericQuestionModeBarChartData'"
                [dataModalId]="'cqModalNumericQuestionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row mx-1 py-5" style="background-color: 'white';">
            <div class="col-sm-12">
              <div style="display:'flex';justify-content: 'center';">
                <a (click)="navigateToOptionQuestionChart()" target="_blank" class="btn btn-primary px-4 py-2"> View
                  Options Questions Charts </a>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityCQShelfBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.cityShelfBarChartDataLoaded" [barChartLabels]="cityCQShelfBarChartLabels"
                [chartTitle]="'Brand Health Tracker – City, Share of Shelf'" [barChartExcelData]="cityCQShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_city_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqCityShelfBarChartData'"
                [dataModalId]="'cqModalCityShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="cityCQDistributionBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.cityDistributionBarChartDataLoaded"
                [barChartLabels]="cityCQDistributionBarChartLabels"
                [chartTitle]="'Brand Health Tracker – City, Distribution Check'"
                [barChartExcelData]="cityCQDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_city_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqCityDistributionBarChartData'"
                [dataModalId]="'cqModalCityDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCQShelfBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.channelShelfBarChartDataLoaded" [barChartLabels]="channelCQShelfBarChartLabels"
                [chartTitle]="'Brand Health Tracker – Channel, Share of Shelf'"
                [barChartExcelData]="channelCQShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_Channel_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqChannelShelfBarChartData'"
                [dataModalId]="'cqModalChannelShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="channelCQDistributionBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.channelDistributionBarChartDataLoaded"
                [barChartLabels]="channelCQDistributionBarChartLabels"
                [chartTitle]="'Brand Health Tracker – Channel, Distribution Check'"
                [barChartExcelData]="channelCQDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_Channel_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqChannelDistributionBarChartData'"
                [dataModalId]="'cqModalChannelDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandCQShelfBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.brandShelfBarChartDataLoaded" [barChartLabels]="brandCQShelfBarChartLabels"
                [chartTitle]="'Brand Health Tracker – Brand, Share of Shelf'"
                [barChartExcelData]="brandCQShelfExcelData" [barChartExcelFileName]="'out_of_stock_brand_shelf_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'cqBrandShelfBarChartData'" [dataModalId]="'cqModalBrandShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="brandCQDistributionBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.brandDistributionBarChartDataLoaded"
                [barChartLabels]="brandCQDistributionBarChartLabels"
                [chartTitle]="'Brand Health Tracker – Brand, Distribution Check'"
                [barChartExcelData]="brandCQDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_brand_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqBrandDistributionBarChartData'"
                [dataModalId]="'cqModalBrandDistributionBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="skuCQShelfBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.skuShelfBarChartDataLoaded" [barChartLabels]="skuCQShelfBarChartLabels"
                [chartTitle]="'Brand Health Tracker – SKU, Share of Shelf'" [barChartExcelData]="skuCQShelfExcelData"
                [barChartExcelFileName]="'out_of_stock_sku_shelf_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqSkuShelfBarChartData'"
                [dataModalId]="'cqModalSkuShelfBarChartData'">
              </app-barchart>
            </div>
            <div class="col-lg-12">
              <app-barchart [barChartData]="skuCQDistributionBarChartData" [canvasHeight]="height"
                [loaded]="cqLoader.skuDistributionBarChartDataLoaded" [barChartLabels]="skuCQDistributionBarChartLabels"
                [chartTitle]="'Brand Health Tracker – SKU, Distribution Check'"
                [barChartExcelData]="skuCQDistributionExcelData"
                [barChartExcelFileName]="'out_of_stock_sku_Distribution_report'" [barChartTypeToUse]="'bar'"
                [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'cqSkuDistributionBarChartData'"
                [dataModalId]="'cqModalSkuDistributionBarChartData'">
              </app-barchart>
            </div>
          </div> -->
        </div>


      </div>

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- end Footer -->


  </div>
  <!-- END wrapper -->


</body>

</html>
