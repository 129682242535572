import { SharedService } from './../_shared/shared.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  constructor(
    private sharedService: SharedService
  ) { }

  // channel APIs

  getChannels() {
    return this.sharedService.get(`${environment.baseUrl}/market/channel/list?ordering=name`);
  }

  updateChannel(channelId, channelData) {
    return this.sharedService.put(`${environment.baseUrl}/market/channel/${channelId}/update`, channelData);
  }

  addChannel(channelData) {
    return this.sharedService.post(`${environment.baseUrl}/market/channel/add`, channelData);
  }

  removeChannel(channelId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/market/channel/${channelId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }


  // retailer APIs

  getRetailers(countryIds?) {
    if(countryIds && countryIds.length > 0) {
      return this.sharedService.get(`${environment.baseUrl}/market/retailer/list?ordering=name&country=${countryIds.join(',')}`);
    }
    return this.sharedService.get(`${environment.baseUrl}/market/retailer/list?ordering=name`);
  }

  getOperationRetailers(countryIds?) {
    if(countryIds && countryIds.length > 0) {
      return this.sharedService.get(`${environment.baseUrl}/snap/snap-retailer/list?ordering=name&country=${countryIds.join(',')}`);
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/snap-retailer/list?ordering=name`);
  }

  addOperationRetailer(retailerData) {
    return this.sharedService.post(`${environment.baseUrl}/snap/snap-retailer/add`, retailerData);
  }

  updateOperationReatilerById(retailerId, retailerData) {
    return this.sharedService.put(`${environment.baseUrl}/snap/snap-retailer/${retailerId}/update`, retailerData);
  }

  removeOperationRetailer(retailerId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/snap/snap-retailer/${retailerId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  addRetailer(retailerData) {
    return this.sharedService.post(`${environment.baseUrl}/market/retailer/add`, retailerData);
  }

  getRetailerById(retailerId) {
    return this.sharedService.get(`${environment.baseUrl}/market/retailer/${retailerId}`)
  }

  updateReatilerById(retailerId, retailerData) {
    return this.sharedService.put(`${environment.baseUrl}/market/retailer/${retailerId}/update`, retailerData);
  }

  removeRetailer(retailerId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/market/retailer/${retailerId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  importRetailers(retailerData) {
    return this.sharedService.post(`${environment.baseUrl}/market/retailer/import`, retailerData)
  }

  // branch APIs

  getBranches() {
    return this.sharedService.get(`${environment.baseUrl}/market/store/list?ordering=name`);
  }

  getBranchByRetailerId(retailerId, countryIds) {
    Swal.showLoading();
    return new Promise<any[]>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/market/store/basic-list?ordering=name&retailer=${retailerId}&country=${countryIds.join(',')}`).subscribe(res => {
        Swal.close();
        if (res && res.success) {
          let branches = res.data.results;
          branches = branches.filter(branch => {
            delete branch.retailer
            delete branch.country
            delete branch.city
            return branch
          });
          resolve(branches)
        }
      }, err => {
        Swal.close();
        reject([])
      });
    })
  }

  addStore(storeData) {
    return this.sharedService.post(`${environment.baseUrl}/market/store/add`, storeData);
  }

  updateStore(storeId, StoreData) {
    return this.sharedService.put(`${environment.baseUrl}/market/store/${storeId}/update`, StoreData);
  }

  removeStore(storeId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/market/store/${storeId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  // Category APIs
  addCategory(cateogryData){
    return this.sharedService.post(`${environment.baseUrl}/product/category/add`,cateogryData);
  }

  getCategories(){
    return this.sharedService.get(`${environment.baseUrl}/product/category/list?ordering=name`);
  }
  
  updateCategory(categoryId, categoryData) {
    return this.sharedService.put(`${environment.baseUrl}/product/category/${categoryId}/update`, categoryData);
  }

  removeCategory(categoryId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/product/category/${categoryId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  // Brand APIs
  getBrands(countryIds?) {
    if(countryIds && countryIds.length > 0) {
      return this.sharedService.get(`${environment.baseUrl}/product/brand/list?ordering=name&country=${countryIds.join(',')}`);
    }
    return this.sharedService.get(`${environment.baseUrl}/product/brand/list?ordering=name`);
  }
 
  addBrand(brandData) {
    return this.sharedService.post(`${environment.baseUrl}/product/brand/add`, brandData);
  }

  updateBrand(brandId, brandData) {
    return this.sharedService.put(`${environment.baseUrl}/product/brand/${brandId}/update`, brandData);
  }

  removeBrand(brandId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/product/brand/${brandId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  getBrandsByCategory(categoryId) {
    return this.sharedService.get(`${environment.baseUrl}/product/brand/list?category=${categoryId}`);
  }

  // SKU APIs

  getSKUs() {
    return this.sharedService.get(`${environment.baseUrl}/product/sku/list?ordering=name`);
  }

  addSKUs(skuData) {
    return this.sharedService.post(`${environment.baseUrl}/product/sku/add`, skuData);
  }

  removeSKUs(skuId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/product/sku/${skuId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

  updateSku(skuId, skuData) {
    return this.sharedService.put(`${environment.baseUrl}/product/sku/${skuId}/update`, skuData);
  }

  importSKUs(skuData) {
    return this.sharedService.post(`${environment.baseUrl}/product/sku/import`, skuData)
  }

  getSKUsByBrand(brandId) {
    return this.sharedService.get(`${environment.baseUrl}/product/sku/list?brand=${brandId}`)
  }

  getSkusByBrandId(brandId, countryIds) {
    return new Promise<any[]>((resolve, reject) => {
      this.sharedService.get(`${environment.baseUrl}/product/sku/list?ordering=name&brand=${brandId}&country=${countryIds.join(',')}`)
      .subscribe(res => {
        Swal.close()
        if (res && res.success) {
          let skus = res.data.results;
          skus = skus.filter(sku => {
            delete sku.brand
            delete sku.category
            return sku
          });
          resolve(skus)
        }
      }, err => {
        Swal.close()
        reject([])
      });
    })
  }

  // QUESTIONNAIRE TYPE APIs

  getQTs() {
    return this.sharedService.get(`${environment.baseUrl}/questionnaire/questionnaire-type/list`);
  }

  updateQT(qtId, qtData) {
    return this.sharedService.put(`${environment.baseUrl}/questionnaire/questionnaire-type/${qtId}/update`, qtData);
  }

  addQT(qtData) {
    return this.sharedService.post(`${environment.baseUrl}/questionnaire/questionnaire-type/add`, qtData);
  }

  removeQT(qtId, accessToken) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: {
              'Authorization': `JWT ${accessToken}`
            },
            url: `${environment.baseUrl}/questionnaire/questionnaire-type/${qtId}/delete`,
            type: 'DELETE',
            dataType: 'json'
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject(false);
            });
        }
      });
    })
  }

}
