import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../_services';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponents implements OnInit {
  public invalidForm: boolean = null;
  public errorMessage: string;
  public successMessage: string;

  uid: string;
  token: string;
  isValidToReset: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.uid = this.route.snapshot.params['uid'] || null;
    this.token = this.route.snapshot.params['token'] || null;
    if (!(this.uid && this.token)) {
      this.router.navigate(['/'])
    }
  }

  pwdResetForm = this.formBuilder.group({
    new_password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  })

  passwordReset() {
    this.invalidForm = null;
    
    if (this.pwdResetForm.valid) {
      Swal.showLoading();
      if (this.pwdResetForm.value.new_password == this.pwdResetForm.value.confirmPassword) {
        let pwdResetFormData = {
          ...this.pwdResetForm.value,
          uid: this.uid,
          token: this.token
        }
        this.authService.confirmResetPassword(pwdResetFormData)
          .subscribe(res => {
            Swal.close()
            if (res.success) {
              localStorage.clear();
              Swal.fire({
                icon: 'success',
                title: 'Password has been reset!'
              });
              this.router.navigate([''])
            } else {
              this.invalidForm = true;
              this.errorMessage = res.error_message;
            }
          }, err => {
            Swal.close()
            this.invalidForm = true;
            this.errorMessage = err.error?.error_message;
          })
      } else {
        Swal.close()
        this.invalidForm = true;
        this.errorMessage = "Password didn't match";
      }
    } else {
      for (var i in this.pwdResetForm.controls) {
        this.pwdResetForm.controls[i].markAsDirty();
        this.pwdResetForm.controls[i].updateValueAndValidity();
      }
      return;
    }
  }
}
