import { Component, OnInit,AfterViewInit,ViewChild} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merch-brand',
  templateUrl: './merch-brand.component.html',
  styleUrls: ['./merch-brand.component.scss']
})
export class MerchBrandComponent implements OnInit,AfterViewInit {

  Brands: Array<any> = [];
  brandForm: FormGroup;
  accessToken: string;

  updating: boolean = false;
  brandIdToUpdate: Number = null;
  subcategoryDropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, {
    static: false,
  })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public subcategorys: Array<any> = [];


  constructor(
    private fb: FormBuilder,
    private operationService: MerchOperationService
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken'); 
      this.loadSubcategorys(); 

    this.brandForm = this.fb.group({
      name: ['', [Validators.required]],
      name_ar: [''],
      subcategory: ['', [Validators.required]],
      subcategoryshow: ['', [Validators.required]]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }

    this.subcategoryDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    }; 
      this.listAllBrands(); 
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  getappropriatename(id) {
    return this.subcategorys.filter(item => item.id === id)[0].name;
  }
  loadSubcategorys() {
    Swal.showLoading();
    this.operationService.getsubCategories().subscribe(response => {
      Swal.close();
      if (response.success) {
        this.subcategorys = response.data?.results;
        console.log(this.subcategorys)
        this.rerender();
      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching subcategorys!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching subcategorys!"
      });
    })
  }

  listAllBrands() {
    Swal.showLoading();
    this.operationService.getbrand()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.Brands = response.data?.results;

          console.log(this.Brands)
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching Brands!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching Brands!"
        });
      })
  }

  removebrand(brandId) {
    this.operationService.removebrand(brandId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "brand deleted successfully!"
        }).then(res => {
          this.listAllBrands();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting brand!"
        })
      })
  }

  updatebrand() {
    if (this.brandForm.invalid) {
      for (var i in this.brandForm.controls) {
        this.brandForm.controls[i].markAsDirty();
        this.brandForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.brandIdToUpdate) {
        this.operationService.updatebrand(this.brandIdToUpdate, this.brandForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "brand updated successfully!"
                }).then(() => {
                  this.brandIdToUpdate = null;
                  this.listAllBrands();
                  this.updating = false;
                  this.brandForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating brand!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding brand!"
              });
            }
          )
      }
    }

  }

  addBrand() {
    this.brandForm.controls['subcategory'].setValue(this.brandForm.controls['subcategoryshow'].value[0].id)
    if (this.brandForm.invalid) {
      for (var i in this.brandForm.controls) {
        this.brandForm.controls[i].markAsDirty();
        this.brandForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      this.operationService.addbrand(this.brandForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "brand added successfully!"
              }).then(() => {
                this.listAllBrands();
                this.updating = false;
                this.brandForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding brand!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding brand!"
            });
          }
        )
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
  // helper methods
  fetchbrand(brand) {
    this.updating = true;
    this.brandIdToUpdate = brand.id || null;
    this.brandForm = this.fb.group({
      name: [brand.name, [Validators.required]],
      name_ar: [brand.name_ar],
      subcategory: [brand.subcategory, [Validators.required]],
      subcategoryshow: [[{
        'id': brand.subcategory,
        'name': this.getappropriatename(brand.subcategory),
      }], [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.brandForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
