<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Questionnaire'"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="'Create Questionnaire'" [subTitle]="'Create New Field Worker Now'"></app-top-title>
          </div>

          <!-- start row -->
          <div class="row">
            <div class="col-12">
              <div class="card-box">
                <div class="row">
                  <div class="col-12">
                    <div class="p-4" style="background-color: 'white';">
                      <form class="form-horizontal" role="form" [formGroup]="questionnaireForm"
                        (ngSubmit)="isUpdateMode ? updateQuestionnaire() : addQuestionnaire()">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="example-placeholder">Questionnaire Name<span
                              style="color:#ff0000">*</span></label>
                          <div class="col-md-9">
                            <input
                              type="text"
                              id="example-placeholder"
                              class="form-control"
                              placeholder="Enter questionnaire name"
                              formControlName="name"
                              [class.valid]="questionnaireForm.get('name').valid && 
                            (questionnaireForm.get('name').dirty || questionnaireForm.get('name').touched)"
                              [class.invalid]="questionnaireForm.get('name').invalid && 
                            (questionnaireForm.get('name').dirty || questionnaireForm.get('name').touched)">
                            <div
                              *ngIf="questionnaireForm.get('name').invalid && 
                                                                   questionnaireForm.get('name').errors && 
                                                                   (questionnaireForm.get('name').dirty || questionnaireForm.get('name').touched)">
                              <small class="text-danger"
                                *ngIf="questionnaireForm.get('name').hasError('required')">
                                Questionnaire Name is required. </small>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">Questionnaire Type<span
                              style="color:#ff0000">*</span></label>
                          <div class="col-md-9">
                            <select class="form-control" 
                              formControlName="questionnaire_type">
                              <option disabled value="" selected>Select questionnaire type</option>
                              <option *ngFor="let questionnaireType of questionnaireTypes"
                                [value]="questionnaireType.id">
                                {{questionnaireType.name}}</option>
                            </select>
                            <div
                              *ngIf="questionnaireForm.get('questionnaire_type').invalid && 
                                                                   questionnaireForm.get('questionnaire_type').errors && 
                                                                   (questionnaireForm.get('questionnaire_type').dirty || questionnaireForm.get('questionnaire_type').touched)">
                              <small class="text-danger"
                                *ngIf="questionnaireForm.get('questionnaire_type').hasError('required')">
                                Questionnaire Type is required. </small>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">Questionnaire Cycle</label>
                          <div class="col-md-9">
                            <select class="form-control"
                              formControlName="repeat_cycle">
                              <option value="" selected>Select repeat cycle</option>
                              <option *ngFor="let cycle of repeat_cycles" [value]=cycle.id>
                                {{ cycle.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">Country</label>
                          <div class="col-md-9">
                            <ng-multiselect-dropdown style="max-width: fit-content;" name="country" id="selCountry"
                              [placeholder]="'Select Country'" 
                              (onSelect)="getCitiesByCountryId()"
                              (onDeSelect)="resetCities($event)" [data]="countries" [settings]="dropdownCitySettings"
                              [formControl]="questionnaireForm.controls['country']">
                            </ng-multiselect-dropdown>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">City<span style="color:#ff0000">*</span></label>
                          <div class="col-md-9">
                            <ng-multiselect-dropdown name="city" id="selCity" [placeholder]="'Select City'"
                              [data]="cities" [settings]="dropdownCitySettings"
                              [formControl]="questionnaireForm.controls['city']"
                              [class.valid]="questionnaireForm.get('city').valid && 
                                                                (questionnaireForm.get('city').dirty || questionnaireForm.get('city').touched)"
                              [class.invalid]="questionnaireForm.get('city').invalid && 
                                                                (questionnaireForm.get('city').dirty || questionnaireForm.get('city').touched)">
                            </ng-multiselect-dropdown>
                            <div
                              *ngIf="questionnaireForm.get('city').invalid && 
                                                                   questionnaireForm.get('city').errors && 
                                                                   (questionnaireForm.get('city').dirty || questionnaireForm.get('city').touched)">
                              <small class="text-danger" *ngIf="questionnaireForm.get('city').hasError('required')">
                                City is required. </small>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">Special Tags</label>
                          <div class="col-md-9">
                            <ng-multiselect-dropdown name="tags" id="selTags" [placeholder]="'Select Tags'"
                              [data]="tags" [settings]="dropdownTagsSettings"
                              [formControl]="questionnaireForm.controls['tags']"
                              [class.valid]="questionnaireForm.get('tags').valid && 
                                                                (questionnaireForm.get('tags').dirty || questionnaireForm.get('tags').touched)"
                              [class.invalid]="questionnaireForm.get('tags').invalid && 
                                                                (questionnaireForm.get('tags').dirty || questionnaireForm.get('tags').touched)">
                            </ng-multiselect-dropdown>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="category"> Categories <span
                              style="color:#ff0000">*</span></label>
                          <div class="col-md-9">
                            <ng-multiselect-dropdown  name="category"
                            [disabled]="isUpdateMode ? true : false"
                              id="selRetailer" [placeholder]="'Select Category'"
                              [data]="categories" [settings]="dropdownSettings"
                              [formControl]="questionnaireForm.controls['category']">
                            </ng-multiselect-dropdown>
                            <div
                              *ngIf="questionnaireForm.get('category').invalid && 
                                                                   questionnaireForm.get('category').errors && 
                                                                   (questionnaireForm.get('category').dirty || questionnaireForm.get('category').touched)">
                              <small class="text-danger" *ngIf="questionnaireForm.get('category').hasError('required')">
                                Category is required. </small>
                            </div>
                          </div>

                        </div>
                        <hr>
                        <button *ngIf="!isUpdateMode" type="submit"
                          class="btn btn-success rounded-pill pl-4 pr-4">Submit</button> &nbsp;
                        <!-- <button *ngIf="!isUpdateMode"  type="button" (click)="submitAndAddQuestion()"
                          class="btn btn-success rounded-pill pl-4 pr-4">Submit & Add Question(s)</button> &nbsp; -->
                        <button *ngIf="isUpdateMode" type="submit"
                          class="btn btn-success rounded-pill pl-4 pr-4">Update</button> &nbsp;
                        <button type="button" (click)="resetQuestionnaire()"
                          class="btn btn-danger rounded-pill pl-4 pr-4">Cancel</button>
                      </form>
                    </div>
                  </div>

                </div>


              </div> <!-- end card-box -->
            </div><!-- end col -->
          </div>
          <!-- end row -->



          <br /><br />

        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->
  <script>
    $('input').tagsinput({
      typeahead: {
        source: function (query) {
          return $.getJSON('citynames.json');
        }
      }
    });

  </script>

</body>

</html>