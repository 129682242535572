import { LoginComponent } from '../login/login.component';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor( public router: Router,
    private authService: AuthService) { }
  
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<any> {
    
      try {
        let accessToken = localStorage.getItem('accessToken');
        let isValidToken = accessToken && await this.authService.verifyToken(accessToken)
        
        if (isValidToken) {
          const userRole = this.authService.getCurrentUserRole(accessToken);
          if (route.data.role && route.data.role.indexOf(userRole) === -1) {
            this.router.navigate(['/snap-dashboard']);
            return false;
          }

          return true;
        } else {
          this.router.navigate(['']);
        }
      } catch (err){
        this.router.navigate(['']);
      }
    
  }

}

