import { OperationService } from './../../_services/operation.service';

import { LocalizeService } from './../../_services/localize.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { find, get, pull } from 'lodash';

@Component({
  selector: 'app-retailer',
  templateUrl: './retailer.component.html',
  styleUrls: ['./retailer.component.scss']
})
export class RetailerComponent implements OnInit {

  @ViewChild('retailerInput') retailerInputRef: ElementRef;

  retailerForm: FormGroup;
  retailers: Array<any> = [];
  retailerNames: Array<any> = [];

  isUpdateMode: boolean = false;
  retailerIdToUpdate: number;

  
  public channels: Array<any> = [];
  accessToken: String;

  constructor(
    private fb: FormBuilder,
    private localizeService: LocalizeService,
    private operationService: OperationService) { }

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  
  public invalidRetailerLength: boolean= false;

  ngOnInit(): void {

    this.listRetailers();

    this.accessToken = localStorage.getItem('accessToken');

    this.retailerForm = this.fb.group({
      name: ['']
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  // crud methods

  listRetailers() {
    Swal.showLoading();
    this.operationService.getRetailers()
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            let retailers = response.data?.results;
            this.retailers = retailers.length > 0 ? retailers : [];
            this.rerender()
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error.error_message || "Problem in fetching retailers!"
            });
          }
        },
        error => {
          Swal.close()
          Swal.fire({
            icon: 'error',
            title: error.error.error_message || "Problem in fetching retailers!"
          });
        }
      )
  }

  updateValidator() {
    if (this.retailerNames.length <= 0) {
      this.invalidRetailerLength = true;
      return false;
    } 
    this.invalidRetailerLength = false;
    return true;
  }

  addRetailer() {

    if (!this.updateValidator()) {
      return
    }
    
    Swal.showLoading();
    // format values as required by api
    let formattedFormValues = {...this.retailerForm.value};
    formattedFormValues.name = [...this.retailerNames];

    this.operationService.addRetailer(formattedFormValues)
      .subscribe(
        response => {
          Swal.close();
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: "Retailer added successfully!"
            }).then(() => {
              this.listRetailers();
              this.retailerForm.reset();
              this.retailerNames = [];
              this.retailerForm.markAsPristine();
              this.retailerForm.markAsUntouched();
              this.invalidRetailerLength = false;
            })
          } else {
            this.handleNotification(response.error_message || "Problem in adding retailer!", true);
          }
        },
        error => {
          Swal.close();
          this.handleNotification(error.error.error_message || "Problem in adding retailer!", true);
        }
      )
  }


  removeRetailer(retailerid) {
    this.operationService.removeRetailer(retailerid, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Retailer deleted successfully!"
        }).then(res => {
          this.listRetailers();
        })
      })
      .catch(error => {
        this.handleNotification("Something went wrong while deleting retailer!", true);
      })
  }

  // loads retailer data to the form
  editRetailer() {

    if (this.retailerForm.valid) {
      Swal.showLoading();
      // format values as required by api
      let formattedFormValues = this.retailerForm.value;
      for (let formValue in formattedFormValues) {
        if (formValue == "country" || formValue == "city")
          formattedFormValues[formValue] = formattedFormValues[formValue] && formattedFormValues[formValue][0].id;
      }
      if (this.retailerIdToUpdate) {
        this.operationService.updateReatilerById(this.retailerIdToUpdate, this.retailerForm.value)
          .subscribe(response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "Retailer updated successfully!"
              }).then(() => {
                this.isUpdateMode = false;
                this.retailerIdToUpdate = null;
                this.listRetailers();
                this.retailerForm.reset();
                this.retailerForm.markAsPristine();
                this.retailerForm.markAsUntouched();
              })
            } else {
              this.handleNotification(response.error_message || "Problem in updating retailer!", true);
            }
          }, error => {
            Swal.close();
            this.handleNotification(error.error.error_message || "Problem in updating retailer!", true);
          })
      }
    } else {
      for (var i in this.retailerForm.controls) {
        this.retailerForm.controls[i].markAsDirty();
        this.retailerForm.controls[i].updateValueAndValidity();
      }
    }

  }

  importRetailers(event) {
    let file = event.target.files[0];
    if (file) {

      Swal.showLoading();
      // check if file has valid extension
      let fileExtension = file.name.split('.')[1];
      if (fileExtension !== 'csv') {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please upload csv file!'
        });
        return false;
      }

      // check valid file size
      let size = file.size / (1024 * 1024);
      if (size > 4) {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please only upload file less than 4MB !'
        });
        return false;
      }      

      // send data to api
      let csvData = new FormData();
      csvData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
      this.operationService.importRetailers(csvData)
        .subscribe(res => {
          Swal.close()
          if (res.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message
            }).then(() => {
              this.listRetailers();
              event.target.value = '';
            })
          } else {
            event.target.value = '';
            this.handleNotification(res.error_message || "Something went wrong while importing the file!", true);
          }
        }, err => {
          event.target.value = '';
          Swal.close()
          this.handleNotification(err.error.error_message || "Something went wrong while importing the file!", true);
        });
    }
  }

  // helper methods

  fetchRetailer(retailer) {
    this.isUpdateMode = true;
    this.retailerIdToUpdate = retailer.id || null;
    this.retailerForm = this.fb.group({
      channel: [[retailer.channel], [Validators.required]],
      name: [retailer.name, [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.isUpdateMode = false;
    this.retailerForm.reset();
    this.retailerForm.markAsPristine();
    this.retailerForm.markAsUntouched();
    this.invalidRetailerLength = false;
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }


  // UI Helpers

  onKeyUp(event: KeyboardEvent, whichTag: string): void {
    let inputValue: string = null;
    inputValue = this.retailerForm.controls.name.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else if (event.code === 'Enter') {
      this.addTag(inputValue);
      this.retailerForm.controls.name.setValue('');
    }
  }


  addTag(tagToAdd: string) {
    event.preventDefault();
    if(this.retailerNames.includes(tagToAdd)) {
      this.handleNotification('Duplicate option entry is not allowed!', true)
      return;
    }
    this.retailerNames.push(tagToAdd);
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.retailerNames, tag);
    } else {
      this.retailerNames.splice(-1);
    }
  }

}
