<div class="hero-image" style="height: 100%;">
  <!-- <div class="hero-text">
    <h1 style="font-style: Georgia; color: #fff; font-size:50px;">Canada Goose</h1>
    <p>spring styles have arrived</p>
    <button>SHOP NOW</button>
  </div> -->
  <div class="hero-exclusions">

    <div class="account-pages mt-5 mb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5 center-div">
            <div class="card">

              <div class="card-body p-4">

                <div class="text-center mb-4">
                  <h4 class="mt-0 mb-3">Change your Password</h4>
                  <p class="text-muted mb-0 font-13">Enter a new password for your account. We highly recommend you
                    create a unique password - one that you don't use for any other. </p>
                </div>

                <div class="alert alert-danger" role="alert" *ngIf="invalidForm">
                  {{errorMessage}}
                </div>
                <div class="alert alert-info" role="alert" *ngIf="!invalidForm && invalidForm != null">
                  {{successMessage}}
                </div>
                <form action="#" (ngSubmit)="passwordReset()" [formGroup]="pwdResetForm">

                  <!-- <div class="form-group mb-3">
                                  <label for="password" class="font-weight-bold" >Current password</label>
                                  <input class="form-control" type="password" required="" id="currentpassword" placeholder="Enter your password">
                              </div> -->
                  <div class="form-group mb-3">
                    <label for="password" class="font-weight-bold">New password</label>
                    <input class="form-control" formControlName="new_password" type="password" required=""
                      id="newpassword" placeholder="Enter new password" [class.valid]="pwdResetForm.get('new_password').valid && 
                            (pwdResetForm.get('new_password').dirty || pwdResetForm.get('new_password').touched)"
                      [class.invalid]="pwdResetForm.get('new_password').invalid && 
                            (pwdResetForm.get('new_password').dirty || pwdResetForm.get('new_password').touched)">
                    <div *ngIf="pwdResetForm.get('new_password').invalid && 
                          pwdResetForm.get('new_password').errors && 
                          (pwdResetForm.get('new_password').dirty || pwdResetForm.get('new_password').touched)">
                      <small class="text-danger" *ngIf="pwdResetForm.get('new_password').hasError('required')">
                        This field is required. </small>
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="password" class="font-weight-bold">Confirm new password</label>
                    <input class="form-control" type="password" formControlName="confirmPassword" required=""
                      id="confirmnewpassword" placeholder="Confirm new password"
                      [class.valid]="pwdResetForm.get('confirmPassword').valid && 
                            (pwdResetForm.get('confirmPassword').dirty || pwdResetForm.get('confirmPassword').touched)"
                      [class.invalid]="pwdResetForm.get('confirmPassword').invalid && 
                            (pwdResetForm.get('confirmPassword').dirty || pwdResetForm.get('confirmPassword').touched)">
                    <div *ngIf="pwdResetForm.get('confirmPassword').invalid && 
                            pwdResetForm.get('confirmPassword').errors && 
                            (pwdResetForm.get('confirmPassword').dirty || pwdResetForm.get('confirmPassword').touched)">
                      <small class="text-danger" *ngIf="pwdResetForm.get('confirmPassword').hasError('required')">
                        This field is required. </small>
                    </div>
                  </div>

                  <div class="form-group mb-0 text-center">
                    <button class="btn btn-block" type="submit" style="background-color: '#afcb3a';">
                      Change Password
                    </button>
                  </div>
                </form>

              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->

            <div class="row mt-3">
              <div class="col-12 text-center">
                <p class="text-muted">Back to <a routerLink="/" class="ml-1">
                    <b class="green-color">Log in</b>
                  </a>
                </p>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!--lightbox style popup goes here-->
  </div>