<!DOCTYPE html>
<html lang="en">

<body>

    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">

        <!-- Topbar Start -->
        <app-top-bar *ngIf="!isUpdateMode" [pageTitle]="'Create Portal User'"></app-top-bar>
        <app-top-bar *ngIf="isUpdateMode" [pageTitle]="'Update Portal User'"></app-top-bar>
        <!-- end Topbar -->

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div>
                        <app-top-title *ngIf="!isUpdateMode" [title]="'Create Portal User'"
                            [subTitle]="'Create Portal User Now'">
                        </app-top-title>
                        <app-top-title *ngIf="isUpdateMode" [title]="'Update Portal User'"
                            [subTitle]="'Edit Portal User Now'">
                        </app-top-title>
                    </div>
                    <div class="div" *ngIf="errorStatus">
                        <app-notification [errorMessage]="errorMessage" [errorStatus]="errorStatus"></app-notification>
                    </div>

                    <div class="div" *ngIf="successStatus">
                        <app-notification [successMessage]="successMessage" [successStatus]="successStatus">
                        </app-notification>
                    </div>

                    <div class="mt-1 p-2 bg-white">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="p-2">
                                            <form class="form-horizontal" enctype="multipart/form-data" role="form"
                                                [formGroup]="createPortalUser" (ngSubmit)="onSubmit()">
                                                <div class="card-box">
                                                    <div class="form-group row">
                                                        <label class="col-md-2">Profile Picture</label>
                                                        <div class="col-md-10">
                                                            <label class="col-form-label"
                                                                for="profileImage">
                                                                    <img alt="Profile Image" class="rounded-circle img-thumbnail avatar-md"
                                                                        [src]="fileUrl ? fileUrl : '/assets/images/user_icon.jpg'" />
                                                            </label>
                                                            <div>
                                                                <input type="file" class="form-control-file"
                                                                    id="profileImage" (change)="handleFileInput($event)"
                                                                     placeholder="Placeholder" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--  end of card box -->
                                                <div class="card-box">


                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label" for="txtFullName">Full
                                                            Name<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <input type="text" id="txtFullName" class="form-control"
                                                                placeholder="Enter Full Name" formControlName="fullname"
                                                                [class.valid]="createPortalUser.get('fullname').valid && 
                                                                (createPortalUser.get('fullname').dirty || createPortalUser.get('fullname').touched)"
                                                                [class.invalid]="createPortalUser.get('fullname').invalid && 
                                                                (createPortalUser.get('fullname').dirty || createPortalUser.get('fullname').touched)">
                                                            <div
                                                                *ngIf="createPortalUser.get('fullname').invalid && 
                                                                   createPortalUser.get('fullname').errors && 
                                                                   (createPortalUser.get('fullname').dirty || createPortalUser.get('fullname').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('fullname').hasError('required')">
                                                                    Full name is required. </small>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label">Nationality<span
                                                                style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                          <ng-multiselect-dropdown
                                                            name="nationality"
                                                            id="selNationality"
                                                            [placeholder]="'Select Country'"
                                                            [data]="countries"
                                                            [settings]="dropdownSettings"
                                                            [formControl]="createPortalUser.controls['nationality']"
                                                            [class.valid]="createPortalUser.get('nationality').valid && 
                                                                (createPortalUser.get('nationality').dirty || createPortalUser.get('nationality').touched)"
                                                                [class.invalid]="createPortalUser.get('nationality').invalid && 
                                                                (createPortalUser.get('nationality').dirty || createPortalUser.get('nationality').touched)">
                                                            </ng-multiselect-dropdown>
                                                            <div
                                                                *ngIf="createPortalUser.get('nationality').invalid && 
                                                                   createPortalUser.get('nationality').errors && 
                                                                   (createPortalUser.get('nationality').dirty || createPortalUser.get('nationality').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('nationality').hasError('required')">
                                                                    Nationality is required. </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label" for="date">Date of
                                                            Birth<span style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <input class="form-control" id="dob" type="date" name="date"
                                                                placeholder="Enter date of birth"
                                                                formControlName="date_of_birth"
                                                                [class.valid]="createPortalUser.get('date_of_birth').valid && 
                                                                (createPortalUser.get('date_of_birth').dirty || createPortalUser.get('date_of_birth').touched)"
                                                                [class.invalid]="createPortalUser.get('date_of_birth').invalid && 
                                                                (createPortalUser.get('date_of_birth').dirty || createPortalUser.get('date_of_birth').touched)">
                                                                <div
                                                                *ngIf="createPortalUser.get('date_of_birth').invalid && 
                                                                   createPortalUser.get('date_of_birth').errors && 
                                                                   (createPortalUser.get('date_of_birth').dirty || createPortalUser.get('date_of_birth').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('date_of_birth').hasError('required')">
                                                                    Date of birth is required. </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label"
                                                            for="example-placeholder">Contact Number<span
                                                                style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <input type="text" formControlName="contact_number"
                                                                id="example-placeholder" class="form-control"
                                                                placeholder="Enter contact number"
                                                                [class.valid]="createPortalUser.get('contact_number').valid && 
                                                              (createPortalUser.get('contact_number').dirty || createPortalUser.get('contact_number').touched)"
                                                                [class.invalid]="createPortalUser.get('contact_number').invalid && 
                                                              (createPortalUser.get('contact_number').dirty || createPortalUser.get('contact_number').touched)">

                                                            <div
                                                                *ngIf="createPortalUser.get('contact_number').invalid && 
                                                                 createPortalUser.get('contact_number').errors && 
                                                                 (createPortalUser.get('contact_number').dirty || createPortalUser.get('contact_number').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('contact_number').hasError('required')">
                                                                    Contact Number is required. </small>
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('contact_number').hasError('pattern')">
                                                                    Contact Number should be number. </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label"
                                                            for="example-placeholder">Position<span
                                                                style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <input type="text" formControlName="position"
                                                                id="example-placeholder" class="form-control"
                                                                placeholder="Enter Position"
                                                                [class.valid]="createPortalUser.get('position').valid && 
                                                              (createPortalUser.get('position').dirty || createPortalUser.get('position').touched)"
                                                                [class.invalid]="createPortalUser.get('position').invalid && 
                                                              (createPortalUser.get('position').dirty || createPortalUser.get('position').touched)">
                                                            <div
                                                                *ngIf="createPortalUser.get('position').invalid && 
                                                                   createPortalUser.get('position').errors && 
                                                                   (createPortalUser.get('position').dirty || createPortalUser.get('position').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('position').hasError('required')">
                                                                    Position is required. </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label"
                                                            for="example-placeholder">Role<span
                                                                style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <select name="role" formControlName="role"
                                                                class="form-control" [(ngModel)]="selectedRole"
                                                                [class.valid]="createPortalUser.get('role').valid && 
                                                                (createPortalUser.get('role').dirty || createPortalUser.get('role').touched)"
                                                                [class.invalid]="createPortalUser.get('role').invalid && 
                                                                (createPortalUser.get('role').dirty || createPortalUser.get('role').touched)">
                                                                <option *ngFor="let role of roles" [ngValue]="role.id">
                                                                    {{role.name}}</option>
                                                            </select>
                                                            <div
                                                            *ngIf="createPortalUser.get('role').invalid && 
                                                               createPortalUser.get('role').errors && 
                                                               (createPortalUser.get('role').dirty || createPortalUser.get('role').touched)">
                                                            <small class="text-danger"
                                                                *ngIf="createPortalUser.get('role').hasError('required')">
                                                                Role is required. </small>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label" for="username">User Name
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-10">
                                                            <input type="text" id="username" formControlName="username"
                                                                name="username" class="form-control"
                                                                placeholder="Enter username" username
                                                                [class.valid]="createPortalUser.get('username').valid && 
                                                                    (createPortalUser.get('username').dirty || createPortalUser.get('username').touched)"
                                                                [class.invalid]="createPortalUser.get('username').invalid && 
                                                                    (createPortalUser.get('username').dirty || createPortalUser.get('username').touched)">
                                                            <div
                                                                *ngIf="createPortalUser.get('username').invalid && 
                                                                   createPortalUser.get('username').errors && 
                                                                   (createPortalUser.get('username').dirty || createPortalUser.get('username').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('username').hasError('required')">
                                                                    Username is required. </small>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 col-form-label" for="example-email">Email
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-10">
                                                            <input type="email" id="example-email"
                                                                formControlName="email" name="example-email"
                                                                class="form-control" placeholder="Enter your email" email
                                                                [class.valid]="createPortalUser.get('email').valid && 
                                                                    (createPortalUser.get('email').dirty || createPortalUser.get('email').touched)"
                                                                [class.invalid]="createPortalUser.get('email').invalid && 
                                                                    (createPortalUser.get('email').dirty || createPortalUser.get('email').touched)">
                                                            <div
                                                                *ngIf="createPortalUser.get('email').invalid && 
                                                                   createPortalUser.get('email').errors && 
                                                                   (createPortalUser.get('email').dirty || createPortalUser.get('email').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('email').hasError('required')">
                                                                    Email is required. </small>
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('email').hasError('email')">
                                                                    Enter a valid email. </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" *ngIf="!isUpdateMode">
                                                        <label class="col-md-2 col-form-label"
                                                            for="example-password">Password<span
                                                                style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <input type="password" id="example-password"
                                                                formControlName="password" class="form-control"
                                                                value="password" placeholder="Enter your password"
                                                                [class.valid]="createPortalUser.get('password').valid && 
                                                                    (createPortalUser.get('password').dirty || createPortalUser.get('password').touched)"
                                                                [class.invalid]="createPortalUser.get('password').invalid && 
                                                                    (createPortalUser.get('password').dirty || createPortalUser.get('password').touched)">
                                                            <div
                                                                *ngIf="createPortalUser.get('password').invalid && 
                                                                       createPortalUser.get('password').errors && 
                                                                       (createPortalUser.get('password').dirty || createPortalUser.get('password').touched)">
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.get('password').hasError('required')">
                                                                    Password is required. </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" *ngIf="!isUpdateMode">
                                                        <label class="col-md-2 col-form-label"
                                                            for="re-enter-password">Re-Enter Password<span
                                                                style="color:#ff0000">*</span></label>
                                                        <div class="col-md-10">
                                                            <input type="password" id="re-enter-password"
                                                                formControlName="confirm_password" class="form-control"
                                                                value="password" placeholder="Confirm password"
                                                                >
                                                            <div>
                                                                <small class="text-danger"
                                                                    *ngIf="createPortalUser.hasError('notSame')">
                                                                    Passwords do not match. </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button *ngIf="!isUpdateMode" type="submit"
                                                        class="btn btn-success rounded-pill pl-4 pr-4"
                                                        >Save</button>

                                                    <button *ngIf="isUpdateMode" type="submit"
                                                        class="btn btn-success rounded-pill pl-4 pr-4"
                                                       >Update</button> &nbsp;

                                                    <button type="button" *ngIf="!isUpdateMode" (click)="resetForm()"
                                                        class="btn btn-danger rounded-pill pl-4 pr-4">Cancel</button>
                                                </div>
                                                <!-- end of card box -->
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <!-- end row -->

                            </div><!-- end col -->
                        </div>
                        <!-- end row -->
                        <br /><br />
                    </div>
                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->


</body>

</html>