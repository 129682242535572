<div *ngIf="isAdmin" class="" style="background-color: 'white';margin-bottom: '10px';">
  <div class="row pt-2" style="display: 'flex';flex-direction: 'row';justify-content: 'center';">
    <div class="m-2">
      <div
        style="width:'240px';min-height:'70px';background-color: #71B6F9;color:white; display:'flex'; justify-content: 'center';align-items: 'center';">
        <b>{{ overviewData?.field_work }}</b> &nbsp; Number of Interviewers
      </div>
    </div>
    <div class="m-2">
      <div
        style="width:'240px';min-height:'70px';background-color: #10C368;color:white; display:'flex'; justify-content: 'center';align-items: 'center';">
        <b>{{ overviewData?.questionnaire }}</b> &nbsp; Number of Projects
      </div>
    </div>
    <div class="m-2">
      <div
        style="width:'240px';min-height:'70px';background-color: #FF5B5C;color:white; display:'flex'; justify-content: 'center';align-items: 'center';">
        <b>{{ overviewData?.answer }}</b> &nbsp; Questionnaires Completed
      </div>
    </div>
  </div>
</div>


<div class="py-2" style="background-color: 'white';margin-bottom: '10px';">

  <form action="" [formGroup]="dashboardForm" (ngSubmit)="onFilterSubmit()">
    <div class="row p-3">
      <div class="col-md-6">

        <!-- Date range picker -->
        <label class=" col-form-label">Date Range: <span style="color:#ff0000">*</span> </label>
        <input id="daterangepicker" class="form-control" startKey="date_after" endKey="date_before"
          opens="right" drops="down" emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd
          [formControl]="dashboardForm.controls['date_range']" [locale]="{'format': 'MM/DD/YYYY'}"
          [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
          [showDropdowns]="true" [showRangeLabelOnInput]="showRangeLabelOnInput"
          placeholder="Select the date range..." />
      </div>


      <div class=" col-md-3" style="width: 100%">
        <div class="form-group">
          <label class=" col-form-label">Country: </label>
          <ng-multiselect-dropdown style="max-width: fit-content;" name="country" id="selCountry"
            [placeholder]="'Select Country'"
            (onSelect)="onCountrySelect($event)"
            (onDeSelect)="onCountryDeSelect($event)"
            [data]="countries" [settings]="dropdownMultiselectSettings"
            [formControl]="dashboardForm.controls['country']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="city">City List: </label>
          <ng-multiselect-dropdown name="city" id="selCity" [placeholder]="'Select City'" [data]="cities"
            [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['city']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="country">Channel: </label>
          <ng-multiselect-dropdown name="channel" id="selCity" [placeholder]="'Select Channel'" [data]="channels"
            [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['channel']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="retailer">Retailers: </label>
          <ng-multiselect-dropdown name="retailer" id="selRetailer" [placeholder]="'Select Retailer'" [data]="retailers"
            [settings]="dropdownMultiselectSettings"
            (onSelect)="onRetailerSelect()"
            (onDeSelect)="onRetailerDeSelect()"
            (onSelectAll)="onRetailerSelectAll($event)"
            [formControl]="dashboardForm.controls['retailer']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="store">Branch: </label>
          <ng-multiselect-dropdown name="store" id="selBranch" [placeholder]="'Select Branch'" [data]="branches"
            [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['store']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="country">Category: </label>
          <ng-multiselect-dropdown name="category" id="selCategory" [placeholder]="'Select Category'" [data]="categories"
            [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['category']">
          </ng-multiselect-dropdown>
        </div>
      </div>


      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="brand">Brand: </label>
          <ng-multiselect-dropdown name="brand" id="selBrand" [placeholder]="'Select Brand'" [data]="brands"
            [settings]="dropdownMultiselectSettings"
            (onSelect)="onBrandSelect()"
            (onDeSelect)="onBrandDeSelect()"
            (onSelectAll)="onBrandSelectAll($event)"
            [formControl]="dashboardForm.controls['brand']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="sku">SKU: </label>
          <ng-multiselect-dropdown name="sku" id="selSKU" [placeholder]="'Select SKU'" [data]="skus"
            [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['sku']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="col-form-label" for="questionnaire_type">Questionnaire Type: <span
              style="color:#ff0000">*</span></label>
          <select class="form-control" formControlName="questionnaire_type" (change)="onQnnTypeChange($event)">
            <option *ngFor="let questionnaireType of questionnaire_types" [value]="questionnaireType.id">
              {{questionnaireType.name}}</option>
          </select>
        </div>
      </div>

      <div class="col-md-3 d-flex align-items-center pt-3">
        <button type="submit" class="btn btn-info mr-2">Apply Filters</button>
        <button type="button" (click)="resetFilters()" class="btn btn-danger">Reset Filters</button>
      </div>

    </div>
  </form>

</div>
