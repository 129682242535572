import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merch-country',
  templateUrl: './merch-country.component.html',
  styleUrls: ['./merch-country.component.scss']
})
export class MerchCountryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
