import { SnapDashboardService } from './../../_services/snap-dashboard.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ExcelData } from 'src/app/dashboard/dashboard.component';
import Swal from 'sweetalert2';
import { DataService } from 'src/app/_services/data.service';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-snapdashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class SnapDashboardComponent implements OnInit {
  pmLoader = {
    minMaxBarChartDataLoaded: false,
    monthMaxBarChartDataLoaded: false,
    monthMinBarChartDataLoaded: false,
    monthModeBarChartDataLoaded: false,
    monthMeanBarChartDataLoaded: false,
    countryMaxBarChartDataLoaded: false,
    countryMinBarChartDataLoaded: false,
    countryModeBarChartDataLoaded: false,
    countryMeanBarChartDataLoaded: false,
    brandVsAvgChartDataLoaded: false,
    answerPerCityChartDataLoaded: false,
    // totalAnswersPerCountryPieChartDataLoaded : false,
    // totalAnswersPerSkuPieChartDataLoaded : false,
    cityMaxBarChartDataLoaded: false,
    cityMinBarChartDataLoaded: false,
    cityModeBarChartDataLoaded: false,
    cityMeanBarChartDataLoaded: false,
    channelMaxBarChartDataLoaded: false,
    channelMinBarChartDataLoaded: false,
    channelModeBarChartDataLoaded: false,
    channelMeanBarChartDataLoaded: false,
    channelCityMaxBarChartDataLoaded: false,
    channelCityMinBarChartDataLoaded: false,
    channelCityModeBarChartDataLoaded: false,
    channelCityMeanBarChartDataLoaded: false,
    brandMaxBarChartDataLoaded: false,
    brandMinBarChartDataLoaded: false,
    brandModeBarChartDataLoaded: false,
    brandMeanBarChartDataLoaded: false,
  }
  dcLoader = {
    totalDistributionDCDataLoaded: false,
    shelfShareByCityDCDataLoaded: false,
    totalOutletVisitedDCDataLoaded: false,
    countryDistributionBarChartDataLoaded: false,
    countryShelfBarChartDataLoaded: false,
    cityDistributionBarChartDataLoaded: false,
    cityShelfBarChartDataLoaded: false,
    channelDistributionBarChartDataLoaded: false,
    channelShelfBarChartDataLoaded: false,
    channelCityDistributionBarChartDataLoaded: false,
    channelCityShelfBarChartDataLoaded: false,
    brandDistributionBarChartDataLoaded: false,
    brandShelfBarChartDataLoaded: false,
    skuDistributionBarChartDataLoaded: false,
    skuShelfBarChartDataLoaded: false,
  }
  oosLoader = {
    overallOOSBLDataLoaded: false,
    monthAvailableOOSDataLoaded: false,
    monthNotAvailableOOSDataLoaded: false,
    lessMonthNotAvailableOOSDataLoaded: false,
    cityAvailableOOSDataLoaded: false,
    cityNotAvailableOOSDataLoaded: false,
    lessSKUInCityOOSDataLoaded: false,
    totalVisitsInCityOOSDataLoaded: false,
    weekNotAvailableOOSDataLoaded: false,
    cityAvailableBarChartDataLoaded: false,
    cityNotAvailableBarChartDataLoaded: false,
    cityLessThanBarChartDataLoaded: false,
    channelCityAvailableBarChartDataLoaded: false,
    channelCityNotAvailableBarChartDataLoaded: false,
    channelCityLessThanBarChartDataLoaded: false,
    monthAvailableBarChartDataLoaded: false,
    monthNotAvailableBarChartDataLoaded: false,
    monthLessThanBarChartDataLoaded: false,
    storeAvailableBarChartDataLoaded: false,
    storeNotAvailableBarChartDataLoaded: false,
    storeLessThanBarChartDataLoaded: false,


  }
  cqLoader = {
    yesNoQuestionCQDataLoaded: false,
    ratingOneToThreeQuestionCQDataLoaded: false,
    ratingOneToFiveQuestionCQDataLoaded: false,
    ratingOneToTenCQDataLoaded: false,
    numericQuestionCQDataLoaded: false,
    // cityDistributionBarChartDataLoaded: false,
    // cityShelfBarChartDataLoaded: false,
    // channelDistributionBarChartDataLoaded: false,
    // channelShelfBarChartDataLoaded: false,
    // brandDistributionBarChartDataLoaded: false,
    // brandShelfBarChartDataLoaded: false,
    // skuDistributionBarChartDataLoaded: false,
    // skuShelfBarChartDataLoaded: false,
  }
  currentQnnView: string = 'pm';
  currentFilterString: string;

  // common variables
  height: string = '120';
  doubleHeight: string = '200';
  // bar chart variables
  maxDataArray = [];
  minDataArray = [];
  meanDataArray = [];
  modeDataArray = [];
  barChartExcelData: ExcelData = { labels: [], data: [] };
  public minMaxBarChartData: ChartDataSets[] = [];
  public minMaxBarChartLabels: Label[] = [];

  // stacked barchart for SKU VS month max report variables
  public monthMaxBarChartData: ChartDataSets[] = [];
  public monthMaxBarChartLabels: Label[] = [];
  public barChartMonthMaxExcelData: ExcelData = { labels: [], data: [] };

  // stacked bar chart month min variables
  public monthMinBarChartData: ChartDataSets[] = [];
  public monthMinBarChartLabels: Label[] = [];
  public barChartMonthMinExcelData: ExcelData = { labels: [], data: [] };

  // stacked bar chart month mode variables
  public monthModeBarChartData: ChartDataSets[] = [];
  public monthModeBarChartLabels: Label[] = [];
  public barChartMonthModeExcelData: ExcelData = { labels: [], data: [] };

  // stacked bar chart month mode variables
  public monthMeanBarChartData: ChartDataSets[] = [];
  public monthMeanBarChartLabels: Label[] = [];
  public barChartMonthMeanExcelData: ExcelData = { labels: [], data: [] };

  // bar chart country max varaibles
  countryMaxValueArray = [];
  countryMaxExcelData: ExcelData = { labels: [], data: [] };
  public countryMaxBarChartData: ChartDataSets[] = [];
  public countryMaxBarChartLabels: Label[] = [];

  // bar chart country min varaibles
  countryMinValueArray = [];
  countryMinExcelData: ExcelData = { labels: [], data: [] };
  public countryMinBarChartData: ChartDataSets[] = [];
  public countryMinBarChartLabels: Label[] = [];

  // bar chart country mode varaibles
  countryModeValueArray = [];
  countryModeExcelData: ExcelData = { labels: [], data: [] };
  public countryModeBarChartData: ChartDataSets[] = [];
  public countryModeBarChartLabels: Label[] = [];

  // bar chart country mean varaibles
  countryMeanValueArray = [];
  countryMeanExcelData: ExcelData = { labels: [], data: [] };
  public countryMeanBarChartData: ChartDataSets[] = [];
  public countryMeanBarChartLabels: Label[] = [];

  // PieChart - Outlet visited by Country
  outletVisitsValue = [];
  public outletPieChartLabels: Label[] = [];
  public outletPieChartData: ChartDataSets[] = [];
  public pieChartExcelData: ExcelData = { labels: [], data: [] };

  // line chart answer per city variables
  answerPerCityValuesArray = [];
  public answerPerCityChartData: ChartDataSets[] = [];
  public answerPerCityChartLabels: Label[] = [];
  public answerPerCityExcelData: ExcelData = { labels: [], data: [] };

  // bar chart brand vs avg variables
  brandVsAvgMaxDataArray = [];
  brandVsAvgMinDataArray = [];
  brandVsAvgMeanDataArray = [];
  brandVsAvgModeDataArray = [];
  brandVsAvgExcelData: ExcelData = { labels: [], data: [] };
  public brandVsAvgChartData: ChartDataSets[] = [];
  public brandVsAvgChartLabels: Label[] = [];

  // PieChart - Total answers per country
  public totalAnswersPerCountryPieChartLabels: Label[] = [];
  public totalAnswersPerCountryPieChartData: ChartDataSets[] = [];
  public totalAnswersPerCountryPieChartExcelData: ExcelData = { labels: [], data: [] };

  // PieChart - Total answers per country
  public totalAnswersPerSkuPieChartLabels: Label[] = [];
  public totalAnswersPerSkuPieChartData: ChartDataSets[] = [];
  public totalAnswersPerSkuPieChartExcelData: ExcelData = { labels: [], data: [] };


  // Barchart with Line graph for Overall
  public overallOOSBLData: ChartDataSets[] = [];
  public overallOOSBLLabels: Label[] = [];
  public overallOOSBLExcelData: ExcelData = { labels: [], data: [] }

  // Barchart for Month Available
  public monthAvailableOOSData: ChartDataSets[] = [];
  public monthAvailableOOSLabels: Label[] = [];
  public monthAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Barchart for Month Not Available
  public monthNotAvailableOOSData: ChartDataSets[] = [];
  public monthNotAvailableOOSLabels: Label[] = [];
  public monthNotAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Barchart for Less than 6 SKU report
  public lessMonthNotAvailableOOSData: ChartDataSets[] = [];
  public lessMonthOOSLabels: Label[] = [];
  public lessMonthOOSExcelData: ExcelData = { labels: [], data: [] }
  // Barchart for Store Less
  public storeLessOOSData: ChartDataSets[] = [];
  public storeLessOOSLabels: Label[] = [];
  public storeLessOOSExcelData: ExcelData = { labels: [], data: [] }

  // Line Chart for City Available
  public cityAvailableOOSData: ChartDataSets[] = [];
  public cityAvailableOOSLabels: Label[] = [];
  public cityAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Line Chart for City Not Available
  public cityNotAvailableOOSData: ChartDataSets[] = [];
  public cityNotAvailableOOSLabels: Label[] = [];
  public cityNotAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  // Pie Chart for Total Visits in a city
  public totalVisitsInCityOOSData: ChartDataSets[] = [];
  public totalVisitsInCityOOSLabels: Label[] = [];
  public totalVisitsInCityOOSExcelData: ExcelData = { labels: [], data: [] }

  // Line Chart for Total Visits in a city
  public lessSKUInCityOOSData: ChartDataSets[] = [];
  public lessSKUInCityOOSLabels: Label[] = [];
  public lessSKUInCityOOSExcelData: ExcelData = { labels: [], data: [] }

  // Table for SKU not available in 4 weeks
  public weekNotAvailableOOSData: any;
  public weekNotAvailableOOSLabels: Label[] = [];
  public weekNotAvailableOOSExcelData: ExcelData = { labels: [], data: [] }

  //Distribution Check
  //Visit per city
  public submittedQnnByCityDCData: any = [];
  public submittedQnnByCityDCLabels: Label[] = [];
  public submittedQnnByCityDCExcelData: ExcelData = { labels: [], data: [] }

  //Visit per country
  public submittedQnnByCountryDCData: any = [];
  public submittedQnnByCountryDCLabels: Label[] = [];
  public submittedQnnByCountryDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total visit by channel
  public submittedQnnByChannelDCData: any = [];
  public submittedQnnByChannelDCLabels: Label[] = [];
  public submittedQnnByChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total sku in outlets in a city
  public totalOutletsOfSkuInCityDCData: any = [];
  public totalOutletsOfSkuInCityDCLabels: Label[] = [];
  public totalOutletsOfSkuInCityDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total sku in outlets in Country
  public totalOutletsOfSkuInCountryDCData: any = [];
  public totalOutletsOfSkuInCountryDCLabels: Label[] = [];
  public totalOutletsOfSkuInCountryDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total sku in outlets in Country
  public totalOutletsOfSkuInChannelDCData: any = [];
  public totalOutletsOfSkuInChannelDCLabels: Label[] = [];
  public totalOutletsOfSkuInChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // horizontal bar chart DC sum of one brand by city
  public sumOfOneBrandByCityDCData: any = [];
  public sumOfOneBrandByCityDCLabels: Label[] = [];
  public sumOfOneBrandByCityDCExcelData: ExcelData = { labels: [], data: [] }

  // horizontal bar chart DC sum of one brand by country
  public sumOfOneBrandByCountryDCData: any = [];
  public sumOfOneBrandByCountryDCLabels: Label[] = [];
  public sumOfOneBrandByCountryDCExcelData: ExcelData = { labels: [], data: [] }

  // horizontal bar chart DC sum of one brand by channel
  public sumOfOneBrandByChannelDCData: any = [];
  public sumOfOneBrandByChannelDCLabels: Label[] = [];
  public sumOfOneBrandByChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC average per sku
  public avgPerSkuDCData: any = [];
  public avgPerSkuDCLabels: Label[] = [];
  public avgPerSkuDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC total distribution
  public totalDistributionDCData: any = [];
  public totalDistributionDCLabels: Label[] = [];
  public totalDistributionDCExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart DC total sku in outlets in Country
  public totalNumberOfOutletVisitedDCData: any = [];
  public totalNumberOfOutletVisitedDCLabels: Label[] = [];
  public totalNumberOfOutletVisitedDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC Shelf Share bu city
  public shelfShareByCityDCData: any = [];
  public shelfShareByCityDCLabels: Label[] = [];
  public shelfShareByCityDCExcelData: ExcelData = { labels: [], data: [] }

  // bar chart DC number of visited
  public totalOutletVisitedDCData: any = [];
  public totalOutletVisitedDCLabels: Label[] = [];
  public totalOutletVisitedDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC average per brand
  public avgPerBrandDCData: any = [];
  public avgPerBrandDCLabels: Label[] = [];
  public avgPerBrandDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC sku by channel
  public avgSKUPerChannelDC: any = [];
  public avgSKUPerChannelDCLabels: Label[] = [];
  public avgSKUPerChannelDCExcelData: ExcelData = { labels: [], data: [] }

  // pie chart DC Brand by channel
  public avgBrandByChannelDCData: any = [];
  public avgBrandByChannelDCLabels: Label[] = [];
  public avgBrandByChannelDCExcelData: ExcelData = { labels: [], data: [] }

  //Consumer Questionnaire

  // Stacked Bar chart CQ yes no question chart
  public yesNoQuestionCQData: any = [];
  public yesNoQuestionCQLabels: Label[] = [];
  public yesNoQuestionCQExcelData: ExcelData = { labels: [], data: [] }

  // Stacked Bar chart CQ Rating One to Ten
  public ratingOneToTenCQData: any = [];
  public ratingOneToTenCQLabels: Label[] = [];
  public ratingOneToTenCQExcelData: ExcelData = { labels: [], data: [] }

  // Stacked Bar chart CQ rating one to three question chart
  public ratingOneToThreeQuestionCQData: any = [];
  public ratingOneToThreeCQLabels: Label[] = [];
  public ratingOneToThreeCQExcelData: ExcelData = { labels: [], data: [] }

  // Stacked Bar chart CQ rating one to five question chart
  public ratingOneToFiveQuestionCQData: any = [];
  public ratingOneToFiveCQLabels: Label[] = [];
  public ratingOneToFiveCQExcelData: ExcelData = { labels: [], data: [] }

  // Bar chart CQ numeric question chart
  public numericQuestionCQData: any = [];
  public numericCQLabels: Label[] = [];
  public numericCQExcelData: ExcelData = { labels: [], data: [] }
  //snapdata
  snapData: any;
  // nextUrl: any;
  // previousUrl: any;
  // @ViewChild(DataTableDirective, { static: false })
  // public dtElement: DataTableDirective;
  // public dtOptions: any = {};
  // public dtTrigger: Subject<any> = new Subject();

  //new charts
  //Price Monitor
  // city min chart
  public cityMinBarChartData: ChartDataSets[] = [];
  public cityMinBarChartLabels: Label[] = [];
  public cityMinExcelData: ExcelData = { labels: [], data: [] };
  // city max chart
  public cityMaxBarChartData: ChartDataSets[] = [];
  public cityMaxBarChartLabels: Label[] = [];
  public cityMaxExcelData: ExcelData = { labels: [], data: [] };
  // city mean chart
  public cityMeanBarChartData: ChartDataSets[] = [];
  public cityMeanBarChartLabels: Label[] = [];
  public cityMeanExcelData: ExcelData = { labels: [], data: [] };
  // city max chart
  public cityModeBarChartData: ChartDataSets[] = [];
  public cityModeBarChartLabels: Label[] = [];
  public cityModeExcelData: ExcelData = { labels: [], data: [] };
  // channel min chart
  public channelMinBarChartData: ChartDataSets[] = [];
  public channelMinBarChartLabels: Label[] = [];
  public channelMinExcelData: ExcelData = { labels: [], data: [] };
  // channel max chart
  public channelMaxBarChartData: ChartDataSets[] = [];
  public channelMaxBarChartLabels: Label[] = [];
  public channelMaxExcelData: ExcelData = { labels: [], data: [] };
  // channel mean chart
  public channelMeanBarChartData: ChartDataSets[] = [];
  public channelMeanBarChartLabels: Label[] = [];
  public channelMeanExcelData: ExcelData = { labels: [], data: [] };
  // channel max chart
  public channelModeBarChartData: ChartDataSets[] = [];
  public channelModeBarChartLabels: Label[] = [];
  public channelModeExcelData: ExcelData = { labels: [], data: [] };
  // channelCity min chart
  public channelCityMinBarChartData: ChartDataSets[] = [];
  public channelCityMinBarChartLabels: Label[] = [];
  public channelCityMinExcelData: ExcelData = { labels: [], data: [] };
  // channelCity max chart
  public channelCityMaxBarChartData: ChartDataSets[] = [];
  public channelCityMaxBarChartLabels: Label[] = [];
  public channelCityMaxExcelData: ExcelData = { labels: [], data: [] };
  // channelCity mean chart
  public channelCityMeanBarChartData: ChartDataSets[] = [];
  public channelCityMeanBarChartLabels: Label[] = [];
  public channelCityMeanExcelData: ExcelData = { labels: [], data: [] };
  // channelCity max chart
  public channelCityModeBarChartData: ChartDataSets[] = [];
  public channelCityModeBarChartLabels: Label[] = [];
  public channelCityModeExcelData: ExcelData = { labels: [], data: [] };
  // brand min chart
  public brandMinBarChartData: ChartDataSets[] = [];
  public brandMinBarChartLabels: Label[] = [];
  public brandMinExcelData: ExcelData = { labels: [], data: [] };
  // brand max chart
  public brandMaxBarChartData: ChartDataSets[] = [];
  public brandMaxBarChartLabels: Label[] = [];
  public brandMaxExcelData: ExcelData = { labels: [], data: [] };
  // brand mean chart
  public brandMeanBarChartData: ChartDataSets[] = [];
  public brandMeanBarChartLabels: Label[] = [];
  public brandMeanExcelData: ExcelData = { labels: [], data: [] };
  // brand max chart
  public brandModeBarChartData: ChartDataSets[] = [];
  public brandModeBarChartLabels: Label[] = [];
  public brandModeExcelData: ExcelData = { labels: [], data: [] };

  //Distribution Check
  // country shelf chart
  public countryDCShelfBarChartData: ChartDataSets[] = [];
  public countryDCShelfBarChartLabels: Label[] = [];
  public countryDCShelfExcelData: ExcelData = { labels: [], data: [] };
  // country distribution chart
  public countryDCDistributionBarChartData: ChartDataSets[] = [];
  public countryDCDistributionBarChartLabels: Label[] = [];
  public countryDCDistributionExcelData: ExcelData = { labels: [], data: [] };
  // city shelf chart
  public cityDCShelfBarChartData: ChartDataSets[] = [];
  public cityDCShelfBarChartLabels: Label[] = [];
  public cityDCShelfExcelData: ExcelData = { labels: [], data: [] };
  // city distribution chart
  public cityDCDistributionBarChartData: ChartDataSets[] = [];
  public cityDCDistributionBarChartLabels: Label[] = [];
  public cityDCDistributionExcelData: ExcelData = { labels: [], data: [] };
  // channel shelf chart
  public channelDCShelfBarChartData: ChartDataSets[] = [];
  public channelDCShelfBarChartLabels: Label[] = [];
  public channelDCShelfExcelData: ExcelData = { labels: [], data: [] };
  // channel distribution chart
  public channelDCDistributionBarChartData: ChartDataSets[] = [];
  public channelDCDistributionBarChartLabels: Label[] = [];
  public channelDCDistributionExcelData: ExcelData = { labels: [], data: [] };
  // channel city shelf chart
  public channelCityDCShelfBarChartData: ChartDataSets[] = [];
  public channelCityDCShelfBarChartLabels: Label[] = [];
  public channelCityDCShelfExcelData: ExcelData = { labels: [], data: [] };
  // channel city distribution chart
  public channelCityDCDistributionBarChartData: ChartDataSets[] = [];
  public channelCityDCDistributionBarChartLabels: Label[] = [];
  public channelCityDCDistributionExcelData: ExcelData = { labels: [], data: [] };
  // brand shelf chart
  public brandDCShelfBarChartData: ChartDataSets[] = [];
  public brandDCShelfBarChartLabels: Label[] = [];
  public brandDCShelfExcelData: ExcelData = { labels: [], data: [] };
  // brand distribution chart
  public brandDCDistributionBarChartData: ChartDataSets[] = [];
  public brandDCDistributionBarChartLabels: Label[] = [];
  public brandDCDistributionExcelData: ExcelData = { labels: [], data: [] };
  // sku shelf chart
  public skuDCShelfBarChartData: ChartDataSets[] = [];
  public skuDCShelfBarChartLabels: Label[] = [];
  public skuDCShelfExcelData: ExcelData = { labels: [], data: [] };
  // sku distribution chart
  public skuDCDistributionBarChartData: ChartDataSets[] = [];
  public skuDCDistributionBarChartLabels: Label[] = [];
  public skuDCDistributionExcelData: ExcelData = { labels: [], data: [] };

  //Out of stock
  // city available chart
  public cityOOSAvailableBarChartData: ChartDataSets[] = [];
  public cityOOSAvailableBarChartLabels: Label[] = [];
  public cityOOSAvailableExcelData: ExcelData = { labels: [], data: [] };
  // city not avaiable chart
  public cityOOSNotAvailableBarChartData: ChartDataSets[] = [];
  public cityOOSNotAvailableBarChartLabels: Label[] = [];
  public cityOOSNotAvailableExcelData: ExcelData = { labels: [], data: [] };
  // city less than chart
  public cityOOSLessThanBarChartData: ChartDataSets[] = [];
  public cityOOSLessThanBarChartLabels: Label[] = [];
  public cityOOSLessThanExcelData: ExcelData = { labels: [], data: [] };
  // channel city available chart
  public channelCityOOSAvailableBarChartData: ChartDataSets[] = [];
  public channelCityOOSAvailableBarChartLabels: Label[] = [];
  public channelCityOOSAvailableExcelData: ExcelData = { labels: [], data: [] };
  // channelCity not avaiable chart
  public channelCityOOSNotAvailableBarChartData: ChartDataSets[] = [];
  public channelCityOOSNotAvailableBarChartLabels: Label[] = [];
  public channelCityOOSNotAvailableExcelData: ExcelData = { labels: [], data: [] };
  // channelCity less than chart
  public channelCityOOSLessThanBarChartData: ChartDataSets[] = [];
  public channelCityOOSLessThanBarChartLabels: Label[] = [];
  public channelCityOOSLessThanExcelData: ExcelData = { labels: [], data: [] };
  // month available chart
  public monthOOSAvailableBarChartData: ChartDataSets[] = [];
  public monthOOSAvailableBarChartLabels: Label[] = [];
  public monthOOSAvailableExcelData: ExcelData = { labels: [], data: [] };
  // month not avaiable chart
  public monthOOSNotAvailableBarChartData: ChartDataSets[] = [];
  public monthOOSNotAvailableBarChartLabels: Label[] = [];
  public monthOOSNotAvailableExcelData: ExcelData = { labels: [], data: [] };
  // month less than chart
  public monthOOSLessThanBarChartData: ChartDataSets[] = [];
  public monthOOSLessThanBarChartLabels: Label[] = [];
  public monthOOSLessThanExcelData: ExcelData = { labels: [], data: [] };
  // store available chart
  public storeOOSAvailableBarChartData: ChartDataSets[] = [];
  public storeOOSAvailableBarChartLabels: Label[] = [];
  public storeOOSAvailableExcelData: ExcelData = { labels: [], data: [] };
  // store not available chart
  public storeOOSNotAvailableBarChartData: ChartDataSets[] = [];
  public storeOOSNotAvailableBarChartLabels: Label[] = [];
  public storeOOSNotAvailableExcelData: ExcelData = { labels: [], data: [] };
  // store less than chart
  public storeOOSLessThanBarChartData: ChartDataSets[] = [];
  public storeOOSLessThanBarChartLabels: Label[] = [];
  public storeOOSLessThanExcelData: ExcelData = { labels: [], data: [] };

  //Brand health tracker
  // city shelf chart
  public cityCQShelfBarChartData: ChartDataSets[] = [];
  public cityCQShelfBarChartLabels: Label[] = [];
  public cityCQShelfExcelData: ExcelData = { labels: [], data: [] };
  // city distribution chart
  public cityCQDistributionBarChartData: ChartDataSets[] = [];
  public cityCQDistributionBarChartLabels: Label[] = [];
  public cityCQDistributionExcelData: ExcelData = { labels: [], data: [] };
  // channel shelf chart
  public channelCQShelfBarChartData: ChartDataSets[] = [];
  public channelCQShelfBarChartLabels: Label[] = [];
  public channelCQShelfExcelData: ExcelData = { labels: [], data: [] };
  // channel distribution chart
  public channelCQDistributionBarChartData: ChartDataSets[] = [];
  public channelCQDistributionBarChartLabels: Label[] = [];
  public channelCQDistributionExcelData: ExcelData = { labels: [], data: [] };
  // brand shelf chart
  public brandCQShelfBarChartData: ChartDataSets[] = [];
  public brandCQShelfBarChartLabels: Label[] = [];
  public brandCQShelfExcelData: ExcelData = { labels: [], data: [] };
  // brand distribution chart
  public brandCQDistributionBarChartData: ChartDataSets[] = [];
  public brandCQDistributionBarChartLabels: Label[] = [];
  public brandCQDistributionExcelData: ExcelData = { labels: [], data: [] };
  // sku shelf chart
  public skuCQShelfBarChartData: ChartDataSets[] = [];
  public skuCQShelfBarChartLabels: Label[] = [];
  public skuCQShelfExcelData: ExcelData = { labels: [], data: [] };
  // sku distribution chart
  public skuCQDistributionBarChartData: ChartDataSets[] = [];
  public skuCQDistributionBarChartLabels: Label[] = [];
  public skuCQDistributionExcelData: ExcelData = { labels: [], data: [] };


  constructor(
    private dashboardService: SnapDashboardService,
    private dataService: DataService,
    private router: Router,
    private snapDashboardService: SnapDashboardService
  ) { }

  ngOnInit(): void {
    if (this.currentQnnView == 'pm') {
      this.refreshPriceMonitorChartApis("?month=2022-05-01");
      //this.viewPMSnapData(10, 0, "", "");
    }

    if (this.currentQnnView == 'oos')
      this.refreshOutOfStockChartApis();

    if (this.currentQnnView == 'dc')
      this.refreshDistributionCheckChartApis();

    if (this.currentQnnView == 'cq')
      this.refreshConsumerQuestionnaireChartApis();
  }

  onQnnTypeChange(currentOptionObj) {
    let { currentOptionText, filterString } = currentOptionObj;
    if (currentOptionText == "Price Monitor")
      this.currentQnnView = 'pm'

    if (currentOptionText == "Out Of Stock")
      this.currentQnnView = 'oos'

    if (currentOptionText == "Brand Health Tracker")
      this.currentQnnView = 'cq'

    if (currentOptionText == "Distribution Check")
      this.currentQnnView = 'dc'

    this.filterApplied(filterString)
  }

  filterApplied(filterString) {

    if (filterString) {
      this.currentFilterString = filterString
    }

    if (this.currentQnnView == 'pm')
      this.refreshPriceMonitorChartApis(filterString);
    this.setPMLoader();

    if (this.currentQnnView == 'oos')
      this.refreshOutOfStockChartApis(filterString);
    this.setOOSLoader();

    if (this.currentQnnView == 'dc')
      this.refreshDistributionCheckChartApis(filterString);
    this.setDCLoader();

    if (this.currentQnnView == 'cq')
      this.refreshConsumerQuestionnaireChartApis(filterString);
    this.setcqLoader();
  }
  //loader
  setPMLoader() {
    Object.keys(this.pmLoader).forEach(v => this.pmLoader[v] = false);
  }
  setOOSLoader() {
    Object.keys(this.oosLoader).forEach(v => this.oosLoader[v] = false);
  }
  setcqLoader() {
    Object.keys(this.cqLoader).forEach(v => this.cqLoader[v] = false);
  }
  setDCLoader() {
    Object.keys(this.dcLoader).forEach(v => this.dcLoader[v] = false);
  }
  // main methods
  refreshPriceMonitorChartApis(filterString?) {
    this.pmAllPriceMeasures(filterString);
    this.pmMonthMaxPrice(filterString);
    this.pmMonthMinPrice(filterString);
    this.pmTotalBrandAllPriceMeasures(filterString);
    this.pmMinPriceByCountry(filterString);
    this.pmMonthModePrice(filterString);
    this.pmMonthMeanPrice(filterString);
    this.pmOutletVisitedPerCity(filterString);
    this.pmMaxPriceByCountry(filterString);
    this.pmTotalCountryMeanPrice(filterString);
    this.pmTotalCountryModePrice(filterString);
    this.pmCityMinPrice(filterString);
    this.pmCityMaxPrice(filterString);
    this.pmCityMeanPrice(filterString);
    this.pmCityModePrice(filterString);
    this.pmChannelMinPrice(filterString);
    this.pmChannelMaxPrice(filterString);
    this.pmChannelMeanPrice(filterString);
    this.pmChannelModePrice(filterString);
    this.pmChannelCityMinPrice(filterString);
    this.pmChannelCityMaxPrice(filterString);
    this.pmChannelCityMeanPrice(filterString);
    this.pmChannelCityModePrice(filterString);
    this.pmBrandMinPrice(filterString);
    this.pmBrandMaxPrice(filterString);
    this.pmBrandMeanPrice(filterString);
    this.pmBrandModePrice(filterString);
    // this.pmNumberOfOutletsVisitedByCountry(filterString);
    // this.pmSKUCountByChannel(filterString);
  }

  refreshOutOfStockChartApis(filterString?) {
    this.loadOOSOverall(filterString)
    this.loadOOSMonthAvailable(filterString)
    this.loadOOSMonthNotAvailable(filterString)
    this.loadOOSOOSMonthLess(filterString)
    // this.loadOOSStoreNotAvailable(filterString)
    this.loadOOSCityAvailable(filterString)
    this.loadOOSCityNotAvailable(filterString)
    this.loadOOSTotalVisitsInCity(filterString)
    this.loadOOSCityLess(filterString);
    this.loadOOSWeekNotAvailable(filterString)
    this.loadOOSCity(filterString)
    this.loadOOSChannelCity(filterString)
    this.loadOOSMonth(filterString)
    this.loadOOSStore(filterString)

  }

  refreshDistributionCheckChartApis(filterString?) {
    this.loadDCTotalDistribution(filterString)
    this.loadDCShelfShareByCity(filterString)
    this.loadTotalOutletVisited(filterString)
    this.loadDCCountry(filterString)
    this.loadDCCity(filterString)
    this.loadDCChannel(filterString)
    this.loadDCChannelCity(filterString)
    this.loadDCBrand(filterString)
    this.loadDCSku(filterString)
    // this.loadTotalOutletsOfSkuInCity(filterString)
    // this.loadDCSubmittedQnnByCity(filterString)
    // this.loadDCSubmittedQnnByCountry(filterString)
    // this.loadTotalOutletsOfSkuInCountry(filterString)
    // this.loadTotalOutletsOfSkuInChannel(filterString)
    // this.loadSumOfBrandByCity(filterString)
    // this.loadSumOfBrandByCountry(filterString)
    // this.loadSumOfBrandByChannel(filterString)
    // this.loadDCAvgPerSku(filterString)
    // this.loadDCAvgPerBrand(filterString)
    // this.loadDCShelfShareSkuChannel(filterString)
    // this.loadDCShelfShareBrandChannel(filterString)
  }

  refreshConsumerQuestionnaireChartApis(filterString?) {
    this.loadCQYesNoQuestion(filterString)
    this.loadCQRatingOneToTen(filterString)
    this.loadCQRatingOneToThreeQuestion(filterString)
    this.loadCQRatingOneToFiveQuestion(filterString)
    this.loadCQNumericQuestion(filterString)
    // this.loadCQShelfCity(filterString)
    // this.loadCQDistributionCity(filterString)
    // this.loadCQShelfChannel(filterString)
    // this.loadCQDistributionChannel(filterString)
    // this.loadCQShelfBrand(filterString)
    // this.loadCQDistributionBrand(filterString)
    // this.loadCQShelfSku(filterString)
    // this.loadCQDistributionSku(filterString)
  }

  resetFilter() {

    if (this.currentQnnView == 'pm')
      this.refreshPriceMonitorChartApis();

    if (this.currentQnnView == 'oos')
      this.refreshOutOfStockChartApis();

    if (this.currentQnnView == 'dc')
      this.refreshDistributionCheckChartApis();

    if (this.currentQnnView == 'cq')
      this.refreshConsumerQuestionnaireChartApis();
  }

  // Price Monitor Graph APIs

  pmAllPriceMeasures(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmAllPriceMeasures(filterString)
      .subscribe(
        response => {
          this.pmLoader.minMaxBarChartDataLoaded = true;
          this.barChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let pmMinMaxData = response.data ? [...response.data] : [];
            this.maxDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.max);
            this.minDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.min);
            this.modeDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.mode);
            this.meanDataArray = pmMinMaxData.map(eachSKUData => eachSKUData.mean);

            this.minMaxBarChartLabels = pmMinMaxData.map(eachSKUData => eachSKUData.sku);
            this.minMaxBarChartData = [
              { data: this.maxDataArray, label: 'max' },
              { data: this.minDataArray, label: 'min' },
              { data: this.meanDataArray, label: 'mean' },
              { data: this.modeDataArray, label: 'mode' },
            ];

            this.barChartExcelData.labels = Object.keys(pmMinMaxData[0]);
            this.barChartExcelData.data = pmMinMaxData;

          } else {
            this.minMaxBarChartData = [{ data: [], label: '' }]
            this.minMaxBarChartLabels = []
            this.barChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.pmLoader.minMaxBarChartDataLoaded = true;
          Swal.close();
          this.minMaxBarChartData = [{ data: [], label: '' }]
          this.minMaxBarChartLabels = []
          this.barChartExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching min max data!", true);
        }
      )
  }
  pmMonthMaxPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMonthMaxPrice(filterString)
      .subscribe(
        response => {

          this.pmLoader.monthMaxBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmMonthMaxData = response.data ? [...response.data] : [];
            this.monthMaxBarChartLabels = this.formatMonthAvailableBarData(pmMonthMaxData).uniqueMonth
            this.monthMaxBarChartData = this.formatMonthAvailableBarData(pmMonthMaxData).stackedBarChartData
            this.barChartMonthMaxExcelData.labels = Object.keys(pmMonthMaxData[0]);
            this.barChartMonthMaxExcelData.data = [...pmMonthMaxData];

          } else {
            this.monthMaxBarChartData = [{ data: [], label: '' }]
            this.monthMaxBarChartLabels = []
            this.barChartMonthMaxExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.pmLoader.monthMaxBarChartDataLoaded = true;
          Swal.close();
          this.monthMaxBarChartData = [{ data: [], label: '' }]
          this.monthMaxBarChartLabels = []
          this.barChartMonthMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching month max data!", true);
        }
      )
  }
  pmMonthMinPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMonthMinPrice(filterString)
      .subscribe(
        response => {
          this.pmLoader.monthMinBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmMonthMinData = response.data ? [...response.data] : [];
            // let pmMonthMinData:Array<any> = this.responseBarDataStacked;

            this.monthMinBarChartLabels = this.formatMonthAvailableBarData(pmMonthMinData).uniqueMonth
            this.monthMinBarChartData = this.formatMonthAvailableBarData(pmMonthMinData).stackedBarChartData;


            this.barChartMonthMinExcelData.labels = Object.keys(pmMonthMinData[0]);
            this.barChartMonthMinExcelData.data = [...pmMonthMinData];

          } else {
            this.monthMinBarChartData = [{ data: [], label: '' }]
            this.monthMinBarChartLabels = []
            this.barChartMonthMaxExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.pmLoader.monthMinBarChartDataLoaded = true;
          Swal.close();
          this.handleNotification("Error fetching month min data!", true);
        }
      )
  }
  pmMinPriceByCountry(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMinPriceByCountry(filterString)
      .subscribe(
        response => {
          this.pmLoader.countryMinBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmCountryMinData = response.data ? [...response.data] : [];

            this.countryMinBarChartLabels = this.formatCountryMinBarData(pmCountryMinData).uniqueCountry;
            this.countryMinBarChartData = this.formatCountryMinBarData(pmCountryMinData).stackedBarChartData;

            this.countryMinExcelData.labels = Object.keys(pmCountryMinData[0]);
            this.countryMinExcelData.data = [...pmCountryMinData];

          } else {
            this.countryMinBarChartData = [{ data: [], label: '' }]
            this.countryMinBarChartLabels = []
            this.countryMinExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.countryMinBarChartDataLoaded = true;
          Swal.close();
          this.countryMinBarChartData = [{ data: [], label: '' }]
          this.countryMinBarChartLabels = []
          this.countryMinExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country min data!", true);
        }
      )
  }
  pmMaxPriceByCountry(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmMaxPriceByCountry(filterString)
      .subscribe(
        response => {
          this.pmLoader.countryMaxBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmCountryMaxData = response.data ? [...response.data] : [];

            this.countryMaxBarChartLabels = this.formatCountryMaxBarData(pmCountryMaxData).uniqueCountry;
            this.countryMaxBarChartData = this.formatCountryMaxBarData(pmCountryMaxData).stackedBarChartData;

            this.countryMaxExcelData.labels = Object.keys(pmCountryMaxData[0]);
            this.countryMaxExcelData.data = [...pmCountryMaxData];

          } else {
            this.pmLoader.countryMaxBarChartDataLoaded = true;
            this.countryMaxBarChartData = [{ data: [], label: '' }]
            this.countryMaxBarChartLabels = []
            this.countryMaxExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.countryMaxBarChartData = [{ data: [], label: '' }]
          this.countryMaxBarChartLabels = []
          this.countryMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country max data!", true);
        }
      )
  }
  pmMonthModePrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmMonthModePrice(filterString)
      .subscribe(
        response => {
          this.pmLoader.monthModeBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmMonthModeData = response.data ? [...response.data] : [];
            this.monthModeBarChartLabels = this.formatMonthAvailableBarData(pmMonthModeData).uniqueMonth
            this.monthModeBarChartData = this.formatMonthAvailableBarData(pmMonthModeData).stackedBarChartData;

            this.barChartMonthModeExcelData.labels = Object.keys(pmMonthModeData[0]);
            this.barChartMonthModeExcelData.data = [...pmMonthModeData];

          } else {
            this.monthModeBarChartData = [{ data: [], label: '' }]
            this.monthModeBarChartLabels = []
            this.barChartMonthModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.monthModeBarChartDataLoaded = true;
          Swal.close();
          this.monthModeBarChartData = [{ data: [], label: '' }]
          this.monthModeBarChartLabels = []
          this.barChartMonthModeExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country mode data!", true);
        }
      )
  }
  pmMonthMeanPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMonthMeanPrice(filterString)
      .subscribe(
        response => {
          this.pmLoader.monthMeanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmMonthMeanData = response.data ? [...response.data] : [];

            this.monthMeanBarChartLabels = this.formatMonthAvailableBarData(pmMonthMeanData).uniqueMonth
            this.monthMeanBarChartData = this.formatMonthAvailableBarData(pmMonthMeanData).stackedBarChartData;

            this.barChartMonthMeanExcelData.labels = Object.keys(pmMonthMeanData[0]);
            this.barChartMonthMeanExcelData.data = [...pmMonthMeanData];

          } else {
            this.monthMeanBarChartData = [{ data: [], label: '' }]
            this.monthMeanBarChartLabels = []
            this.barChartMonthMeanExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.monthMeanBarChartDataLoaded = true;
          Swal.close();
          this.monthMeanBarChartData = [{ data: [], label: '' }]
          this.monthMeanBarChartLabels = []
          this.barChartMonthMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country mean data!", true);
        }
      )
  }
  pmTotalCountryModePrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmTotalCountryModePrice(filterString)
      .subscribe(
        response => {
          this.pmLoader.countryModeBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmCountryModeData = response.data ? [...response.data] : [];

            this.countryModeBarChartLabels = this.formatCountryMinBarData(pmCountryModeData).uniqueCountry;
            this.countryModeBarChartData = this.formatCountryMinBarData(pmCountryModeData).stackedBarChartData;

            this.countryModeExcelData.labels = Object.keys(pmCountryModeData[0]);
            this.countryModeExcelData.data = [...pmCountryModeData];

          } else {
            this.pmLoader.countryModeBarChartDataLoaded = true;
            this.countryModeBarChartData = [{ data: [], label: '' }]
            this.countryModeBarChartLabels = []
            this.countryModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.handleNotification("Error fetching country mode data!", true);
        }
      )
  }
  pmTotalCountryMeanPrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmTotalCountryMeanPrice(filterString)
      .subscribe(
        response => {
          this.pmLoader.countryMeanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmCountryMeanData = response.data ? [...response.data] : [];

            this.countryMeanBarChartLabels = this.formatCountryMaxBarData(pmCountryMeanData).uniqueCountry;
            this.countryMeanBarChartData = this.formatCountryMaxBarData(pmCountryMeanData).stackedBarChartData;

            this.countryMeanExcelData.labels = Object.keys(pmCountryMeanData[0]);
            this.countryMeanExcelData.data = [...pmCountryMeanData];

          } else {
            this.countryMeanBarChartData = [{ data: [], label: '' }]
            this.countryMeanBarChartLabels = []
            this.countryMeanExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching country mean data!", true);
          }
        },
        error => {
          this.pmLoader.countryMeanBarChartDataLoaded = true;
          Swal.close();
          this.countryMeanBarChartData = [{ data: [], label: '' }]
          this.countryMeanBarChartLabels = []
          this.countryMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country mean data!", true);
        }
      )
  }
  pmOutletVisitedPerCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmOutletVisitedPerCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.answerPerCityChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let pmAnswerPerCityData = response.data ? [...response.data] : [];
            this.answerPerCityValuesArray = pmAnswerPerCityData.map(eachSKUData => eachSKUData.value);

            this.answerPerCityChartLabels = this.formatAnswerPerCityData(pmAnswerPerCityData).uniqueCity;
            this.answerPerCityChartData = this.formatAnswerPerCityData(pmAnswerPerCityData).stackedBarChartData;

            this.answerPerCityExcelData.labels = Object.keys(pmAnswerPerCityData[0]);
            this.answerPerCityExcelData.data = [...pmAnswerPerCityData];


          } else {
            this.answerPerCityChartData = [{ data: [], label: '' }]
            this.answerPerCityChartLabels = []
            this.answerPerCityExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching country mean data!", true);
          }
        },
        error => {
          this.pmLoader.answerPerCityChartDataLoaded = true;
          Swal.close();
          this.answerPerCityChartData = [{ data: [], label: '' }]
          this.answerPerCityChartLabels = []
          this.answerPerCityExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching country mean data!", true);
        }
      )
  }
  // pmNumberOfOutletsVisitedByCountry(filterString?) {

  //   Swal.showLoading();
  //   this.dashboardService.pmNumberOfOutletsVisitedByCountry(filterString)
  //     .subscribe(
  //       response => {
  //         this.pmLoader.totalAnswersPerCountryPieChartDataLoaded = true;
  //         Swal.close();
  //         if (response && response.success && response.data.length > 0) {

  //           let pmAnswerPerCountryData = response.data ? [...response.data] : [];

  //           this.totalAnswersPerCountryPieChartData = pmAnswerPerCountryData.map(eachAnswerByCountry => eachAnswerByCountry.value);
  //           this.totalAnswersPerCountryPieChartLabels = pmAnswerPerCountryData.map(eachAnswerByCountry => eachAnswerByCountry.country);
  //           this.totalAnswersPerCountryPieChartExcelData.labels = Object.keys(pmAnswerPerCountryData[0]);
  //           this.totalAnswersPerCountryPieChartExcelData.data = [...pmAnswerPerCountryData];
  //         } else {
  //           this.totalAnswersPerCountryPieChartData = [{ data: [], label: '' }]
  //           this.totalAnswersPerCountryPieChartLabels = []
  //           this.totalAnswersPerCountryPieChartExcelData = { labels: [], data: [] };
  //         }
  //       },
  //       error => {
  //         this.pmLoader.totalAnswersPerCountryPieChartDataLoaded = true;
  //         Swal.close();
  //         this.totalAnswersPerCountryPieChartData = [{ data: [], label: '' }]
  //         this.totalAnswersPerCountryPieChartLabels = []
  //         this.totalAnswersPerCountryPieChartExcelData = { labels: [], data: [] };
  //         this.handleNotification("Error in fetching total answers per country data!", true);
  //       }
  //     )
  // }

  // pmSKUCountByChannel(filterString?) {

  //   Swal.showLoading();
  //   this.dashboardService.pmSKUCountByChannel(filterString)
  //     .subscribe(
  //       response => {
  //         this.pmLoader.totalAnswersPerSkuPieChartDataLoaded = true;
  //         Swal.close();
  //         if (response && response.success && response.data.length > 0) {

  //           var x= response.data ? [...response.data] : [];
  //           let pmAnswerPerSkuData = x.map(o=>({...o,value:parseInt(o.value)}))
  //           this.totalAnswersPerSkuPieChartData = pmAnswerPerSkuData.map(eachAnswerBySku => eachAnswerBySku.value);

  //           this.totalAnswersPerSkuPieChartLabels = pmAnswerPerSkuData.map(eachAnswerBySku => eachAnswerBySku.sku);

  //           this.totalAnswersPerSkuPieChartExcelData.labels = Object.keys(pmAnswerPerSkuData[0]);
  //           this.totalAnswersPerSkuPieChartExcelData.data = [...pmAnswerPerSkuData];

  //         } else {
  //           this.totalAnswersPerSkuPieChartData = [{ data: [], label: '' }]
  //           this.totalAnswersPerSkuPieChartLabels = []
  //           this.totalAnswersPerSkuPieChartExcelData = { labels: [], data: [] };
  //           //  this.handleNotification("Problem in fetching total answers per Sku data!", true);
  //         }

  //       },
  //       error => {
  //         this.pmLoader.totalAnswersPerSkuPieChartDataLoaded = true;
  //         Swal.close();
  //         this.totalAnswersPerSkuPieChartData = [{ data: [], label: '' }]
  //         this.totalAnswersPerSkuPieChartLabels = []
  //         this.totalAnswersPerSkuPieChartExcelData = { labels: [], data: [] };
  //         this.handleNotification("Error in fetching total answers per Sku data!", true);
  //       }
  //     )
  // }
  pmTotalBrandAllPriceMeasures(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmTotalBrandAllPriceMeasures(filterString)
      .subscribe(
        response => {
          this.pmLoader.brandVsAvgChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let pmBrandVsAvgData = response.data ? [...response.data] : [];

            this.brandVsAvgMaxDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.max);
            this.brandVsAvgMinDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.min);
            this.brandVsAvgModeDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.mode);
            this.brandVsAvgMeanDataArray = pmBrandVsAvgData.map(eachSKUData => eachSKUData.mean);

            this.brandVsAvgChartLabels = pmBrandVsAvgData.map(eachSKUData => eachSKUData.brand);
            this.brandVsAvgChartData = [
              { data: this.brandVsAvgMaxDataArray, label: 'max' },
              { data: this.brandVsAvgMinDataArray, label: 'min' },
              { data: this.brandVsAvgMeanDataArray, label: 'mean' },
              { data: this.brandVsAvgModeDataArray, label: 'mode' },
            ];

            this.brandVsAvgExcelData.labels = Object.keys(pmBrandVsAvgData[0]);
            this.brandVsAvgExcelData.data = [...pmBrandVsAvgData];

          } else {
            this.brandVsAvgChartData = [{ data: [], label: '' }]
            this.brandVsAvgChartLabels = []
            this.brandVsAvgExcelData = { labels: [], data: [] };
            //  this.handleNotification("Problem in fetching brand vs avg data!", true);
          }
        },
        error => {
          this.pmLoader.brandVsAvgChartDataLoaded = true;
          Swal.close();
          this.brandVsAvgChartData = [{ data: [], label: '' }]
          this.brandVsAvgChartLabels = []
          this.brandVsAvgExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching brand vs avg data!", true);
        }
      )
  }

  //City chart
  pmCityMinPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMinPriceByCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.cityMinBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            let cityMinData = x.map(o => ({ ...o, months: o.month + "," + o.city }))
            this.cityMinBarChartLabels = this.formatChannelBarData(cityMinData).uniqueMonth
            this.cityMinBarChartData = this.formatChannelBarData(cityMinData).stackedBarChartData;

            this.cityMaxExcelData.labels = Object.keys(cityMinData[0]).filter(o => o !== "months");
            this.cityMaxExcelData.data = cityMinData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.cityMinBarChartDataLoaded = true;
            this.cityMinBarChartData = [{ data: [], label: '' }]
            this.cityMinBarChartLabels = []
            this.cityMinExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.cityMinBarChartDataLoaded = true;
          Swal.close();
          this.cityMinBarChartData = [{ data: [], label: '' }]
          this.cityMinBarChartLabels = []
          this.cityMinExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city min data!", true);
        }
      )
  }
  pmCityMaxPrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmMaxPriceByCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.cityMaxBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let cityMaxData = x.map(o => ({ ...o, months: o.month + "," + o.city }))
            this.cityMaxBarChartLabels = this.formatChannelBarData(cityMaxData).uniqueMonth
            this.cityMaxBarChartData = this.formatChannelBarData(cityMaxData).stackedBarChartData;

            this.cityMaxExcelData.labels = Object.keys(cityMaxData[0]).filter(o => o !== "months");
            this.cityMaxExcelData.data = cityMaxData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.cityMaxBarChartDataLoaded = true;
            this.cityMaxBarChartData = [{ data: [], label: '' }]
            this.cityMaxBarChartLabels = []
            this.cityMaxExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.cityMaxBarChartDataLoaded = true;
          Swal.close();
          this.cityMaxBarChartData = [{ data: [], label: '' }]
          this.cityMaxBarChartLabels = []
          this.cityMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city max data!", true);
        }
      )
  }
  pmCityModePrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmModePriceByCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.cityModeBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let cityModeData = x.map(o => ({ ...o, months: o.month + "," + o.city }))
            this.cityModeBarChartLabels = this.formatChannelBarData(cityModeData).uniqueMonth
            this.cityModeBarChartData = this.formatChannelBarData(cityModeData).stackedBarChartData;

            this.cityModeExcelData.labels = Object.keys(cityModeData[0]).filter(o => o !== "months");
            this.cityModeExcelData.data = cityModeData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.cityModeBarChartDataLoaded = true;
            this.cityModeBarChartData = [{ data: [], label: '' }]
            this.cityModeBarChartLabels = []
            this.cityModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.cityModeBarChartDataLoaded = true;
          Swal.close();
          this.cityModeBarChartData = [{ data: [], label: '' }]
          this.cityModeBarChartLabels = []
          this.cityModeExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city mode data!", true);
        }
      )
  }
  pmCityMeanPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMeanPriceByCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.cityMeanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let cityMeanData = x.map(o => ({ ...o, months: o.month + "," + o.city }))
            this.cityMeanBarChartLabels = this.formatChannelBarData(cityMeanData).uniqueMonth
            this.cityMeanBarChartData = this.formatChannelBarData(cityMeanData).stackedBarChartData;

            this.cityMeanExcelData.labels = Object.keys(cityMeanData[0]).filter(o => o !== "months");
            this.cityMeanExcelData.data = cityMeanData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.cityMeanBarChartDataLoaded = true;
            this.cityMeanBarChartData = [{ data: [], label: '' }]
            this.cityMeanBarChartLabels = []
            this.cityMeanExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.cityMeanBarChartDataLoaded = true;
          Swal.close();
          this.cityMeanBarChartData = [{ data: [], label: '' }]
          this.cityMeanBarChartLabels = []
          this.cityMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city mean data!", true);
        }
      )
  }

  //Channel chart
  pmChannelMinPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMinPriceByChannel(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelMinBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            let channelMinData = x.map(o => ({ ...o, months: o.month + "," + o.channel }))
            this.channelMinBarChartLabels = this.formatChannelBarData(channelMinData).uniqueMonth
            this.channelMinBarChartData = this.formatChannelBarData(channelMinData).stackedBarChartData;

            this.channelMaxExcelData.labels = Object.keys(channelMinData[0]).filter(o => o !== "months");
            this.channelMaxExcelData.data = channelMinData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelMinBarChartDataLoaded = true;
            this.channelMinBarChartData = [{ data: [], label: '' }]
            this.channelMinBarChartLabels = []
            this.channelMinExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelMinBarChartDataLoaded = true;
          Swal.close();
          this.channelMinBarChartData = [{ data: [], label: '' }]
          this.channelMinBarChartLabels = []
          this.channelMinExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channel min data!", true);
        }
      )
  }
  pmChannelMaxPrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmMaxPriceByChannel(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelMaxBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let channelMaxData = x.map(o => ({ ...o, months: o.month + "," + o.channel }))
            this.channelMaxBarChartLabels = this.formatChannelBarData(channelMaxData).uniqueMonth
            this.channelMaxBarChartData = this.formatChannelBarData(channelMaxData).stackedBarChartData;

            this.channelMaxExcelData.labels = Object.keys(channelMaxData[0]).filter(o => o !== "months");
            this.channelMaxExcelData.data = channelMaxData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelMaxBarChartDataLoaded = true;
            this.channelMaxBarChartData = [{ data: [], label: '' }]
            this.channelMaxBarChartLabels = []
            this.channelMaxExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelMaxBarChartDataLoaded = true;
          Swal.close();
          this.channelMaxBarChartData = [{ data: [], label: '' }]
          this.channelMaxBarChartLabels = []
          this.channelMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channel max data!", true);
        }
      )
  }
  pmChannelModePrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmModePriceByChannel(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelModeBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let channelModeData = x.map(o => ({ ...o, months: o.month + "," + o.channel }))
            this.channelModeBarChartLabels = this.formatChannelBarData(channelModeData).uniqueMonth
            this.channelModeBarChartData = this.formatChannelBarData(channelModeData).stackedBarChartData;

            this.channelModeExcelData.labels = Object.keys(channelModeData[0]).filter(o => o !== "months");
            this.channelModeExcelData.data = channelModeData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelModeBarChartDataLoaded = true;
            this.channelModeBarChartData = [{ data: [], label: '' }]
            this.channelModeBarChartLabels = []
            this.channelModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelModeBarChartDataLoaded = true;
          Swal.close();
          this.channelModeBarChartData = [{ data: [], label: '' }]
          this.channelModeBarChartLabels = []
          this.channelModeExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channel mode data!", true);
        }
      )
  }
  pmChannelMeanPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMeanPriceByChannel(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelMeanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let channelMeanData = x.map(o => ({ ...o, months: o.month + "," + o.channel }))
            this.channelMeanBarChartLabels = this.formatChannelBarData(channelMeanData).uniqueMonth
            this.channelMeanBarChartData = this.formatChannelBarData(channelMeanData).stackedBarChartData;

            this.channelMeanExcelData.labels = Object.keys(channelMeanData[0]).filter(o => o !== "months");
            this.channelMeanExcelData.data = channelMeanData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelMeanBarChartDataLoaded = true;
            this.channelMeanBarChartData = [{ data: [], label: '' }]
            this.channelMeanBarChartLabels = []
            this.channelMeanExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelMeanBarChartDataLoaded = true;
          Swal.close();
          this.channelMeanBarChartData = [{ data: [], label: '' }]
          this.channelMeanBarChartLabels = []
          this.channelMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channel mean data!", true);
        }
      )
  }
  //Channel city chart
  pmChannelCityMinPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMinPriceByChannelCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelCityMinBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            let channelCityMinData = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            this.channelCityMinBarChartLabels = this.formatChannelCityBarData(channelCityMinData).uniqueCity
            this.channelCityMinBarChartData = this.formatChannelCityBarData(channelCityMinData).stackedBarChartData;

            this.channelCityMaxExcelData.labels = Object.keys(channelCityMinData[0]).filter(o => o !== "months");
            this.channelCityMaxExcelData.data = channelCityMinData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelCityMinBarChartDataLoaded = true;
            this.channelCityMinBarChartData = [{ data: [], label: '' }]
            this.channelCityMinBarChartLabels = []
            this.channelCityMinExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelCityMinBarChartDataLoaded = true;
          Swal.close();
          this.channelCityMinBarChartData = [{ data: [], label: '' }]
          this.channelCityMinBarChartLabels = []
          this.channelCityMinExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channelCity min data!", true);
        }
      )
  }
  pmChannelCityMaxPrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmMaxPriceByChannelCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelCityMaxBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let channelCityMaxData = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            this.channelCityMaxBarChartLabels = this.formatChannelCityBarData(channelCityMaxData).uniqueCity
            this.channelCityMaxBarChartData = this.formatChannelCityBarData(channelCityMaxData).stackedBarChartData;

            this.channelCityMaxExcelData.labels = Object.keys(channelCityMaxData[0]).filter(o => o !== "months");
            this.channelCityMaxExcelData.data = channelCityMaxData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelCityMaxBarChartDataLoaded = true;
            this.channelCityMaxBarChartData = [{ data: [], label: '' }]
            this.channelCityMaxBarChartLabels = []
            this.channelCityMaxExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelCityMaxBarChartDataLoaded = true;
          Swal.close();
          this.channelCityMaxBarChartData = [{ data: [], label: '' }]
          this.channelCityMaxBarChartLabels = []
          this.channelCityMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channelCity max data!", true);
        }
      )
  }
  pmChannelCityModePrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmModePriceByChannelCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelCityModeBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let channelCityModeData =x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            this.channelCityModeBarChartLabels = this.formatChannelCityBarData(channelCityModeData).uniqueCity
            this.channelCityModeBarChartData = this.formatChannelCityBarData(channelCityModeData).stackedBarChartData;

            this.channelCityModeExcelData.labels = Object.keys(channelCityModeData[0]).filter(o => o !== "months");
            this.channelCityModeExcelData.data = channelCityModeData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelCityModeBarChartDataLoaded = true;
            this.channelCityModeBarChartData = [{ data: [], label: '' }]
            this.channelCityModeBarChartLabels = []
            this.channelCityModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelCityModeBarChartDataLoaded = true;
          Swal.close();
          this.channelCityModeBarChartData = [{ data: [], label: '' }]
          this.channelCityModeBarChartLabels = []
          this.channelCityModeExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channelCity mode data!", true);
        }
      )
  }
  pmChannelCityMeanPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMeanPriceByChannelCity(filterString)
      .subscribe(
        response => {
          this.pmLoader.channelCityMeanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let channelCityMeanData = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            this.channelCityMeanBarChartLabels = this.formatChannelCityBarData(channelCityMeanData).uniqueCity
            this.channelCityMeanBarChartData = this.formatChannelCityBarData(channelCityMeanData).stackedBarChartData;

            this.channelCityMeanExcelData.labels = Object.keys(channelCityMeanData[0]).filter(o => o !== "months");
            this.channelCityMeanExcelData.data = channelCityMeanData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.channelCityMeanBarChartDataLoaded = true;
            this.channelCityMeanBarChartData = [{ data: [], label: '' }]
            this.channelCityMeanBarChartLabels = []
            this.channelCityMeanExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.channelCityMeanBarChartDataLoaded = true;
          Swal.close();
          this.channelCityMeanBarChartData = [{ data: [], label: '' }]
          this.channelCityMeanBarChartLabels = []
          this.channelCityMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching channelCity mean data!", true);
        }
      )
  }

  //Brand chart
  pmBrandMinPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMinPriceByBrand(filterString)
      .subscribe(
        response => {
          this.pmLoader.brandMinBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            let brandMinData = x.map(o => ({ ...o, months: o.month + "," + o.brand }))
            this.brandMinBarChartLabels = this.formatChannelBarData(brandMinData).uniqueMonth
            this.brandMinBarChartData = this.formatChannelBarData(brandMinData).stackedBarChartData;

            this.brandMaxExcelData.labels = Object.keys(brandMinData[0]).filter(o => o !== "months");
            this.brandMaxExcelData.data = brandMinData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.brandMinBarChartDataLoaded = true;
            this.brandMinBarChartData = [{ data: [], label: '' }]
            this.brandMinBarChartLabels = []
            this.brandMinExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.brandMinBarChartDataLoaded = true;
          Swal.close();
          this.brandMinBarChartData = [{ data: [], label: '' }]
          this.brandMinBarChartLabels = []
          this.brandMinExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching brand min data!", true);
        }
      )
  }
  pmBrandMaxPrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmMaxPriceByBrand(filterString)
      .subscribe(
        response => {
          this.pmLoader.brandMaxBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let brandMaxData = x.map(o => ({ ...o, months: o.month + "," + o.brand }))
            this.brandMaxBarChartLabels = this.formatChannelBarData(brandMaxData).uniqueMonth
            this.brandMaxBarChartData = this.formatChannelBarData(brandMaxData).stackedBarChartData;

            this.brandMaxExcelData.labels = Object.keys(brandMaxData[0]).filter(o => o !== "months");
            this.brandMaxExcelData.data = brandMaxData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.brandMaxBarChartDataLoaded = true;
            this.brandMaxBarChartData = [{ data: [], label: '' }]
            this.brandMaxBarChartLabels = []
            this.brandMaxExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.brandMaxBarChartDataLoaded = true;
          Swal.close();
          this.brandMaxBarChartData = [{ data: [], label: '' }]
          this.brandMaxBarChartLabels = []
          this.brandMaxExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching brand max data!", true);
        }
      )
  }
  pmBrandModePrice(filterString?) {
    Swal.showLoading();
    this.dashboardService.pmModePriceByBrand(filterString)
      .subscribe(
        response => {
          this.pmLoader.brandModeBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let brandModeData = x.map(o => ({ ...o, months: o.month + "," + o.brand }))
            this.brandModeBarChartLabels = this.formatChannelBarData(brandModeData).uniqueMonth
            this.brandModeBarChartData = this.formatChannelBarData(brandModeData).stackedBarChartData;

            this.brandModeExcelData.labels = Object.keys(brandModeData[0]).filter(o => o !== "months");
            this.brandModeExcelData.data = brandModeData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.brandModeBarChartDataLoaded = true;
            this.brandModeBarChartData = [{ data: [], label: '' }]
            this.brandModeBarChartLabels = []
            this.brandModeExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.brandModeBarChartDataLoaded = true;
          Swal.close();
          this.brandModeBarChartData = [{ data: [], label: '' }]
          this.brandModeBarChartLabels = []
          this.brandModeExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching brand mode data!", true);
        }
      )
  }
  pmBrandMeanPrice(filterString?) {

    Swal.showLoading();
    this.dashboardService.pmMeanPriceByBrand(filterString)
      .subscribe(
        response => {
          this.pmLoader.brandMeanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            var x = response.data ? [...response.data] : [];
            let brandMeanData = x.map(o => ({ ...o, months: o.month + "," + o.brand }))
            this.brandMeanBarChartLabels = this.formatChannelBarData(brandMeanData).uniqueMonth
            this.brandMeanBarChartData = this.formatChannelBarData(brandMeanData).stackedBarChartData;

            this.brandMeanExcelData.labels = Object.keys(brandMeanData[0]).filter(o => o !== "months");
            this.brandMeanExcelData.data = brandMeanData.map(o => { delete o.months; return o });

          } else {
            this.pmLoader.brandMeanBarChartDataLoaded = true;
            this.brandMeanBarChartData = [{ data: [], label: '' }]
            this.brandMeanBarChartLabels = []
            this.brandMeanExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.pmLoader.brandMeanBarChartDataLoaded = true;
          Swal.close();
          this.brandMeanBarChartData = [{ data: [], label: '' }]
          this.brandMeanBarChartLabels = []
          this.brandMeanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching brand mean data!", true);
        }
      )
  }

  //format data
  formatCountryMaxBarData(dataToFormat: Array<any>) {
    // let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))],
    //   order = Object.fromEntries(uniqueSku.map((v, i) => ([v, i]))),
    //   stackedBarChartData = Object.values(dataToFormat.reduce((r, { country, value, sku }) => { r[country] ||= { data: Array(uniqueSku.length).fill(null), label: country }; r[country].data[order[sku]] = value; return r; }, {}));
    // return {
    //   stackedBarChartData,
    //   uniqueSku
    // }
    let uniqueCountry = [...new Set(dataToFormat.map(item => item.country))],
      order = Object.fromEntries(uniqueCountry.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { country, value, sku }) => { r[sku] ||= { data: Array(uniqueCountry.length).fill(null), label: sku }; r[sku].data[order[country]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCountry
    }
  }
  formatStackedBarData(dataToFormat: Array<any>) {
    let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))],
      order = Object.fromEntries(uniqueSku.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { month, value, sku }) => { r[month] ||= { data: Array(uniqueSku.length).fill(null), label: month, stack: 'sameStack' }; r[month].data[order[sku]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueSku
    }
  }
  formatCountryMinBarData(dataToFormat: Array<any>) {
    let uniqueCountry = [...new Set(dataToFormat.map(item => item.country))],
      order = Object.fromEntries(uniqueCountry.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { country, value, sku }) => { r[sku] ||= { data: Array(uniqueCountry.length).fill(null), label: sku }; r[sku].data[order[country]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCountry
    }
  }
  formatAnswerPerCityData(dataToFormat: Array<any>) {
    // let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
    // let stackedBarChartData = []
    // let values = []
    // uniqueCities.forEach(uniqueCity => {
    //   dataToFormat.forEach(dTF => {
    //     if (dTF.city == uniqueCity) {
    //       values.push(dTF.value);
    //     }
    //   })
    // })
    // stackedBarChartData.push({ data: values, label: 'Outlets' })

    // return {
    //   stackedBarChartData,
    //   uniqueCities
    // }


    // let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))],
    //   order = Object.fromEntries(uniqueSku.map((v, i) => ([v, i]))),
    //   stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, sku }) => { r[city] ||= { data: Array(uniqueSku.length).fill(null), label: city, stack: 'sameStack' }; r[city].data[order[sku]] = value; return r; }, {}));
    // return {
    //   stackedBarChartData,
    //   uniqueSku
    // }
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }


  // Out of Stock Graph APIs

  //City chart
  loadOOSCity(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSByCity(filterString)
      .subscribe(
        response => {
          this.oosLoader.cityAvailableBarChartDataLoaded = true;
          this.oosLoader.cityNotAvailableBarChartDataLoaded = true;
          this.oosLoader.cityLessThanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Available calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.city }));
            let cityAvailableData = data.map(o => { delete o.not_available_by_city,delete o.less_available_by_city; return o });
            this.cityOOSAvailableBarChartLabels = this.formatAvailableCityData(cityAvailableData).uniqueMonth
            this.cityOOSAvailableBarChartData = this.formatAvailableCityData(cityAvailableData).stackedBarChartData;
            this.cityOOSAvailableExcelData.labels = Object.keys(cityAvailableData[0]).filter(o => o !== "months");
            this.cityOOSAvailableExcelData.data = cityAvailableData.map(o => { delete o.months; return o });

            // Not Available calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.city }));
            let cityNotAvailableData = data.map(o => { delete o.available_by_city,delete o.less_available_by_city; return o });
            this.cityOOSNotAvailableBarChartLabels = this.formatNotAvailableCityData(cityNotAvailableData).uniqueMonth
            this.cityOOSNotAvailableBarChartData = this.formatNotAvailableCityData(cityNotAvailableData).stackedBarChartData;
            this.cityOOSNotAvailableExcelData.labels = Object.keys(cityNotAvailableData[0]).filter(o => o !== "months");
            this.cityOOSNotAvailableExcelData.data = cityNotAvailableData.map(o => { delete o.months; return o });

            // Less Than calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.city }));
            let cityLessThanData = data.map(o => { delete o.not_available_by_city,delete o.available_by_city; return o });
            this.cityOOSLessThanBarChartLabels = this.formatLessAvailableCityData(cityLessThanData).uniqueMonth
            this.cityOOSLessThanBarChartData = this.formatLessAvailableCityData(cityLessThanData).stackedBarChartData;
            this.cityOOSLessThanExcelData.labels = Object.keys(cityLessThanData[0]).filter(o => o !== "months");
            this.cityOOSLessThanExcelData.data = cityLessThanData.map(o => { delete o.months; return o });

          } else {
            this.oosLoader.cityAvailableBarChartDataLoaded = true;
            this.oosLoader.cityNotAvailableBarChartDataLoaded = true;
            this.oosLoader.cityLessThanBarChartDataLoaded = true;
            this.cityOOSAvailableBarChartData = [{ data: [], label: '' }]
            this.cityOOSAvailableBarChartLabels = []
            this.cityOOSAvailableExcelData = { labels: [], data: [] };

            this.cityOOSNotAvailableBarChartData = [{ data: [], label: '' }]
            this.cityOOSNotAvailableBarChartLabels = []
            this.cityOOSNotAvailableExcelData = { labels: [], data: [] };

            this.cityOOSLessThanBarChartData = [{ data: [], label: '' }]
            this.cityOOSLessThanBarChartLabels = []
            this.cityOOSLessThanExcelData = { labels: [], data: [] };


          }
        },
        error => {
          this.oosLoader.cityAvailableBarChartDataLoaded = true;
          this.oosLoader.cityNotAvailableBarChartDataLoaded = true;
          this.oosLoader.cityLessThanBarChartDataLoaded = true;
          Swal.close();
          this.cityOOSAvailableBarChartData = [{ data: [], label: '' }]
          this.cityOOSAvailableBarChartLabels = []
          this.cityOOSAvailableExcelData = { labels: [], data: [] };

          this.cityOOSNotAvailableBarChartData = [{ data: [], label: '' }]
          this.cityOOSNotAvailableBarChartLabels = []
          this.cityOOSNotAvailableExcelData = { labels: [], data: [] };

          this.cityOOSLessThanBarChartData = [{ data: [], label: '' }]
          this.cityOOSLessThanBarChartLabels = []
          this.cityOOSLessThanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching OOS city data!", true);
        }
      )
  }
  loadOOSChannelCity(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSByChannelCity(filterString)
      .subscribe(
        response => {
          console.log(response,"rescc");
          this.oosLoader.channelCityAvailableBarChartDataLoaded = true;
          this.oosLoader.channelCityNotAvailableBarChartDataLoaded = true;
          this.oosLoader.channelCityLessThanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Available calculation
            var data = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            let channelCityAvailableData = data.map(o => { delete o.not_available_by_city,delete o.less_available_by_city; return o });
            this.channelCityOOSAvailableBarChartLabels = this.formatAvailableChannelCityData(channelCityAvailableData).uniqueMonth
            this.channelCityOOSAvailableBarChartData = this.formatAvailableChannelCityData(channelCityAvailableData).stackedBarChartData;
            this.channelCityOOSAvailableExcelData.labels = Object.keys(channelCityAvailableData[0]).filter(o => o !== "months");
            this.channelCityOOSAvailableExcelData.data = channelCityAvailableData.map(o => { delete o.months; return o });

            // Not Available calculation
            var data = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            let channelCityNotAvailableData = data.map(o => { delete o.available_by_city,delete o.less_available_by_city; return o });
            this.channelCityOOSNotAvailableBarChartLabels = this.formatNotAvailableChannelCityData(channelCityNotAvailableData).uniqueMonth
            this.channelCityOOSNotAvailableBarChartData = this.formatNotAvailableChannelCityData(channelCityNotAvailableData).stackedBarChartData;
            this.channelCityOOSNotAvailableExcelData.labels = Object.keys(channelCityNotAvailableData[0]).filter(o => o !== "months");
            this.channelCityOOSNotAvailableExcelData.data = channelCityNotAvailableData.map(o => { delete o.months; return o });

            // Less Than calculation
            var data = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            let channelCityLessThanData = data.map(o => { delete o.not_available_by_city,delete o.available_by_city; return o });
            this.channelCityOOSLessThanBarChartLabels = this.formatLessAvailableCityData(channelCityLessThanData).uniqueMonth
            this.channelCityOOSLessThanBarChartData = this.formatLessAvailableCityData(channelCityLessThanData).stackedBarChartData;
            this.channelCityOOSLessThanExcelData.labels = Object.keys(channelCityLessThanData[0]).filter(o => o !== "months");
            this.channelCityOOSLessThanExcelData.data = channelCityLessThanData.map(o => { delete o.months; return o });

          } else {
            this.oosLoader.channelCityAvailableBarChartDataLoaded = true;
            this.oosLoader.channelCityNotAvailableBarChartDataLoaded = true;
            this.oosLoader.channelCityLessThanBarChartDataLoaded = true;
            this.channelCityOOSAvailableBarChartData = [{ data: [], label: '' }]
            this.channelCityOOSAvailableBarChartLabels = []
            this.channelCityOOSAvailableExcelData = { labels: [], data: [] };

            this.channelCityOOSNotAvailableBarChartData = [{ data: [], label: '' }]
            this.channelCityOOSNotAvailableBarChartLabels = []
            this.channelCityOOSNotAvailableExcelData = { labels: [], data: [] };

            this.channelCityOOSLessThanBarChartData = [{ data: [], label: '' }]
            this.channelCityOOSLessThanBarChartLabels = []
            this.channelCityOOSLessThanExcelData = { labels: [], data: [] };


          }
        },
        error => {
          this.oosLoader.channelCityAvailableBarChartDataLoaded = true;
          this.oosLoader.channelCityNotAvailableBarChartDataLoaded = true;
          this.oosLoader.channelCityLessThanBarChartDataLoaded = true;
          Swal.close();
          this.channelCityOOSAvailableBarChartData = [{ data: [], label: '' }]
          this.channelCityOOSAvailableBarChartLabels = []
          this.channelCityOOSAvailableExcelData = { labels: [], data: [] };

          this.channelCityOOSNotAvailableBarChartData = [{ data: [], label: '' }]
          this.channelCityOOSNotAvailableBarChartLabels = []
          this.channelCityOOSNotAvailableExcelData = { labels: [], data: [] };

          this.channelCityOOSLessThanBarChartData = [{ data: [], label: '' }]
          this.channelCityOOSLessThanBarChartLabels = []
          this.channelCityOOSLessThanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching OOS channelCity data!", true);
        }
      )
  }
  //Month chart
  loadOOSMonth(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSByMonth(filterString)
      .subscribe(
        response => {
          this.oosLoader.monthAvailableBarChartDataLoaded = true;
          this.oosLoader.monthNotAvailableBarChartDataLoaded = true;
          this.oosLoader.monthLessThanBarChartDataLoaded = true;
          Swal.close();
          console.log(response,"res");
          if (response && response.success && response.data.length > 0) {
            let x = response.data ? [...response.data] : [];

            // Available calculation
            let monthAvailableData = x.map(o => {  return o });
            this.monthOOSAvailableBarChartLabels = this.formatAvailableMonthData(monthAvailableData).uniqueMonth
            this.monthOOSAvailableBarChartData = this.formatAvailableMonthData(monthAvailableData).stackedBarChartData;
            this.monthOOSAvailableExcelData.labels = Object.keys(monthAvailableData[0]);
            this.monthOOSAvailableExcelData.data = monthAvailableData;
            // Not Available calculation
            let monthNotAvailableData = x.map(o => { return o });
            this.monthOOSNotAvailableBarChartLabels = this.formatNotAvailableMonthData(monthNotAvailableData).uniqueMonth
            this.monthOOSNotAvailableBarChartData = this.formatNotAvailableMonthData(monthNotAvailableData).stackedBarChartData;
            this.monthOOSNotAvailableExcelData.labels = Object.keys(monthNotAvailableData[0])
            this.monthOOSNotAvailableExcelData.data = monthNotAvailableData

            // Less Than calculation
            let monthLessThanData = x.map(o => { return o });
            this.monthOOSLessThanBarChartLabels = this.formatLessAvailableMonthData(monthLessThanData).uniqueMonth
            this.monthOOSLessThanBarChartData = this.formatLessAvailableMonthData(monthLessThanData).stackedBarChartData;
            this.monthOOSLessThanExcelData.labels = Object.keys(monthLessThanData[0])
            this.monthOOSLessThanExcelData.data = monthLessThanData

          } else {
            this.oosLoader.monthAvailableBarChartDataLoaded = true;
            this.oosLoader.monthNotAvailableBarChartDataLoaded = true;
            this.oosLoader.monthLessThanBarChartDataLoaded = true;
            this.monthOOSAvailableBarChartData = [{ data: [], label: '' }]
            this.monthOOSAvailableBarChartLabels = []
            this.monthOOSAvailableExcelData = { labels: [], data: [] };

            this.monthOOSNotAvailableBarChartData = [{ data: [], label: '' }]
            this.monthOOSNotAvailableBarChartLabels = []
            this.monthOOSNotAvailableExcelData = { labels: [], data: [] };

            this.monthOOSLessThanBarChartData = [{ data: [], label: '' }]
            this.monthOOSLessThanBarChartLabels = []
            this.monthOOSLessThanExcelData = { labels: [], data: [] };


          }
        },
        error => {
          this.oosLoader.monthAvailableBarChartDataLoaded = true;
          this.oosLoader.monthNotAvailableBarChartDataLoaded = true;
          this.oosLoader.monthLessThanBarChartDataLoaded = true;
          Swal.close();
          this.monthOOSAvailableBarChartData = [{ data: [], label: '' }]
          this.monthOOSAvailableBarChartLabels = []
          this.monthOOSAvailableExcelData = { labels: [], data: [] };

          this.monthOOSNotAvailableBarChartData = [{ data: [], label: '' }]
          this.monthOOSNotAvailableBarChartLabels = []
          this.monthOOSNotAvailableExcelData = { labels: [], data: [] };

          this.monthOOSLessThanBarChartData = [{ data: [], label: '' }]
          this.monthOOSLessThanBarChartLabels = []
          this.monthOOSLessThanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching OOS month data!", true);
        }
      )
  }
  //Sku chart
  loadOOSStore(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSByStore(filterString)
      .subscribe(
        response => {
          this.oosLoader.storeAvailableBarChartDataLoaded = true;
          this.oosLoader.storeNotAvailableBarChartDataLoaded = true;
          this.oosLoader.storeLessThanBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Available calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.store }));
            let storeAvailableData = data.map(o => { delete o.not_available_by_store,delete o.less_available_by_store; return o });
            this.storeOOSAvailableBarChartLabels = this.formatAvailableStoreData(storeAvailableData).uniqueMonth
            this.storeOOSAvailableBarChartData = this.formatAvailableStoreData(storeAvailableData).stackedBarChartData;
            this.storeOOSAvailableExcelData.labels = Object.keys(storeAvailableData[0]).filter(o => o !== "months");
            this.storeOOSAvailableExcelData.data = storeAvailableData.map(o => { delete o.months; return o });

            // Not Available calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.store }));
            let storeNotAvailableData = data.map(o => { delete o.available_by_store,delete o.less_available_by_store; return o });
            this.storeOOSNotAvailableBarChartLabels = this.formatNotAvailableStoreData(storeNotAvailableData).uniqueMonth
            this.storeOOSNotAvailableBarChartData = this.formatNotAvailableStoreData(storeNotAvailableData).stackedBarChartData;
            this.storeOOSNotAvailableExcelData.labels = Object.keys(storeNotAvailableData[0]).filter(o => o !== "months");
            this.storeOOSNotAvailableExcelData.data = storeNotAvailableData.map(o => { delete o.months; return o });

            // Less Than calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.store }));
            let storeLessThanData = data.map(o => { delete o.not_available_by_store,delete o.available_by_store; return o });
            this.storeOOSLessThanBarChartLabels = this.formatLessAvailableStoreData(storeLessThanData).uniqueMonth
            this.storeOOSLessThanBarChartData = this.formatLessAvailableStoreData(storeLessThanData).stackedBarChartData;
            this.storeOOSLessThanExcelData.labels = Object.keys(storeLessThanData[0]).filter(o => o !== "months");
            this.storeOOSLessThanExcelData.data = storeLessThanData.map(o => { delete o.months; return o });

          } else {
            this.oosLoader.storeAvailableBarChartDataLoaded = true;
            this.oosLoader.storeNotAvailableBarChartDataLoaded = true;
            this.oosLoader.storeLessThanBarChartDataLoaded = true;
            this.storeOOSAvailableBarChartData = [{ data: [], label: '' }]
            this.storeOOSAvailableBarChartLabels = []
            this.storeOOSAvailableExcelData = { labels: [], data: [] };

            this.storeOOSNotAvailableBarChartData = [{ data: [], label: '' }]
            this.storeOOSNotAvailableBarChartLabels = []
            this.storeOOSNotAvailableExcelData = { labels: [], data: [] };

            this.storeOOSLessThanBarChartData = [{ data: [], label: '' }]
            this.storeOOSLessThanBarChartLabels = []
            this.storeOOSLessThanExcelData = { labels: [], data: [] };


          }
        },
        error => {
          this.oosLoader.storeAvailableBarChartDataLoaded = true;
          this.oosLoader.storeNotAvailableBarChartDataLoaded = true;
          this.oosLoader.storeLessThanBarChartDataLoaded = true;
          Swal.close();
          this.storeOOSAvailableBarChartData = [{ data: [], label: '' }]
          this.storeOOSAvailableBarChartLabels = []
          this.storeOOSAvailableExcelData = { labels: [], data: [] };

          this.storeOOSNotAvailableBarChartData = [{ data: [], label: '' }]
          this.storeOOSNotAvailableBarChartLabels = []
          this.storeOOSNotAvailableExcelData = { labels: [], data: [] };

          this.storeOOSLessThanBarChartData = [{ data: [], label: '' }]
          this.storeOOSLessThanBarChartLabels = []
          this.storeOOSLessThanExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching OOS store data!", true);
        }
      )
  }

  loadOOSOverall(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSAllMeasures(filterString)
      .subscribe(
        response => {
          this.oosLoader.overallOOSBLDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let oosOverallData = response.data ? [...response.data] : [];

            const availableData = oosOverallData.map(eachOData => eachOData.available);
            const notAvailableData = oosOverallData.map(eachOData => eachOData.not_available);
            const lessData = oosOverallData.map(eachOData => eachOData.less);

            this.overallOOSBLLabels = oosOverallData.map(eachOData => eachOData.sku);
            this.overallOOSBLData = [
              { data: notAvailableData, label: 'Not Available', type: 'line', fill: false, borderColor: 'red', },
              { data: lessData, label: 'Less than 6 pack', type: 'line', fill: false, borderColor: 'blue', },
              { data: availableData, label: 'Available' },
            ];

            this.overallOOSBLExcelData.labels = Object.keys(oosOverallData[0]);
            this.overallOOSBLExcelData.data = [...oosOverallData];

          } else {
            this.overallOOSBLData = [{ data: [], label: '' }]
            this.overallOOSBLLabels = []
            this.overallOOSBLExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.overallOOSBLDataLoaded = true;
          Swal.close();
          this.overallOOSBLData = [{ data: [], label: '' }]
          this.overallOOSBLLabels = []
          this.overallOOSBLExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching overall out of stock data!", true);
        }
      )
  }
  loadOOSMonthAvailable(filterString?) {
    Swal.showLoading();
    this.dashboardService.OOSAvailableSKU(filterString)
      .subscribe(
        response => {
          this.oosLoader.monthAvailableOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let oosmonthAvailableData = response.data ? [...response.data] : [];

            this.monthAvailableOOSLabels = this.formatMonthAvailableBarData(oosmonthAvailableData).uniqueMonth
            this.monthAvailableOOSData = this.formatMonthAvailableBarData(oosmonthAvailableData).stackedBarChartData

            this.monthAvailableOOSExcelData.labels = Object.keys(oosmonthAvailableData[0]);
            this.monthAvailableOOSExcelData.data = [...oosmonthAvailableData];

          } else {
            this.monthAvailableOOSData = [{ data: [], label: '' }]
            this.monthAvailableOOSLabels = []
            this.monthAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.monthAvailableOOSDataLoaded = true;
          Swal.close();
          this.monthAvailableOOSData = [{ data: [], label: '' }]
          this.monthAvailableOOSLabels = []
          this.monthAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching Out of stock month available data!", true);
        }
      )
  }
  loadOOSMonthNotAvailable(filterString?) {
    Swal.showLoading();
    this.dashboardService.OOSNotAvailableSKU(filterString)
      .subscribe(
        response => {
          this.oosLoader.monthNotAvailableOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let oosMonthNotAvailableData = response.data ? [...response.data] : [];

            this.monthNotAvailableOOSLabels = this.formatMonthAvailableBarData(oosMonthNotAvailableData).uniqueMonth
            this.monthNotAvailableOOSData = this.formatMonthAvailableBarData(oosMonthNotAvailableData).stackedBarChartData

            this.monthNotAvailableOOSExcelData.labels = Object.keys(oosMonthNotAvailableData[0]);
            this.monthNotAvailableOOSExcelData.data = [...oosMonthNotAvailableData];

          } else {
            this.monthNotAvailableOOSData = [{ data: [], label: '' }]
            this.monthNotAvailableOOSLabels = []
            this.monthNotAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.monthNotAvailableOOSDataLoaded = true;
          Swal.close();
          this.monthNotAvailableOOSData = [{ data: [], label: '' }]
          this.monthNotAvailableOOSLabels = []
          this.monthNotAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching month not available data!", true);
        }
      )
  }
  loadOOSOOSMonthLess(filterString?) {
    Swal.showLoading();
    this.dashboardService.OOSLessThanSixPackSku(filterString)
      .subscribe(
        response => {
          this.oosLoader.lessMonthNotAvailableOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let oosLessMonthData = response.data ? [...response.data] : [];

            this.lessMonthOOSLabels = this.formatMonthAvailableBarData(oosLessMonthData).uniqueMonth
            this.lessMonthNotAvailableOOSData = this.formatMonthAvailableBarData(oosLessMonthData).stackedBarChartData

            this.lessMonthOOSExcelData.labels = Object.keys(oosLessMonthData[0]);
            this.lessMonthOOSExcelData.data = [...oosLessMonthData];

          } else {
            this.lessMonthNotAvailableOOSData = [{ data: [], label: '' }]
            this.lessMonthOOSLabels = []
            this.lessMonthOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.lessMonthNotAvailableOOSDataLoaded = true;
          Swal.close();
          this.lessMonthNotAvailableOOSData = [{ data: [], label: '' }]
          this.lessMonthOOSLabels = []
          this.lessMonthOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching less month available data!", true);
        }
      )
  }
  loadOOSCityAvailable(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSAvailableSkuByCity(filterString)
      .subscribe(
        response => {
          this.oosLoader.cityAvailableOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let ooscityAvailableData = response.data ? [...response.data] : [];

            this.cityAvailableOOSLabels = this.formatCityAvailableLineData(ooscityAvailableData).uniqueCity
            this.cityAvailableOOSData = this.formatCityAvailableLineData(ooscityAvailableData).stackedBarChartData
            this.cityAvailableOOSExcelData.labels = Object.keys(ooscityAvailableData[0]);
            this.cityAvailableOOSExcelData.data = [...ooscityAvailableData];

          } else {
            this.cityAvailableOOSData = [{ data: [], label: '' }]
            this.cityAvailableOOSLabels = []
            this.cityAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.cityAvailableOOSDataLoaded = true;
          Swal.close();
          this.cityAvailableOOSData = [{ data: [], label: '' }]
          this.cityAvailableOOSLabels = []
          this.cityAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city available data!", true);
        }
      )
  }
  loadOOSCityNotAvailable(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSNotAvailableSkuByCity(filterString)
      .subscribe(
        response => {
          this.oosLoader.cityNotAvailableOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let ooscityNotAvailableData = response.data ? [...response.data] : [];

            this.cityNotAvailableOOSLabels = this.formatCityNotAvailableLineData(ooscityNotAvailableData).uniqueCity;
            this.cityNotAvailableOOSData = this.formatCityNotAvailableLineData(ooscityNotAvailableData).stackedBarChartData;
            this.cityNotAvailableOOSExcelData.labels = Object.keys(ooscityNotAvailableData[0]);
            this.cityNotAvailableOOSExcelData.data = [...ooscityNotAvailableData];

          } else {
            this.cityNotAvailableOOSData = [{ data: [], label: '' }]
            this.cityNotAvailableOOSLabels = []
            this.cityNotAvailableOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.cityNotAvailableOOSDataLoaded = true;
          Swal.close();
          this.cityNotAvailableOOSData = [{ data: [], label: '' }]
          this.cityNotAvailableOOSLabels = []
          this.cityNotAvailableOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city not available data!", true);
        }
      )
  }
  loadOOSCityLess(filterString?) { //for less than 6 sku in cities

    Swal.showLoading();
    this.dashboardService.OOSLessThanSixPacksSkuByCity(filterString)
      .subscribe(
        response => {
          this.oosLoader.lessSKUInCityOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let oosCityLessData = response.data ? [...response.data] : [];

            this.lessSKUInCityOOSLabels = this.formatCityLessLineData(oosCityLessData).uniqueCity
            this.lessSKUInCityOOSData = this.formatCityLessLineData(oosCityLessData).stackedBarChartData

            this.lessSKUInCityOOSExcelData.labels = Object.keys(oosCityLessData[0]);
            this.lessSKUInCityOOSExcelData.data = [...oosCityLessData];

          } else {
            this.lessSKUInCityOOSData = [{ data: [], label: '' }]
            this.lessSKUInCityOOSLabels = []
            this.lessSKUInCityOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.lessSKUInCityOOSDataLoaded = true;
          Swal.close();
          this.lessSKUInCityOOSData = [{ data: [], label: '' }]
          this.lessSKUInCityOOSLabels = []
          this.lessSKUInCityOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching city not available data!", true);
        }
      )
  }
  loadOOSTotalVisitsInCity(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSTotalOutletVisitedByCity(filterString)
      .subscribe(
        response => {
          this.oosLoader.totalVisitsInCityOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let oosTotalVisitsInCityData = response.data ? [...response.data] : [];

            this.totalVisitsInCityOOSData = oosTotalVisitsInCityData.map(eachVisitByCity => eachVisitByCity.value);

            this.totalVisitsInCityOOSLabels = oosTotalVisitsInCityData.map(eachVisitByCity => eachVisitByCity.city);

            this.totalVisitsInCityOOSExcelData.labels = Object.keys(oosTotalVisitsInCityData[0]);
            this.totalVisitsInCityOOSExcelData.data = [...oosTotalVisitsInCityData];
          } else {
            this.totalVisitsInCityOOSData = [{ data: [], label: '' }]
            this.totalVisitsInCityOOSLabels = []
            this.totalVisitsInCityOOSExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.oosLoader.totalVisitsInCityOOSDataLoaded = true;
          Swal.close();
          this.totalVisitsInCityOOSData = [{ data: [], label: '' }]
          this.totalVisitsInCityOOSLabels = []
          this.totalVisitsInCityOOSExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visits per city data!", true);
        }
      )
  }
  loadOOSWeekNotAvailable(filterString?) {

    Swal.showLoading();
    this.dashboardService.OOSNotAvailableSKUByMonths(filterString)
      .subscribe(
        response => {
          this.oosLoader.weekNotAvailableOOSDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let oosWeekNotAvailableData = response.data ? [...response.data] : [];
            this.weekNotAvailableOOSLabels = Object.keys(this.formatWeekNotAvailableData(oosWeekNotAvailableData));
            this.weekNotAvailableOOSData = this.formatWeekNotAvailableData(oosWeekNotAvailableData);
          } else {
            this.weekNotAvailableOOSData = {};
            this.weekNotAvailableOOSLabels = [];
          }
        },
        error => {
          Swal.close();
          this.weekNotAvailableOOSData = {};
          this.weekNotAvailableOOSLabels = [];
          this.handleNotification("Error in fetching SKU not available in weeks data!", true);
        }
      )
  }
  formatAvailableCityData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, available_by_city, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = available_by_city; return r; }, {}));
      return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatNotAvailableCityData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, not_available_by_city, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = not_available_by_city; return r; }, {}));
      return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatLessAvailableCityData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, less_available_by_city, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = less_available_by_city; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatAvailableChannelCityData(dataToFormat: Array<any>) {
    
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, available_by_city, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = available_by_city; return r; }, {}));
      return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatNotAvailableChannelCityData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, not_available_by_city, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = not_available_by_city; return r; }, {}));
      return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatLessAvailableChannelCityData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, less_available_by_city, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = less_available_by_city; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatAvailableMonthData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.month))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { month, available_by_month, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[month]] = available_by_month; return r; }, {}));
      return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatNotAvailableMonthData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.month))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { month, not_available_by_month, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[month]] = not_available_by_month; return r; }, {}));
      return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatLessAvailableMonthData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.month))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { month, less_available_by_month, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[month]] = less_available_by_month; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatAvailableStoreData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, available_by_store, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = available_by_store; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatNotAvailableStoreData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, not_available_by_store, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = not_available_by_store; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatLessAvailableStoreData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, less_available_by_store, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = less_available_by_store; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatAvailableBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, available, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = available; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatNotAvailableBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, not_available, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = not_available; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatLessBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, less_than, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = less_than; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }

  //Consumer Question Methods
  
  loadCQYesNoQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.SnapDataYesOrNo(filterString)
      .subscribe(
        response => {
          this.cqLoader.yesNoQuestionCQDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let cqYesNoQuestion = response.data ? [...response.data] : [];

            this.yesNoQuestionCQLabels = [...new Set(cqYesNoQuestion.map(item => item.question_statement))]
            this.yesNoQuestionCQData = this.formatCQYesNoQuestionBarData(cqYesNoQuestion);
            this.yesNoQuestionCQExcelData.labels = Object.keys(cqYesNoQuestion[0]);
            this.yesNoQuestionCQExcelData.data = [...cqYesNoQuestion];

          } else {
            this.yesNoQuestionCQData = [{ data: [], label: '' }]
            this.yesNoQuestionCQLabels = []
            this.yesNoQuestionCQExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.cqLoader.yesNoQuestionCQDataLoaded = true;
          Swal.close();
          this.yesNoQuestionCQData = [{ data: [], label: '' }]
          this.yesNoQuestionCQLabels = []
          this.yesNoQuestionCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching yes no question data!", true);
        }
      )
  }
  loadCQRatingOneToThreeQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.SnapDataConsumerRatingThreePoints(filterString)
      .subscribe(
        response => {
          this.cqLoader.ratingOneToThreeQuestionCQDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let cqRatingOneToThreeQuestion = response.data ? [...response.data] : [];

            this.ratingOneToThreeCQLabels = [...new Set(cqRatingOneToThreeQuestion.map(item => item.question_statement))]
            this.ratingOneToThreeQuestionCQData = this.formatCQOneToThreeBarData(cqRatingOneToThreeQuestion);
            this.ratingOneToThreeCQExcelData.labels = Object.keys(cqRatingOneToThreeQuestion[0]);
            this.ratingOneToThreeCQExcelData.data = [...cqRatingOneToThreeQuestion];

          } else {
            this.ratingOneToThreeQuestionCQData = [{ data: [], label: '' }]
            this.ratingOneToThreeCQLabels = []
            this.ratingOneToThreeCQExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.cqLoader.ratingOneToThreeQuestionCQDataLoaded = true;
          Swal.close();
          this.ratingOneToThreeQuestionCQData = [{ data: [], label: '' }]
          this.ratingOneToThreeCQLabels = []
          this.ratingOneToThreeCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching rating one to three question data!", true);
        }
      )
  }
  loadCQRatingOneToFiveQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.SnapDataConsumerRatingFivePoints(filterString)
      .subscribe(
        response => {
          this.cqLoader.ratingOneToFiveQuestionCQDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let cqRatingOneToFiveQuestion = response.data ? [...response.data] : [];

            this.ratingOneToFiveCQLabels = [...new Set(cqRatingOneToFiveQuestion.map(item => item.question_statement))]
            this.ratingOneToFiveQuestionCQData = this.formatCQOneToFiveBarData(cqRatingOneToFiveQuestion);
            this.ratingOneToFiveCQExcelData.labels = Object.keys(cqRatingOneToFiveQuestion[0]);
            this.ratingOneToFiveCQExcelData.data = [...cqRatingOneToFiveQuestion];

          } else {
            this.ratingOneToFiveQuestionCQData = [{ data: [], label: '' }]
            this.ratingOneToFiveCQLabels = []
            this.ratingOneToFiveCQExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching City not available data!", true);
          }
        },
        error => {
          this.cqLoader.ratingOneToFiveQuestionCQDataLoaded = true;
          Swal.close();
          this.ratingOneToFiveQuestionCQData = [{ data: [], label: '' }]
          this.ratingOneToFiveCQLabels = []
          this.ratingOneToFiveCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching rating one to five question data!", true);
        }
      )
  }
  loadCQNumericQuestion(filterString?) {
    Swal.showLoading();
    this.dashboardService.SnapDataConsumerAverage(filterString)
      .subscribe(
        response => {
          this.cqLoader.numericQuestionCQDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let cqNumericQuestion = response.data ? [...response.data] : [];

            this.numericCQLabels = this.formatCQNumericQuestionData(cqNumericQuestion).question_statement;
            this.numericQuestionCQData = [{ data: this.formatCQNumericQuestionData(cqNumericQuestion).value }];
            this.numericCQExcelData.labels = Object.keys(cqNumericQuestion[0]);
            this.numericCQExcelData.data = [...cqNumericQuestion];

          } else {
            this.numericQuestionCQData = [{ data: [], label: '' }]
            this.numericCQLabels = []
            this.numericCQExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.cqLoader.numericQuestionCQDataLoaded = true;
          Swal.close();
          this.numericQuestionCQData = [{ data: [], label: '' }]
          this.numericCQLabels = []
          this.numericCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching numeric question data!", true);
        }
      )
  }
  loadCQRatingOneToTen(filterString?) {
    Swal.showLoading();
    this.dashboardService.SnapDataConsumerRatingTenPoints(filterString)
      .subscribe(
        response => {
          this.cqLoader.ratingOneToTenCQDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let cqRatingOneToTen = response.data ? [...response.data] : [];
            this.ratingOneToTenCQLabels = [...new Set(cqRatingOneToTen.map(item => item.question_statement))]
            this.ratingOneToTenCQData = this.formatCQRatingOneToTenBarData(cqRatingOneToTen);
            this.ratingOneToTenCQExcelData.labels = Object.keys(cqRatingOneToTen[0]);
            this.ratingOneToTenCQExcelData.data = [...cqRatingOneToTen];

          } else {
            this.ratingOneToTenCQData = [{ data: [], label: '' }]
            this.ratingOneToTenCQLabels = []
            this.ratingOneToTenCQExcelData = { labels: [], data: [] };
            // this.handleNotification("Problem in fetching City not available data!", true);
          }
        },
        error => {
          this.cqLoader.ratingOneToTenCQDataLoaded = true;
          Swal.close();
          this.ratingOneToTenCQData = [{ data: [], label: '' }]
          this.ratingOneToTenCQLabels = []
          this.ratingOneToTenCQExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching yes no question data!", true);
        }
      )
  }

  formatCQYesNoQuestionBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let yesData = []
    let noData = []

    dataToFormat.forEach(pair => {
      yesData = dataToFormat
        .map(uniqueStackData => uniqueStackData.yes);
      noData = dataToFormat
        .map(uniqueStackData => uniqueStackData.no)
    });
    stackedBarChartData.push({
      data: yesData,
      label: 'Yes',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: noData,
      label: 'No',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }
  formatCQRatingOneToTenBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let ratingOne = []
    let ratingTwo = []
    let ratingThree = []
    let ratingFour = []
    let ratingFive = []
    let ratingSix = []
    let ratingSeven = []
    let ratingEight = []
    let ratingNine = []
    let ratingTen = []

    dataToFormat.forEach(pair => {
      ratingOne = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_one);
      ratingTwo = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_two);
      ratingThree = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_three);
      ratingFour = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_four);
      ratingFive = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_five);
      ratingSix = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_six);
      ratingSeven = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_seven);
      ratingEight = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_eight);
      ratingNine = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_nine);
      ratingTen = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_ten);

    });
    stackedBarChartData.push({
      data: ratingOne,
      label: 'Rating 1',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTwo,
      label: 'Rating 2',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingThree,
      label: 'Rating 3',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFour,
      label: 'Rating 4',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFive,
      label: 'Rating 5',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingSix,
      label: 'Rating 6',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingSeven,
      label: 'Rating 7',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingEight,
      label: 'Rating 8',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingNine,
      label: 'Rating 9',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTen,
      label: 'Rating 10',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }
  formatCQOneToThreeBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let ratingOne = []
    let ratingTwo = []
    let ratingThree = []

    dataToFormat.forEach(pair => {
      ratingOne = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_one);
      ratingTwo = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_two);
      ratingThree = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_three);
    });
    stackedBarChartData.push({
      data: ratingOne,
      label: 'Rating 1',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTwo,
      label: 'Rating 2',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingThree,
      label: 'Rating 3',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }
  formatCQOneToFiveBarData(dataToFormat: Array<any>) {
    let stackedBarChartData = []
    let ratingOne = []
    let ratingTwo = []
    let ratingThree = []
    let ratingFour = []
    let ratingFive = []

    dataToFormat.forEach(pair => {
      ratingOne = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_one);
      ratingTwo = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_two);
      ratingThree = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_three);
      ratingFour = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_four);
      ratingFive = dataToFormat
        .map(uniqueStackData => uniqueStackData.rating_five);
    });
    stackedBarChartData.push({
      data: ratingOne,
      label: 'Rating 1',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingTwo,
      label: 'Rating 2',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingThree,
      label: 'Rating 3',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFour,
      label: 'Rating 4',
      stack: 'sameStack'
    });
    stackedBarChartData.push({
      data: ratingFive,
      label: 'Rating 5',
      stack: 'sameStack'
    });
    return stackedBarChartData;
  }
  formatCQNumericQuestionData(dataToFormat: Array<any>) {
    let question_statement = [];
    let value = [];

    dataToFormat.map(numericData => {
      question_statement.push(numericData.question_statement);
      value.push(numericData.value)
    });

    return {
      question_statement,
      value
    }
  }
  formatCityAvailableLineData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  //   let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))];
  //   let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
  //   let stackedBarChartData = []
  //   uniqueCities.forEach(uniqueCity => {
  //     let values = []
  //     dataToFormat.forEach(dTF => {
  //       if (dTF.city == uniqueCity) {
  //         values.push(dTF.value);
  //       } else {
  //         values.push(0);
  //       }
  //     })
  //     stackedBarChartData.push({ data: values, label: uniqueCity })
  //   })

  //   return {
  //     stackedBarChartData,
  //     uniqueSku
  //   }
  // }
  formatCityLessLineData(dataToFormat: Array<any>) {
    // let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))];
    // let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
    // let stackedBarChartData = []
    // uniqueCities.forEach(uniqueCity => {
    //   let values = []
    //   dataToFormat.forEach(dTF => {
    //     if (dTF.city == uniqueCity) {
    //       values.push(dTF.value);
    //     } else {
    //       values.push(0);
    //     }
    //   })
    //   stackedBarChartData.push({ data: values, label: uniqueCity })
    // })

    // return {
    //   stackedBarChartData,
    //   uniqueSku
    // }
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  formatMonthAvailableBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.month))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { month, value, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[month]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatChannelBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, value, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatChannelCityBarData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, value, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[months]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  formatCityNotAvailableLineData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
    // let uniqueSku = [...new Set(dataToFormat.map(item => item.sku))];
    // let uniqueCities = [...new Set(dataToFormat.map(item => item.city))];
    // let stackedBarChartData = []
    // uniqueCities.forEach(uniqueCity => {
    //   let values = []
    //   dataToFormat.forEach(dTF => {
    //     if (dTF.city == uniqueCity) {
    //       values.push(dTF.value);
    //     } else {
    //       values.push(0);
    //     }
    //   })
    //   stackedBarChartData.push({ data: values, label: uniqueCity })
    // })

    // return {
    //   stackedBarChartData,
    //   uniqueSku
    // }
  }
  formatWeekNotAvailableData(dataToFormat: Array<any>) {
    let tableData = {
    };
    let uniqueWeeks = [...new Set(dataToFormat.map(item => item.week))];
    uniqueWeeks.forEach(week => {
      tableData[week] = dataToFormat.filter(weekData => weekData.week == week)
    });

    return tableData;
  }

  // Distribution Check Methods
  //Country chart
  loadDCCountry(filterString?) {

    Swal.showLoading();
    this.dashboardService.DCByCountry(filterString)
      .subscribe(
        response => {
          this.dcLoader.countryShelfBarChartDataLoaded = true;
          this.dcLoader.countryDistributionBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Shelf calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.country }));
            let countryShelfData = data.map(o => { delete o.total_distribution; return o });
            this.countryDCShelfBarChartLabels = this.formatShelfShareBarData(countryShelfData).uniqueMonth
            this.countryDCShelfBarChartData = this.formatShelfShareBarData(countryShelfData).stackedBarChartData;
            this.countryDCShelfExcelData.labels = Object.keys(countryShelfData[0]).filter(o => o !== "months");
            this.countryDCShelfExcelData.data = countryShelfData.map(o => { delete o.months; return o });

            // Distribution calculation
            var datas = x.map(o => ({ ...o, months: o.month + "," + o.country }));
            let countryDistributionData = datas.map(o => { delete o.shelf_share; return o });
            this.countryDCDistributionBarChartLabels = this.formatTotalDistributionBarData(countryDistributionData).uniqueMonth
            this.countryDCDistributionBarChartData = this.formatTotalDistributionBarData(countryDistributionData).stackedBarChartData;
            this.countryDCDistributionExcelData.labels = Object.keys(countryDistributionData[0]).filter(o => o !== "months");
            this.countryDCDistributionExcelData.data = countryDistributionData.map(o => { delete o.months; return o });

          } else {
            this.dcLoader.countryShelfBarChartDataLoaded = true;
            this.dcLoader.countryDistributionBarChartDataLoaded = true;
            this.countryDCShelfBarChartData = [{ data: [], label: '' }]
            this.countryDCShelfBarChartLabels = []
            this.countryDCShelfExcelData = { labels: [], data: [] };

            this.countryDCDistributionBarChartData = [{ data: [], label: '' }]
            this.countryDCDistributionBarChartLabels = []
            this.countryDCDistributionExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.countryShelfBarChartDataLoaded = true;
          this.dcLoader.countryDistributionBarChartDataLoaded = true;
          Swal.close();
          this.countryDCShelfBarChartData = [{ data: [], label: '' }]
          this.countryDCShelfBarChartLabels = []
          this.countryDCShelfExcelData = { labels: [], data: [] };

          this.countryDCDistributionBarChartData = [{ data: [], label: '' }]
          this.countryDCDistributionBarChartLabels = []
          this.countryDCDistributionExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching DC country data!", true);
        }
      )
  }
  //City chart
  loadDCCity(filterString?) {

    Swal.showLoading();
    this.dashboardService.DCByCity(filterString)
      .subscribe(
        response => {
          this.dcLoader.cityShelfBarChartDataLoaded = true;
          this.dcLoader.cityDistributionBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Shelf calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.city }));
            let cityShelfData = data.map(o => { delete o.total_distribution; return o });
            this.cityDCShelfBarChartLabels = this.formatShelfShareBarData(cityShelfData).uniqueMonth
            this.cityDCShelfBarChartData = this.formatShelfShareBarData(cityShelfData).stackedBarChartData;
            this.cityDCShelfExcelData.labels = Object.keys(cityShelfData[0]).filter(o => o !== "months");
            this.cityDCShelfExcelData.data = cityShelfData.map(o => { delete o.months; return o });

            // Distribution calculation
            var datas = x.map(o => ({ ...o, months: o.month + "," + o.city }));
            let cityDistributionData = datas.map(o => { delete o.shelf_share; return o });
            this.cityDCDistributionBarChartLabels = this.formatTotalDistributionBarData(cityDistributionData).uniqueMonth
            this.cityDCDistributionBarChartData = this.formatTotalDistributionBarData(cityDistributionData).stackedBarChartData;
            this.cityDCDistributionExcelData.labels = Object.keys(cityDistributionData[0]).filter(o => o !== "months");
            this.cityDCDistributionExcelData.data = cityDistributionData.map(o => { delete o.months; return o });

          } else {
            this.dcLoader.cityShelfBarChartDataLoaded = true;
            this.dcLoader.cityDistributionBarChartDataLoaded = true;
            this.cityDCShelfBarChartData = [{ data: [], label: '' }]
            this.cityDCShelfBarChartLabels = []
            this.cityDCShelfExcelData = { labels: [], data: [] };

            this.cityDCDistributionBarChartData = [{ data: [], label: '' }]
            this.cityDCDistributionBarChartLabels = []
            this.cityDCDistributionExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.cityShelfBarChartDataLoaded = true;
          this.dcLoader.cityDistributionBarChartDataLoaded = true;
          Swal.close();
          this.cityDCShelfBarChartData = [{ data: [], label: '' }]
          this.cityDCShelfBarChartLabels = []
          this.cityDCShelfExcelData = { labels: [], data: [] };

          this.cityDCDistributionBarChartData = [{ data: [], label: '' }]
          this.cityDCDistributionBarChartLabels = []
          this.cityDCDistributionExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching DC city data!", true);
        }
      )
  }
  //Channel chart
  loadDCChannel(filterString?) {

    Swal.showLoading();
    this.dashboardService.DCByChannel(filterString)
      .subscribe(
        response => {
          this.dcLoader.channelShelfBarChartDataLoaded = true;
          this.dcLoader.channelDistributionBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Shelf calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.channel }));
            let channelShelfData = data.map(o => { delete o.total_distribution; return o });
            this.channelDCShelfBarChartLabels = this.formatShelfShareBarData(channelShelfData).uniqueMonth
            this.channelDCShelfBarChartData = this.formatShelfShareBarData(channelShelfData).stackedBarChartData;
            this.channelDCShelfExcelData.labels = Object.keys(channelShelfData[0]).filter(o => o !== "months");
            this.channelDCShelfExcelData.data = channelShelfData.map(o => { delete o.months; return o });

            // Distribution calculation
            var datas = x.map(o => ({ ...o, months: o.month + "," + o.channel }));
            let channelDistributionData = datas.map(o => { delete o.shelf_share; return o });
            this.channelDCDistributionBarChartLabels = this.formatTotalDistributionBarData(channelDistributionData).uniqueMonth
            this.channelDCDistributionBarChartData = this.formatTotalDistributionBarData(channelDistributionData).stackedBarChartData;
            this.channelDCDistributionExcelData.labels = Object.keys(channelDistributionData[0]).filter(o => o !== "months");
            this.channelDCDistributionExcelData.data = channelDistributionData.map(o => { delete o.months; return o });

          } else {
            this.dcLoader.channelShelfBarChartDataLoaded = true;
            this.dcLoader.channelDistributionBarChartDataLoaded = true;
            this.channelDCShelfBarChartData = [{ data: [], label: '' }]
            this.channelDCShelfBarChartLabels = []
            this.channelDCShelfExcelData = { labels: [], data: [] };

            this.channelDCDistributionBarChartData = [{ data: [], label: '' }]
            this.channelDCDistributionBarChartLabels = []
            this.channelDCDistributionExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.channelShelfBarChartDataLoaded = true;
          this.dcLoader.channelDistributionBarChartDataLoaded = true;
          Swal.close();
          this.channelDCShelfBarChartData = [{ data: [], label: '' }]
          this.channelDCShelfBarChartLabels = []
          this.channelDCShelfExcelData = { labels: [], data: [] };

          this.channelDCDistributionBarChartData = [{ data: [], label: '' }]
          this.channelDCDistributionBarChartLabels = []
          this.channelDCDistributionExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching DC channel data!", true);
        }
      )
  }
  //Channel city chart
  loadDCChannelCity(filterString?) {

    Swal.showLoading();
    this.dashboardService.DCByChannelCity(filterString)
      .subscribe(
        response => {
          this.dcLoader.channelCityShelfBarChartDataLoaded = true;
          this.dcLoader.channelCityDistributionBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Shelf calculation
            var data = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            let channelCityShelfData = data.map(o => { delete o.total_distribution; return o });
            this.channelCityDCShelfBarChartLabels = this.formatCCShelfShareBarData(channelCityShelfData).uniqueCity
            this.channelCityDCShelfBarChartData = this.formatCCShelfShareBarData(channelCityShelfData).stackedBarChartData;
            this.channelCityDCShelfExcelData.labels = Object.keys(channelCityShelfData[0]).filter(o => o !== "months");
            this.channelCityDCShelfExcelData.data = channelCityShelfData.map(o => { delete o.months; return o });

            // Distribution calculation
            var datas = x.map(o => ({ ...o, months: o.month+ ","+o.city + "," + o.channel  }));
            let channelCityDistributionData = datas.map(o => { delete o.shelf_share; return o });
            this.channelCityDCDistributionBarChartLabels = this.formatCCTotalDistributionBarData(channelCityDistributionData).uniqueCity
            this.channelCityDCDistributionBarChartData = this.formatCCTotalDistributionBarData(channelCityDistributionData).stackedBarChartData;
            this.channelCityDCDistributionExcelData.labels = Object.keys(channelCityDistributionData[0]).filter(o => o !== "months");
            this.channelCityDCDistributionExcelData.data = channelCityDistributionData.map(o => { delete o.months; return o });

          } else {
            this.dcLoader.channelCityShelfBarChartDataLoaded = true;
            this.dcLoader.channelCityDistributionBarChartDataLoaded = true;
            this.channelCityDCShelfBarChartData = [{ data: [], label: '' }]
            this.channelCityDCShelfBarChartLabels = []
            this.channelCityDCShelfExcelData = { labels: [], data: [] };

            this.channelCityDCDistributionBarChartData = [{ data: [], label: '' }]
            this.channelCityDCDistributionBarChartLabels = []
            this.channelCityDCDistributionExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.channelCityShelfBarChartDataLoaded = true;
          this.dcLoader.channelCityDistributionBarChartDataLoaded = true;
          Swal.close();
          this.channelCityDCShelfBarChartData = [{ data: [], label: '' }]
          this.channelCityDCShelfBarChartLabels = []
          this.channelCityDCShelfExcelData = { labels: [], data: [] };

          this.channelCityDCDistributionBarChartData = [{ data: [], label: '' }]
          this.channelCityDCDistributionBarChartLabels = []
          this.channelCityDCDistributionExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching DC channelCity data!", true);
        }
      )
  }
  //Brand chart
  loadDCBrand(filterString?) {

    Swal.showLoading();
    this.dashboardService.DCByBrand(filterString)
      .subscribe(
        response => {
          this.dcLoader.brandShelfBarChartDataLoaded = true;
          this.dcLoader.brandDistributionBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Shelf calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.brand }));
            let brandShelfData = data.map(o => { delete o.total_distribution; return o });
            this.brandDCShelfBarChartLabels = this.formatShelfShareBarData(brandShelfData).uniqueMonth
            this.brandDCShelfBarChartData = this.formatShelfShareBarData(brandShelfData).stackedBarChartData;
            this.brandDCShelfExcelData.labels = Object.keys(brandShelfData[0]).filter(o => o !== "months");
            this.brandDCShelfExcelData.data = brandShelfData.map(o => { delete o.months; return o });

            // Distribution calculation
            var datas = x.map(o => ({ ...o, months: o.month + "," + o.brand }));
            let brandDistributionData = datas.map(o => { delete o.shelf_share; return o });
            this.brandDCDistributionBarChartLabels = this.formatTotalDistributionBarData(brandDistributionData).uniqueMonth
            this.brandDCDistributionBarChartData = this.formatTotalDistributionBarData(brandDistributionData).stackedBarChartData;
            this.brandDCDistributionExcelData.labels = Object.keys(brandDistributionData[0]).filter(o => o !== "months");
            this.brandDCDistributionExcelData.data = brandDistributionData.map(o => { delete o.months; return o });

          } else {
            this.dcLoader.brandShelfBarChartDataLoaded = true;
            this.dcLoader.brandDistributionBarChartDataLoaded = true;
            this.brandDCShelfBarChartData = [{ data: [], label: '' }]
            this.brandDCShelfBarChartLabels = []
            this.brandDCShelfExcelData = { labels: [], data: [] };

            this.brandDCDistributionBarChartData = [{ data: [], label: '' }]
            this.brandDCDistributionBarChartLabels = []
            this.brandDCDistributionExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.brandShelfBarChartDataLoaded = true;
          this.dcLoader.brandDistributionBarChartDataLoaded = true;
          Swal.close();
          this.brandDCShelfBarChartData = [{ data: [], label: '' }]
          this.brandDCShelfBarChartLabels = []
          this.brandDCShelfExcelData = { labels: [], data: [] };

          this.brandDCDistributionBarChartData = [{ data: [], label: '' }]
          this.brandDCDistributionBarChartLabels = []
          this.brandDCDistributionExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching DC brand data!", true);
        }
      )
  }
  //Sku chart
  loadDCSku(filterString?) {

    Swal.showLoading();
    this.dashboardService.DCBySku(filterString)
      .subscribe(
        response => {
          this.dcLoader.skuShelfBarChartDataLoaded = true;
          this.dcLoader.skuDistributionBarChartDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            var x = response.data ? [...response.data] : [];
            // Shelf calculation
            var data = x.map(o => ({ ...o, months: o.month + "," + o.sku }));
            let skuShelfData = data.map(o => { delete o.total_distribution; return o });
            this.skuDCShelfBarChartLabels = this.formatShelfShareBarData(skuShelfData).uniqueMonth
            this.skuDCShelfBarChartData = this.formatShelfShareBarData(skuShelfData).stackedBarChartData;
            this.skuDCShelfExcelData.labels = Object.keys(skuShelfData[0]).filter(o => o !== "months");
            this.skuDCShelfExcelData.data = skuShelfData.map(o => { delete o.months; return o });

            // Distribution calculation
            var datas = x.map(o => ({ ...o, months: o.month + "," + o.sku }));
            let skuDistributionData = datas.map(o => { delete o.shelf_share; return o });
            this.skuDCDistributionBarChartLabels = this.formatTotalDistributionBarData(skuDistributionData).uniqueMonth
            this.skuDCDistributionBarChartData = this.formatTotalDistributionBarData(skuDistributionData).stackedBarChartData;
            this.skuDCDistributionExcelData.labels = Object.keys(skuDistributionData[0]).filter(o => o !== "months");
            this.skuDCDistributionExcelData.data = skuDistributionData.map(o => { delete o.months; return o });

          } else {
            this.dcLoader.skuShelfBarChartDataLoaded = true;
            this.dcLoader.skuDistributionBarChartDataLoaded = true;
            this.skuDCShelfBarChartData = [{ data: [], label: '' }]
            this.skuDCShelfBarChartLabels = []
            this.skuDCShelfExcelData = { labels: [], data: [] };

            this.skuDCDistributionBarChartData = [{ data: [], label: '' }]
            this.skuDCDistributionBarChartLabels = []
            this.skuDCDistributionExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.skuShelfBarChartDataLoaded = true;
          this.dcLoader.skuDistributionBarChartDataLoaded = true;
          Swal.close();
          this.skuDCShelfBarChartData = [{ data: [], label: '' }]
          this.skuDCShelfBarChartLabels = []
          this.skuDCShelfExcelData = { labels: [], data: [] };

          this.skuDCDistributionBarChartData = [{ data: [], label: '' }]
          this.skuDCDistributionBarChartLabels = []
          this.skuDCDistributionExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching DC sku data!", true);
        }
      )
  }

  loadDCSubmittedQnnByChannel(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSubmittedQnnByChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let dcSubmittedQnnByChannel = response.data ? [...response.data] : [];
            this.submittedQnnByChannelDCLabels = this.formatQnnByChannelDCData(dcSubmittedQnnByChannel).channels;
            this.submittedQnnByChannelDCData = [
              { data: this.formatQnnByChannelDCData(dcSubmittedQnnByChannel).channelValue }
            ];

            this.submittedQnnByChannelDCExcelData.labels = Object.keys(dcSubmittedQnnByChannel[0]);
            this.submittedQnnByChannelDCExcelData.data = [...dcSubmittedQnnByChannel];

          } else {
            this.submittedQnnByChannelDCData = [{ data: [], label: '' }];
            this.submittedQnnByChannelDCLabels = [];
            this.submittedQnnByChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.submittedQnnByChannelDCData = [{ data: [], label: '' }];
          this.submittedQnnByChannelDCLabels = [];
          this.submittedQnnByChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visit by channel data!", true);
        }
      )
  }
  // -------------- new snap

  loadDCTotalDistribution(filterString?) {
    Swal.showLoading();
    this.dashboardService.getTotalDistribution(filterString)
      .subscribe(
        response => {
          this.dcLoader.totalDistributionDCDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcTotalDistribution = response.data ? [...response.data] : [];
            this.totalDistributionDCLabels = this.formatTolalOutletVisitedDCData(dcTotalDistribution).sku;
            this.totalDistributionDCData = [
              { data: this.formatTolalOutletVisitedDCData(dcTotalDistribution).value }
            ];

            this.totalDistributionDCExcelData.labels = Object.keys(dcTotalDistribution[0]);
            this.totalDistributionDCExcelData.data = [...dcTotalDistribution];
          } else {
            this.totalDistributionDCData = [{ data: [], label: '' }]
            this.totalDistributionDCLabels = []
            this.totalDistributionDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.totalDistributionDCDataLoaded = true;
          Swal.close();
          this.totalDistributionDCData = [{ data: [], label: '' }]
          this.totalDistributionDCLabels = []
          this.totalDistributionDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching average per sku data!", true);
        }
      )
  }
  loadDCShelfShareByCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.getShelfShareByCity(filterString)
      .subscribe(
        response => {
          this.dcLoader.shelfShareByCityDCDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data?.length > 0) {
            let dcShelfShareByCity = response.data ? [...response.data] : [];

            this.shelfShareByCityDCLabels = this.formatAnswerPerCityData(dcShelfShareByCity).uniqueCity;
            this.shelfShareByCityDCData = this.formatAnswerPerCityData(dcShelfShareByCity).stackedBarChartData;

            this.shelfShareByCityDCExcelData.labels = Object.keys(dcShelfShareByCity[0]);
            this.shelfShareByCityDCExcelData.data = [...dcShelfShareByCity];
          } else {
            this.shelfShareByCityDCData = [{ data: [], label: '' }]
            this.shelfShareByCityDCLabels = []
            this.shelfShareByCityDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.shelfShareByCityDCDataLoaded = true;
          Swal.close();
          this.shelfShareByCityDCData = [{ data: [], label: '' }]
          this.shelfShareByCityDCLabels = []
          this.shelfShareByCityDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching shelf share bu city data!", true);
        }
      )
  }
  loadTotalOutletVisited(filterString?) {
    Swal.showLoading();
    this.dashboardService.getTotalOutletVisited(filterString)
      .subscribe(
        response => {
          this.dcLoader.totalOutletVisitedDCDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data?.length > 0) {
            let totalOutletVisited = response.data ? [...response.data] : [];

            this.totalOutletVisitedDCLabels = this.formatTolalOutletVisitedDCData(totalOutletVisited).sku;
            this.totalOutletVisitedDCData = [
              { data: this.formatTolalOutletVisitedDCData(totalOutletVisited).value }
            ];

            this.totalOutletVisitedDCExcelData.labels = Object.keys(totalOutletVisited[0]);
            this.totalOutletVisitedDCExcelData.data = [...totalOutletVisited];
          } else {
            this.totalOutletVisitedDCData = [{ data: [], label: '' }];
            this.totalOutletVisitedDCLabels = [];
            this.totalOutletVisitedDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          this.dcLoader.totalOutletVisitedDCDataLoaded = true;
          Swal.close();
          this.totalOutletVisitedDCData = [{ data: [], label: '' }];
          this.totalOutletVisitedDCLabels = [];
          this.totalOutletVisitedDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total number of visited outlets!", true);
        }
      )
  }
  // --------------- new snap end
  loadDCSubmittedQnnByCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.getDCSubmittedQnnByCity(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let dcSubmittedQnnByCity = response.data ? [...response.data] : [];

            this.submittedQnnByCityDCLabels = this.formatQnnByCityDCData(dcSubmittedQnnByCity).city;
            this.submittedQnnByCityDCData = [
              { data: this.formatQnnByCityDCData(dcSubmittedQnnByCity).value }
            ];

            this.submittedQnnByCityDCExcelData.labels = Object.keys(dcSubmittedQnnByCity[0]);
            this.submittedQnnByCityDCExcelData.data = [...dcSubmittedQnnByCity];

          } else {
            this.submittedQnnByCityDCData = [{ data: [], label: '' }];
            this.submittedQnnByCityDCLabels = [];
            this.submittedQnnByCityDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.submittedQnnByCityDCData = [{ data: [], label: '' }];
          this.submittedQnnByCityDCLabels = [];
          this.submittedQnnByCityDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visit by city data!", true);
        }
      )
  }
  loadDCSubmittedQnnByCountry(filterString?) {
    Swal.showLoading();
    this.dashboardService.getDCSubmittedQnnByCountry(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let dcSubmittedQnnByCountry = response.data ? [...response.data] : [];
            this.submittedQnnByCountryDCLabels = this.formatQnnByCountryDCData(dcSubmittedQnnByCountry).country;
            this.submittedQnnByCountryDCData = [
              { data: this.formatQnnByCountryDCData(dcSubmittedQnnByCountry).value }
            ];

            this.submittedQnnByCountryDCExcelData.labels = Object.keys(dcSubmittedQnnByCountry[0]);
            this.submittedQnnByCountryDCExcelData.data = [...dcSubmittedQnnByCountry];

          } else {
            this.submittedQnnByCountryDCData = [{ data: [], label: '' }];
            this.submittedQnnByCountryDCLabels = [];
            this.submittedQnnByCountryDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.submittedQnnByCountryDCData = [{ data: [], label: '' }];
          this.submittedQnnByCountryDCLabels = [];
          this.submittedQnnByCountryDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total visit by country data!", true);
        }
      )
  }
  loadTotalOutletsOfSkuInCountry(filterString?) {
    Swal.showLoading();
    this.dashboardService.getTotalOutletsInCountry(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let totalOutletsOfSkuInCountry = response.data ? [...response.data] : [];

            this.totalOutletsOfSkuInCountryDCLabels = this.formatTotalOutletsOfSKUInCountryDCData(totalOutletsOfSkuInCountry).uniqueCountry
            this.totalOutletsOfSkuInCountryDCData = this.formatTotalOutletsOfSKUInCountryDCData(totalOutletsOfSkuInCountry).stackedBarChartData

            this.totalOutletsOfSkuInCountryDCExcelData.labels = Object.keys(totalOutletsOfSkuInCountry[0]);
            this.totalOutletsOfSkuInCountryDCExcelData.data = [...totalOutletsOfSkuInCountry];
          } else {
            this.totalOutletsOfSkuInCountryDCData = [{ data: [], label: '' }];
            this.totalOutletsOfSkuInCountryDCLabels = [];
            this.totalOutletsOfSkuInCountryDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.totalOutletsOfSkuInCountryDCData = [{ data: [], label: '' }];
          this.totalOutletsOfSkuInCountryDCLabels = [];
          this.totalOutletsOfSkuInCountryDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total number of outlets in the country data!", true);
        }
      )
  }
  loadTotalOutletsOfSkuInChannel(filterString?) {
    Swal.showLoading();
    this.dashboardService.getTotalOutletsInChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            let totalOutletsOfSkuInChannel = response.data ? [...response.data] : [];

            this.totalOutletsOfSkuInChannelDCLabels = this.formatTotalOutletsOfSKUInChannelDCData(totalOutletsOfSkuInChannel).uniqueChannel
            this.totalOutletsOfSkuInChannelDCData = this.formatTotalOutletsOfSKUInChannelDCData(totalOutletsOfSkuInChannel).stackedBarChartData

            this.totalOutletsOfSkuInChannelDCExcelData.labels = Object.keys(totalOutletsOfSkuInChannel[0]);
            this.totalOutletsOfSkuInChannelDCExcelData.data = [...totalOutletsOfSkuInChannel];
          } else {
            this.totalOutletsOfSkuInChannelDCData = [{ data: [], label: '' }];
            this.totalOutletsOfSkuInChannelDCLabels = [];
            this.totalOutletsOfSkuInChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.totalOutletsOfSkuInChannelDCData = [{ data: [], label: '' }];
          this.totalOutletsOfSkuInChannelDCLabels = [];
          this.totalOutletsOfSkuInChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching total number of outlets in the channel data!", true);
        }
      )
  }
  loadSumOfBrandByCity(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSumOfOneBrandByCity(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcBrandByCityData = response.data ? [...response.data] : [];
            this.sumOfOneBrandByCityDCLabels = this.formatBrandPerCityData(dcBrandByCityData).uniqueCity;
            this.sumOfOneBrandByCityDCData = this.formatBrandPerCityData(dcBrandByCityData).stackedBarChartData;

            this.sumOfOneBrandByCityDCExcelData.labels = Object.keys(dcBrandByCityData[0]);
            this.sumOfOneBrandByCityDCExcelData.data = [...dcBrandByCityData];

          } else {
            this.sumOfOneBrandByCityDCData = [{ data: [], label: '' }]
            this.sumOfOneBrandByCityDCLabels = []
            this.sumOfOneBrandByCityDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.sumOfOneBrandByCityDCData = [{ data: [], label: '' }]
          this.sumOfOneBrandByCityDCLabels = []
          this.sumOfOneBrandByCityDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching sum of one brand by city data!", true);
        }
      )
  }
  loadSumOfBrandByCountry(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSumOfOneBrandByCountry(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcBrandByCountryData = response ? [...response.data] : [];
            this.sumOfOneBrandByCountryDCLabels = this.formatBrandPerCountryData(dcBrandByCountryData).uniqueCountry;
            this.sumOfOneBrandByCountryDCData = this.formatBrandPerCountryData(dcBrandByCountryData).stackedBarChartData;

            this.sumOfOneBrandByCountryDCExcelData.labels = Object.keys(dcBrandByCountryData[0]);
            this.sumOfOneBrandByCountryDCExcelData.data = [...dcBrandByCountryData];

          } else {
            this.sumOfOneBrandByCountryDCData = [{ data: [], label: '' }]
            this.sumOfOneBrandByCountryDCLabels = []
            this.sumOfOneBrandByCountryDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.sumOfOneBrandByCountryDCData = [{ data: [], label: '' }]
          this.sumOfOneBrandByCountryDCLabels = []
          this.sumOfOneBrandByCountryDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching sum of one brand by country data!", true);
        }
      )
  }
  loadSumOfBrandByChannel(filterString?) {
    Swal.showLoading();
    this.dashboardService.getSumOfOneBrandByChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcBrandByChannelData = response ? [...response.data] : [];
            this.sumOfOneBrandByChannelDCLabels = this.formatBrandPerChannelData(dcBrandByChannelData).uniqueChannel;
            this.sumOfOneBrandByChannelDCData = this.formatBrandPerChannelData(dcBrandByChannelData).stackedBarChartData;

            this.sumOfOneBrandByChannelDCExcelData.labels = Object.keys(dcBrandByChannelData[0]);
            this.sumOfOneBrandByChannelDCExcelData.data = [...dcBrandByChannelData];

          } else {
            this.sumOfOneBrandByChannelDCData = [{ data: [], label: '' }]
            this.sumOfOneBrandByChannelDCLabels = []
            this.sumOfOneBrandByChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.sumOfOneBrandByChannelDCData = [{ data: [], label: '' }]
          this.sumOfOneBrandByChannelDCLabels = []
          this.sumOfOneBrandByChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error fetching sum of one brand by country data!", true);
        }
      )
  }
  loadDCAvgPerSku(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCAvgPerSku(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcAvgPerSkuData = response.data ? [...response.data] : [];

            this.avgPerSkuDCData = dcAvgPerSkuData.map(avgPerSku => avgPerSku.value);

            this.avgPerSkuDCLabels = dcAvgPerSkuData.map(avgPerSku => avgPerSku.sku);

            this.avgPerSkuDCExcelData.labels = Object.keys(dcAvgPerSkuData[0]);
            this.avgPerSkuDCExcelData.data = [...dcAvgPerSkuData];
          } else {
            this.avgPerSkuDCData = [{ data: [], label: '' }]
            this.avgPerSkuDCLabels = []
            this.avgPerSkuDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgPerSkuDCData = [{ data: [], label: '' }]
          this.avgPerSkuDCLabels = []
          this.avgPerSkuDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching average per sku data!", true);
        }
      )
  }
  loadDCAvgPerBrand(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCAvgPerBrand(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcAvgPerBrandData = response.data ? [...response.data] : [];

            this.avgPerBrandDCData = dcAvgPerBrandData.map(avgPerSku => avgPerSku.value);

            this.avgPerBrandDCLabels = dcAvgPerBrandData.map(avgPerSku => avgPerSku.brand);

            this.avgPerBrandDCExcelData.labels = Object.keys(dcAvgPerBrandData[0]);
            this.avgPerBrandDCExcelData.data = [...dcAvgPerBrandData];
          } else {
            this.avgPerBrandDCData = [{ data: [], label: '' }]
            this.avgPerBrandDCLabels = []
            this.avgPerBrandDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgPerBrandDCData = [{ data: [], label: '' }]
          this.avgPerBrandDCLabels = []
          this.avgPerBrandDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching average per sku data!", true);
        }
      )
  }
  loadDCShelfShareSkuChannel(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCShelfShareSkuPerChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcAvgPerBrandData = response.data ? [...response.data] : [];

            this.avgSKUPerChannelDC = this.formatSSSkuChannel(dcAvgPerBrandData).stackedBarChartData;

            this.avgSKUPerChannelDCLabels = this.formatSSSkuChannel(dcAvgPerBrandData).uniqueChannel;

            this.avgSKUPerChannelDCExcelData.labels = Object.keys(dcAvgPerBrandData[0]);
            this.avgSKUPerChannelDCExcelData.data = [...dcAvgPerBrandData];
          } else {
            this.avgSKUPerChannelDC = [{ data: [], label: '' }]
            this.avgSKUPerChannelDCLabels = []
            this.avgSKUPerChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgSKUPerChannelDC = [{ data: [], label: '' }]
          this.avgSKUPerChannelDCLabels = []
          this.avgSKUPerChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching shelf share sku per channel!", true);
        }
      )
  }
  loadDCShelfShareBrandChannel(filterString?) {

    Swal.showLoading();
    this.dashboardService.getDCShelfShareBrandPerChannel(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success && response.data.length > 0) {

            let dcAvgPerBrandData = response.data ? [...response.data] : [];

            this.avgBrandByChannelDCData = this.formatSSBrandChannel(dcAvgPerBrandData).stackedBarChartData;

            this.avgBrandByChannelDCLabels = this.formatSSBrandChannel(dcAvgPerBrandData).uniqueChannel;

            this.avgBrandByChannelDCExcelData.labels = Object.keys(dcAvgPerBrandData[0]);
            this.avgBrandByChannelDCExcelData.data = [...dcAvgPerBrandData];
          } else {
            this.avgBrandByChannelDCData = [{ data: [], label: '' }]
            this.avgBrandByChannelDCLabels = []
            this.avgBrandByChannelDCExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.avgBrandByChannelDCData = [{ data: [], label: '' }]
          this.avgBrandByChannelDCLabels = []
          this.avgBrandByChannelDCExcelData = { labels: [], data: [] };
          this.handleNotification("Error in fetching shelf share brand by channel!", true);
        }
      )
  }

  formatQnnByChannelDCData(dataToFormat: Array<any>) {
    let channels = [];
    let channelValue = [];

    dataToFormat.map(channaleData => {
      channels.push(channaleData.channel);
      channelValue.push(channaleData.value)
    });

    return {
      channels,
      channelValue
    }
  }
  formatTotalDistributionDCData(dataToFormat: Array<any>) {
    let skus = [];
    let skuValue = [];

    dataToFormat.map(channaleData => {
      skus.push(channaleData.sku);
      skuValue.push(channaleData.value)
    });

    return {
      skus,
      skuValue
    }
  }
  formatQnnByCityDCData(dataToFormat: Array<any>) {
    let city = [];
    let value = [];

    dataToFormat.map(cityData => {
      city.push(cityData.city);
      value.push(cityData.value)
    });

    return {
      city,
      value
    }
  }
  formatQnnByCountryDCData(dataToFormat: Array<any>) {
    let country = [];
    let value = [];

    dataToFormat.map(countryData => {
      country.push(countryData.country);
      value.push(countryData.value)
    });

    return {
      country,
      value
    }
  }
  formatShelfShareBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, shelf_share, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = shelf_share; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatTotalDistributionBarData(dataToFormat: Array<any>) {
    let uniqueMonth = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueMonth.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, total_distribution, sku }) => { r[sku] ||= { data: Array(uniqueMonth.length).fill(null), label: sku }; r[sku].data[order[months]] = total_distribution; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueMonth
    }
  }
  formatCCShelfShareBarData(dataToFormat: Array<any>) {
    
    let uniqueCity = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, shelf_share, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[months]] = shelf_share; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  formatCCTotalDistributionBarData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.months))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { months, total_distribution, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[months]] = total_distribution; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  formatTolalOutletVisitedDCData(dataToFormat: Array<any>) {
    let sku = [];
    let value = [];

    dataToFormat.map(skuData => {
      sku.push(skuData.sku);
      value.push(skuData.value)
    });

    return {
      sku,
      value
    }
  }
  formatTolalOutletVisitedCity(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, sku }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  formatTotalOutletsOfSKUInCityDCData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, city, value }) => { r[sku] ||= { data: Array(uniqueCity.length).fill(null), label: sku }; r[sku].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  formatSSSkuChannel(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, channel, value }) => { r[sku] ||= { data: Array(uniqueChannel.length).fill(null), label: sku }; r[sku].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }
  formatSSBrandChannel(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { brand, channel, value }) => { r[brand] ||= { data: Array(uniqueChannel.length).fill(null), label: brand }; r[brand].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }
  formatTotalOutletsOfSKUInCountryDCData(dataToFormat: Array<any>) {
    let uniqueCountry = [...new Set(dataToFormat.map(item => item.country))],
      order = Object.fromEntries(uniqueCountry.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, country, value }) => { r[sku] ||= { data: Array(uniqueCountry.length).fill(null), label: sku }; r[sku].data[order[country]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCountry
    }
  }
  formatTotalOutletsOfSKUInChannelDCData(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { sku, channel, value }) => { r[sku] ||= { data: Array(uniqueChannel.length).fill(null), label: sku }; r[sku].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }
  formatBrandPerCityData(dataToFormat: Array<any>) {
    let uniqueCity = [...new Set(dataToFormat.map(item => item.city))],
      order = Object.fromEntries(uniqueCity.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { city, value, brand }) => { r[brand] ||= { data: Array(uniqueCity.length).fill(null), label: brand }; r[brand].data[order[city]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCity
    }
  }
  formatBrandPerCountryData(dataToFormat: Array<any>) {
    let uniqueCountry = [...new Set(dataToFormat.map(item => item.country))],
      order = Object.fromEntries(uniqueCountry.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { country, value, brand }) => { r[brand] ||= { data: Array(uniqueCountry.length).fill(null), label: brand }; r[brand].data[order[country]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueCountry
    }
  }
  formatBrandPerChannelData(dataToFormat: Array<any>) {
    let uniqueChannel = [...new Set(dataToFormat.map(item => item.channel))],
      order = Object.fromEntries(uniqueChannel.map((v, i) => ([v, i]))),
      stackedBarChartData = Object.values(dataToFormat.reduce((r, { channel, value, brand }) => { r[brand] ||= { data: Array(uniqueChannel.length).fill(null), label: brand }; r[brand].data[order[channel]] = value; return r; }, {}));
    return {
      stackedBarChartData,
      uniqueChannel
    }
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  navigateToOptionQuestionChart() {
    this.dataService.serviceData = this.currentFilterString
    this.router.navigate(['/snap-consumer-question', 'view-option-question-charts']);
  }
  //view snap data
  // viewPMSnapData(limit?: number, offset?: number, dateBefore?: string, dateAfter?: string) {
  //   Swal.showLoading();
  //   this.snapDashboardService.viewPMSnapData(limit, offset, dateBefore, dateAfter)
  //     .subscribe(res => {
  //       Swal.close();
  //       if (res && res.success) {
  //         this.snapData = res && res.data ? res.data.results : [];

  //         this.previousUrl = res && res.data ? res.data.previous : null;
  //         this.nextUrl = res && res.data ? res.data.next : null;

  //         // this.count = res && res.data ? res.data.count : 0;

  //         this.rerender();
  //       } else {
  //         this.snapData = [];
  //       }
  //     }, error => {
  //       Swal.close();
  //       Swal.fire({
  //         icon: 'error',
  //         title: error.error_message || 'Something went wrong in fetching snap data!',
  //         timer: 1500
  //       });
  //     });
  // }

  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.destroy();
  //     this.dtTrigger.next();
  //   });
  // }

}
