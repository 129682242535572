import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { FooterComponent } from './footer/footer.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SupportComponent } from './support/support.component';
import { TopTitleComponent } from './top-title/top-title.component';
import { TermsComponent } from './terms/terms.component';
import { FieldWorkCreateComponent } from './field-work-create/field-work-create.component';
import { FieldWorkComponent } from './field-work/field-work.component';
import { FieldWorkEditComponent } from './field-work-edit/field-work-edit.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ProfileInputsComponent } from './profile-inputs/profile-inputs.component';
import { FieldWorkViewComponent } from './field-work-view/field-work-view.component';
import { SingleFieldWorkComponent } from './single-field-work/single-field-work.component';
import { QuestionnairesComponent } from './questionnaires/questionnaires.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { SingleQuestionnaireComponent } from './single-questionnaire/single-questionnaire.component';
import { CreateQuestionnaireComponent } from './create-questionnaire/create-questionnaire.component';
import { RlTagInputModule } from 'angular2-tag-input';
import { OperationsComponent } from './operations/operations.component';
import { ChartsModule } from 'ng2-charts';
import { BarchartComponent } from './barchart/barchart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PolarChartComponent } from './polar-chart/polar-chart.component';
import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { TopDashboardComponent } from './top-dashboard/top-dashboard.component';
import { PortalUserComponent } from './portal-user/portal-user.component';
import { ChangepasswordComponents } from './change-password/changepassword.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthService, PortalService, LocalizeService } from './_services';
import { NotificationComponent } from './notification/notification.component';
import { CreatePortalUserComponent } from './portal-user/create-portal-user.component';

import { DataTablesModule } from "angular-datatables";
import { MyAccountComponent } from './my-account/my-account.component';
import { ChannelComponent } from './operations/channel/channel.component';
import { RetailerComponent } from './operations/retailer/retailer.component';
import { BranchComponent } from './operations/branch/branch.component';
import { QuestionnaireTypeComponent } from './operations/questionnaire-type/questionnaire-type.component';
import { BrandComponent } from './operations/brand/brand.component';
import { SkuComponent } from './operations/sku/sku.component';
import { CategoryComponent } from './operations/category/category.component';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { OptionQuestionChartComponent } from './dashboard/option-question-chart/option-question-chart.component';
import { SharedService } from './_shared/shared.service';
import { PriceMonitorComponent } from './snap-data/price-monitor/price-monitor.component';
import { OutOfStockComponent } from './snap-data/out-of-stock/out-of-stock.component';
import { DistributionCheckComponent } from './snap-data/distribution-check/distribution-check.component';
import { ConsumerCheckComponent } from './snap-data/consumer-check/consumer-check.component';
import { SnapFilterComponent } from './snap-dashboard/snap-filter/snap-filter.component';
import { SnapDashboardComponent } from './snap-dashboard/dashboard/dashboard.component';
import { SnapOptionQuestionChartComponent } from './snap-dashboard/dashboard/snap-option-question-chart/snap-option-question-chart.component';
import { SnapOperationsComponent } from './snap-operations/operations.component';
import { SnapChannelComponent } from './snap-operations/channel/channel.component';
import { SnapCategoryComponent } from './snap-operations/category/category.component';
import { SnapBranchComponent } from './snap-operations/branch/branch.component';
import { SnapBrandComponent } from './snap-operations/brand/brand.component';
import { SnapSkuComponent } from './snap-operations/sku/sku.component';
import { SnapRetailerComponent } from './snap-operations/retailer/retailer.component';
import { SnapCityComponent } from './snap-operations/city/city.component';
import { MerchOperationsComponent } from './merch-operations/merch-operations.component';
import { MerchCountryComponent } from './merch-operations/merch-country/merch-country.component';
import { MerchRegionsComponent } from './merch-operations/merch-regions/merch-regions.component';
import { MerchGovernComponent } from './merch-operations/merch-govern/merch-govern.component';
import { MerchStoreNameComponent } from './merch-operations/merch-store-name/merch-store-name.component';
import { MerchStoreTypeComponent } from './merch-operations/merch-store-type/merch-store-type.component';
import { MerchCategoryComponent } from './merch-operations/merch-category/merch-category.component';
import { MerchSubCategoryComponent } from './merch-operations/merch-sub-category/merch-sub-category.component';
import { MerchSkuComponent } from './merch-operations/merch-sku/merch-sku.component';
import { MerchAgentsComponent } from './merch-operations/merch-agents/merch-agents.component';
import { MerchAuditsComponent } from './merch-operations/merch-audits/merch-audits.component';
import { MerchBranchNameComponent } from './merch-operations/merch-branch-name/merch-branch-name.component';
import { MerchBranchNoComponent } from './merch-operations/merch-branch-no/merch-branch-no.component';
import { DashboardMerchComponent } from './merch-dashboard/dashboard-merch/dashboard-merch.component';
import { MerchOptionQuestionChartComponent } from './merch-dashboard/dashboard-merch/merch-option-question-chart/merch-option-question-chart.component';
import { MerchFilterComponent } from './merch-dashboard/merch-filter/merch-filter.component';
import { MerchCityComponent } from './merch-operations/merch-city/merch-city.component';
import { MerchBrandComponent } from './merch-operations/merch-brand/merch-brand.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetpasswordComponent,
    ChangepasswordComponents,
    DashboardComponent,
    LeftSideBarComponent,
    FooterComponent,
    TopBarComponent,
    SupportComponent,
    TopTitleComponent,
    TermsComponent,
    FieldWorkCreateComponent,
    FieldWorkComponent,
    FieldWorkEditComponent,
    CreateProfileComponent,
    EditProfileComponent,
    ProfileInputsComponent,
    FieldWorkViewComponent,
    SingleFieldWorkComponent,
    QuestionnairesComponent,
    QuestionnaireComponent,
    SingleQuestionnaireComponent,
    CreateQuestionnaireComponent,
    OperationsComponent,
    BarchartComponent,
    PieChartComponent,
    DonutChartComponent,
    LineChartComponent,
    PolarChartComponent,
    RadarChartComponent,
    TopDashboardComponent,
    PortalUserComponent,
    NotificationComponent,
    CreatePortalUserComponent,
    MyAccountComponent,
    ChannelComponent,
    RetailerComponent,
    BranchComponent,
    QuestionnaireTypeComponent,
    BrandComponent,
    SkuComponent,
    CategoryComponent,
    CreateQuestionComponent,
    OptionQuestionChartComponent,
    PriceMonitorComponent,
    OutOfStockComponent,
    DistributionCheckComponent,
    ConsumerCheckComponent,
    SnapFilterComponent,
    SnapDashboardComponent,
    SnapOptionQuestionChartComponent,
    SnapOperationsComponent,
    SnapChannelComponent,
    SnapCategoryComponent,
    SnapBranchComponent,
    SnapBrandComponent,
    SnapSkuComponent,
    SnapRetailerComponent,
    SnapCityComponent,
    MerchOperationsComponent,
    MerchCountryComponent,
    MerchRegionsComponent,
    MerchGovernComponent,
    MerchStoreNameComponent,
    MerchStoreTypeComponent,
    MerchCategoryComponent,
    MerchSubCategoryComponent,
    MerchSkuComponent,
    MerchAgentsComponent,
    MerchAuditsComponent,
    MerchBranchNameComponent,
    MerchBranchNoComponent,
    DashboardMerchComponent, 
    MerchOptionQuestionChartComponent,
    MerchFilterComponent,
    MerchBrandComponent,
    MerchCityComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    AppRoutingModule,
    FontAwesomeModule,
    RlTagInputModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    NoopAnimationsModule
  ],
  providers: [
    PortalService,
    AuthService,
    LocalizeService,
    { provide: HTTP_INTERCEPTORS, useClass: SharedService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
