<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">



    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Add Data to your Database'"
              [subTitle]="'You can add data to the portal from here'"></app-top-title>
          </div>
          <div class="bg-white">

            <form class="" role="form" [formGroup]="qtForm" (ngSubmit)="addQT()">
              <div class="row p-4">

                <div class="col-md-5" style="width: 100%;">
                  <div class="form-group">
                    <label class="col-form-label" for="qt">Questionnaire Type<span
                        style="color:#ff0000">*</span></label>
                    <input placeholder="Enter questionnaire type" type="text" class="form-control" id="qtType"
                      formControlName="name" [class.valid]="qtForm.get('name').valid && 
                      (qtForm.get('name').dirty || qtForm.get('name').touched)" [class.invalid]="qtForm.get('name').invalid && 
                      (qtForm.get('name').dirty || qtForm.get('name').touched)">
                    <div
                      *ngIf="qtForm.get('name').invalid && qtForm.get('name').errors && (qtForm.get('name').dirty || qtForm.get('name').touched)">
                      <small class="text-danger" *ngIf="qtForm.get('name').hasError('required')">
                        Questionnaire type is required. </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row px-5 pb-3 d-flex">
                <div class="form-group">
                  <button type="submit" class="btn btn-success px-5 mr-2">Add</button>
                  <!-- <button *ngIf="isUpdateMode" type="submit" class="btn btn-success px-5 mr-2">Update</button> -->
                  <button (click)="toggleUpdateMode()" type="button" class="btn btn-danger px-5">Cancel</button>
                </div>
              </div>

            </form>
            <!-- end row -->
          </div>


          <div class="mt-3 p-3 bg-white mb-5">
            <table  class="custom-table"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Type Name</th>
                  <!-- <th>Edit</th>-->
                  <th>Delete</th> 
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let qt of qts">
                  <td>{{ qt.id }}</td>
                  <td>{{ qt.name }}</td>
                  <!-- <td>
                    <a [routerLink]="" (click)="fetchQT(qt)" style="color:'blue';">Edit</a>
                  </td>-->
                  <td>
                    <a [routerLink]="" (click)="removeQT(qt.id)" style="color:'red';"> Delete </a>
                  </td> 
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->

</body>

</html>