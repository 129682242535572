<!DOCTYPE html>
<html lang="en">

<body>

    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">

        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'My Account'"></app-top-bar>
        <!-- end Topbar -->

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div>
                        <app-top-title [title]="'My Account'" [subTitle]="'View and edit your account details'">
                        </app-top-title>
                    </div>
                    <div class="p-5 card shadow" style="background-color: white;">

                        <div class="row">
                            <div class="col-md-5 mr-5">
                                <img [src]="profileImage" onerror="this.src='../../assets/images/user_icon.jpg';"
                                    alt="Profile Image" class="rounded img-fluid mx-auto d-block" height="250px"
                                    width="300px" />

                                <hr>
                                <br>
                                <a (click)="changeProfilePicture(userId)" type="button"
                                    class="btn btn-primary shadow btn-md btn-block">Change Profile Picture</a>

                                <a (click)="changePassword()" type="button"
                                    class="btn btn-secondary shadow btn-md btn-block">Change Password</a>
                            </div>

                            <div class="col-md-6 d-flex-column justify-content-center"
                                style="border-left: 1px dashed #333;">
                                <form (ngSubmit)="updateProfileFields(userId)" [formGroup]="profileDetails">

                                    <div class="form-group">
                                        <label for="inputPassword" class="col-form-label text-center">Fullname:
                                        </label>
                                        <div class="">
                                            <input class="form-control" formControlName="fullname"
                                                [class.valid]="profileDetails.get('fullname').valid && 
                                                                    (profileDetails.get('fullname').dirty || profileDetails.get('fullname').touched)"
                                                [class.invalid]="profileDetails.get('fullname').invalid && 
                                                                    (profileDetails.get('fullname').dirty || profileDetails.get('fullname').touched)">
                                        </div>
                                        <div
                                            *ngIf="profileDetails.get('fullname').invalid && 
                                                                   profileDetails.get('fullname').errors && 
                                                                   (profileDetails.get('fullname').dirty || profileDetails.get('fullname').touched)">
                                            <small class="text-danger"
                                                *ngIf="profileDetails.get('fullname').hasError('required')">
                                                Full Name is required. </small>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputPassword" class="col-form-label text-center">Email:
                                        </label>
                                        <div class="">
                                            <input class="form-control" formControlName="email"
                                                [class.valid]="profileDetails.get('email').valid && 
                                                                    (profileDetails.get('email').dirty || profileDetails.get('email').touched)"
                                                [class.invalid]="profileDetails.get('email').invalid && 
                                                                    (profileDetails.get('email').dirty || profileDetails.get('email').touched)">
                                        </div>
                                        <div
                                            *ngIf="profileDetails.get('email').invalid && 
                                                                   profileDetails.get('email').errors && 
                                                                   (profileDetails.get('email').dirty || profileDetails.get('email').touched)">
                                            <small class="text-danger"
                                                *ngIf="profileDetails.get('email').hasError('required')">
                                                Email is required. </small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputPassword" class="col-form-label text-center">Nationality:
                                        </label>
                                        <div class="">
                                            <!-- <select name="nationality" id="selNationality" formControlName="nationality"
                                                class="form-control" [(ngModel)]="selectedCountry"
                                                [class.valid]="profileDetails.get('email').valid && 
                                                                    (profileDetails.get('password').dirty || profileDetails.get('password').touched)"
                                                [class.invalid]="profileDetails.get('password').invalid && 
                                                                    (profileDetails.get('password').dirty || profileDetails.get('password').touched)">
                                                <option *ngFor="let country of countries" [ngValue]="country.id">
                                                    {{country.name}}</option>
                                            </select> -->
                                            <ng-multiselect-dropdown name="nationality" id="selNationality"
                                                [placeholder]="'Select Country'" [data]="countries"
                                                [settings]="dropdownSettings"
                                                [formControl]="profileDetails.controls['nationality']"
                                                [class.valid]="profileDetails.get('nationality').valid && 
                                                                (profileDetails.get('nationality').dirty || profileDetails.get('nationality').touched)"
                                                [class.invalid]="profileDetails.get('nationality').invalid && 
                                                                (profileDetails.get('nationality').dirty || profileDetails.get('nationality').touched)">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div
                                            *ngIf="profileDetails.get('nationality').invalid && 
                                                                   profileDetails.get('nationality').errors && 
                                                                   (profileDetails.get('nationality').dirty || profileDetails.get('nationality').touched)">
                                            <small class="text-danger"
                                                *ngIf="profileDetails.get('nationality').hasError('required')">
                                                Nationality is required. </small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputPassword" class="col-form-label text-center">Contact
                                            Number:
                                        </label>
                                        <div class="">
                                            <input class="form-control" formControlName="contact_number"
                                                [class.valid]="profileDetails.get('contact_number').valid && 
                                                                    (profileDetails.get('contact_number').dirty || profileDetails.get('contact_number').touched)"
                                                [class.invalid]="profileDetails.get('contact_number').invalid && 
                                                                    (profileDetails.get('contact_number').dirty || profileDetails.get('contact_number').touched)">
                                        </div>
                                        <div
                                            *ngIf="profileDetails.get('contact_number').invalid && 
                                                                   profileDetails.get('contact_number').errors && 
                                                                   (profileDetails.get('contact_number').dirty || profileDetails.get('contact_number').touched)">
                                            <small class="text-danger"
                                                *ngIf="profileDetails.get('contact_number').hasError('required')">
                                                Contact number is required. </small>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputPassword" class="col-form-label text-center">Date of Birth:
                                        </label>
                                        <div class="">
                                            <input class="form-control" type="date" formControlName="date_of_birth"
                                                [class.valid]="profileDetails.get('date_of_birth').valid && 
                                                                    (profileDetails.get('date_of_birth').dirty || profileDetails.get('date_of_birth').touched)"
                                                [class.invalid]="profileDetails.get('date_of_birth').invalid && 
                                                                    (profileDetails.get('date_of_birth').dirty || profileDetails.get('date_of_birth').touched)">
                                        </div>
                                        <div
                                            *ngIf="profileDetails.get('date_of_birth').invalid && 
                                                                   profileDetails.get('date_of_birth').errors && 
                                                                   (profileDetails.get('date_of_birth').dirty || profileDetails.get('date_of_birth').touched)">
                                            <small class="text-danger"
                                                *ngIf="profileDetails.get('date_of_birth').hasError('required')">
                                                Date of birth is required. </small>
                                            <small class="text-danger"
                                                *ngIf="profileDetails.get('date_of_birth').hasError('serverError')">
                                                Date of birth is required. </small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputPassword" class="col-form-label text-center">Position:
                                        </label>
                                        <div class="">
                                            <input class="form-control" formControlName="position"
                                                [class.valid]="profileDetails.get('position').valid && 
                                                                    (profileDetails.get('position').dirty || profileDetails.get('position').touched)"
                                                [class.invalid]="profileDetails.get('position').invalid && 
                                                                    (profileDetails.get('position').dirty || profileDetails.get('position').touched)">
                                        </div>
                                        <div
                                            *ngIf="profileDetails.get('position').invalid && 
                                                                   profileDetails.get('position').errors && 
                                                                   (profileDetails.get('position').dirty || profileDetails.get('position').touched)">
                                            <small class="text-danger"
                                                *ngIf="profileDetails.get('position').hasError('required')">
                                                Position is required. </small>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="!isResearcher">
                                        <label for="inputPassword" class="col-form-label text-center">Role: </label>
                                        <div class="">
                                            <select name="role" formControlName="role" class="form-control" id="role"
                                                [(ngModel)]="selectedRole"
                                                [class.valid]="profileDetails.get('role').valid && 
                                                (profileDetails.get('role').dirty || profileDetails.get('role').touched)"
                                                [class.invalid]="profileDetails.get('role').invalid && 
                                                (profileDetails.get('role').dirty || profileDetails.get('role').touched)">
                                                <option *ngFor="let role of roles" [ngValue]="role.id">
                                                    {{role.name}}</option>
                                            </select>
                                            <div
                                                *ngIf="profileDetails.get('role').invalid && 
                                                                   profileDetails.get('role').errors && 
                                                                   (profileDetails.get('role').dirty || profileDetails.get('role').touched)">
                                                <small class="text-danger"
                                                    *ngIf="profileDetails.get('role').hasError('required')">
                                                    Role is required. </small>
                                            </div>
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary shadow btn-md">
                                            Update Profile Details</button>
                                    </div>

                                </form>
                            </div>
                        </div>



                    </div>
                    <br /><br />
                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->


</body>

</html>